import { DoctorSelect } from 'components/doctor-select';
import React from 'react';
import { useForm } from 'react-hook-form';
import { RequestReviewLayout } from './request-review-layout';
import { RequestReviewStepTransitionProps } from './types';

type DoctorAssignStepProps = RequestReviewStepTransitionProps & {
  doctorId?: string;
  setDoctorId: (id: string) => void;
};

export const DoctorAssignStep: React.FC<DoctorAssignStepProps> = ({
  previousStep,
  nextStep,
  doctorId,
  setDoctorId,
}) => {
  const { control, handleSubmit, watch } = useForm<{
    doctorId: string;
  }>({ defaultValues: { doctorId } });

  const onSubmit = handleSubmit(({ doctorId }) => {
    setDoctorId(doctorId);
    nextStep();
  });

  return (
    <RequestReviewLayout
      title="Choose a practitioner to assign this consultation to"
      previous={previousStep}
      nextDisabled={!watch().doctorId}
      onSubmit={onSubmit}
    >
      <DoctorSelect name="doctorId" control={control} showAllDoctors={false} />
    </RequestReviewLayout>
  );
};
