import { config } from 'config';
import { SearchBar } from 'components/search-bar';
import { Input } from 'components/input';
import { useLazyQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Button } from 'components/button';
import React from 'react';
import { ErxMedicine } from 'graphql/types';
import { Dropdown } from 'components/dropdown';
import { getMimsProductQuery, searchMimsQuery } from './constants';
import { RxMedicationFields } from './medication-info-fields';
import { create_medication_manually_option } from './constants';

export const RxMedicationAccordionContents = ({
  register,
  control,
  setValue,
  errors,
  medicationNumber,
  medication,
  onDelete,
  onSave,
  onCreate,
}: {
  register: ReturnType<typeof useForm>['register'];
  control: ReturnType<typeof useForm>['control'];
  setValue: ReturnType<typeof useForm>['setValue'];
  errors: ReturnType<typeof useForm>['errors'];
  medicationNumber: number;
  medication: ErxMedicine | undefined;
  onDelete: (medicationNumber: number) => void;
  onSave: (medicationNumber: number) => void;
  onCreate: (medicationNumber: number) => void;
}): React.ReactElement => {
  const isAustralian = config.country === 'Australia';
  const [manuallyAddProduct, setManuallyAddProduct] = React.useState(
    !isAustralian,
  );

  const [searchMims, { data }] = useLazyQuery(searchMimsQuery, {
    fetchPolicy: 'network-only',
  });
  const [getMimsProduct, { data: productData }] = useLazyQuery(
    getMimsProductQuery,
    {
      fetchPolicy: 'network-only',
    },
  );

  const runMimsSearch = (name: string | null): void => {
    if (!name) {
      return;
    }
    searchMims({ variables: { name } });

    const fieldsToClear = [
      'productName',
      'tradeName',
      'itemForm',
      'itemStrength',
      'genericName',
      'routeAdministration',
    ];
    fieldsToClear.forEach((field) => {
      setValue(`erxMedicines[${medicationNumber}].${field}`, '', {
        shouldValidate: true,
      });
    });
  };

  const onMimsProductSelect = (mimsOption: { value?: string }): void => {
    if (mimsOption == undefined) {
      return;
    }
    if (mimsOption.value == create_medication_manually_option.value) {
      setManuallyAddProduct(true);
      return;
    }
    setManuallyAddProduct(false);
    getMimsProduct({ variables: { mimsProductId: mimsOption.value } });
  };

  if (medication?.genericName != undefined) {
    // We have data, so show it
    return (
      <div className="px-4 pb-6 space-y-5">
        <div className="hidden">
          <Input
            label="Mims ID"
            name={`erxMedicines[${medicationNumber}].mimsId`}
            ref={register()}
            defaultValue={medication.mimsId || ''}
          />
        </div>
        <RxMedicationFields
          errors={errors}
          register={register}
          medicationNumber={medicationNumber}
          defaultValues={{
            productName: medication.productName || '',
            tradeName: medication.tradeName || '',
            genericName: medication.genericName,
            quantity: medication.quantity || '',
            instructions: medication.instructions || '',
            itemStrength: medication.itemStrength,
            itemForm: medication.itemForm || '',
            routeAdministration: medication.routeAdministration || '',
            routeAdministrationOptions: [medication.routeAdministration],
            mimsId: medication.mimsId || '',
          }}
          control={control}
          readOnly={!!medication.mimsId}
        />
        <div className="flex items-center justify-between">
          <div>
            <Button
              fullWidth
              type="button"
              color="primary"
              onClick={(): void => {
                onSave(medicationNumber);
              }}
            >
              Save
            </Button>
          </div>
          <div>
            <Button
              fullWidth
              type="button"
              color="danger"
              variant="outline"
              onClick={(): void => {
                onDelete(medicationNumber);
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    // No existing medication found, so show the search bar
    return (
      <div className="px-4 pb-6 space-y-5">
        {isAustralian && (
          <div className="border-gray-300 bg-gray-100 rounded-md border-opacity-100 p-3 border-solid border space-y-5">
            <div>
              <SearchBar
                label="MIMS Search"
                name={`erxMedicines[${medicationNumber}].mimsSearch`}
                ref={register()}
                onSearch={runMimsSearch}
              />
            </div>
            {data?.mimsProductSearch && (
              <Dropdown
                label="Results:"
                name={`erxMedicines[${medicationNumber}].mimsId`}
                options={[
                  ...(data?.mimsProductSearch.map(
                    (product: { productName: string; productId: string }) => ({
                      label: product.productName,
                      value: product.productId,
                    }),
                  ) ?? []),
                  create_medication_manually_option,
                ]}
                control={control}
                handleSingleSelect={(option): void => {
                  onMimsProductSelect(option);
                }}
              />
            )}
          </div>
        )}
        {!manuallyAddProduct && productData?.getMimsProduct && (
          <RxMedicationFields
            errors={errors}
            register={register}
            medicationNumber={medicationNumber}
            defaultValues={{
              productName: productData?.getMimsProduct.productName || '',
              tradeName: productData?.getMimsProduct.tradeName || '',
              genericName: productData?.getMimsProduct.genericName || '',
              quantity: '',
              instructions: '',
              itemStrength: productData?.getMimsProduct.itemStrength || '',
              itemForm: productData?.getMimsProduct.itemForm || '',
              routeAdministration: '',
              routeAdministrationOptions:
                productData?.getMimsProduct.routeAdministration || [],
              mimsId: productData?.getMimsProduct.productId || '',
            }}
            isExtemp={productData?.getMimsProduct.isExtemp}
            control={control}
          />
        )}
        {manuallyAddProduct && (
          <RxMedicationFields
            errors={errors}
            register={register}
            medicationNumber={medicationNumber}
            readOnly={false}
            control={control}
            manuallyAddProduct={true}
          />
        )}
        <div className="flex items-center justify-between">
          <div>
            <Button
              fullWidth
              type="button"
              color="primary"
              onClick={(): void => {
                onCreate(medicationNumber);
              }}
            >
              Create
            </Button>
          </div>
          <div>
            <Button
              fullWidth
              type="button"
              color="danger"
              variant="outline"
              onClick={(): void => {
                onDelete(medicationNumber);
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    );
  }
};
