import React from 'react';
import { ApolloQueryResult, gql, useMutation, useQuery } from '@apollo/client';
import {
  ProblemType,
  Product,
  ProductOrderByInput,
  ProductType,
  ProductWhereInput,
  Variant,
} from 'graphql/types';
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useOrderBy,
  usePageIndex,
  useSelectablePaginatingSortingTable,
} from 'components/table';
import { Loading } from 'components/loading';
import { Tag } from 'components/tag';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { Input } from 'components/input';
import { Dropdown } from 'components/dropdown';
import { useHistory } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { useForm } from 'react-hook-form';
import { Colors, getProblemTypeColor, getProblemTypeEmoji } from 'utils/misc';
import { routes } from 'utils/routes';
import { problemTypeOptions } from 'utils/dropdown-options';
import { ProductsFilter, useBuildProductsQueryFilter } from './product-filter';
import { useNotifications } from 'notifications';
import { requiredValidation } from 'utils/form-validation';
import { ProductTypeSelect } from 'components/product-type-select';
import { DeliveryBufferSelect } from 'components/delivery-buffer-select';
import { config } from 'config';

const TABLE_PAGE_SIZE = 30;

const productsQuery = gql`
  query ProductsAndCount(
    $orderBy: [ProductOrderByInput!]!
    $where: ProductWhereInput
    $pageSize: Int
    $skip: Int
  ) {
    products(orderBy: $orderBy, where: $where, take: $pageSize, skip: $skip) {
      id
      createdAt
      name
      slug
      productType
      problemTypes
      discontinuedAt
      plan {
        id
        name
      }
      variants {
        id
        name
        price
        public
        isRefill
        inventory {
          id
          sku
        }
      }
    }
    productsCount(where: $where)
  }
`;

type ProductRowData = {
  id: string;
  productType: ProductType;
  name: string;
  sku: string;
  calculatedProblemTypes?: ProblemType[];
  plan: string;
  visibility:
    | 'public'
    | 'hidden'
    | 'public (discontinued)'
    | 'hidden (discontinued)';
};

const Products = (): React.ReactElement => {
  const [showModal, setShowModal] = React.useState(false);
  const history = useHistory();
  const orderBy = useOrderBy({ defaultValue: 'createdAt_desc' });
  const pageIndex = usePageIndex();
  const activeFilter = useBuildProductsQueryFilter();
  const { data, loading, refetch } = useQuery<
    {
      products: Product[];
      productsCount: number;
    },
    {
      orderBy: ProductOrderByInput[];
      where: ProductWhereInput;
      pageSize: number;
      skip: number;
    }
  >(productsQuery, {
    variables: {
      orderBy: orderBy,
      where: activeFilter,
      pageSize: TABLE_PAGE_SIZE,
      skip: pageIndex * TABLE_PAGE_SIZE,
    },
  });

  const productsRowData = React.useMemo(
    () => processProductsData(data?.products),
    [data],
  );

  const totalProductsCount = data?.productsCount ?? 0;

  const pageNumber = Math.ceil((totalProductsCount ?? 1) / TABLE_PAGE_SIZE);

  const tableInstance = useSelectablePaginatingSortingTable({
    columns,
    data: productsRowData,
    pageNumber,
    orderBy,
    pageIndex,
  });

  return (
    <>
      <div>
        <div className="flex justify-between space-x-5">
          <div className="w-1/2">
            <ProductsFilter />
          </div>
          <div className="w-auto">
            <Button
              fullWidth
              variant="outline"
              onClick={(): void => setShowModal(true)}
            >
              Create new
            </Button>
          </div>
        </div>
        <Table tableInstance={tableInstance}>
          <>
            <TableHead />
            <TableBody>
              <>
                {tableInstance.page.map((row) => {
                  tableInstance.prepareRow(row);
                  return (
                    <TableRow row={row} key={row.id}>
                      <>
                        {row.cells.map((cell) => (
                          <TableCell
                            key={`${cell.row.original.id}-${cell.column.id}`}
                            cell={cell}
                            onClick={(): void => {
                              if (cell.column.id === 'selection') {
                                return;
                              }
                              history.push(
                                `${routes.products}/${cell.row.original.id}`,
                              );
                            }}
                          />
                        ))}
                      </>
                    </TableRow>
                  );
                })}
              </>
            </TableBody>
          </>
        </Table>
        {!totalProductsCount && !loading && (
          <div className="text-center font-medium pt-8">No products found</div>
        )}
        {loading && (
          <div className="flex justify-center my-8">
            <Loading />
          </div>
        )}
        <Pagination total={totalProductsCount} tableInstance={tableInstance} />
      </div>
      <CreateNewProductModal
        showModal={showModal}
        setShowModal={setShowModal}
        refetchProductsData={refetch}
      />
    </>
  );
};

const createProductMutation = gql`
  mutation CreateProduct(
    $name: String!
    $productType: ProductType!
    $problemTypes: [ProblemType!]!
    $deliveryBufferInDays: Int
  ) {
    createOneProduct(
      data: {
        name: $name
        productType: $productType
        problemTypes: { set: $problemTypes }
        deliveryBufferInDays: $deliveryBufferInDays
      }
    ) {
      id
    }
  }
`;

const CreateNewProductModal = ({
  showModal,
  setShowModal,
  refetchProductsData,
}: {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  refetchProductsData(): Promise<ApolloQueryResult<{ products: Product[] }>>;
}): React.ReactElement => {
  const showNotification = useNotifications();
  const history = useHistory();

  const { register, handleSubmit, watch, control, errors } = useForm<{
    name: string;
    problemTypes: ProblemType[];
    productType: ProductType;
    deliveryBufferInDays: string;
  }>({
    mode: 'onChange',
  });

  const { productType } = watch();

  const allowDeliveryBuffer =
    config.enableDeliveryBufferInProduct && productType === 'RX';

  const [createProduct, { loading }] = useMutation<{
    createOneProduct: Product;
  }>(createProductMutation, {
    onCompleted: ({ createOneProduct }) => {
      showNotification({
        type: 'success',
        message: 'Product created successfully',
      });
      history.push(`${routes.products}/${createOneProduct.id}`);
      refetchProductsData();
    },
    onError: () =>
      showNotification({
        type: 'error',
        message: 'Unable to create product',
      }),
  });

  const submit = handleSubmit(
    async ({ name, problemTypes, productType, deliveryBufferInDays }) => {
      await createProduct({
        variables: {
          name,
          problemTypes: productType === 'ACCESSORY' ? [] : problemTypes,
          productType,
          ...(allowDeliveryBuffer
            ? {
                deliveryBufferInDays: deliveryBufferInDays
                  ? parseInt(deliveryBufferInDays, 10)
                  : null,
              }
            : {}),
        },
      });
    },
  );
  return (
    <Modal
      show={showModal}
      onClose={(): void => setShowModal(false)}
      isAutoOverflow={false}
    >
      <form onSubmit={submit} className="bg-white p-5 rounded">
        <h1 className="heading-md mb-5">Create new product</h1>
        <div className="mb-5 ">
          <Input
            name="name"
            label="Name"
            ref={register(requiredValidation('Product name'))}
            errorMessage={errors?.name?.message}
          />
        </div>
        <div className="flex space-x-4 mb-8">
          <div className="flex-1">
            <ProductTypeSelect
              label="Product Type"
              name="productType"
              control={control}
              rules={requiredValidation('Product type')}
              errorMessage={errors?.productType?.message}
            />
          </div>
          <div className="flex-1">
            <Dropdown
              label="Problem types"
              name="problemTypes"
              options={problemTypeOptions}
              control={control}
              disabled={productType === 'ACCESSORY'}
              rules={
                productType === 'ACCESSORY'
                  ? undefined
                  : requiredValidation('Problem types')
              }
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              errorMessage={(errors?.problemTypes as any)?.message}
              isMulti
            />
          </div>
          {allowDeliveryBuffer && (
            <div className="flex-1">
              <DeliveryBufferSelect
                label="Refill Delivery Buffer (days)"
                name="deliveryBufferInDays"
                control={control}
                rules={requiredValidation('Delivery buffer')}
                errorMessage={errors?.deliveryBufferInDays?.message}
              />
            </div>
          )}
        </div>
        <Button fullWidth type="submit" loading={loading}>
          Create new
        </Button>
      </form>
    </Modal>
  );
};

const ProblemTypesCell = (
  cell: CellProps<ProductRowData, ProductRowData['calculatedProblemTypes']>,
): React.ReactElement => (
  <div className="mt-1">
    {cell.value?.map((problemType, i) => (
      <div key={`${i}_${problemType}`} className="inline-block mr-1 mb-1">
        <Tag size="small" color={getProblemTypeColor(problemType)}>
          <span role="img" className="pr-1">
            {problemType && getProblemTypeEmoji(problemType)}
          </span>
          {problemType?.replaceAll('_', ' ')}
        </Tag>
      </div>
    ))}
  </div>
);

const PlanCell = (
  cell: CellProps<ProductRowData, ProductRowData['plan']>,
): React.ReactElement => <div>{cell.value || '-'}</div>;

const VisibilityCell = (
  cell: CellProps<ProductRowData, ProductRowData['visibility']>,
): React.ReactElement => (
  <Tag color={getVisibilityCellColor(cell.value)} size="small">
    {cell.value.toUpperCase()}
  </Tag>
);

const getVisibilityCellColor = (value: string): Colors => {
  if (value.includes('discontinued')) {
    return 'orange';
  }
  return value === 'hidden' ? 'gray' : 'green';
};

const columns: Column<ProductRowData>[] = [
  {
    Header: 'Product Type',
    accessor: 'productType',
    disableSortBy: true,
    Cell: (c) => <div>{c.value}</div>,
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: (c) => <div>{c.value}</div>,
  },
  {
    Header: 'SKU',
    accessor: 'sku',
    disableSortBy: true,
    Cell: (c) => <div>{c.value}</div>,
  },
  {
    Header: 'Problem types',
    accessor: 'calculatedProblemTypes',
    disableSortBy: true,
    Cell: ProblemTypesCell,
  },
  {
    Header: 'Plan',
    accessor: 'plan',
    disableSortBy: true,
    Cell: PlanCell,
  },
  {
    Header: 'Visibility',
    accessor: 'visibility',
    disableSortBy: true,
    Cell: VisibilityCell,
  },
];

const processProductsData = (data: Product[] = []): ProductRowData[] =>
  data.map(
    (product): ProductRowData => ({
      id: product.id,
      productType: product.productType,
      name: product.name ?? '',
      sku: getVariant(product, false)?.inventory?.sku ?? '',
      calculatedProblemTypes: product.problemTypes,
      plan: product.plan?.name ?? '',
      visibility: getVisibility(product, false),
    }),
  );

const getVariant = (product: Product, isRefill: boolean): Variant | undefined =>
  product.variants?.find((v) => v.isRefill === isRefill);

const getVisibility = (
  product: Product,
  isRefill: boolean,
): ProductRowData['visibility'] => {
  const isPublic = getVariant(product, isRefill)?.public;
  if (product.discontinuedAt) {
    return isPublic ? 'public (discontinued)' : 'hidden (discontinued)';
  }
  return isPublic ? 'public' : 'hidden';
};

export default Products;
