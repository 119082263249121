import { Button } from 'components/button';
import React, { ReactElement } from 'react';

export const ObservationResponse = ({
  onClick,
}: {
  onClick: () => void;
}): ReactElement => {
  return (
    <div className="w-max py-2">
      <p className="pb-2">Photos are avaliable for your review.</p>
      <Button fullWidth variant="outline" onClick={onClick}>
        View patient photos
      </Button>
    </div>
  );
};
