import React, { ChangeEventHandler } from 'react';
import clsx from 'clsx';
import { Label } from 'components/label';
import { InputError } from 'components/input-error';
import { TooltipProps } from 'components/tooltip';

interface Props {
  name: string;
  placeholder: string;
  rows: number;
  disabled?: boolean;
  resize?: boolean;
  label?: string;
  tooltip?: TooltipProps;
  errorMessage?: string | JSX.Element;
  defaultValue?: string;
  hint?: string;
  description?: React.ReactNode;
  maxLength?: number;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
}

const BaseTextArea = (
  {
    name,
    placeholder,
    rows,
    disabled,
    resize,
    label,
    tooltip,
    errorMessage,
    defaultValue,
    hint,
    maxLength,
    description,
    onChange,
  }: Props,
  ref: React.Ref<HTMLTextAreaElement>,
): React.ReactElement => (
  <div className="flex flex-col flex-grow">
    {(label || description) && (
      <div className="mb-3 flex flex-col gap-1">
        {label && (
          <Label htmlFor={name} tooltip={tooltip}>
            {label}
          </Label>
        )}
        {description && <p className="text-sm text-gray-700">{description}</p>}
      </div>
    )}
    <div
      className={clsx(
        'border',
        'border-gray-300',
        'focus-within:ring ring-primary ring-opacity-50',
        'focus-within:border-primary',
        'rounded-md',
        'transition-shadow',
        'duration-200',
        'overflow-hidden',
        'flex',
        'h-full',
      )}
    >
      <textarea
        id={name}
        name={name}
        disabled={disabled}
        className={clsx(
          'focus:outline-none',
          'px-3',
          'py-2',
          'tracking-tight',
          'h-full',
          'w-full',
          'text-gray-900',
          { 'resize-none': !resize },
          { 'bg-gray-100': disabled },
          {
            'border border-red-500 focus:border-red-500 focus:ring ring-red-500 ring-opacity-50':
              errorMessage,
          },
        )}
        placeholder={placeholder}
        rows={rows}
        ref={ref}
        defaultValue={defaultValue}
        maxLength={maxLength}
        onChange={onChange}
      />
    </div>
    {hint && <p className="text-xs mt-2 text-gray-600">{hint}</p>}
    <InputError>{errorMessage}</InputError>
  </div>
);

export const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(
  BaseTextArea,
);
