import 'reactflow/dist/style.css';
import { FaSave, FaTrash } from 'react-icons/fa';
import { isEqual } from 'lodash';
import { ProductType, SequenceEditorSequenceNodeFragment } from 'graphql/types';
import { useEffect, useMemo, useState } from 'react';
import { SmartStepEdge } from '@tisoap/react-flow-smart-edge';
import {
  Background,
  Controls,
  Edge,
  MiniMap,
  Node,
  Panel,
  ReactFlow,
} from 'reactflow';
import { useNotifications } from 'notifications';
import { v4 } from 'uuid';
import { Node as SequenceNode, NodeData } from './node';
import type { Node as NodeType } from './node';
import { Button } from 'components/button';
import { Prompt } from 'react-router-dom';
import { usePrevious } from 'utils/use-previous';

type OnDirty = (() => unknown) | undefined;
type OnReset = (() => unknown) | undefined;
type Props = {
  nodes?: Array<NodeType>;
  variants?: Array<{
    id: string;
    productId: string;
    productType: ProductType;
    productName: string;
    name: string;
    sku: string;
    maximumDurationInDaysToNextVariantNode: number | null;
  }>;
  validTopics?: Array<string>;
} & (
  | {
      readOnly?: false;
      onSave: (nodes: Array<NodeType>) => unknown;
      /**
       * This function is called whenever the sequence editor moves from a clean state
       * to a dirty state.
       *
       * This function is not called if the sequence editor moves from a dirty state
       * to a dirty state.
       *
       * Please pass in a stable function reference (useCallback) across renders to avoid multiple calls
       * to the function.
       */
      onDirty?: OnDirty;
      /**
       * This function is called whenever the sequence editor moves from a dirty state
       * to a clean state.
       *
       * Please pass in a stable function reference (useCallback) across renders to avoid multiple calls
       * to the function.
       */
      onReset?: OnReset;
    }
  | { readOnly: true }
);

const nodeTypes = { sequenceNode: SequenceNode };
const edgeTypes = { smart: SmartStepEdge };
const minimapStyle = { height: 120 };

function validateNodesErrorMessage(nodes: Array<NodeType>) {
  const startNode = nodes[0];

  if (!startNode) {
    return 'Sequence must contain at least one node.';
  }

  if (startNode.nodeType === 'wait') {
    return 'Sequence must not start with a wait node.';
  }

  return undefined;
}

export function SequenceEditor(props: Props) {
  const [openEdits, setOpenEdits] = useState(() => new Set<string>());
  const [localNodes, setLocalNodes] = useState<Array<NodeType>>([]);
  const showNotification = useNotifications();

  useEffect(() => {
    setOpenEdits(new Set<string>());
    setLocalNodes((ln) => {
      // Used to ensure we persist local changes if they have occurred
      if (ln.length > 0) {
        return ln;
      }
      return initStructure(props.nodes);
    });
  }, [props.nodes]);

  const pendingChanges = useMemo(
    () => !isEqual(localNodes, initStructure(props.nodes)),
    [props.nodes, localNodes],
  );

  const dirty = pendingChanges || !!openEdits.size;
  const previousDirty = usePrevious(dirty);

  let onDirty: OnDirty = undefined;
  let onReset: OnReset = undefined;
  if (!props.readOnly) {
    onDirty = props.onDirty;
    onReset = props.onReset;
  }

  useEffect(() => {
    if (dirty && !previousDirty) {
      onDirty?.();
    }
    if (!dirty && previousDirty) {
      onReset?.();
    }
  }, [dirty, previousDirty, onDirty, onReset]);

  const [nodes, edges] = useMemo(() => {
    const ns: Node<NodeData>[] = [];
    const es: Edge[] = [];

    const finalNode = localNodes.at(-1);

    let x = 0;
    for (const [idx, node] of localNodes.entries()) {
      const loopSource = idx === localNodes.length - 1;
      const loopTargetable =
        !finalNode?.nextNodeId || node.id === finalNode?.nextNodeId;

      let controls;
      if (!props.readOnly) {
        let left: NodeType | undefined;
        if (idx > 0) {
          left = localNodes.at(idx - 1);
        }

        const defaultNewNode: SequenceNode = {
          id: v4(),
          nodeType: 'wait',
          intervalType: 'DAYS',
          count: 1,
        };

        controls = {
          insertLeft: () => {
            setLocalNodes((ln) => {
              const lnNew = ln.slice();
              if (left) {
                lnNew.splice(idx - 1, 1, {
                  ...left,
                  nextNodeId: defaultNewNode.id,
                });
              }
              lnNew.splice(idx, 0, {
                ...defaultNewNode,
                nextNodeId: node.id,
              });
              return lnNew;
            });
          },
          insertRight: () => {
            setLocalNodes((ln) => {
              const lnNew = ln.toSpliced(idx, 1, {
                ...node,
                nextNodeId: defaultNewNode.id,
              });
              lnNew.splice(idx + 1, 0, {
                ...defaultNewNode,
                nextNodeId: node.nextNodeId,
              });
              return lnNew;
            });
          },
          remove: () => {
            setOpenEdits((es) => {
              const e = new Set(es);
              e.delete(node.id);
              return e;
            });
            setLocalNodes((ln) => withRemovedNode(ln, node.id));
          },
          edit: () => {
            setOpenEdits((es) => new Set(es).add(node.id));
          },
          save: (updatedNode: SequenceNode) => {
            setLocalNodes((ln) =>
              ln.toSpliced(idx, 1, { ...node, ...updatedNode }),
            );
            setOpenEdits((es) => {
              const e = new Set(es);
              e.delete(node.id);
              return e;
            });
          },
        };
      }

      ns.push({
        id: node.id,
        selectable: true,
        // Ideally we'd set draggable: false here to ensure key presses
        // don't move the node.
        // However doing this causes dropdowns to stop working.
        position: { x, y: 0 },
        data: {
          node,
          editing: openEdits.has(node.id),
          controls,
          loopSource,
          loopTargetable,
          variants: props.variants,
          validTopics: props.validTopics,
        },
        type: 'sequenceNode',
      });

      if (node.nextNodeId) {
        es.push({
          id: `${node.id}-${node.nextNodeId}`,
          type: 'smart',
          animated: true,
          source: node.id,
          target: node.nextNodeId,
          deletable: loopSource, // Only the loop edge can be deleted.
          targetHandle: loopSource ? 'top-target' : undefined,
          sourceHandle: loopSource ? 'top-source' : 'right-source',
          style: { strokeWidth: 3 },
        });
      }
      x += 428;
    }

    return [ns, es];
  }, [
    openEdits,
    localNodes,
    props.readOnly,
    props.variants,
    props.validTopics,
  ]);

  return (
    <ReactFlow
      fitView
      attributionPosition="top-right"
      onConnect={(e) => {
        setLocalNodes((ln) => {
          const last = ln.at(-1);
          if (!last) {
            return ln;
          }
          return ln.toSpliced(-1, 1, {
            ...last,
            nextNodeId: e.target,
          });
        });
      }}
      onNodesDelete={(ns) => {
        setLocalNodes((ln) => {
          const n = ns.at(0);
          return n ? withRemovedNode(ln, n.id) : ln;
        });
      }}
      onEdgesDelete={() => {
        // We only support deleting the last edge (loop edge), so we "know"
        // that this will be that one and skip checking the edge ID.
        setLocalNodes((ln) => {
          const last = ln.at(-1);
          if (!last) {
            return ln;
          }

          return ln.toSpliced(-1, 1, {
            ...last,
            nextNodeId: undefined,
          });
        });
      }}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      edges={edges}
      nodes={nodes}
    >
      <MiniMap style={minimapStyle} zoomable pannable />
      <Controls showInteractive={false} />
      <Background color="#aaa" gap={16} />
      <Panel position="top-right">
        {pendingChanges && !props.readOnly && (
          <div className="flex gap-2">
            <Button
              color="success"
              size="small"
              onClick={() => {
                const message = validateNodesErrorMessage(localNodes);
                if (message) {
                  showNotification({
                    type: 'error',
                    message,
                  });
                } else {
                  props.onSave(localNodes);
                }
              }}
              disabled={openEdits.size > 0}
              hoverText={
                openEdits.size > 0
                  ? 'Disabled whilst there are pending node edits'
                  : undefined
              }
            >
              <div className="flex gap-1 items-center">
                <FaSave /> Save
              </div>
            </Button>
            <Button
              color="danger"
              size="small"
              onClick={() => {
                setLocalNodes(initStructure(props.nodes ?? []));
                setOpenEdits(new Set<string>());
              }}
            >
              <div className="flex gap-1 items-center">
                <FaTrash /> Discard
              </div>
            </Button>
          </div>
        )}
      </Panel>
      <Prompt
        when={dirty && !props.readOnly}
        message="Are you sure you want to navigate away with open edits?"
      />
    </ReactFlow>
  );
}

/** Returns a copy of the provided node array without the specified node. */
function withRemovedNode(nodes: NodeType[], id: string) {
  const ns = nodes.slice();

  const idx = ns.findIndex((n) => n.id === id);
  const last = ns.at(-1);
  const right = ns.at(idx + 1);

  let left;
  if (idx > 0) {
    left = ns.at(idx - 1);
  }

  if (left) {
    ns.splice(idx - 1, 1, {
      ...left,
      nextNodeId: right?.id ?? undefined,
    });
  }

  // Prevents last node's nextNodeId being set to a
  // now non-existent node.
  if (last?.nextNodeId === id) {
    ns.splice(-1, 1, {
      ...last,
      nextNodeId: undefined,
    });
  }

  ns.splice(idx, 1);

  return ns;
}

function initStructure(nodes?: Array<NodeType>): Array<NodeType> {
  const ns = nodes?.slice() || [];

  // Fill with initial seed node. We don't support empty sequences so we can
  // assume that this case will only appear in the sequence create screen or
  // when editing a draft sequence.
  if (ns.length === 0) {
    ns.push({ nodeType: 'wait', id: v4(), intervalType: 'DAYS', count: 1 });
  }

  return ns;
}

/** Maps the nodes returned from the graphql schema into the format
 * required by the sequence editor component.
 */
export function queryNodesToSequenceEditorNodes(
  nodes: SequenceEditorSequenceNodeFragment[],
): Array<NodeType> {
  const parsed: Array<NodeType> = [];
  for (const n of nodes) {
    switch (n.__typename) {
      case 'WaitSequenceNode':
        parsed.push({
          nodeType: 'wait',
          id: n.id,
          nextNodeId: n.nextNodeId,
          count: n.count,
          intervalType: n.intervalType,
        });
        break;
      case 'EventSequenceNode':
        parsed.push({
          nodeType: 'event',
          id: n.id,
          nextNodeId: n.nextNodeId,
          name: n.name,
        });
        break;
      case 'VariantSequenceNode':
        parsed.push({
          nodeType: 'variant',
          id: n.id,
          nextNodeId: n.nextNodeId,
          price: n.price,
          quantity: n.quantity,
          variantId: n.variant?.id || '',
          maximumDurationInDaysToNextVariantNode:
            n.maximumDurationInDaysToNextVariantNode ?? null,
        });
        break;
      case 'InternalTriggerSequenceNode':
        parsed.push({
          nodeType: 'internal_trigger',
          id: n.id,
          nextNodeId: n.nextNodeId,
          triggerName: n.triggerName,
          topic: n.topic,
          label: n.label,
        });
        break;
      case 'SmsSequenceNode':
        break;
      default:
        throw new Error(`Unknown node type: ${n.__typename}`);
    }
  }

  return parsed;
}
