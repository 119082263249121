import {
  Maybe,
  QuizFlagLevel,
  QuizQuestionType,
  QuizResponse,
  QuizResponseResponseAnswer,
} from 'graphql/types';
import clsx from 'clsx';
import { ImageResponse } from 'components/image-response';
import { FaCheckSquare, FaRegSquare } from 'react-icons/fa';
import { formatDob } from 'utils/misc';

export const QuizResponseFormatter = ({
  quizResponse,
}: {
  quizResponse: QuizResponse;
}): JSX.Element => {
  const { question, response } = quizResponse;

  return question?.options?.length ? (
    <ResponseMultipleOption
      answers={response?.answers?.map((it) => it.value) ?? []}
      flag={response?.flag?.level}
      options={question?.options.map((it) => it.value) ?? []}
    />
  ) : (
    <ResponseSingleOption
      answer={response?.answers?.[0]}
      flag={response?.flag?.level}
      type={question?.type}
    />
  );
};

const ResponseSingleOption = ({
  answer,
  type,
  flag,
}: {
  answer?: Maybe<QuizResponseResponseAnswer>;
  type?: QuizQuestionType | null;
  flag?: QuizFlagLevel;
}): React.ReactElement => {
  const answerValue = answer?.value || '';
  let content: React.ReactChild = answerValue;

  if (type === 'IMAGEUPLOAD') {
    content = <ImageResponse imageUrl={answer?.imageUrl} />;
  } else if (type === 'DATE') {
    const date = answer ? formatDob(answerValue) : '';
    content = <div className="text-gray-800">{date}</div>;
  }

  return <AnswerFormatter flag={flag}>{content}</AnswerFormatter>;
};

export const AnswerFormatter = ({
  children,
  flag,
}: {
  children: React.ReactChild | React.ReactChild[];
  flag?: Maybe<QuizFlagLevel>;
}): React.ReactElement => {
  return (
    <div
      className={clsx(
        'text-gray-800 whitespace-pre-line',
        {
          'bg-yellow-200': flag === 'MODERATE',
        },
        {
          'bg-red-300': flag === 'CRITICAL',
        },
      )}
    >
      {children}
    </div>
  );
};

const ResponseMultipleOption = ({
  answers,
  options,
  flag,
}: {
  answers: string[];
  options: string[];
  flag?: QuizFlagLevel;
}): JSX.Element => {
  // If there are multiple options then we need to display all the options
  // and highlight selected
  // CHECKBOX answers come in the answersArray and radio comes in answer
  // but both have question options to display, so handle both by making an array

  // If the user's answer is not included in the options,
  // then it means question answer options have been changed
  const changedAnswers = answers.filter((answer) => !options.includes(answer));
  const flagClassName = clsx(
    {
      'bg-yellow-200': flag === 'MODERATE',
    },
    {
      'bg-red-300': flag === 'CRITICAL',
    },
  );
  return (
    <div>
      {options.map((option) => (
        <div key={option}>
          {answers.includes(option ?? '') ? (
            <div className={flagClassName}>
              <SelectedResponse value={option} />
            </div>
          ) : (
            <UnselectedResponse value={option ?? undefined} />
          )}
        </div>
      ))}
      {changedAnswers.map((answer) => (
        <div key={answer} className={flagClassName}>
          <SelectedResponse value={`${answer} (Answer was renamed)`} />
        </div>
      ))}
    </div>
  );
};

const SelectedResponse = ({ value }: { value?: string }): JSX.Element => (
  <div className="flex items-center text-gray-800">
    <FaCheckSquare className="text-blue-500" />
    <span className="ml-2">{value}</span>
  </div>
);

const UnselectedResponse = ({ value }: { value?: string }): JSX.Element => (
  <div className="flex items-center text-gray-500">
    <FaRegSquare />
    <span className="ml-2">{value}</span>
  </div>
);
