import { Button } from 'components/button';
import { useState } from 'react';
import {
  PractitionerBookingHistoryDrawer,
  PractitionerBookingHistoryDrawerProps,
} from './practitionerBookingHistoryDrawer';

export const PractitionerBookingHistoryDrawerButton: React.FC<
  Omit<PractitionerBookingHistoryDrawerProps, 'show' | 'onClose'>
> = (props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button size="small" variant="outline" onClick={() => setShowModal(true)}>
        History
      </Button>
      <PractitionerBookingHistoryDrawer
        show={showModal}
        onClose={() => setShowModal(false)}
        history={props.history}
      />
    </>
  );
};
