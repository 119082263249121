import { Input } from 'components/input';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDebouncedEffect } from 'utils/use-debounce-effect';
import { useUrlQuery } from 'utils/use-url-query';
import { useChangeUrl } from 'utils/user-change-url';
import { Dropdown, Option } from '../../components/dropdown';
import { problemTypeOptions } from '../../utils/dropdown-options';
import { getProblemTypeFromString } from '../../utils/misc';

export type PathologiesUrlQueryParams = {
  search: string;
  status: string;
  type: string;
};

const FILTER_CHANGE_DEBOUNCE_MS = 500;

export const PathologiesFilter = (): React.ReactElement => {
  const query = useGetNativeQuery();

  const { register, watch, control } = useForm<PathologiesUrlQueryParams>({
    mode: 'onChange',
    defaultValues: query,
  });

  const search = watch().search;
  const status = watch().status;
  const type = getProblemTypeFromString(watch().type);

  const changeUrl = useChangeUrl();
  useDebouncedEffect(
    () =>
      changeUrl({
        params: {
          search,
          status,
          type,
          pageIndex: 0,
        },
      }),
    FILTER_CHANGE_DEBOUNCE_MS,
    [search, status, type],
  );

  return (
    <form>
      <div className="flex space-x-8 mb-8">
        <div className="w-1/3">
          <Input
            ref={register}
            name="search"
            label="Search"
            placeholder="Search by Email, Name, or Reference No"
          />
        </div>
        <div className="w-1/3">
          <Dropdown
            name="status"
            label="Status"
            options={statusFilterOptions}
            control={control}
          />
        </div>
        <div className="w-1/3">
          <Dropdown
            name="type"
            label="Problem Type"
            options={[{ label: 'All', value: '' }, ...problemTypeOptions]}
            control={control}
          />
        </div>
      </div>
    </form>
  );
};

const statusFilterOptions: Option[] = [
  { label: 'All', value: '' },
  { label: 'Awaiting results', value: 'AWAITING_RESULTS' },
  { label: 'Results available', value: 'RESULTS_AVAILABLE' },
];

const useGetNativeQuery = (): PathologiesUrlQueryParams => {
  const urlQuery = useUrlQuery();

  return {
    search: urlQuery.get('search') ?? '',
    status: urlQuery.get('status') ?? '',
    type: urlQuery.get('type') ?? '',
  };
};

export const useBuildPathologiesQueryFilter = (): {
  status?: string;
  type?: string;
  search?: string;
} => {
  const { search, status, type } = useGetNativeQuery();

  return React.useMemo(() => {
    return {
      ...(status ? { status } : {}),
      ...(type ? { type } : {}),
      search,
    };
  }, [search, type, status]);
};
