import React from 'react';
import {
  getProblemTypeEmoji,
  upperSnakeCaseToCapitalCase,
} from '../../utils/misc';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import { ProblemType } from 'graphql/types';

export const ProblemTypeHeader = ({
  tabs,
  activeTab,
  setActiveTab,
}: {
  tabs: ProblemType[];
  activeTab: ProblemType | undefined;
  setActiveTab: (activeTab: ProblemType) => void;
}): React.ReactElement => {
  const { customerId } = useParams<{ customerId: string }>();

  return (
    <ul className="flex">
      {tabs.map((pt) => (
        <li key={pt} className="mr-3">
          <a
            className={clsx({
              'inline-block cursor-pointer rounded py-1 px-3 bg-primary-darker text-white':
                pt === activeTab,
              'inline-block cursor-pointer rounded hover:border hover:border-gray-200 text-primary-darker hover:bg-white py-1 px-3':
                pt !== activeTab,
            })}
            href={`/customers/${customerId}?pt=${pt}`}
            onClick={(e): void => {
              e.preventDefault();
              setActiveTab(pt);
            }}
          >
            {getProblemTypeEmoji(pt)} {upperSnakeCaseToCapitalCase(pt)}
          </a>
        </li>
      ))}
    </ul>
  );
};
