import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { ScriptsQuery, ScriptsQueryVariables } from 'graphql/types';
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useOrderBy,
  usePageIndex,
  usePaginatingSortingTable,
} from 'components/table';
import { Button } from 'components/button';
import { Loading } from 'components/loading';
import { Tag } from 'components/tag';
import { useHistory } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import {
  formatBooleanAsString,
  formatDateAndTime,
  getProblemTypeColor,
  getProblemTypeEmoji,
} from 'utils/misc';
import { routes } from 'utils/routes';
import { ScriptsFilter, useSearchURLQuery } from './script-filter';
import { scriptTags } from '../script/script-tag';

const TABLE_PAGE_SIZE = 30;

const scriptsQuery = gql`
  query Scripts($pageSize: Int!, $skip: Int!, $emailFilter: String) {
    scripts(
      pagination: { pageSize: $pageSize, skip: $skip }
      emailFilter: $emailFilter
    ) {
      id
      createdAt
      scriptUrl
      signedScriptUrl
      type
      reg24
      isDirect
      patient {
        id
        email
      }
      consultation {
        id
        type
      }
    }
    scriptsCount(emailFilter: $emailFilter)
  }
`;

type Script = NonNullable<ScriptsQuery['scripts']>[number];

const Scripts = (): React.ReactElement => {
  const history = useHistory();
  const orderBy = useOrderBy({ defaultValue: 'createdAt_desc' });
  const pageIndex = usePageIndex();
  const searchQuery = useSearchURLQuery();
  const { data, loading } = useQuery<ScriptsQuery, ScriptsQueryVariables>(
    scriptsQuery,
    {
      variables: {
        emailFilter: searchQuery,
        pageSize: TABLE_PAGE_SIZE,
        skip: pageIndex * TABLE_PAGE_SIZE,
      },
    },
  );

  const totalScriptsCount = data?.scriptsCount ?? 0;

  const pageNumber = Math.ceil((totalScriptsCount ?? 1) / TABLE_PAGE_SIZE);

  const tableInstance = usePaginatingSortingTable({
    columns,
    data: data?.scripts ?? [],
    pageNumber,
    orderBy,
    pageIndex,
  });

  return (
    <div>
      <ScriptsFilter />
      <Table tableInstance={tableInstance}>
        <>
          <TableHead />
          <TableBody>
            <>
              {tableInstance.page.map((row) => {
                tableInstance.prepareRow(row);
                return (
                  <TableRow row={row} key={row.id}>
                    <>
                      {row.cells.map((cell) => (
                        <TableCell
                          key={`${cell.row.original.id}-${cell.column.id}`}
                          cell={cell}
                          onClick={(): void => {
                            if (
                              cell.column.id === 'selection' ||
                              cell.column.id === 'scriptUrl' ||
                              cell.column.id === 'signedScriptUrl'
                            ) {
                              return;
                            }
                            history.push(
                              `${routes.scripts}/${cell.row.original.id}`,
                            );
                          }}
                        />
                      ))}
                    </>
                  </TableRow>
                );
              })}
            </>
          </TableBody>
        </>
      </Table>
      {!totalScriptsCount && !loading && (
        <div className="text-center font-medium pt-8">No scripts found</div>
      )}
      {loading && (
        <div className="flex justify-center my-8">
          <Loading />
        </div>
      )}
      <Pagination total={totalScriptsCount} tableInstance={tableInstance} />
    </div>
  );
};

const ScriptCreatedAtCell = (
  cell: CellProps<Script, Script['createdAt']>,
): React.ReactElement => <div>{formatDateAndTime(cell.value)}</div>;

const ProblemTypeCell = (
  cell: CellProps<Script, Script['consultation']>,
): React.ReactElement | null => {
  const problemType = cell.value?.type;
  if (!problemType) {
    return null;
  }
  return (
    <Tag size="small" color={getProblemTypeColor(problemType)}>
      <span role="img" className="pr-1">
        {getProblemTypeEmoji(problemType)}
      </span>
      {problemType.replaceAll('_', ' ')}
    </Tag>
  );
};

const ScriptTypeCell = (
  cell: CellProps<Script, Script['type']>,
): React.ReactElement => {
  switch (cell.value) {
    case 'ESCRIPT':
      return scriptTags.escript();
    case 'LEGACY':
      return scriptTags.legacy();
    case 'PAPER':
    default:
      return scriptTags.paper();
  }
};

const IsReg24Cell = (
  cell: CellProps<Script, Script['reg24']>,
): React.ReactElement => <div>{formatBooleanAsString(cell.value)}</div>;

const IsDirectCell = (
  cell: CellProps<Script, Script['isDirect']>,
): React.ReactElement => <div>{formatBooleanAsString(cell.value)}</div>;

const ViewFileCell = (
  cell: CellProps<Script, Script['scriptUrl']>,
): React.ReactElement => (
  <>
    {cell.value && cell.row.original.type !== 'ESCRIPT' && (
      <Button
        fullWidth
        variant="outline"
        onClick={(): void => {
          window.open(cell.value ?? '');
        }}
      >
        View
      </Button>
    )}
  </>
);
const EmailCell = ({
  value,
}: CellProps<Script, Script['patient']>): React.ReactElement | null =>
  value ? <div>{value.email}</div> : null;

const columns: Column<Script>[] = [
  {
    Header: 'Created',
    accessor: 'createdAt',
    disableSortBy: true,
    Cell: ScriptCreatedAtCell,
  },
  {
    Header: 'Script ID',
    accessor: 'id',
    disableSortBy: true,
    Cell: (c) => <div>{c.value}</div>,
  },
  {
    Header: 'Patient',
    accessor: 'patient',
    disableSortBy: true,
    Cell: EmailCell,
  },
  {
    Header: 'Problem type',
    accessor: 'consultation',
    Cell: ProblemTypeCell,
  },
  {
    Header: 'Is reg 24',
    accessor: 'reg24',
    disableSortBy: true,
    Cell: IsReg24Cell,
  },
  {
    Header: 'Is direct',
    accessor: 'isDirect',
    disableSortBy: true,
    Cell: IsDirectCell,
  },
  {
    Header: 'Type',
    accessor: 'type',
    disableSortBy: true,
    Cell: ScriptTypeCell,
  },
  {
    Header: 'Legacy Script',
    accessor: 'scriptUrl',
    disableSortBy: true,
    Cell: ViewFileCell,
  },
  {
    Header: 'Signed File',
    accessor: 'signedScriptUrl',
    disableSortBy: true,
    Cell: ViewFileCell,
  },
];

export default Scripts;
