import React from 'react';

export const InputError = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement | null =>
  children ? (
    <div className="text-red-500 text-xs mt-2 font-medium">{children}</div>
  ) : null;
