import type React from 'react';

import { Rectangle } from 'recharts';

export const CustomGraphCursor = ({
  points,
  height,
  color = '#667EEA',
}: {
  points?: { x: number; y: number }[];
  height?: number;
  color?: string;
}): React.ReactElement | null => {
  const xPoint = points?.[0]?.x;
  const yPoint = points?.[0]?.y;

  if (xPoint === undefined || yPoint === undefined) {
    return null;
  }

  return (
    <Rectangle
      fill={color}
      stroke={color}
      x={xPoint}
      y={yPoint}
      width={0.5}
      height={height}
    />
  );
};
