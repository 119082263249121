import { Control, RegisterOptions } from 'react-hook-form';
import { Dropdown, Option } from './dropdown';
import { gql, useQuery } from '@apollo/client';
import { DoctorSelectQuery, DoctorSelectQueryVariables } from 'graphql/types';

interface Props {
  name: string;
  control: Control;
  label?: string;
  disabled?: boolean;
  isMulti?: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  hasAllOption?: boolean;
  errorMessage?: string | JSX.Element;
  rules?: RegisterOptions;
  showAllDoctors: boolean;
}

export const DoctorSelect = ({
  name,
  control,
  label,
  isMulti = false,
  disabled = false,
  menuPlacement = 'auto',
  hasAllOption = true,
  rules,
  showAllDoctors = true,
}: Props): JSX.Element => {
  const { data } = useQuery<DoctorSelectQuery, DoctorSelectQueryVariables>(
    gql`
      query DoctorSelect(
        $showAllDoctors: Boolean!
        $showAssignableDoctors: Boolean!
      ) {
        allDoctors @include(if: $showAllDoctors) {
          id
          email
          clinicianName
        }
        assignableDoctors @include(if: $showAssignableDoctors) {
          id
          email
          clinicianName
        }
      }
    `,
    {
      variables: {
        showAssignableDoctors: !showAllDoctors,
        showAllDoctors,
      },
    },
  );

  const doctors = data?.allDoctors ?? data?.assignableDoctors ?? [];

  const doctorOptions: Option[] = doctors.map((d) => ({
    label: d.clinicianName ?? d.email ?? 'Unknown',
    value: d.id,
  }));

  if (hasAllOption) {
    doctorOptions.unshift({ label: 'All', value: '' });
  }

  return (
    <Dropdown
      name={name}
      label={label}
      options={doctorOptions}
      control={control}
      disabled={disabled}
      isMulti={isMulti}
      menuPlacement={menuPlacement}
      rules={rules}
    />
  );
};
