import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { useQueueManager } from './use-queue-manager';
import { ProblemType, QueueSummaryWindowFragment } from 'graphql/types';
import clsx from 'clsx';
import { OverrideSwitch } from './override-switch';
import { isWithinInterval } from 'date-fns';
import { useState } from 'react';
import { ProblemTypeTag } from 'components/tags/problem-type';

type QueueManagementModalProps = {
  show: boolean;
  onClose: () => void;
  onComplete: () => void;
  columnHeadings: string[];
  rowHeadings: string[];
  cells: Map<string, QueueSummaryWindowFragment>;
  problemType: ProblemType;
};

export const QueueManagementModal: React.FC<QueueManagementModalProps> = ({
  show,
  onClose,
  problemType,
  onComplete,
  columnHeadings,
  rowHeadings,
  cells,
}) => {
  const { loading, submit, register, watch, reset } = useQueueManager({
    practitionerBookingWindows: Array.from(cells.values()),
    problemType,
    onComplete: async () => {
      await onComplete();
      onClose();
    },
  });

  const cellBorderStyle = 'border border-gray-300 py-2.5 px-2';
  const headerClassName = clsx('font-semibold', cellBorderStyle);

  const onModalClose = () => {
    onClose();
    reset();
  };
  return (
    <Modal show={show} onClose={onModalClose}>
      <div className="bg-gray-200 p-6">
        <div className="flex flex-row gap-4 justify-between items-center">
          <div className="text-lg font-semibold">Block queue windows</div>
          <ProblemTypeTag problemType={problemType} />
        </div>

        <form
          className="mt-6"
          onSubmit={(evt) => {
            submit(evt);
          }}
        >
          <table
            className="bg-white shadow w-full text-center border-collapse table-fixed"
            style={{
              borderStyle: 'hidden',
            }}
          >
            <thead className="text-xs">
              <tr className="uppercase">
                <th className={headerClassName}>Date</th>
                <th className={headerClassName}>Time</th>
                <th
                  className={clsx(
                    headerClassName,
                    'text-blue-500 normal-case cursor-pointer underline',
                  )}
                  onClick={() => reset()}
                >
                  Reset changes
                </th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {columnHeadings.map((column, columnIndex) =>
                rowHeadings.map((row, rowIndex) => {
                  const cell = cells.get(`${column}${row}`);
                  const windowId = cell?.id ?? '';
                  const isSwitchEnabled = watch()[windowId];

                  if (cell && isSwitchEnabled === undefined) {
                    reset();
                    return;
                  }

                  const isActiveNow =
                    cell &&
                    isWithinInterval(new Date(), {
                      start: new Date(cell.startAt),
                      end: new Date(cell.endAt),
                    });

                  return (
                    <tr key={row}>
                      {rowIndex === 0 && (
                        <td
                          className={clsx(cellBorderStyle, {
                            // First day rendered will always be the current day
                            'font-semibold': columnIndex === 0,
                          })}
                          rowSpan={rowHeadings.length}
                        >
                          {column}
                        </td>
                      )}

                      {!cell && (
                        <>
                          <td className={clsx(cellBorderStyle, 'bg-gray-100')}>
                            {row}
                          </td>
                          <td className={clsx(cellBorderStyle, 'bg-gray-100')}>
                            -
                          </td>
                        </>
                      )}

                      {cell && (
                        <>
                          <td
                            className={clsx(cellBorderStyle, {
                              'font-semibold': isActiveNow,
                            })}
                          >
                            {row}
                          </td>
                          <td
                            className={clsx(cellBorderStyle, {
                              'font-semibold': isActiveNow,
                            })}
                          >
                            <OverrideSwitch
                              label={isSwitchEnabled ? 'Open' : 'Blocked'}
                              labelPosition="right"
                              ref={register}
                              checked={isSwitchEnabled}
                              value={windowId}
                              name={windowId}
                            />
                          </td>
                        </>
                      )}
                    </tr>
                  );
                }),
              )}
            </tbody>
          </table>
          <div className="flex flex-row gap-x-5 mt-8">
            <Button fullWidth color="danger" onClick={onModalClose}>
              Cancel
            </Button>
            <Button fullWidth loading={loading} type="submit" color="primary">
              Confirm
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export type QueueManagementModalButtonProps = {
  onComplete: () => void;
  columnHeadings: string[];
  rowHeadings: string[];
  cells: Map<string, QueueSummaryWindowFragment>;
  problemType: ProblemType;
};

export const QueueManagementModalButton: React.FC<
  QueueManagementModalButtonProps
> = (props) => {
  const [showQueueManagementModal, setShowQueueManagementModal] =
    useState(false);

  return (
    <>
      <Button
        fullWidth
        fillHeight
        variant="outline"
        onClick={() => setShowQueueManagementModal(true)}
      >
        Block windows
      </Button>

      <QueueManagementModal
        {...props}
        onClose={() => setShowQueueManagementModal(false)}
        show={showQueueManagementModal}
      />
    </>
  );
};
