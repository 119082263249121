import { config } from 'config';
import { datadogLogs, HandlerType } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

const service = `${config.brand}-super-admins-ui-${config.environment}`;

// Datadog Logging

datadogLogs.init({
  clientToken: config.datadogToken,
  forwardErrorsToLogs: true,
  sampleRate: 100,
  env: config.environment,
  service,
  version: import.meta.env.VITE_RELEASE_VERSION,
});

export const logger = datadogLogs.logger;
logger.setHandler(
  config.environment === 'production' ? HandlerType.http : HandlerType.console,
);

export const onError = (e: unknown): void => {
  if (config.environment !== 'production') {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

// Datadog Real User Monitoring (RUM)

datadogRum.init({
  applicationId: '6286cb45-e8ed-43ce-9423-62177ac03fa0',
  clientToken: 'puba5d63e833251851cd83fb5859d2367e6',
  site: 'datadoghq.com',
  service,
  env: config.environment,
  version: import.meta.env.VITE_RELEASE_VERSION,
  sampleRate: 100,
  trackInteractions: true,
  allowedTracingUrls: [config.apiUrl],
});

/**
 * setUser sync the current logged in user to the datadog context
 */
export const setUser = ({
  email,
  userId,
}: {
  email?: string;
  userId?: string;
}): void => {
  datadogLogs.addLoggerGlobalContext('email', email);
  datadogLogs.addLoggerGlobalContext('user_id', userId);
  datadogRum.setRumGlobalContext({
    email,
    userId,
  });
};
