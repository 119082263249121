import { TableOfObject } from './table-of-object';
import { flattenObject } from './utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RenderAny = ({ obj }: { obj: any }): React.ReactElement => {
  if (
    typeof obj === 'string' ||
    typeof obj === 'number' ||
    typeof obj === 'boolean'
  ) {
    return <>{obj}</>;
  }
  if (obj === null || obj === undefined) {
    return <></>;
  }

  return <TableOfObject obj={flattenObject(obj)} />;
};
