import React, { useEffect } from 'react';
import { logger } from 'logging';
import { ConfirmModal } from 'components/confirm-modal';
import { useHistory } from 'react-router-dom';

type ShowAlert = (props: AlertProps) => Promise<boolean>;

interface AlertProps {
  content: React.ReactNode;
}

// eslint-disable-next-line
const AlertContext = React.createContext<ShowAlert>(
  async (): Promise<boolean> => {
    logger.warn('No AlertProvider found as ancestor.');
    return false;
  },
);

export const useAlert = (): ShowAlert => React.useContext(AlertContext);

export const AlertProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const resolveRef = React.useRef<(confirmed: boolean) => void>();
  const [show, setShow] = React.useState(false);
  const [content, setContent] = React.useState<React.ReactNode>();
  const showAlert = React.useCallback(
    (showProps: AlertProps): Promise<boolean> => {
      setContent(showProps.content);
      setShow(true);
      return new Promise<boolean>((resolve) => {
        resolveRef.current = resolve;
      }).then((confirmed) => {
        setShow(false);
        return confirmed;
      });
    },
    [],
  );

  const history = useHistory();
  useEffect(() => {
    return history.listen(() => {
      setShow(false);
      if (resolveRef.current) {
        resolveRef.current(false);
      }
    });
  }, [history]);

  return (
    <AlertContext.Provider value={showAlert}>
      {children}
      <ConfirmModal
        show={show}
        onConfirm={(): void => {
          if (resolveRef.current) {
            resolveRef.current(true);
          }
        }}
        onClose={(): void => {
          if (resolveRef.current) {
            resolveRef.current(false);
          }
        }}
      >
        <div className="font-size whitespace-pre-line">{content}</div>
      </ConfirmModal>
    </AlertContext.Provider>
  );
};
