import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

const createVariantMutation = gql`
  mutation createVariantWithSku($variant: VariantWithSkuCreateInput!) {
    createVariantWithSku(variant: $variant) {
      id
    }
  }
`;

const updateVariantMutation = gql`
  mutation updateVariant($variant: VariantUpdateInput!) {
    updateVariant(variant: $variant) {
      id
    }
  }
`;

const deleteVariantMutation = gql`
  mutation deleteVariant($id: String!) {
    deleteVariant(id: $id)
  }
`;

export interface VariantWithSkuCreateInput {
  productId: string;
  name: string;
  slug?: string | null;
  price: number;
  public?: boolean;
  shopifyVariantId?: string | null;
  isRefill?: boolean;
  sku?: string;
}

export interface VariantUpdateInput {
  id: string;
  productId: string;
  name?: string;
  slug?: string | null;
  price?: number;
  public?: boolean;
  shopifyVariantId?: string | null;
  isRefill?: boolean;
}

export interface CreateVariantResponse {
  createVariant: {
    id: string;
  };
}

export const useVariantCrud = (): {
  createVariantWithSku: (
    variantWithSkuCreateInput: VariantWithSkuCreateInput,
  ) => Promise<void>;
  updateVariant: (variantUpdateInput: VariantUpdateInput) => Promise<void>;
  deleteVariant: (id: string) => Promise<void>;
} => {
  const [createVariant] = useMutation<{
    createVariant: { id: string };
  }>(createVariantMutation);
  const [updateVariant] = useMutation<{
    updateVariant: { id: string };
  }>(updateVariantMutation);
  const [deleteVariant] = useMutation<{ deleteVariant: boolean }>(
    deleteVariantMutation,
  );

  const createVariantWithSkuWrapper = useCallback(
    async (variantCreateInput: VariantWithSkuCreateInput): Promise<void> => {
      await createVariant({
        variables: {
          variant: variantCreateInput,
        },
      });
    },
    [createVariant],
  );

  const updateVariantWrapper = useCallback(
    async (variantUpdateInput: VariantUpdateInput): Promise<void> => {
      await updateVariant({
        variables: {
          variant: variantUpdateInput,
        },
      });
    },
    [updateVariant],
  );

  const deleteVariantWrapper = useCallback(
    async (id: string): Promise<void> => {
      await deleteVariant({
        variables: {
          id,
        },
      });
    },
    [deleteVariant],
  );

  return {
    createVariantWithSku: createVariantWithSkuWrapper,
    updateVariant: updateVariantWrapper,
    deleteVariant: deleteVariantWrapper,
  };
};
