import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { InputMaybe } from 'graphql/types';
import { useForm } from 'react-hook-form';
import { Input } from 'components/input';

export const CreateVariantGoalModal = ({
  showModal,
  onClose,
  onCreateVariantGoal,
}: {
  showModal: boolean;
  onCreateVariantGoal: (useCase: InputMaybe<string>) => Promise<void>;
  onClose: () => void;
}): React.ReactElement => {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState: { isSubmitting },
  } = useForm<{
    useCase: InputMaybe<string>;
  }>({
    mode: 'onChange',
  });

  const onSubmit = handleSubmit(async (data) => {
    await onCreateVariantGoal(data.useCase);
    reset();
    onClose();
  });

  return (
    <Modal show={showModal} onClose={onClose}>
      <form
        onSubmit={onSubmit}
        className="bg-gray-200 rounded p-4 flex flex-col space-y-8"
      >
        <div className="flex-col space-y-6">
          <div className="flex flex-col space-y-2">
            <p className="text-lg text-gray-900 font-semibold">
              Create a variant
            </p>
            <p className="text-gray-800">
              Provide a use case for this variant. A new variant can’t be
              assigned to patients until you publish changes.
            </p>
          </div>
          <Input
            label="Use case*"
            name="useCase"
            description="What patient use case does this variant serve? E.g. vegetarian."
            ref={register({
              validate: {
                required: (v) => !!v || 'Please enter a name',
              },
            })}
            errorMessage={errors.useCase?.message}
          />
        </div>
        <div className="flex space-x-4">
          <Button fullWidth color="danger" variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            fullWidth
            variant="solid"
            type="submit"
            loading={isSubmitting}
          >
            Create variant
          </Button>
        </div>
      </form>
    </Modal>
  );
};
