import { FaStar } from 'react-icons/fa';

export const BookingPriorityCount: React.FC<{ priority: number }> = ({
  priority,
}) => {
  if (priority === 0) {
    return '-';
  }

  if (priority <= 3) {
    return (
      <div className="flex">
        {new Array(priority).fill(<FaStar color="gold" size={16} />)}
      </div>
    );
  }

  return (
    <div className="flex whitespace-nowrap items-center text-sm gap-1">
      <FaStar color="gold" size={16} /> x {priority}
    </div>
  );
};
