import { formatDate } from 'utils/misc';
import { Customer } from './types';
import { Tag } from 'components/tag';
import { CopyToClipboardButton } from 'components/copy-to-clipboard-button';
import { SmallTableHeadings } from 'components/small-table/small-table-headings';

const columns = ['ID', 'Status', 'Asset', 'From', 'To'];

export const AccessSection: React.FC<{
  accesses: NonNullable<Customer['accesses']>;
}> = ({ accesses }) => {
  return (
    <div className="border-t border-gray-200">
      <div className="px-4 py-5 border-b border-gray-200 space-y-2">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Access
          <span className="ml-2 text-gray-500">{accesses.length}</span>
        </h3>
      </div>
      <div>
        <div className="-mt-2 overflow-x-auto">
          <div className="pt-2 min-w-full">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <SmallTableHeadings headings={columns} />
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {accesses.length === 0 && (
                  <div className="mx-4 my-2 text-gray-500 text-sm">
                    Patient has no accesses
                  </div>
                )}
                {accesses.map((access) => (
                  <tr key={access.id}>
                    <td className="px-6 py-2 whitespace-nowrap text-gray-500">
                      <span>{access.id}</span>
                      <CopyToClipboardButton value={access.id} />
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <Tag
                        size="small"
                        shape="box"
                        color={access.active ? 'indigo' : 'gray'}
                      >
                        {access.active ? 'Active' : 'Expired'}
                      </Tag>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      {access.asset.name}
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      {access.__typename === 'TimedAccess'
                        ? formatDate(new Date(access.start))
                        : '-'}
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      {access.__typename === 'TimedAccess'
                        ? formatDate(new Date(access.end))
                        : '-'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
