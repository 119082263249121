import { gql, useQuery } from '@apollo/client';
import { VFC } from 'react';
import { CollectionsPageTemplate } from '../../../components/education/collections/collections-page-template';
import { Loading } from '../../../components/loading';
import {
  GetEducationCollectionsQuery,
  GetEducationCollectionsQueryVariables,
} from '../../../graphql/types';

const CollectionsPage: VFC = () => {
  const { data, loading, error } = useQuery<
    GetEducationCollectionsQuery,
    GetEducationCollectionsQueryVariables
  >(gql`
    query GetEducationCollections {
      educationCollections {
        id
        name
        description
        readings {
          id
          name
          contentfulId
        }
      }
    }
  `);

  if (loading && !data) {
    return <Loading />;
  }

  if (error) {
    return <>Error. Please contact help-engineering.</>;
  }

  const collections = data?.educationCollections;
  if (!collections || collections.length === 0) {
    return <>No collections found.</>;
  }

  return <CollectionsPageTemplate collections={collections} />;
};

export default CollectionsPage;
