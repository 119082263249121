import { Button } from 'components/button';
import { useState } from 'react';
import {
  AddressHistoryDrawer,
  AddressHistoryDrawerProps,
} from './addressHistoryDrawer';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';

export const AddressHistoryDrawerButton: React.FC<
  Omit<AddressHistoryDrawerProps, 'show' | 'onClose'>
> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const featureFlagClient = useFeatureFlagClient();

  const historyEnabled = featureFlagClient.getBoolean(
    'ff_pra7_address_history',
  );

  if (!historyEnabled) {
    return null;
  }

  return (
    <>
      <Button size="small" variant="outline" onClick={() => setShowModal(true)}>
        History
      </Button>
      <AddressHistoryDrawer
        show={showModal}
        onClose={() => setShowModal(false)}
        {...props}
      />
    </>
  );
};
