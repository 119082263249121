import React from 'react';
import { Loading } from 'components/loading';
import clsx from 'clsx';
const GlobalLoaderContext = React.createContext<
  undefined | React.Dispatch<React.SetStateAction<boolean>>
>(undefined);

export const GlobalLoaderProvider = (props: {
  children: React.ReactNode;
}): React.ReactElement => {
  const [loading, setLoading] = React.useState(false);

  return (
    <GlobalLoaderContext.Provider value={setLoading}>
      {props.children}
      {loading && (
        <div
          className={clsx(
            'fixed',
            'bottom-0',
            'right-0',
            'shadow-md',
            'mr-6',
            'mb-6',
            'bg-white',
            'flex',
            'items-center',
            'rounded-full',
            'px-4',
            'py-2',
            'font-medium',
          )}
        >
          <Loading />
          <span className="ml-2 uppercase">Loading...</span>
        </div>
      )}
    </GlobalLoaderContext.Provider>
  );
};

export const GlobalLoader = (): null => {
  const setLoading = React.useContext(GlobalLoaderContext);

  React.useEffect(() => {
    setLoading?.(true);
    return (): void => {
      setLoading?.(false);
    };
  }, [setLoading]);

  return null;
};
