import React from 'react';
import { formatDateAndTime } from '../../utils/misc';
import { LogsAccordion } from 'components/audit/logs-accordion';
import { AuditDetails } from 'components/audit/audit-details';
import { CustomerTreatmentView } from './types';

const TABLE_COLUMNS = [
  'Updated At',
  'Event',
  'Status',
  'Data',
  'Updated By',
  'Role',
];

export const TreatmentLogsTable = ({
  treatment,
}: {
  treatment: CustomerTreatmentView;
}): React.ReactElement => (
  <div className="bg-white shadow overflow-hidden">
    <LogsAccordion title="Treatment Audit Logs">
      <div className="-mt-2 overflow-x-auto">
        <div className="pt-2 min-w-full">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                {TABLE_COLUMNS.map((h) => (
                  <th
                    key={h}
                    className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {h}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {treatment.logs.map((log) => (
                <tr key={log.id} className="border-t h-12 text-sm">
                  <td className="px-6 py-2 whitespace-nowrap">
                    {formatDateAndTime(log.updatedAt)}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">{log.event}</td>
                  <td className="px-6 py-2 whitespace-nowrap">{log.status}</td>
                  <td className="px-6 py-2">
                    <AuditDetails details={log.data} />
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    {log.updatedBy?.fullName}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    {log.updatedBy?.role}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </LogsAccordion>
  </div>
);
