import { Button } from 'components/button';
import { Link, useHistory } from 'react-router-dom';
import { routes } from 'utils/routes';
import { ApolloQueryResult, gql, useQuery } from '@apollo/client';
import { User, UserOrderByInput, UserWhereInput } from 'graphql/types';
import clsx from 'clsx';
import { Loading } from 'components/loading';
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useOrderBy,
  usePageIndex,
  useSelectablePaginatingSortingTable,
} from 'components/table';
import React, { useMemo, useState } from 'react';
import { CellProps, Column, TableInstance } from 'react-table';
import { formatDateAndTime } from 'utils/misc';
import {
  useBuildUserQueryFilter,
  UserFilter,
} from '../../components/user-filter';
import { DeleteManyUsersButton } from 'components/delete-many-users-button';
import { Tag } from 'components/tag';
import { getClinicianTypeLabel } from '../clinician/clinician-type-dropdown';
import { ActivityModal } from './activity-modal';
import { useChatContext } from 'stream-chat-react';

const TABLE_PAGE_SIZE = 30;

type UserWithFilteredConsultations = User & {
  filteredConsultations: User['consultations'];
  scriptsPending: number;
  oldestUnsignedScriptDate: Date;
};

const AvailabilityCell = ({
  value,
}: CellProps<
  UserWithFilteredConsultations,
  UserWithFilteredConsultations
>): React.ReactElement => {
  const { color, label } = useMemo<{
    color: 'red' | 'green' | 'orange';
    label: string;
  }>(() => {
    const isDeactivated = !!value.deactivatedAt;
    const isAvailable = !!value.isAvailable;

    if (isDeactivated) {
      return { color: 'red', label: 'Deactivated' };
    } else if (isAvailable) {
      return { color: 'green', label: 'Available' };
    } else {
      return { color: 'orange', label: 'Unavailable' };
    }
  }, [value]);

  return (
    <div className="mt-1">
      <div className="inline-block mr-1 mb-1">
        <Tag size="small" color={color}>
          {label}
        </Tag>
      </div>
    </div>
  );
};

const consultationsPendingCell = (
  cell: CellProps<
    UserWithFilteredConsultations,
    UserWithFilteredConsultations['filteredConsultations']
  >,
): React.ReactElement => <div>{cell.value.length}</div>;

const scriptsPendingCell = (
  cell: CellProps<
    UserWithFilteredConsultations,
    UserWithFilteredConsultations['scriptsPending']
  >,
): React.ReactElement => <div>{cell.value}</div>;

const oldestUnsignedScriptDateCell = (
  cell: CellProps<
    UserWithFilteredConsultations,
    UserWithFilteredConsultations['oldestUnsignedScriptDate']
  >,
): React.ReactElement => (
  <div>{cell.value ? formatDateAndTime(cell.value) : ''}</div>
);

const columns: Column<UserWithFilteredConsultations>[] = [
  {
    Header: 'Name',
    accessor: 'firstName',
    Cell: (c) => <div>{c.row.original.clinicianName}</div>,
    className: 'w-1/6',
  },
  {
    Header: 'Practitioner Type',
    accessor: 'provider',
    Cell: (c) => {
      const clinicianType = c.row.original.provider?.clinicianType;
      if (!clinicianType) {
        return null;
      }
      return <div>{getClinicianTypeLabel(clinicianType)}</div>;
    },
    className: 'w-1/12',
    disableSortBy: true,
  },
  {
    Header: 'Availability',
    accessor: (row): UserWithFilteredConsultations => row,
    Cell: AvailabilityCell,
    className: 'w-1/6',
    disableSortBy: true,
  },
  {
    Header: 'Email',
    accessor: 'email',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-1/6',
  },
  {
    Header: 'Consultations Pending',
    accessor: 'filteredConsultations',
    Cell: consultationsPendingCell,
    className: 'w-1/6',
    disableSortBy: true,
  },
  {
    Header: 'Scripts Pending',
    accessor: 'scriptsPending',
    Cell: scriptsPendingCell,
    className: 'w-1/6',
    disableSortBy: true,
  },
  {
    Header: 'Oldest Script',
    accessor: 'oldestUnsignedScriptDate',
    Cell: oldestUnsignedScriptDateCell,
    className: 'w-1/6',
  },
];

const useCliniciansTable: () => [
  TableInstance<UserWithFilteredConsultations>,
  boolean,
  (
    variables?: Partial<{
      where: UserWhereInput;
      orderBy: UserOrderByInput[];
      skip: number;
      pageSize: number;
    }>,
  ) => Promise<
    ApolloQueryResult<{
      clinicians: UserWithFilteredConsultations[];
      cliniciansCount: number;
    }>
  >,
  number | string,
] = () => {
  const activeFilter = useBuildUserQueryFilter('DOCTOR');
  const orderBy = useOrderBy({ defaultValue: 'createdAt_desc' });
  const pageIndex = usePageIndex();
  const { data, loading, refetch } = useQuery<
    {
      clinicians: UserWithFilteredConsultations[];
      cliniciansCount: number;
    },
    {
      where: UserWhereInput;
      orderBy: UserOrderByInput[];
      skip: number;
      pageSize: number;
    }
  >(
    gql`
      query CliniciansTable(
        $where: UserWhereInput
        $orderBy: [UserOrderByInput!]
        $skip: Int
        $pageSize: Int
      ) {
        clinicians: users(
          orderBy: $orderBy
          where: $where
          skip: $skip
          take: $pageSize
        ) {
          id
          createdAt
          isAvailable
          deactivatedAt
          email
          clinicianName
          firstName
          lastName
          fullName
          provider {
            id
            clinicianType
          }
          filteredConsultations: doctorConsultations(
            where: { status: { equals: DOCTOR_ASSIGNED } }
          ) {
            id
          }
          scriptsPending
          oldestUnsignedScriptDate
        }
        cliniciansCount: usersCount(where: $where)
      }
    `,
    {
      variables: {
        where: activeFilter,
        orderBy: orderBy,
        skip: pageIndex * TABLE_PAGE_SIZE,
        pageSize: TABLE_PAGE_SIZE,
      },
    },
  );

  const clinicians = React.useMemo(() => data?.clinicians ?? [], [data]);
  const total = data?.cliniciansCount ?? 0;
  const pageNumber = Math.ceil((total ?? 1) / TABLE_PAGE_SIZE);
  const cliniciansTable =
    useSelectablePaginatingSortingTable<UserWithFilteredConsultations>({
      columns,
      data: clinicians,
      pageNumber,
      orderBy,
      pageIndex,
    });

  return [cliniciansTable, loading, refetch, total];
};
const Clinicians = (): React.ReactElement => {
  const history = useHistory();
  const chatContext = useChatContext();
  const [cliniciansTable, loading, refetch, total] = useCliniciansTable();
  const selectedRows = cliniciansTable.selectedFlatRows;
  const [activityModalOpen, setActivityModalOpen] = useState<boolean>(false);

  return (
    <div>
      <div className="flex flex-row w-1/2 justify-end float-right">
        {!!selectedRows.length && (
          <div className="w-auto pr-4">
            <DeleteManyUsersButton
              table="User"
              ids={selectedRows.map((row) => row.original.id)}
              onDeleted={refetch}
            />
          </div>
        )}
        <div className="flex items-center space-x-2">
          {chatContext.client && (
            <Button
              fullWidth
              variant="outline"
              onClick={() => setActivityModalOpen(true)}
            >
              View activity
            </Button>
          )}
          <Link to={routes.createClinician}>
            <Button fullWidth variant="solid">
              Create&nbsp;New
            </Button>
          </Link>
        </div>
      </div>
      <div className="pt-16">
        <UserFilter />
      </div>
      <div
        className={clsx({ 'visually-hidden': !cliniciansTable.page.length })}
      >
        <Table tableInstance={cliniciansTable}>
          <TableHead />
          <TableBody>
            <React.Fragment>
              {cliniciansTable.page?.map((row) => {
                cliniciansTable.prepareRow(row);
                return (
                  <TableRow row={row} key={row.id}>
                    <React.Fragment>
                      {row.cells.map((cell) => (
                        <TableCell
                          key={`${cell.row.original.id}-${cell.column.id}`}
                          cell={cell}
                          onClick={(): void => {
                            if (cell.column.id !== 'selection') {
                              history.push(
                                `${routes.clinicians}/${cell.row.original.id}`,
                              );
                            }
                          }}
                        />
                      ))}
                    </React.Fragment>
                  </TableRow>
                );
              })}
            </React.Fragment>
          </TableBody>
        </Table>
        <Pagination total={total} tableInstance={cliniciansTable} />
      </div>
      {!cliniciansTable.page.length && !loading && (
        <div className="text-center font-medium pt-8">
          No practitioners found
        </div>
      )}
      {loading && (
        <div className="flex justify-center text-lg">
          <Loading />
        </div>
      )}
      {activityModalOpen && (
        <ActivityModal onClose={() => setActivityModalOpen(false)} />
      )}
    </div>
  );
};

export default Clinicians;
