import { gql, useQuery } from '@apollo/client';
import { validate } from 'uuid';
import { ReadingForm } from 'components/education/readings/reading-form';
import { Loading } from 'components/loading';
import {
  GetEducationReadingQuery,
  GetEducationReadingQueryVariables,
} from 'graphql/types';
import { VFC } from 'react';
import { useParams } from 'react-router-dom';

const ReadingPage: VFC = () => {
  const { readingId } = useParams<{ readingId: string }>();
  const { data, loading } = useQuery<
    GetEducationReadingQuery,
    GetEducationReadingQueryVariables
  >(
    gql`
      query GetEducationReading($id: ID!) {
        educationReading(id: $id) {
          id
          name
          contentfulId
        }
      }
    `,
    { variables: { id: readingId }, skip: !validate(readingId) },
  );

  if (loading) {
    return <Loading />;
  }

  const reading = data?.educationReading;

  if (!reading) {
    return <>No reading could be found.</>;
  }

  return (
    <div>
      <div>
        <h1 className="text-2xl font-semibold mb-4">Reading</h1>
      </div>
      <ReadingForm reading={reading} />
    </div>
  );
};

export default ReadingPage;
