import { RefetchTrackerData } from '../types';

export enum TrackerAction {
  UPDATE = 'update',
  DELETE = 'delete',
}

export interface TrackerDisplayInfoProps {
  entryDate: string;
  lastUpdatedAtDate?: string;
  type: string;
  value?: number;
}

export interface TrackerActionsProps extends TrackerDisplayInfoProps {
  observationId: string;
  refetch: RefetchTrackerData;
  disableEdit?: boolean;
}

export interface TrackerActionModalProps extends TrackerActionsProps {
  show: boolean;
  onClose: () => void;
  onActionComplete: () => Promise<void>;
}
