import { gql } from '@apollo/client';
import { ProblemType, QueueSummaryWindowFragment } from 'graphql/types';
import { formatWindowDay, formatWindowInterval } from 'utils/queues';
import { isBefore, isWithinInterval } from 'date-fns';
import { useMemo, useRef } from 'react';
import { QueueManagementModalButton } from './queue-management-modal';
import { EditCapacityModalButton } from './edit-capacity-modal';
import QueueTable from './queue-table';

type QueueSummaryProps = {
  problemType: ProblemType;
  windows: QueueSummaryWindowFragment[];
  onUpdate: () => void;
};

export const QueueSummaryWindowFragmentDoc = gql`
  fragment QueueSummaryWindow on PractitionerBookingWindow {
    id
    problemType
    available
    startAt
    endAt
    capacity
    unassignedBookingsCount
    totalBookingsCount
    override {
      id
    }
  }
`;

export const QueueSummary: React.FC<QueueSummaryProps> = ({
  windows,
  problemType,
  onUpdate,
}) => {
  const referenceCapacities = useRef(new Map<string, number>());

  const { columnHeadings, rowHeadings, cells, currentRow, changedCapacities } =
    useMemo(() => {
      const days = new Set<string>();
      const intervals = new Set<string>();
      const cells = new Map<string, QueueSummaryWindowFragment>();
      const changedCapacities = new Map<string, boolean>();
      let currentRow: string | undefined;

      for (const w of windows) {
        const day = formatWindowDay(w);
        const interval = formatWindowInterval(w);
        const referenceCapacity = referenceCapacities.current.get(w.id);

        days.add(day);
        intervals.add(interval);

        if (isBefore(new Date(w.endAt), new Date())) {
          continue;
        }

        if (
          !currentRow ||
          isWithinInterval(new Date(), {
            start: new Date(w.startAt),
            end: new Date(w.endAt),
          })
        ) {
          currentRow = interval;
        }

        changedCapacities.set(
          w.id,
          referenceCapacity !== undefined && referenceCapacity !== w.capacity,
        );
        referenceCapacities.current.set(w.id, w.capacity);
        cells.set(`${day}${interval}`, w);
      }

      return {
        columnHeadings: Array.from(days),
        rowHeadings: Array.from(intervals),
        cells,
        currentRow,
        changedCapacities,
      };
    }, [windows]);

  return (
    <QueueTable
      columnHeadings={columnHeadings}
      rowHeadings={rowHeadings}
      cells={cells}
      currentRow={currentRow}
      changedCapacities={changedCapacities}
    >
      <div className="flex flex-row p-4 gap-4">
        <div className="basis-1/2">
          <QueueManagementModalButton
            columnHeadings={columnHeadings}
            rowHeadings={rowHeadings}
            cells={cells}
            onComplete={onUpdate}
            problemType={problemType}
          />
        </div>
        <div className="basis-1/2">
          <EditCapacityModalButton
            queueTableProps={{
              columnHeadings,
              rowHeadings,
              cells,
              currentRow,
            }}
            onComplete={onUpdate}
            problemType={problemType}
          />
        </div>
      </div>
    </QueueTable>
  );
};
