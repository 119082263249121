import { Modal } from 'components/modal';
import { UpsertNoteForm } from '../upsert-note-form';

export type UpsertCriticalNoteModalProps = {
  defaultValues: {
    customerId: string;
    noteId?: string;
    body?: string;
  };
  show: boolean;
  onClose: () => void;
};

export const UpsertCriticalNoteModal: React.FC<
  UpsertCriticalNoteModalProps
> = ({ defaultValues, show, onClose }) => {
  return (
    <Modal show={show} onClose={onClose} width="w-5/6">
      <section className="bg-gray-200">
        <header className="border-b border-gray-500 py-3 px-5 bg-gray-300">
          <h2 className="text-lg font-semibold mb-2">
            {defaultValues.noteId ? 'Edit Critical Note' : 'Add Critical Note'}
          </h2>
        </header>

        <div className="space-y-3 px-5 py-3">
          <p className="text-sm">
            This note should be used to raise high priority, clinical risks or
            flags about a patient. Other admins, practitioners and coaches will
            be able to see this note.
          </p>

          <UpsertNoteForm
            title="Critical Note"
            onCompleted={onClose}
            maxLength={500}
            noteInput={{
              id: defaultValues.noteId,
              body: defaultValues.body,
              targetId: defaultValues.customerId,
              targetType: 'CUSTOMER',
              domain: 'CUSTOMER_CRITICAL',
            }}
          />
        </div>
      </section>
    </Modal>
  );
};
