import { Dropdown, Option } from 'components/dropdown';
import { Input } from 'components/input';
import { useForm } from 'react-hook-form';
import { useDebouncedEffect } from 'utils/use-debounce-effect';
import { useChangeUrl } from 'utils/user-change-url';
import { useSearchTerm } from './utils/useSearchTerm';
import { useSearchType } from './utils/useSearchType';

const FILTER_CHANGE_DEBOUNCE_MS = 500;

const trackerPatientsSearchOptions: Option[] = [
  { label: 'Email', value: 'EMAIL' },
  { label: 'Name', value: 'NAME' },
];

export const TrackingFilters = (): React.ReactElement => {
  const changeUrl = useChangeUrl();

  const searchTermInUrl = useSearchTerm();
  const searchTypeInUrl = useSearchType();

  const { register, watch, control } = useForm({
    mode: 'onChange',
    defaultValues: {
      searchType: searchTypeInUrl || 'NAME',
      searchTerm: searchTermInUrl,
    },
  });

  const searchTerm = watch().searchTerm;
  const searchType = watch().searchType;

  useDebouncedEffect(
    () => {
      changeUrl({
        params: {
          searchTerm: searchTerm || undefined,
          pageIndex: undefined,
          searchType: searchTerm ? searchType : undefined,
        },
      });
    },
    FILTER_CHANGE_DEBOUNCE_MS,
    [searchType, searchTerm],
  );

  return (
    <div className="flex space-x-8 mb-8">
      <div className="w-1/6">
        <Dropdown
          name="searchType"
          label="Search By"
          options={trackerPatientsSearchOptions}
          control={control}
        />
      </div>
      <div className="w-2/6">
        <Input
          ref={register}
          name="searchTerm"
          label="Search"
          placeholder={`Search by ${trackerPatientsSearchOptions.find(
            (option) => option.value === searchType,
          )?.label}`}
        />
      </div>
    </div>
  );
};
