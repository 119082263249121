import { RegisterOptions, ValidateResult } from 'react-hook-form';
import { Maybe } from 'graphql/types';
import { isMatch, parse, isBefore, format } from 'date-fns';
import { mustBe } from './misc';
import {
  noUppercaseRegex,
  positiveFloatValidationRegex,
  positiveIntegerValidationRegex,
  validEmailRegex,
  validHealthCoachEmailRegex,
} from './regex-expressions';
import { PhoneNumberType, PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export const validDateFormat = 'dd/MM/yyyy';

export const emailIsValid: RegisterOptions = {
  pattern: {
    value: validEmailRegex,
    message: 'Please enter a valid email',
  },
};

export const healthCoachEmailIsValid: RegisterOptions = {
  pattern: {
    value: validHealthCoachEmailRegex,
    message: 'Error in email format, use: "first[.last]@euc.coach"',
  },
};

export const noUppercaseLetters: RegisterOptions = {
  pattern: {
    value: noUppercaseRegex,
    message: 'Please use only lowercase letters',
  },
};

const camelCaseToReadable = (camelCase: string): string => {
  const readableString = camelCase
    .split(/(?=[A-Z\d])/)
    .join(' ')
    .toLowerCase();
  return readableString.charAt(0).toUpperCase() + readableString.substring(1);
};

export const requiredValidation = (fieldName: string): RegisterOptions => ({
  required: `${camelCaseToReadable(fieldName)} is required`,
});

export const internationalNumber = (
  fieldName: string,
): Pick<RegisterOptions, 'validate'> => ({
  validate(number: string): ValidateResult {
    try {
      const parsedNumber = phoneUtil.parseAndKeepRawInput(number);
      parsedNumber.getCountryCode();
      return;
    } catch {
      return `${camelCaseToReadable(
        fieldName,
      )} must use international formatting`;
    }
  },
});

export const mobileNumberValidation = (
  fieldName: string,
  customerValue: string | null | undefined,
): Pick<RegisterOptions, 'validate'> => ({
  validate(number: string): ValidateResult {
    if (!customerValue && !number) {
      return true;
    }
    try {
      const parsedNumber = phoneUtil.parse(number);

      return (
        phoneUtil.getNumberType(parsedNumber) === PhoneNumberType.MOBILE ||
        `Please enter a valid mobile number`
      );
    } catch {
      return `${camelCaseToReadable(fieldName)} must be a mobile number`;
    }
  },
});

export const minValueValidation = (
  fieldName: string,
  min: number,
): RegisterOptions => ({
  min: {
    message: `The ${fieldName} value can not be less than ${min}`,
    value: min,
  },
});

export const maxValueValidation = (
  fieldName: string,
  max: number,
): RegisterOptions => ({
  max: {
    message: `The ${fieldName} value can not be more than ${max}`,
    value: max,
  },
});

export const positiveFloatValidation = (
  fieldName: string,
): RegisterOptions => ({
  pattern: {
    message: `The ${fieldName} must be a positive number`,
    value: positiveFloatValidationRegex,
  },
});

export const positiveIntegerValidation = (
  fieldName: string,
): RegisterOptions => ({
  pattern: {
    message: `The ${fieldName} must be a positive number`,
    value: positiveIntegerValidationRegex,
  },
});

export const dateValidation = (): RegisterOptions => {
  return {
    validate: {
      dateValidation: (value: string): boolean | string => {
        return (
          isMatch(value, validDateFormat) ||
          `Please enter a valid date in format ${validDateFormat.toLocaleUpperCase()}`
        );
      },
    },
  };
};

export const maxDateValidation = (
  maxDate: Date,
  dateFormat?: string,
): RegisterOptions => {
  return {
    validate: {
      maxDateValidation: (value: string): boolean | string => {
        if (!isMatch(value, dateFormat ?? validDateFormat)) {
          // Ignore the validation when input is not a valid date
          return true;
        }
        return (
          isBefore(
            parse(value, dateFormat ?? validDateFormat, new Date()),
            maxDate,
          ) ||
          `Please enter a date before ${format(
            maxDate,
            dateFormat ?? validDateFormat,
          )}`
        );
      },
    },
  };
};

export const minDateValidation = (
  minDate: Date,
  dateFormat?: string,
): RegisterOptions => {
  return {
    validate: {
      minDateValidation: (value: string): boolean | string => {
        if (!isMatch(value, dateFormat ?? validDateFormat)) {
          // Ignore the validation when input is not a valid date
          return true;
        }
        return (
          !isBefore(
            parse(value, dateFormat ?? validDateFormat, new Date()),
            minDate,
          ) ||
          `Please enter a date on or after ${format(
            minDate,
            dateFormat ?? validDateFormat,
          )}`
        );
      },
    },
  };
};

export const minLengthValidation = (
  fieldName: string,
  length: number,
): RegisterOptions => ({
  minLength: {
    value: length,
    message: `${camelCaseToReadable(
      fieldName,
    )} must be at least ${length} characters`,
  },
});

export const maxLengthValidation = (
  fieldName: string,
  length: number,
): RegisterOptions => ({
  maxLength: {
    value: length,
    message: `${camelCaseToReadable(
      fieldName,
    )} must be at most ${length} characters`,
  },
});

export const combineRules = (
  ...rules: Maybe<RegisterOptions>[]
): RegisterOptions =>
  rules.filter(mustBe).reduce((result, current) => {
    return {
      ...result,
      ...current,
      validate: {
        ...result.validate,
        ...current.validate,
      },
    };
  }, {});
