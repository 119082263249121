import React from 'react';
import { config } from 'config';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GlobalLoader } from 'global-loader';

export const GoogleAuthProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const [loading, setLoading] = React.useState(false);

  if (loading) {
    return <GlobalLoader></GlobalLoader>;
  }

  return (
    <GoogleOAuthProvider
      clientId={config.googleOAuthClientId}
      onScriptLoadSuccess={(): void => setLoading(false)}
    >
      {children}
    </GoogleOAuthProvider>
  );
};
