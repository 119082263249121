import { z } from 'zod';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';

const evermedConfigSchema = z.object({
  fulfillableSkus: z.array(z.string()),
});
type EvermedConfigFlagValue = z.infer<typeof evermedConfigSchema>;

export const useIsEvermedOrder = (skus: string[]): boolean => {
  const featureFlagClient = useFeatureFlagClient();

  const evermedConfig = evermedConfigSchema.parse(
    featureFlagClient.getJson<EvermedConfigFlagValue>('evermed-config'),
  );

  return evermedConfig.fulfillableSkus.some((s) => skus.includes(s));
};
