import { useForm } from 'react-hook-form';
import React from 'react';
import { Input } from '../../../components/input';
import { Tag } from '../../../components/tag';
import {
  positiveIntegerValidation,
  requiredValidation,
} from '../../../utils/form-validation';
import { Label } from '../../../components/label';
import { Button } from '../../../components/button';
import { config } from 'config';
import { Copyable } from '../../../components/copyable';

export const DefaultVariantInfoFields = ({
  register,
  errors,
  defaultValues,
  variantNumber,
  onDelete,
  onCreate,
  onSave,
}: {
  register: ReturnType<typeof useForm>['register'];
  control: ReturnType<typeof useForm>['control'];
  errors: ReturnType<typeof useForm>['errors'];
  defaultValues: {
    id?: string;
    name?: string;
    slug?: string | null;
    price?: number;
    public?: boolean;
    shopifyVariantId?: string | null;
    isRefill?: boolean;
    sku?: string;
  };
  variantNumber: number;
  onDelete: (variantNumber: number) => Promise<void>;
  onCreate: (variantNumber: number) => Promise<void>;
  onSave: (variantNumber: number) => Promise<void>;
}): React.ReactElement => {
  return (
    <li className="space-y-5">
      <input
        name={`variants[${variantNumber}].id`}
        value={defaultValues.id}
        className="hidden"
        ref={register()}
        readOnly
      />
      <div className="space-y-5 bg-gray-100 rounded p-4">
        {defaultValues.isRefill && (
          <div className="flex justify-end">
            <Tag size="small">Refill</Tag>
          </div>
        )}
        {defaultValues.id && (
          <div className="flex justify-end">
            <Copyable text={defaultValues.id}>
              {(copied) => (
                <pre className="text-xs cursor-pointer text-stone-400">
                  Variant ID:&nbsp;
                  {copied ? (
                    'Copied'
                  ) : (
                    <span className="underline">
                      {defaultValues.id?.slice(-6)}
                    </span>
                  )}
                </pre>
              )}
            </Copyable>
          </div>
        )}
        <div>
          <Input
            label="Variant name"
            ref={register(requiredValidation('Name'))}
            name={`variants[${variantNumber}].name`}
            defaultValue={defaultValues.name || ''}
            errorMessage={errors?.variants?.[variantNumber]?.name?.message}
          />
        </div>
        <div>
          <Input
            label="Variant slug"
            ref={register()}
            name={`variants[${variantNumber}].slug`}
            defaultValue={defaultValues.slug || ''}
            errorMessage={errors?.variants?.[variantNumber]?.slug?.message}
          />
        </div>
        {config.fulfilmentVendors.includes('shopify') && (
          <div>
            <Input
              label="Shopify variant ID"
              ref={register()}
              name={`variants[${variantNumber}].shopifyVariantId`}
              defaultValue={defaultValues.shopifyVariantId || ''}
              errorMessage={
                errors?.variants?.[variantNumber]?.shopifyVariantId?.message
              }
            />
          </div>
        )}
        <div>
          <Input
            label="Variant price (cents)"
            ref={register({
              ...requiredValidation('Price'),
              ...positiveIntegerValidation('price'),
              valueAsNumber: true,
            })}
            name={`variants[${variantNumber}].price`}
            type="text"
            defaultValue={(defaultValues.price || 0).toString()}
            errorMessage={errors?.variants?.[variantNumber]?.price?.message}
          />
        </div>
        <div>
          <Input
            label="Variant SKU"
            ref={register()}
            name={`variants[${variantNumber}].inventory.sku`}
            defaultValue={defaultValues.sku || ''}
            errorMessage={
              errors?.variants?.[variantNumber]?.inventory?.sku?.message
            }
          />
        </div>
        {/* TODO: Use Switch component instead of native checkbox */}
        <div className="flex">
          <div className="w-1/2 flex gap-4 items-center">
            <input
              type="checkbox"
              ref={register()}
              name={`variants[${variantNumber}].public`}
              id={`variants[${variantNumber}].public`}
              value="public"
              defaultChecked={defaultValues.public}
            />
            <Label htmlFor={`variants[${variantNumber}].public`}>Public</Label>
          </div>
          <div className="w-1/2 flex gap-4 items-center">
            <input
              type="checkbox"
              ref={register()}
              name={`variants[${variantNumber}].isRefill`}
              id={`variants[${variantNumber}].isRefill`}
              value="isRefill"
              defaultChecked={defaultValues.isRefill}
            />
            <Label htmlFor={`variants[${variantNumber}].isRefill`}>
              Is refill
            </Label>
          </div>
        </div>
        {defaultValues.id && (
          <div className="flex items-center justify-between">
            <div>
              <Button
                fullWidth
                type="button"
                color="primary"
                onClick={async (): Promise<void> => {
                  await onSave(variantNumber);
                }}
              >
                Save
              </Button>
            </div>
            <div>
              <Button
                fullWidth
                type="button"
                color="danger"
                variant="outline"
                onClick={async (): Promise<void> => {
                  await onDelete(variantNumber);
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        )}
        {!defaultValues.id && (
          <div className="flex items-center justify-between">
            <div>
              <Button
                fullWidth
                type="button"
                color="primary"
                onClick={async (): Promise<void> => {
                  await onCreate(variantNumber);
                }}
              >
                Create
              </Button>
            </div>
            <div>
              <Button
                fullWidth
                type="button"
                color="danger"
                variant="outline"
                onClick={async (): Promise<void> => {
                  await onDelete(variantNumber);
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        )}
      </div>
    </li>
  );
};

export const AccessoryVariantInfoFields = ({
  register,
  errors,
  defaultValues,
  variantNumber,
  onDelete,
  onCreate,
  onSave,
}: {
  register: ReturnType<typeof useForm>['register'];
  control: ReturnType<typeof useForm>['control'];
  errors: ReturnType<typeof useForm>['errors'];
  defaultValues: {
    id?: string;
    name?: string;
    slug?: string | null;
    price?: number;
    public?: boolean;
    shopifyVariantId?: string | null;
    isRefill?: boolean;
    sku?: string;
  };
  variantNumber: number;
  onDelete: (variantNumber: number) => Promise<void>;
  onCreate: (variantNumber: number) => Promise<void>;
  onSave: (variantNumber: number) => Promise<void>;
}): React.ReactElement => {
  return (
    <li className="space-y-5">
      <input
        name={`variants[${variantNumber}].id`}
        value={defaultValues.id}
        className="hidden"
        ref={register()}
        readOnly
      />
      <div className="space-y-5 bg-gray-100 rounded p-4">
        {defaultValues.isRefill && (
          <div className="flex justify-end">
            <Tag size="small">Refill</Tag>
          </div>
        )}
        {defaultValues.id && (
          <div className="flex justify-end">
            <Copyable text={defaultValues.id}>
              {(copied) => (
                <pre className="text-xs cursor-pointer text-stone-400">
                  Variant ID:&nbsp;
                  {copied ? (
                    'Copied'
                  ) : (
                    <span className="underline">
                      {defaultValues.id?.slice(-6)}
                    </span>
                  )}
                </pre>
              )}
            </Copyable>
          </div>
        )}
        <div>
          <Input
            label="Variant name"
            ref={register(requiredValidation('Name'))}
            name={`variants[${variantNumber}].name`}
            defaultValue={defaultValues.name || ''}
            errorMessage={errors?.variants?.[variantNumber]?.name?.message}
          />
        </div>
        <div>
          <Input
            label="Variant slug"
            ref={register()}
            name={`variants[${variantNumber}].slug`}
            defaultValue={defaultValues.slug || ''}
            errorMessage={errors?.variants?.[variantNumber]?.slug?.message}
          />
        </div>
        {config.fulfilmentVendors.includes('shopify') && (
          <div>
            <Input
              label="Shopify variant ID"
              ref={register()}
              name={`variants[${variantNumber}].shopifyVariantId`}
              defaultValue={defaultValues.shopifyVariantId || ''}
              errorMessage={
                errors?.variants?.[variantNumber]?.shopifyVariantId?.message
              }
            />
          </div>
        )}
        <div>
          <Input
            label="Variant SKU"
            ref={register()}
            name={`variants[${variantNumber}].inventory.sku`}
            defaultValue={defaultValues.sku || ''}
            errorMessage={
              errors?.variants?.[variantNumber]?.inventory?.sku?.message
            }
          />
        </div>
        {/* TODO: Use Switch component instead of native checkbox */}
        <div className="flex">
          <div className="w-1/2 flex gap-4 items-center">
            <input
              type="checkbox"
              ref={register()}
              name={`variants[${variantNumber}].public`}
              id={`variants[${variantNumber}].public`}
              value="public"
              defaultChecked={defaultValues.public}
            />
            <Label htmlFor={`variants[${variantNumber}].public`}>
              Visible to patients
            </Label>
          </div>
        </div>
        {defaultValues.id && (
          <div className="flex items-center justify-between">
            <div>
              <Button
                fullWidth
                type="button"
                color="primary"
                onClick={async (): Promise<void> => {
                  await onSave(variantNumber);
                }}
              >
                Save
              </Button>
            </div>
            <div>
              <Button
                fullWidth
                type="button"
                color="danger"
                variant="outline"
                onClick={async (): Promise<void> => {
                  await onDelete(variantNumber);
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        )}
        {!defaultValues.id && (
          <div className="flex items-center justify-between">
            <div>
              <Button
                fullWidth
                type="button"
                color="primary"
                onClick={async (): Promise<void> => {
                  await onCreate(variantNumber);
                }}
              >
                Create
              </Button>
            </div>
            <div>
              <Button
                fullWidth
                type="button"
                color="danger"
                variant="outline"
                onClick={async (): Promise<void> => {
                  await onDelete(variantNumber);
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        )}
      </div>
    </li>
  );
};
