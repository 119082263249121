import { gql, useMutation } from '@apollo/client';
import {
  UpdateCustomerProfileMutation,
  UpdateCustomerProfileMutationVariables,
} from 'graphql/types';
import { CopyToClipboardButton } from 'components/copy-to-clipboard-button';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useNotifications } from '../../notifications';
import { Customer, CustomerDetailsSubmit } from './types';
import { useHasPermissions } from '../../components/permissions';
import { ResetPasswordModal } from './resetPasswordModal';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { ImpersonateCustomerModalButton } from 'components/impersonation/impersonate-customer-modal-button';
import {
  emailIsValid,
  mobileNumberValidation,
  noUppercaseLetters,
  requiredValidation,
} from '../../utils/form-validation';
import { InputError } from '../../components/input-error';

export const CustomerSection = ({
  customer,
  refetchCustomer,
}: {
  customer: Customer;
  refetchCustomer: () => void;
}): React.ReactElement => {
  return (
    <div className="bg-white shadow overflow-hidden rounded my-4">
      <div className="flex flex-row justify-between px-4 py-5 border-b border-gray-200">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Patient Details
        </h3>
        <ImpersonateCustomerModalButton customerId={customer.id} />
      </div>
      <div className="px-4 py-5 border-b border-gray-200"></div>
      <>
        <dl>
          <div className="bg-gray-100 px-4 py-3 flex items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm leading-5 font-medium text-gray-500">
              Patient ID
            </dt>
            <dd className="text-sm leading-5 text-gray-500 sm:mt-0 sm:col-span-2">
              {customer.id}
              <CopyToClipboardButton value={customer.id} />
            </dd>
          </div>
        </dl>
        <div className="flex justify-end gap-2 p-2 border-t border-gray-200"></div>
        <CustomerForm customer={customer} refetchCustomer={refetchCustomer} />
      </>
    </div>
  );
};

export const CustomerForm = ({
  customer,
  refetchCustomer,
}: {
  customer: Customer;
  refetchCustomer: () => void;
}): React.ReactElement => {
  const featureFlagClient = useFeatureFlagClient();

  const enablePreferredName = featureFlagClient.getBoolean(
    'innoweek-preferred-name',
    { defaultValue: false, disableExposureTracking: true },
  );

  const { customerId } = useParams<{ customerId: string }>();
  const showNotification = useNotifications();
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);

  const canEditPatients = useHasPermissions(['EDIT_PATIENTS']);

  const defaultValues = {
    firstName: customer.legalFirstName || undefined,
    preferredName: customer.preferredName || undefined,
    lastName: customer.lastName || undefined,
    phone: customer.phone || undefined,
    email: customer.email || undefined,
    password: undefined,
  };
  const {
    errors,
    register,
    handleSubmit: handleCustomerDetailsSubmit,
    formState: {
      isSubmitting: customerDetailsSubmitting,
      dirtyFields,
      isDirty,
      isValid,
    },
  } = useForm<CustomerDetailsSubmit>({
    defaultValues,
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const [customerUpdateMutation, { loading }] = useMutation<
    UpdateCustomerProfileMutation,
    UpdateCustomerProfileMutationVariables
  >(gql`
    mutation UpdateCustomerProfile(
      $id: String!
      $firstName: String
      $lastName: String
      $email: String
      $phone: String
    ) {
      updateCustomerProfile(
        id: $id
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
      ) {
        id
      }
    }
  `);

  const updateCustomerProfileVariablesRef =
    useRef<UpdateCustomerProfileMutationVariables | null>(null);

  const submitCustomerDetails = async (
    formData: CustomerDetailsSubmit,
  ): Promise<void> => {
    const data = {
      firstName: dirtyFields.firstName
        ? formData.firstName ?? undefined
        : undefined,
      lastName: dirtyFields.lastName
        ? formData.lastName ?? undefined
        : undefined,
      phone: dirtyFields.phone ? formData.phone ?? undefined : undefined,
      email: dirtyFields.email ? formData.email ?? undefined : undefined,
    };

    updateCustomerProfileVariablesRef.current = {
      id: customerId,
      ...data,
    };

    await updateCustomerProfile(updateCustomerProfileVariablesRef.current);
  };

  const updateCustomerProfile = async (
    variables: UpdateCustomerProfileMutationVariables,
  ): Promise<void> => {
    try {
      await customerUpdateMutation({
        variables,
      });
      showNotification({
        type: 'success',
        message: 'Updated patient details',
      });
      refetchCustomer();
    } catch {
      // error events show notifications already
      return;
    }
  };

  return (
    <form
      onSubmit={handleCustomerDetailsSubmit(submitCustomerDetails)}
      className="overflow-hidden bg-white p-4"
    >
      <div className="pr-4 mb-4 font-semibold text-gray-900">
        Personal Details
      </div>
      <div className="md:flex md:items-center mb-3">
        <div className="md:w-1/3">
          <label
            className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
            htmlFor="firstName"
          >
            Legal Name
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            id="firstName"
            type="text"
            ref={register}
            name="firstName"
            defaultValue={customer.legalFirstName || undefined}
          />
        </div>
      </div>
      {enablePreferredName && (
        <div className="md:flex md:items-center mb-3">
          <div className="md:w-1/3">
            <label
              className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="preferredName"
            >
              Preferred Name
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="preferredName"
              type="text"
              ref={register}
              name="preferredName"
              defaultValue={customer.preferredName || undefined}
            />
          </div>
        </div>
      )}
      <div className="md:flex md:items-center mb-3">
        <div className="md:w-1/3">
          <label
            className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
            htmlFor="lastName"
          >
            Last Name
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            id="lastName"
            type="text"
            ref={register}
            name="lastName"
            defaultValue={customer.lastName || undefined}
          />
        </div>
      </div>
      <div className="md:flex md:items-center mb-3">
        <div className="md:w-1/3">
          <label
            className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
            htmlFor="email"
          >
            Email
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            id="email"
            type="text"
            ref={register({
              ...requiredValidation('emailAddress'),
              ...emailIsValid,
              ...noUppercaseLetters,
            })}
            name="email"
            defaultValue={customer.email || undefined}
          />
          <InputError>{errors.email?.message}</InputError>
        </div>
      </div>
      <div className="md:flex md:items-center mb-3">
        <div className="md:w-1/3">
          <label
            className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
            htmlFor="phone"
          >
            Phone
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            id="phone"
            type="text"
            ref={register(mobileNumberValidation('phone', customer.phone))}
            name="phone"
            defaultValue={customer.phone || undefined}
          />
          <InputError>{errors.phone?.message}</InputError>
        </div>
      </div>
      <div className="flex justify-between">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border disabled:cursor-not-allowed disabled:opacity-25 border-gray-300 text-sm leading-5 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-gray-800 active:bg-white transition duration-150 ease-in-out"
          onClick={(): void => setShowResetPasswordModal(true)}
          disabled={passwordReset}
        >
          {passwordReset ? 'Password reset' : 'Reset password'}
        </button>
        <button
          type="submit"
          className="inline-flex items-center px-4 py-2 border disabled:cursor-not-allowed disabled:opacity-25 border-gray-300 text-sm leading-5 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-gray-800 active:bg-white transition duration-150 ease-in-out"
          disabled={
            customerDetailsSubmitting ||
            !isDirty ||
            loading ||
            !canEditPatients ||
            !isValid
          }
        >
          ✔ Save changes
        </button>
      </div>
      <ResetPasswordModal
        show={showResetPasswordModal}
        onResetEmailSent={(): void => setPasswordReset(true)}
        onClose={(): void => setShowResetPasswordModal(false)}
        customerId={customer.id}
        email={customer.email}
      />
    </form>
  );
};
