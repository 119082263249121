import React, { useCallback } from 'react';
import { GetSequencesListQuery } from '../../graphql/types';
import { routes } from '../../utils/routes';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  usePaginatingSortingTable,
} from '../../components/table';
import { Loading } from '../../components/loading';
import { getProblemTypeColor, getProblemTypeEmoji } from '../../utils/misc';
import { Tag } from '../../components/tag';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Cell, CellProps, Column } from 'react-table';
import { Button } from 'components/button';

export const SequencesPage = (): React.ReactElement => {
  const history = useHistory();

  const { data, loading, error } = useQuery<GetSequencesListQuery>(gql`
    query getSequencesList {
      sequences(problemTypes: []) {
        id
        internalName
        friendlyName
        problemTypes
        status
      }
    }
  `);

  const goToSequence = useCallback(
    (cell: Cell<Sequence, unknown>) => {
      if (cell.column.id !== 'selection') {
        history.push(
          generatePath(routes.sequence, {
            sequenceId: cell.row.original.id,
          }),
        );
      }
    },
    [history],
  );

  const tableInstance = usePaginatingSortingTable({
    columns,
    data: data?.sequences ?? [],
    pageNumber: 1,
    disableSortBy: true,
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div>
        Error - Unable to load sequences, please contact #help-engineering.
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-end">
        <div className="mb-5">
          <Link to={routes.createSequence}>
            <Button fullWidth variant="outline">
              Create New
            </Button>
          </Link>
        </div>
      </div>
      <Table tableInstance={tableInstance}>
        <TableHead />
        <TableBody>
          {tableInstance.page.map(
            (row) => (
              tableInstance.prepareRow(row),
              (
                <TableRow row={row} key={row.id}>
                  {row.cells.map((cell) => (
                    <TableCell
                      key={`${cell.row.original.id}-${cell.column.id}`}
                      cell={cell}
                      onClick={goToSequence}
                    />
                  ))}
                </TableRow>
              )
            ),
          )}
        </TableBody>
      </Table>
    </>
  );
};

type Sequence = NonNullable<GetSequencesListQuery['sequences']>[number];

const ProblemTypesCell = (
  cell: CellProps<Sequence, Sequence['problemTypes']>,
): React.ReactElement => (
  <div className="mt-1">
    {cell.value?.map((problemType, i) => (
      <div key={`${i}_${problemType}`} className="inline-block mr-1 mb-1">
        <Tag size="small" color={getProblemTypeColor(problemType)}>
          <span role="img" className="pr-1">
            {problemType && getProblemTypeEmoji(problemType)}
          </span>
          {problemType?.replaceAll('_', ' ')}
        </Tag>
      </div>
    ))}
  </div>
);

function StatusCell(
  cell: CellProps<Sequence, Sequence['status']>,
): React.ReactElement {
  if (cell.value === 'AVAILABLE') {
    return (
      <Tag size="small" color="green">
        Available
      </Tag>
    );
  }
  if (cell.value === 'DEPRECATED') {
    return (
      <Tag size="small" color="red">
        Deprecated
      </Tag>
    );
  }
  if (cell.value === 'PHASING_OUT') {
    return (
      <Tag size="small" color="yellow">
        Phasing Out
      </Tag>
    );
  }
  if (cell.value === 'UNAVAILABLE') {
    return (
      <Tag size="small" color="blue">
        Unavailable
      </Tag>
    );
  }
  if (cell.value === 'DRAFT') {
    return (
      <Tag size="small" color="gray">
        Draft
      </Tag>
    );
  }
  return <div>{cell.value}</div>;
}

const columns: Column<Sequence>[] = [
  {
    Header: 'Sequence Name',
    accessor: 'internalName',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-3/8',
  },
  {
    Header: 'Patient Facing Friendly Name',
    accessor: 'friendlyName',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-2/8',
  },
  {
    Header: 'Problem Types',
    accessor: 'problemTypes',
    Cell: ProblemTypesCell,
    className: 'w-2/8',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: StatusCell,
    className: 'w-1/8',
  },
];
