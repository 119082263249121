import React, { useEffect } from 'react';
import useCopyToClipboard from 'react-use/lib/useCopyToClipboard';
import { useAuth } from 'auth';
import { useNotifications } from 'notifications';
import * as keyboardjs from 'keyboardjs';

const Home = (): React.ReactElement => {
  const [, copyToClipboard] = useCopyToClipboard();
  const { accessToken: token } = useAuth();
  const showNotification = useNotifications();
  const copyAccessTokenIntoClipboard = React.useCallback(() => {
    if (token) {
      copyToClipboard(token);
      showNotification({
        type: 'success',
        message: 'DONE',
      });
    }
  }, [showNotification, token, copyToClipboard]);
  useEffect(() => {
    keyboardjs.bind('alt + shift > c', () => {
      copyAccessTokenIntoClipboard();
    });
    keyboardjs.watch();
    return (): void => {
      keyboardjs.stop();
    };
  }, [copyAccessTokenIntoClipboard]);
  return (
    <div>
      <h1 onClick={copyAccessTokenIntoClipboard}>Home</h1>
    </div>
  );
};

export default Home;
