import clsx from 'clsx';
import { Dropdown } from 'components/dropdown';
import { EventNodeName, ProductType, TimeInterval } from 'graphql/types';
import { useEffect, useMemo } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import {
  FaBox,
  FaClock,
  FaCode,
  FaCopy,
  FaPencilAlt,
  FaPlusCircle,
  FaRocket,
  FaSave,
  FaTrash,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Handle, NodeProps, Position } from 'reactflow';
import { formatCurrencyAmountCents } from 'utils/misc';
import { buildRoute } from 'utils/routes';
import { Input } from 'components/input';
import { Copyable } from 'components/copyable';
import { gql } from '@apollo/client';
import { Tooltip } from 'components/tooltip';

export const sequenceNodeFragment = gql`
  fragment SequenceEditorSequenceNode on SequenceNode {
    id
    nextNodeId
    ... on WaitSequenceNode {
      intervalType: type
      count
    }
    ... on EventSequenceNode {
      name
    }
    ... on VariantSequenceNode {
      price
      quantity
      variant {
        id
      }
      maximumDurationInDaysToNextVariantNode
    }
    ... on InternalTriggerSequenceNode {
      triggerName
      topic
      label
    }
  }
`;

type VariantNode = {
  id: string;
  nextNodeId?: string | null;
  nodeType: 'variant';
  price: number;
  quantity: number;
  variantId: string;
  maximumDurationInDaysToNextVariantNode: number | null;
};

type WaitNode = {
  id: string;
  nextNodeId?: string | null;
  nodeType: 'wait';
  count: number;
  intervalType: TimeInterval;
};

type EventNode = {
  id: string;
  nextNodeId?: string | null;
  nodeType: 'event';
  name: EventNodeName;
};

type InternalTriggerNode = {
  id: string;
  nextNodeId?: string | null;
  nodeType: 'internal_trigger';
  triggerName: string;
  topic: string;
  label: string;
};

export type Node = VariantNode | WaitNode | EventNode | InternalTriggerNode;

export interface NodeData {
  node: Node;
  editing: boolean;
  loopSource: boolean;
  loopTargetable: boolean;
  controls?: {
    edit: () => void;
    save: (node: Node) => void;
    remove: () => void;
    insertLeft: () => void;
    insertRight: () => void;
  };
  variants?: Array<{
    id: string;
    productId: string;
    productType: ProductType;
    productName: string;
    name: string;
    sku: string;
    maximumDurationInDaysToNextVariantNode: number | null;
  }>;
  validTopics?: Array<string>;
}

const typeOptions: Array<{ label: string; value: Node['nodeType'] }> = [
  { label: 'Variant', value: 'variant' },
  { label: 'Wait', value: 'wait' },
  { label: 'Emit Event', value: 'event' },
  { label: 'Internal Trigger', value: 'internal_trigger' },
];
const intervalOptions: Array<{ label: string; value: TimeInterval }> = [
  { value: 'DAYS', label: 'Days' },
  { value: 'MONTHS', label: 'Months' },
];
const eventNameOptions: Array<{ label: string; value: EventNodeName }> = [
  { value: 'UPCOMING_ORDER', label: 'Upcoming Order' },
];

export function Node(props: NodeProps<NodeData>) {
  const {
    editing,
    loopSource,
    loopTargetable,
    node,
    controls,
    variants,
    validTopics,
  } = props.data;

  const { reset, handleSubmit, register, control, errors, watch } =
    useForm<Node>({ defaultValues: node });

  useEffect(() => {
    reset(node);
  }, [node, reset]);

  const submitHandler = async (formValues: Node): Promise<void> => {
    controls?.save(formValues);
  };

  const variantOptions = useMemo(() => {
    return (
      variants?.map((variant) => ({
        value: variant.id,
        label: `${variant.productType} - ${variant.productName} - ${
          variant.name
        }${variant.sku || ''}`,
      })) || []
    );
  }, [variants]);

  const topicOptions = useMemo(
    () => validTopics?.map((topic) => ({ value: topic, label: topic })) || [],
    [validTopics],
  );

  let title = 'Node';
  let content = null;
  let icon = null;

  switch (node.nodeType) {
    case 'variant': {
      const variant = variants?.find((v) => v.id === node.variantId);
      icon = <FaBox />;
      title = 'Variant';

      content = (
        <div className="flex flex-col text-sm gap-1">
          <div className="flex justify-between text-sm">
            <span className="font-semibold basis-1/3">Name</span>
            <Link
              className="underline whitespace-nowrap overflow-ellipsis overflow-hidden"
              to={buildRoute.product(variant?.productId ?? '')}
            >
              {variant?.name}
            </Link>
          </div>
          <div className="flex justify-between hover:text-zinc-950 text-sm">
            <span className="font-semibold basis-1/3">ID</span>
            <Copyable text={node.variantId ?? ''}>
              {(copied) => (
                <pre className="cursor-pointer">
                  {copied ? 'Copied' : node.variantId?.slice(-8)}
                </pre>
              )}
            </Copyable>
          </div>
          <div className="flex justify-between hover:text-zinc-950 text-sm">
            <span className="font-semibold basis-1/3">SKU</span>
            <Copyable text={variant?.sku ?? ''}>
              {(copied) => (
                <pre className="cursor-pointer">
                  {copied ? 'Copied' : variant?.sku}
                </pre>
              )}
            </Copyable>
          </div>
          <div className="flex justify-between text-sm">
            <span className="font-semibold basis-1/3">Type</span>
            {variant?.productType}
          </div>
          <div className="flex justify-between text-sm">
            <span className="font-semibold basis-1/3">Unit Price</span>
            <span>
              {node.price} ({formatCurrencyAmountCents(node.price ?? 0)})
            </span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="font-semibold basis-1/3">Units</span>
            <span>{node.quantity}</span>
          </div>
          {(node.maximumDurationInDaysToNextVariantNode ||
            node.maximumDurationInDaysToNextVariantNode === 0) &&
            !Number.isNaN(node.maximumDurationInDaysToNextVariantNode) && (
              <div className="flex justify-between text-sm">
                <div>
                  <span className="font-semibold basis-1/3">Max Delay</span>
                  <Tooltip hoverText="Orders with this variant must execute within this many days" />
                </div>
                <span>{node.maximumDurationInDaysToNextVariantNode}</span>
              </div>
            )}
        </div>
      );
      break;
    }

    case 'internal_trigger':
      icon = <FaRocket />;
      title = 'Internal Trigger';
      content = (
        <div className="flex flex-col text-sm gap-1">
          <div className="flex justify-between text-sm">
            <span className="font-semibold basis-1/3">Name</span>
            {node.triggerName}
          </div>
          <div className="flex justify-between text-sm">
            <span className="font-semibold basis-1/3">Topic</span>
            <span>{node.topic}</span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="font-semibold basis-1/3">Label</span>
            <span>{node.label}</span>
          </div>
        </div>
      );
      break;

    case 'wait':
      icon = <FaClock />;
      title = `Wait ${node.count} ${node.intervalType.toLocaleLowerCase()}`;
      break;

    case 'event':
      icon = <FaCode />;
      title = 'Emit Event';
      content = (
        <div className="flex flex-col text-sm gap-1">
          <div className="flex justify-between text-sm">
            <span className="font-semibold basis-1/3">Name</span>
            {node.name}
          </div>
          <div className="flex justify-between text-sm">
            <span className="font-semibold basis-1/3">Body</span>
            <pre>{`{}`}</pre>
          </div>
        </div>
      );
      break;
  }

  if (editing) {
    const nodeType = watch('nodeType');

    icon = <FaPencilAlt />;
    title = 'Editing';

    const variantFormErrors: FieldErrors<VariantNode> = errors;
    const internalTriggerFormErrors: FieldErrors<InternalTriggerNode> = errors;
    const waitFormErrors: FieldErrors<WaitNode> = errors;
    const eventFormErrors: FieldErrors<EventNode> = errors;

    content = (
      <div className="flex flex-col gap-2 nodrag nowheel">
        <Dropdown
          name="nodeType"
          label="Node Type"
          control={control}
          options={typeOptions}
          errorMessage={errors?.nodeType?.message}
          rules={{
            required: { value: true, message: 'Node type is required' },
          }}
        />
        {nodeType === 'variant' && (
          <>
            <Dropdown
              name="variantId"
              label="Variant"
              control={control}
              options={variantOptions}
              errorMessage={variantFormErrors.variantId?.message}
              rules={{
                required: { value: true, message: 'Variant is required' },
              }}
            />
            <Input
              name="price"
              label="Price (cents, pence, yen)"
              type="number"
              ref={register({
                valueAsNumber: true,
                min: { value: 0, message: 'Price cannot be negative' },
                required: { value: true, message: 'Price is required' },
              })}
              errorMessage={variantFormErrors.price?.message}
            />
            <Input
              name="quantity"
              label="Quantity"
              type="number"
              ref={register({
                valueAsNumber: true,
                min: { value: 1, message: 'Quantity must be greater than 0' },
                required: { value: true, message: 'Quantity is required' },
              })}
              errorMessage={variantFormErrors?.quantity?.message}
            />
            <Input
              name="maximumDurationInDaysToNextVariantNode"
              label="Max delay"
              type="number"
              ref={register({
                valueAsNumber: true,
                min: { value: 0, message: 'Value must be 0 or greater' },
              })}
              errorMessage={
                variantFormErrors?.maximumDurationInDaysToNextVariantNode
                  ?.message
              }
            />
          </>
        )}
        {nodeType === 'internal_trigger' && (
          <>
            <Dropdown
              name="topic"
              label="Topic"
              control={control}
              options={topicOptions}
              errorMessage={internalTriggerFormErrors?.topic?.message}
              rules={{
                required: { value: true, message: 'Topic is required' },
              }}
            />
            <Input
              name="triggerName"
              label="Name"
              ref={register({
                required: { value: true, message: 'Name is required' },
              })}
              errorMessage={internalTriggerFormErrors.triggerName?.message}
            />
            <Input
              name="label"
              label="Label"
              ref={register({
                required: { value: true, message: 'Label is required' },
              })}
              errorMessage={internalTriggerFormErrors?.label?.message}
            />
          </>
        )}
        {nodeType === 'wait' && (
          <>
            <Dropdown
              name="intervalType"
              label="Interval"
              control={control}
              options={intervalOptions}
              errorMessage={waitFormErrors.intervalType?.message}
              rules={{
                required: { value: true, message: 'Interval type is required' },
              }}
            />
            <Input
              name="count"
              label="Count"
              type="number"
              ref={register({
                valueAsNumber: true,
                min: { value: 1, message: 'Count must be greater than 0' },
                required: { value: true, message: 'Count is required' },
              })}
              errorMessage={waitFormErrors.count?.message}
            />
          </>
        )}
        {nodeType === 'event' && (
          <>
            <Dropdown
              name="name"
              label="Name"
              control={control}
              options={eventNameOptions}
              errorMessage={eventFormErrors.name?.message}
              rules={{
                required: { value: true, message: 'Name is required' },
              }}
            />
          </>
        )}
      </div>
    );
  }

  return (
    <div
      className={clsx('w-80 text-zinc-800 shadow-md border-2', {
        'border-zinc-400 bg-zinc-100': props.selected,
        'border-zinc-200 bg-zinc-50': !props.selected,
      })}
    >
      <Handle
        type="target"
        className="!bg-zinc-200 !border-zinc-400"
        id="left-target"
        position={Position.Left}
      />
      <Handle
        type="target"
        className={clsx('!bg-zinc-200 !border-zinc-400', {
          '!invisible': !loopTargetable,
        })}
        id="top-target"
        position={Position.Top}
      />
      <Handle
        type="source"
        className={clsx('!bg-zinc-200 !border-zinc-400', {
          '!invisible': !loopSource,
        })}
        id="top-source"
        position={Position.Top}
      />
      <Handle
        type="source"
        className={clsx('!bg-zinc-200 !border-zinc-400', {
          '!invisible': loopSource,
        })}
        id="right-source"
        position={Position.Right}
      />
      <div className="px-4 py-2">
        <div className="flex flex-col">
          <div className="flex justify-between items-center pb-2">
            <div className="text-lg items-center flex gap-2 font-semibold capitalize">
              {icon}
              {title}
            </div>
            <Copyable text={node.id}>
              {(copied) => (
                <div className="cursor-pointer flex gap-2 text-zinc-600 hover:text-zinc-700 items-center">
                  <pre className="text-sm">
                    {copied ? 'Copied' : node.id.slice(-8)}
                  </pre>
                  <FaCopy size={12} />
                </div>
              )}
            </Copyable>
          </div>
          {content}
        </div>
      </div>

      {controls && (
        <div className="flex h-8 cursor-pointer mt-2 border-t-2 border-zinc-300">
          <div
            onClick={controls.insertLeft}
            className="basis-1/4 hover:bg-zinc-300 bg-zinc-200 flex items-center justify-center"
          >
            <FaPlusCircle className="text-zinc-600" size={18} />
          </div>
          {editing ? (
            <div
              onClick={handleSubmit(submitHandler)}
              className="basis-1/4 hover:bg-primary-200 bg-primary-100 flex items-center justify-center"
            >
              <FaSave className="text-zinc-600" size={18} />
            </div>
          ) : (
            <div
              onClick={controls.edit}
              className="basis-1/4 hover:bg-primary-200 bg-primary-100 flex items-center justify-center"
            >
              <FaPencilAlt className="text-zinc-600" size={18} />
            </div>
          )}
          <div
            onClick={controls.remove}
            className="basis-1/4 hover:bg-red-300 bg-red-200 flex items-center justify-center"
          >
            <FaTrash className="text-zinc-600" size={18} />
          </div>
          <div
            onClick={controls.insertRight}
            className="basis-1/4 hover:bg-zinc-300 bg-zinc-200 flex items-center justify-center"
          >
            <FaPlusCircle className="text-zinc-600" size={18} />
          </div>
        </div>
      )}
    </div>
  );
}
