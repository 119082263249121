import React, { ReactElement } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuth } from 'auth';
import { routes } from '../utils/routes';
import { MainLayout } from './main-layout';
import { GlobalLoader } from 'global-loader';

function PrivateRoute({ children, ...rest }: RouteProps): ReactElement {
  const { accessToken, isPermissionLoaded } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }): React.ReactNode =>
        accessToken ? (
          <MainLayout>
            <React.Suspense fallback={<GlobalLoader />}>
              <>{isPermissionLoaded && children}</>
            </React.Suspense>
          </MainLayout>
        ) : (
          <Redirect
            to={{
              pathname: routes.login,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
