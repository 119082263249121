import { gql, MutationTuple, useMutation, useQuery } from '@apollo/client';
import {
  Role,
  Permission,
  AddRolePermissionsInput,
  RemoveRolePermissionsInput,
  RemoveRoleUsersInput,
  AddRoleUsersInput,
  User,
} from 'graphql/types';
import { Accordion } from 'components/accordion';
import { Button } from 'components/button';
import { Dropdown } from 'components/dropdown';
import { Loading } from 'components/loading';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { AuditLogTable } from 'components/audit/audit-log-table';
import { useHasOneOfPermissions } from 'components/permissions';
import { routes } from 'utils/routes';
import { Redirect } from 'react-router-dom';

export default function Permissions(): React.ReactElement {
  const canAccessPage = useHasOneOfPermissions([
    'ADD_ROLE_PERMISSION',
    'ADD_USER_ROLE',
  ]);
  const permissionsPage = useQuery<{
    roles: Array<Role>;
    permissions: Array<Permission>;
    adminUsers: Array<User>;
  }>(
    gql`
      fragment user on User {
        id
        firstName
        lastName
        fullName
      }
      query permissionsPage {
        roles {
          id
          value
          permissions {
            id
            value
          }
          users {
            ...user
          }
        }
        permissions {
          id
          value
        }
        adminUsers {
          ...user
        }
      }
    `,
    { skip: !canAccessPage },
  );

  if (!canAccessPage) {
    return (
      <Redirect
        to={{
          pathname: routes.home,
        }}
      />
    );
  }

  if (!permissionsPage.data) {
    return <Loading />;
  }

  const roles = permissionsPage.data?.roles ?? [];
  const permissions = permissionsPage.data?.permissions ?? [];
  const users = permissionsPage.data?.adminUsers ?? [];

  return (
    <div className="space-y-5">
      {roles.map((r) => (
        <RoleAccordion
          key={r.id}
          permissions={r.permissions ?? []}
          users={r.users ?? []}
          allPermissions={permissions}
          allUsers={users}
          id={r.id}
          value={r.value}
        />
      ))}
    </div>
  );
}

function RoleAccordion(props: {
  id: string;
  value: string;
  permissions: Array<Permission>;
  users: Array<User>;
  allPermissions: Array<Permission>;
  allUsers: Array<User>;
}): React.ReactElement {
  const [addRolePermissions] = useAddRolePermissions();
  const [removeRolePermissions] = useRemoveRolePermissions();
  const [addRoleUsers] = useAddRoleUsers();
  const [removeRoleUsers] = useRemoveRoleUsers();

  const { reset, control, formState, handleSubmit } = useForm<
    Partial<{
      permissionIds: Array<string>;
      userIds: Array<string>;
    }>
  >({ mode: 'onChange' });

  const resetForm = useCallback(() => {
    reset(
      {
        permissionIds: props.permissions.map((p) => p.id),
        userIds: props.users.map((u) => u.id),
      },
      {
        isDirty: false,
      },
    );
  }, [reset, props.permissions, props.users]);

  React.useEffect(() => {
    resetForm();
  }, [resetForm]);

  return (
    <form
      className="bg-white overflow-visible"
      onSubmit={handleSubmit(async (fields) => {
        const nominatedPermissionIds = new Set(fields.permissionIds);
        const existingPermissionIds = new Set(
          props.permissions.map((p) => p.id),
        );
        const permissionIdsToRemove = Array.from(existingPermissionIds).filter(
          (p) => !nominatedPermissionIds.has(p),
        );
        const permissionIdsToAdd = Array.from(nominatedPermissionIds).filter(
          (p) => !existingPermissionIds.has(p),
        );
        const nominatedUserIds = new Set(fields.userIds);
        const existingUserIds = new Set(props.users.map((p) => p.id));
        const userIdsToRemove = Array.from(existingUserIds).filter(
          (p) => !nominatedUserIds.has(p),
        );

        if (permissionIdsToRemove.length) {
          await removeRolePermissions({
            variables: {
              input: {
                roleId: props.id,
                permissionIds: permissionIdsToRemove,
              },
            },
          });
        }

        if (permissionIdsToAdd.length) {
          await addRolePermissions({
            variables: {
              input: {
                roleId: props.id,
                permissionIds: permissionIdsToAdd,
              },
            },
          });
        }

        if (userIdsToRemove.length) {
          await removeRoleUsers({
            variables: {
              input: {
                roleId: props.id,
                userIds: userIdsToRemove,
              },
            },
          });
        }

        if (nominatedUserIds.size) {
          await addRoleUsers({
            variables: {
              input: {
                roleId: props.id,
                userIds: Array.from(nominatedUserIds),
              },
            },
          });
        }
      })}
    >
      <Accordion overflow="visible" title={props.value}>
        <div className="px-4 pb-2 space-y-4">
          <Dropdown
            label="Permissions"
            name="permissionIds"
            isMulti={true}
            menuPlacement="auto"
            control={control}
            options={props.allPermissions.map((p) => ({
              label: p.value,
              value: p.id,
            }))}
          />
          <Dropdown
            label="Users"
            name="userIds"
            isMulti={true}
            menuPlacement="auto"
            control={control}
            options={props.allUsers.map((u) => ({
              label: `${u.fullName}`,
              value: u.id,
            }))}
          />
          <div className="flex items-center justify-end">
            <div>
              <Button
                fullWidth
                variant="text"
                disabled={!formState.isDirty}
                onClick={resetForm}
              >
                Cancel
              </Button>
            </div>
            <div>
              <Button
                fullWidth
                type="submit"
                variant="outline"
                loading={formState.isSubmitting}
                disabled={!formState.isDirty}
              >
                Set
              </Button>
            </div>
          </div>
        </div>
        <AuditLogTable targetId={props.value} targetType="ADMIN_USER_ROLE" />
      </Accordion>
    </form>
  );
}

function useAddRolePermissions(): MutationTuple<
  unknown,
  { input: AddRolePermissionsInput }
> {
  return useMutation(gql`
    mutation addRolePermissions($input: AddRolePermissionsInput!) {
      addRolePermissions(input: $input) {
        role {
          id
          permissions {
            id
            value
          }
        }
      }
    }
  `);
}

function useRemoveRolePermissions(): MutationTuple<
  unknown,
  { input: RemoveRolePermissionsInput }
> {
  return useMutation(gql`
    mutation removeRolePermissions($input: RemoveRolePermissionsInput!) {
      removeRolePermissions(input: $input) {
        role {
          id
          permissions {
            id
            value
          }
        }
      }
    }
  `);
}

function useAddRoleUsers(): MutationTuple<
  unknown,
  { input: AddRoleUsersInput }
> {
  return useMutation(gql`
    mutation addRoleUsers($input: AddRoleUsersInput!) {
      addRoleUsers(input: $input) {
        role {
          id
          users {
            id
            firstName
            lastName
            fullName
          }
        }
      }
    }
  `);
}

function useRemoveRoleUsers(): MutationTuple<
  unknown,
  { input: RemoveRoleUsersInput }
> {
  return useMutation(gql`
    mutation removeRoleUsers($input: RemoveRoleUsersInput!) {
      removeRoleUsers(input: $input) {
        role {
          id
          users {
            id
            firstName
            lastName
            fullName
          }
        }
      }
    }
  `);
}
