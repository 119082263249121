import React from 'react';
import clsx from 'clsx';
import { FaCaretDown } from 'react-icons/fa';
import { Tag, TagProps } from './tag';
import { Tooltip } from './tooltip';

export type AccordionTitleTagProps = {
  size?: TagProps['size'];
  color?: TagProps['color'];
  shape?: TagProps['shape'];
  text: string;
};

export const Accordion = ({
  title,
  children,
  type = 'default',
  overflow = 'hidden',
  tags,
  startOpen = true,
  tooltipHoverText,
}: {
  title: string;
  children?: React.ReactNode;
  type?: 'danger' | 'default';
  overflow?: 'hidden' | 'visible';
  tags?: AccordionTitleTagProps[];
  startOpen?: boolean;
  tooltipHoverText?: string;
}): JSX.Element => {
  const [open, setOpen] = React.useState(startOpen);

  const caretClass = clsx(
    'transition-transform transform duration-300 ease-in-out scale-150 text-gray-600',
    { 'rotate-180': open },
  );

  const accordionClass = clsx(
    'transition-all ease-in-out duration-200',
    {
      'max-h-0': !open,
    },
    {
      'overflow-hidden': !open || overflow === 'hidden',
      'overflow-visible': open && overflow === 'visible',
    },
  );

  return (
    <div className="shadow-card">
      <div
        className="flex justify-between items-center cursor-pointer p-4"
        onClick={(): void => setOpen(!open)}
      >
        <div className="heading-sm leading-tight flex items-center space-x-2">
          {tags && (
            <span className="space-x-2">
              {tags.map((tag) => (
                <Tag key={tag.text} {...tag}>
                  {tag.text}
                </Tag>
              ))}
            </span>
          )}
          <span className={clsx({ 'text-red-500': type === 'danger' })}>
            {title}
          </span>
          {tooltipHoverText && <Tooltip hoverText={tooltipHoverText} />}
        </div>
        <div className={caretClass}>
          <FaCaretDown />
        </div>
      </div>
      <div className={accordionClass}>
        <div>{children}</div>
      </div>
    </div>
  );
};
