import { gql, useMutation } from '@apollo/client';
import { Button } from 'components/button';
import { Markdown } from 'components/markdown';
import { TextArea } from 'components/text-area';
import {
  NoteDomain,
  TargetType,
  UpsertNoteFormMutation,
  UpsertNoteFormMutationVariables,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { requiredValidation } from 'utils/form-validation';
import { useId } from 'utils/use-id';
import { noteRowFragment } from './notes-table';
import { useSessionStorage } from 'utils/use-session-storage';
import clsx from 'clsx';
import { customerCriticalNoteDocument } from './critical-note/critical-note-card';

const classNames = {
  h3: 'text-xs leading-4 font-medium text-gray-700 uppercase tracking-wider mb-1',
};

type UpsertNoteFormProps = {
  title: 'Note' | 'Addendum' | 'Critical Note' | 'Archival Note';
  disabled?: boolean;
  onCompleted: () => void;
  maxLength?: number;
  allowPreview?: boolean;
  noteInput: {
    id?: string;
    body?: string;
    targetId: string;
    targetType: TargetType;
    domain: NoteDomain;
  };
};

export const UpsertNoteForm: React.FC<UpsertNoteFormProps> = ({
  title,
  noteInput,
  onCompleted,
  disabled,
  maxLength,
  allowPreview = true,
}) => {
  const showNotification = useNotifications();
  const [showPreview, setShowPreview] = useState(false);

  const [upsertNote, { loading }] = useMutation<
    UpsertNoteFormMutation,
    UpsertNoteFormMutationVariables
  >(
    gql`
      mutation UpsertNoteForm($input: UpsertNoteInput!) {
        upsertNote(input: $input) {
          note {
            ...noteRow

            ... on CustomerNote {
              customer {
                id
                ...CustomerCriticalNote
              }
            }

            ... on ConsultationNote {
              id
              consultation {
                id
                customer {
                  id
                }
              }
            }

            ... on MetaNote {
              parent {
                id
                canArchive
                canEdit
                archivalNote {
                  id
                  body
                  author {
                    id
                    fullName
                    clinicianName
                    role
                  }
                }
                ...addendums
              }
            }
          }
        }
      }

      ${noteRowFragment}
      ${customerCriticalNoteDocument}
    `,
    {
      onCompleted: () => {
        showNotification({
          type: 'success',
          message: 'New note successfully added',
        });
      },
      onError: () => {
        showNotification({
          type: 'error',
          message: 'Unable to add the note',
        });
      },
      update: (cache, { data }) => {
        const note = data?.upsertNote?.note;
        if (!note) {
          return;
        }

        // if updating an existing note, changes are handled from the mutation response
        if (noteInput.id) {
          return;
        }

        // critical notes and archiving are handled from response
        if (['CUSTOMER_CRITICAL', 'NOTE_ARCHIVAL'].includes(note.domain)) {
          return;
        }

        if (note.__typename === 'CustomerNote') {
          cache.modify({
            fields: {
              filteredNotes(previous, { storeFieldName }) {
                if (
                  note.customer &&
                  !storeFieldName.includes(note.customer.id)
                ) {
                  return previous;
                }

                const ref = cache.writeFragment({
                  data: note,
                  fragment: gql`
                    # eslint-disable-next-line @graphql-eslint/no-unused-fragments
                    fragment noteUpdate on Note {
                      id
                    }
                  `,
                });

                if (!ref) {
                  throw new Error(
                    'Unable to add note to cache, please refresh the page',
                  );
                }

                return [ref, ...previous];
              },
            },
          });
        }

        if (note.__typename === 'ConsultationNote') {
          cache.modify({
            fields: {
              filteredNotes(previous, { storeFieldName }) {
                if (
                  note.consultation &&
                  !(
                    storeFieldName.includes(note.consultation.id) ||
                    storeFieldName.includes(note.consultation.customer.id)
                  )
                ) {
                  return previous;
                }

                const ref = cache.writeFragment({
                  data: note,
                  fragment: gql`
                    # eslint-disable-next-line @graphql-eslint/no-unused-fragments
                    fragment noteUpdate on Note {
                      id
                    }
                  `,
                });

                if (!ref) {
                  throw new Error(
                    'Unable to add note to cache, please refresh the page',
                  );
                }

                return [ref, ...previous];
              },
            },
          });
        }
      },
    },
  );
  const { get: getStoredBody, set: setStoredBody } = useSessionStorage(
    ['note', noteInput.targetType, noteInput.targetId, noteInput.domain].join(
      '_',
    ),
  );

  const { register, watch, reset, handleSubmit, formState } = useForm<{
    body: string | undefined;
  }>({
    defaultValues: { body: noteInput.body ?? getStoredBody() },
  });

  // reset the form value when the note target or input body changes
  useEffect(() => {
    const storedBody = getStoredBody();
    const body = storedBody ? storedBody : noteInput.body;
    reset({ body });
  }, [reset, getStoredBody, noteInput.body]);

  const [noteId, { refreshId: refreshNoteId }] = useId(noteInput.id);

  const onSubmit = handleSubmit(async ({ body }) => {
    await upsertNote({
      variables: {
        input: {
          noteId,
          targetType: noteInput.targetType,
          targetId: noteInput.targetId,
          domain: noteInput.domain,
          body: body ?? '',
        },
      },
    });

    setStoredBody('');
    reset({ body: '' });

    if (!noteInput.id) {
      refreshNoteId();
    }

    onCompleted();
  });

  const bodyFieldValue = watch('body');

  const charactersRemainingHint = maxLength
    ? `${maxLength - (bodyFieldValue?.length ?? 0)} characters remaining`
    : undefined;

  return (
    <form onSubmit={onSubmit} className="space-y-5">
      <div className="text-base w-full">
        <div
          className={clsx('flex flex-col text-base', { hidden: showPreview })}
        >
          <h3 className={classNames.h3}>Add {title}</h3>
          <TextArea
            name="body"
            placeholder="Enter your note here"
            rows={10}
            disabled={disabled || loading}
            onChange={(event) => setStoredBody(event.target.value)}
            ref={register(requiredValidation(title))}
            maxLength={maxLength}
            hint={charactersRemainingHint}
            errorMessage={formState.errors.body?.message}
          />
        </div>

        {showPreview && (
          <div
            className={clsx('w-full flex flex-col', { hidden: !showPreview })}
          >
            <h3 className={classNames.h3}>Preview</h3>

            <div className="border border-gray-400 rounded-md bg-gray-100">
              <Markdown
                src={bodyFieldValue ?? ''}
                className="h-64 px-3 py-2 overflow-y-scroll tracking-tight"
              />
            </div>
          </div>
        )}
      </div>

      <footer
        className={clsx('flex gap-5', {
          'justify-between': allowPreview,
          'justify-end': !allowPreview,
        })}
      >
        {allowPreview && (
          <div>
            <Button onClick={() => setShowPreview(!showPreview)}>
              {showPreview ? 'Back to editing' : 'Show preview'}
            </Button>
          </div>
        )}

        <div className="flex gap-5">
          <Button onClick={onCompleted} color="danger" disabled={disabled}>
            Cancel
          </Button>

          <Button type="submit" loading={loading} disabled={disabled}>
            Save {title}
          </Button>
        </div>
      </footer>
    </form>
  );
};
