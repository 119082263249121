import { EducationCollection } from 'graphql/types';
import { VFC } from 'react';
import { CollectionCard } from './collection-card';

type CollectionsPageTemplateProps = {
  collections: EducationCollection[];
};

export const CollectionsPageTemplate: VFC<CollectionsPageTemplateProps> = ({
  collections,
}) => {
  return (
    <div className="space-y-4">
      {collections.map((collection) => (
        <CollectionCard key={collection.id} collection={collection} />
      ))}
    </div>
  );
};
