import React from 'react';

export function DataField({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}): React.ReactElement {
  return (
    <div className="even:bg-white-100 odd:bg-gray-100 px-4 py-3 flex items-center sm:grid sm:grid-cols-3 sm:gap-1 sm:px-3">
      <dt className="text-sm leading-5 font-medium text-gray-500">{label}</dt>
      <dd className="text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2 break-words">
        {children}
      </dd>
    </div>
  );
}
