import { Dropdown, Option } from '../../components/dropdown';
import { ClinicianType } from '../../graphql/types';
import React, { ReactElement } from 'react';
import { Control } from 'react-hook-form';

export const ClinicianTypeDropdown = ({
  control,
  name,
  errorMessage,
  hasAllOption = false,
}: {
  control: Control;
  name: string;
  errorMessage?: string;
  hasAllOption?: boolean;
}): ReactElement => {
  return (
    <Dropdown
      label="Practitioner Type"
      name={name}
      options={[
        ...(hasAllOption ? [{ label: 'All', value: undefined }] : []),
        ...clinicianTypeOptions,
      ]}
      control={control}
      errorMessage={errorMessage}
    />
  );
};

export const getClinicianTypeLabel = (clinicianType: ClinicianType): string => {
  switch (clinicianType) {
    case 'DOCTOR':
      return 'Doctor';
    case 'NURSE':
      return 'Nurse';
    case 'PHARMACIST':
      return 'Pharmacist';
  }
};

const clinicianTypeOptions: Option<ClinicianType>[] = [
  {
    label: getClinicianTypeLabel('DOCTOR'),
    value: 'DOCTOR',
  },
  {
    label: getClinicianTypeLabel('NURSE'),
    value: 'NURSE',
  },
  {
    label: getClinicianTypeLabel('PHARMACIST'),
    value: 'PHARMACIST',
  },
];
