import { Link } from 'react-router-dom';
import { buildRoute } from 'utils/routes';

export const EntityLink = ({
  id,
  type,
}: {
  id: string | undefined;
  type: string;
}): React.ReactElement => {
  if (!id) {
    return <></>;
  }

  switch (type) {
    case 'Order':
      return <Link to={buildRoute.order(id)}>{id}</Link>;
    case 'User':
      return <Link to={buildRoute.customer(id)}>{id}</Link>;
    case 'Consultation':
      return <Link to={buildRoute.consultation(id)}>{id}</Link>;
    case 'Script':
      return <Link to={buildRoute.script(id)}>{id}</Link>;
    default:
      return <>{id}</>;
  }
};
