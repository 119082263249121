import { ProblemType } from 'graphql/types';
import { Tag } from 'components/tag';
import React from 'react';
import { getProblemTypeColor, getProblemTypeEmoji } from 'utils/misc';

export const ProblemTypeTag = ({
  problemType,
}: {
  problemType?: ProblemType;
}): React.ReactElement => {
  if (!problemType) {
    return <div className="inline">N/A</div>;
  }
  return (
    <Tag size="small" color={getProblemTypeColor(problemType)}>
      <span role="img" className="pr-1">
        {getProblemTypeEmoji(problemType)}
      </span>
      {problemType.replaceAll('_', ' ')}
    </Tag>
  );
};
