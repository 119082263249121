import { gql, useMutation } from '@apollo/client';
import { Button } from 'components/button';
import { Input } from 'components/input';
import { Modal } from 'components/modal';
import {
  CreateEducationReadingMutation,
  CreateEducationReadingMutationVariables,
  EducationReading,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import { VFC } from 'react';
import { useForm } from 'react-hook-form';
import { requiredValidation } from 'utils/form-validation';

type CreateReadingValues = {
  name: string;
  contentfulId: string;
};

type AddReadingModalProps = {
  show: boolean;
  onCreatedReading: (educationReading: EducationReading) => void;
  onClose: () => void;
};

export const AddReadingModal: VFC<AddReadingModalProps> = ({
  show,
  onCreatedReading,
  onClose,
}) => {
  const showNotification = useNotifications();
  const [createReading, { loading: createReadingLoading }] = useMutation<
    CreateEducationReadingMutation,
    CreateEducationReadingMutationVariables
  >(gql`
    mutation CreateEducationReading(
      $createEducationReadingInput: CreateEducationReadingInput!
    ) {
      createEducationReading(
        createEducationReadingInput: $createEducationReadingInput
      ) {
        educationReading {
          id
          name
          contentfulId
        }
      }
    }
  `);
  const { register, handleSubmit, errors } = useForm<CreateReadingValues>();

  const saveReading = async ({
    name,
    contentfulId,
  }: CreateReadingValues): Promise<void> => {
    const res = await createReading({
      variables: { createEducationReadingInput: { name, contentfulId } },
    });
    const newReading = res.data?.createEducationReading?.educationReading;
    if (newReading) {
      showNotification({
        type: 'success',
        message: 'Successfully created a new reading.',
      });
      onCreatedReading(newReading);
    }
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div className="bg-white rounded p-4">
        <h1 className="text-2xl mb-6 font-bold">Add new reading</h1>
        <form onSubmit={handleSubmit(saveReading)}>
          <div className="flex flex-col space-y-8">
            <div className="flex flex-col space-y-4">
              <div>
                <Input
                  ref={register(requiredValidation('name'))}
                  label="Reading Name"
                  name="name"
                  errorMessage={errors.name?.message}
                />
              </div>
              <div>
                <Input
                  ref={register(requiredValidation('contentfulId'))}
                  label="Contentful ID"
                  name="contentfulId"
                  errorMessage={errors.contentfulId?.message}
                />
              </div>
            </div>
            <div className="flex flex-row space-x-4">
              <Button fullWidth variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                fullWidth
                color="success"
                loading={createReadingLoading}
                type="submit"
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};
