import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Loading } from 'components/loading';
import { VideosPageQuery, VideosPageQueryVariables } from 'graphql/types';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import {
  ContentItemsTable,
  ContentItemsTableData,
} from 'components/content/content-items-table';
import Select from 'react-select';
import { useChangeUrl } from '../../../utils/user-change-url';
import { useUrlQuery } from '../../../utils/use-url-query';
import { Label } from '../../../components/label';
import { UpdateItemModal } from './update-item';
import { CreateItemModal } from './create-item';

enum ModalScreen {
  CREATE,
  UPDATE,
}

export type ContentItemType =
  | 'VideoContentItem'
  | 'VideoWithReadingContentItem'
  | 'ReadingContentItem'
  | 'RecipeContentItem'
  | 'FaqContentItem';

const parseContentType = (type?: string): ContentItemType => {
  switch (type) {
    case 'VideoContentItem':
    case 'VideoWithReadingContentItem':
    case 'ReadingContentItem':
    case 'RecipeContentItem':
    case 'FaqContentItem':
      return type;
    default:
      throw Error(`Unsupported content type ${type}`);
  }
};

export const contentTypeSearchOptions = [
  { value: 'VideoContentItem', label: 'Video' },
  { value: 'ReadingContentItem', label: 'Reading' },
  { value: 'VideoWithReadingContentItem', label: 'Video with Reading' },
  { value: 'RecipeContentItem', label: 'Recipe' },
  { value: 'FaqContentItem', label: 'FAQ' },
];

const ContentSearch = ({ type }: { type: string }): React.ReactElement => {
  const changeUrl = useChangeUrl();

  const optionsWithAll = [
    { value: 'all', label: 'All' },
    ...contentTypeSearchOptions,
  ];

  return (
    <div className="w-1/4">
      <div className="mb-3">
        <Label htmlFor={'typeSelect'}>Type</Label>
      </div>
      <Select
        name="typeSelect"
        options={optionsWithAll}
        className="dropdown"
        classNamePrefix="dd"
        value={optionsWithAll.find((o) => o.value === type)}
        onChange={(option) => {
          if (option && 'value' in option) {
            changeUrl({ params: { type: option?.value } });
          }
        }}
      />
    </div>
  );
};

const Videos = (): React.ReactElement => {
  const urlQuery = useUrlQuery();
  const type = urlQuery.get('type') ?? 'all';

  const [selectedItemId, setSelectedItemId] = React.useState<string | null>(
    null,
  );
  const [modalScreen, setModalScreen] = React.useState<ModalScreen | null>(
    null,
  );

  const {
    data,
    loading: videosLoading,
    refetch: refetchVideos,
  } = useQuery<VideosPageQuery, VideosPageQueryVariables>(gql`
    query VideosPage {
      contentItems {
        id
        status
        tags {
          id
          name
          description
        }
        ... on VideoContentItem {
          contentfulId
          title
          description
          playbackUrl
          thumbnailUrl
          durationSeconds
        }
        ... on ReadingContentItem {
          contentfulId
          title
        }
        ... on VideoWithReadingContentItem {
          contentfulId
          title
          description
          playbackUrl
          thumbnailUrl
          durationSeconds
        }
        ... on RecipeContentItem {
          contentfulId
          title
          recipeDescription: description
          heroUrl
          thumbnailUrl
          serves
          cookingTimeMins
          kiloCalories
          videoDurationSeconds
          recipeVideoPlaybackUrl: playbackUrl
        }
        ... on FaqContentItem {
          contentfulId
          question
          answer
        }
      }
    }
  `);

  if (videosLoading) {
    return <Loading />;
  }

  if (!data?.contentItems) {
    return <div>Could not load content items</div>;
  }

  const tableData: ContentItemsTableData[] = data.contentItems
    .filter((item) => item.__typename === type || type === 'all')
    .map((item) => {
      let title = '';
      switch (item.__typename) {
        case 'FaqContentItem':
          title = item.question;
          break;
        case 'ReadingContentItem':
        case 'RecipeContentItem':
        case 'VideoContentItem':
        case 'VideoWithReadingContentItem':
          title = item.title;
          break;
      }

      const itemData = {
        id: item.id,
        status: item.status,
        contentfulId: item.contentfulId,
        title,
        type: parseContentType(item.__typename),
        deepLinks: `${item.id}:${item.__typename}`,
      };
      return itemData;
    });

  const openAddModal = (): void => {
    setModalScreen(ModalScreen.CREATE);
  };

  const openUpdateModal = (id: string): void => {
    setSelectedItemId(id);
    setModalScreen(ModalScreen.UPDATE);
  };

  const closeModal = (): void => {
    setModalScreen(null);
    setSelectedItemId(null);
  };

  const onCreateSuccess = async (id: string): Promise<void> => {
    await refetchVideos();
    openUpdateModal(id);
  };

  const onUpdateSuccess = async (): Promise<void> => {
    await refetchVideos();
    closeModal();
  };

  return (
    <section>
      <ContentSearch type={type} />
      <div className="flex justify-end mb-8">
        <div>
          <Button fullWidth variant="outline" onClick={openAddModal}>
            Add Item
          </Button>
        </div>
      </div>
      <ContentItemsTable data={tableData} onRowClick={openUpdateModal} />
      <Modal
        show={modalScreen !== null}
        width={
          modalScreen === ModalScreen.UPDATE
            ? 'max-w-screen-md'
            : 'max-w-screen-sm'
        }
        onClose={closeModal}
        isAutoOverflow={false}
      >
        <div className="bg-white rounded p-5">
          {modalScreen === ModalScreen.CREATE && (
            <CreateItemModal
              onCancel={closeModal}
              onCreateSuccess={onCreateSuccess}
            />
          )}
          {modalScreen === ModalScreen.UPDATE && (
            <UpdateItemModal
              item={data.contentItems.find(
                (item) => item.id === selectedItemId,
              )}
              onCancel={closeModal}
              onUpdateSuccess={onUpdateSuccess}
            />
          )}
        </div>
      </Modal>
    </section>
  );
};

export default Videos;
