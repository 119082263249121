import React from 'react';
import clsx from 'clsx';
import { Maybe } from 'graphql/types';

interface AvatarProps {
  user: {
    id: string;
    email: string;
    firstName?: Maybe<string>;
    lastName?: Maybe<string>;
    phone?: Maybe<string>;
    avatar?: Maybe<{
      url: string;
    }>;
  };
  size?: 'small' | 'medium' | 'large';
}

const avatarBgColors = [
  'bg-gray-500',
  'bg-red-500',
  'bg-orange-500',
  'bg-yellow-500',
  'bg-green-500',
  'bg-teal-500',
  'bg-blue-500',
  'bg-indigo-500',
  'bg-purple-500',
  'bg-pink-500',
];

// Hash a string to a number
// Used so that there is a consistent bg color on a user's avatar
const simpleStringHash = (str: string): number => {
  return str.split('').reduce((a, c) => a + c.charCodeAt(0), 0);
};

const getAvatarBgColor = (id: string): string => {
  return avatarBgColors[simpleStringHash(id) % avatarBgColors.length];
};

export const Avatar = ({
  user,
  size = 'medium',
}: AvatarProps): React.ReactElement => {
  const firstName = user.firstName?.substr(0, 1) ?? '';
  const lastName = user.lastName?.substr(0, 1) ?? '';
  const initials = firstName.concat(lastName);

  const bubbleClass = clsx(
    getAvatarBgColor(user.id),
    'rounded-full flex items-center justify-center overflow-hidden flex-shrink-0',
    { 'h-8 w-8': size === 'small' },
    { 'h-10 w-10': size === 'medium' },
    { 'h-16 w-16': size === 'large' },
  );

  const initialsClass = clsx(
    'text-white uppercase font-medium',
    { 'text-xs': size === 'small' },
    { 'text-md': size === 'medium' },
    { 'text-2xl': size === 'large' },
  );

  return (
    <div className={bubbleClass}>
      {user.avatar?.url ? (
        <img src={user.avatar.url} alt="profile" />
      ) : (
        <span className={initialsClass}>{initials}</span>
      )}
    </div>
  );
};
