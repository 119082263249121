import { gql, useQuery } from '@apollo/client';
import clsx from 'clsx';
import { Loading } from 'components/loading';
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useOrderBy,
  usePageIndex,
  useSelectablePaginatingSortingTable,
} from 'components/table';
import { UserFilter, useBuildUserQueryFilter } from 'components/user-filter';
import { CustomersQuery } from 'graphql/types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import { formatDateAndTime, formatPatientName } from 'utils/misc';
import { routes } from 'utils/routes';

const TABLE_PAGE_SIZE = 30;

const Customers = (): React.ReactElement => {
  const history = useHistory();
  const activeFilter = useBuildUserQueryFilter('CUSTOMER');
  const orderBy = useOrderBy({ defaultValue: 'createdAt_desc' });
  const pageIndex = usePageIndex();

  const { data, loading } = useQuery<CustomersQuery>(
    gql`
      query Customers(
        $orderBy: [UserOrderByInput!]
        $where: UserWhereInput
        $pageSize: Int
        $skip: Int
      ) {
        customers: users(
          orderBy: $orderBy
          where: $where
          take: $pageSize
          skip: $skip
        ) {
          id
          email
          firstName
          lastName
          fullName
          createdAt
          treatments(
            where: { status: { equals: ACTIVE } }
            orderBy: { createdAt: asc }
          ) {
            id
            product {
              id
              name
            }
          }
          purchases {
            id
          }
        }
        customersCount: usersCount(where: $where)
      }
    `,
    {
      variables: {
        where: activeFilter,
        pageSize: TABLE_PAGE_SIZE,
        skip: pageIndex * TABLE_PAGE_SIZE,
        orderBy,
      },
    },
  );

  const total = data?.customersCount ?? 0;
  const pageNumber = Math.ceil((total ?? 1) / TABLE_PAGE_SIZE);

  const customers = React.useMemo(() => data?.customers ?? [], [data]);

  const tableInstance = useSelectablePaginatingSortingTable({
    columns,
    data: customers,
    pageNumber,
    orderBy,
    pageIndex,
  });

  return (
    <div>
      <UserFilter />
      <div className={clsx({ 'visually-hidden': !customers.length })}>
        <Table tableInstance={tableInstance}>
          <TableHead />
          <TableBody>
            {tableInstance.page?.map((row) => {
              tableInstance.prepareRow(row);
              return (
                <TableRow
                  row={row}
                  key={row.id}
                  underline={
                    !!row.original.purchases &&
                    row.original.purchases.length > 0
                  }
                >
                  {row.cells.map((cell) => (
                    <TableCell
                      key={`${cell.row.original.id}-${cell.column.id}`}
                      cell={cell}
                      onClick={(): void => {
                        if (cell.column.id !== 'selection') {
                          history.push(
                            `${routes.customers}/${cell.row.original.id}`,
                          );
                        }
                      }}
                    />
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      {!customers.length && !loading && (
        <div className="text-center font-medium pt-8">No customers found</div>
      )}
      {loading && (
        <div className="flex justify-center text-lg">
          <Loading />
        </div>
      )}
      <Pagination total={total} tableInstance={tableInstance} />
    </div>
  );
};

const columns: Column<CustomersQuery['customers'][number]>[] = [
  {
    Header: 'Created',
    accessor: 'createdAt',
    Cell: (c) => <div>{formatDateAndTime(c.value)}</div>,
    className: 'w-48',
  },
  {
    Header: 'ID',
    accessor: 'id',
    className: 'w-48',
    link: (c) => `${routes.customers}/${c.id}`,
    disableSortBy: true,
  },
  {
    Header: 'Name',
    accessor: 'firstName',
    Cell: (c) => <div>{formatPatientName(c.row.original)}</div>,
    disableSortBy: true,
  },
  {
    Header: 'Email',
    accessor: 'email',
    Cell: (c) => <div>{c.value}</div>,
  },
  {
    Header: 'Treatments',
    accessor: 'treatments',
    Cell: (c) => <div>{c.value?.map((it) => it.product.name).join(', ')}</div>,
    disableSortBy: true,
  },
];

export default Customers;
