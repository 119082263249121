import { useState, VFC } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Card } from 'components/card';
import {
  updateLocalProgramWithNewReadingInModule,
  getCardOutlineClassName,
  prepareUpdateEducationModuleInputs,
  updateLocalProgramWithReadingRemovedFromModule,
  updateLocalProgramWithReorderedReadings,
} from 'components/education/utils';
import {
  EducationProgram,
  EducationReading,
  UpdateEducationModuleInput,
  UpdateEducationModuleMutation,
  UpdateEducationModuleMutationVariables,
} from 'graphql/types';
import { ReadingsTableCard } from './readings-table-card';
import { EducationHeader } from './education-header';

type ModifyProgramProps = {
  program: EducationProgram;
};

export const ModifyProgram: VFC<ModifyProgramProps> = ({ program }) => {
  const [localProgram, setLocalProgram] = useState<EducationProgram>(program);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [
    updateEducationModuleMutation,
    { loading: updateEducationModuleLoading },
  ] = useMutation<
    UpdateEducationModuleMutation,
    UpdateEducationModuleMutationVariables
  >(gql`
    mutation UpdateEducationModule(
      $updateEducationModuleInput: UpdateEducationModuleInput!
    ) {
      updateEducationModule(
        updateEducationModuleInput: $updateEducationModuleInput
      ) {
        educationModule {
          id
          name
          description
          readings {
            id
            name
            contentfulId
          }
        }
      }
    }
  `);

  const updateLocalProgram = (newProgram: EducationProgram): void => {
    setLocalProgram(newProgram);
    setUnsavedChanges(true);
  };

  const addReadingToModule = (
    educationReading: EducationReading,
    moduleId: string,
  ): void => {
    const newProgram = updateLocalProgramWithNewReadingInModule(
      localProgram,
      moduleId,
      educationReading,
    );
    updateLocalProgram(newProgram);
  };

  const removeReadingFromModule = (
    moduleId: string,
    readingId: string,
  ): void => {
    const newProgram = updateLocalProgramWithReadingRemovedFromModule(
      localProgram,
      moduleId,
      readingId,
    );
    updateLocalProgram(newProgram);
  };

  const reorderReadings = (
    moduleId: string,
    educationReadings: EducationReading[],
  ): void => {
    const newProgram = updateLocalProgramWithReorderedReadings(
      localProgram,
      moduleId,
      educationReadings,
    );
    updateLocalProgram(newProgram);
  };

  const onSaveProgram = async (): Promise<void> => {
    const updateEducationModuleInputs: UpdateEducationModuleInput[] =
      prepareUpdateEducationModuleInputs(localProgram.modules);
    const promises = updateEducationModuleInputs.map(
      (updateEducationModuleInput) =>
        updateEducationModuleMutation({
          variables: { updateEducationModuleInput },
        }),
    );
    for (const promise of promises) {
      await promise;
    }
    setUnsavedChanges(false);
  };

  const educationModules = localProgram.modules;

  return (
    <>
      <div className="space-y-2">
        <Card className={getCardOutlineClassName(unsavedChanges)}>
          <EducationHeader
            unsavedChanges={unsavedChanges}
            saveChangesLoading={updateEducationModuleLoading}
            name={`Program: ${localProgram.name}`}
            onSaveChanges={onSaveProgram}
          />
        </Card>
        <div className="p-4">
          {educationModules?.map((module) => (
            <ReadingsTableCard
              key={module.id}
              name={`Module: ${module.name}`}
              readings={module.readings || []}
              addReading={(reading): void =>
                addReadingToModule(reading, module.id)
              }
              unsavedChanges={unsavedChanges}
              removeReading={(readingId): void =>
                removeReadingFromModule(module.id, readingId)
              }
              reorderReadings={(readings): void =>
                reorderReadings(module.id, readings)
              }
            />
          ))}
        </div>
      </div>
    </>
  );
};
