import { CopyToClipboardButton } from 'components/copy-to-clipboard-button';
import { ReactElement, useState } from 'react';
import { formatDateAndTime, upperSnakeCaseToCapitalCase } from 'utils/misc';
import { QuizApplications } from '../types';
import { QuizModal } from './quiz-modal';

export const QuizTableBody = ({
  quiz: quizzes,
}: {
  quiz: NonNullable<QuizApplications>;
}): ReactElement => {
  const [showQuizModal, setShowQuizModal] = useState<
    NonNullable<QuizApplications>[number] | null
  >(null);

  const onClickHandle = (quiz: NonNullable<QuizApplications>[number]): void => {
    setShowQuizModal(quiz);
  };

  return (
    <>
      {quizzes.map((c) => (
        <tr key={c.id}>
          <td className="px-6 py-2 whitespace-nowrap text-sm leading-5 text-gray-500">
            <button onClick={(): void => onClickHandle(c)}>{c.id}</button>
            <CopyToClipboardButton value={c.id} />
          </td>
          <td className="px-6 py-2 whitespace-nowrap text-sm leading-5 text-gray-500">
            {c.quizId}
            <CopyToClipboardButton value={c.quizId} />
          </td>
          <td className="px-6 py-2 whitespace-nowrap">
            <span
              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100`}
            >
              {upperSnakeCaseToCapitalCase(c.purpose)}
            </span>
          </td>
          {[c.createdAt, c.submittedAt, c.archivedAt].map((value) => (
            <td className="px-6 py-2 whitespace-nowrap" key={value}>
              <div className="flex items-center">
                <div className="text-sm leading-5 text-gray-900">
                  {value ? formatDateAndTime(value) : `-`}
                </div>
              </div>
            </td>
          ))}
        </tr>
      ))}
      {showQuizModal && (
        <tr>
          <td>
            <QuizModal
              closeHandler={(): void => setShowQuizModal(null)}
              quiz={showQuizModal}
            />
          </td>
        </tr>
      )}
    </>
  );
};
