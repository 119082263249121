import React from 'react';
import { createRoot } from 'react-dom/client';
import { NotificationsProvider } from './notifications';
import { AuthProvider } from './auth';
import { ClientProvider } from './apollo';
import { GlobalLoaderProvider } from './global-loader';
import { useTitle } from './utils/use-title';
import './tailwind.generated.css';
import 'logging';
import Routes from 'routes';
import { GoogleAuthProvider } from 'google-auth';
import { WrappingFeatureFlagProvider } from 'wrapping-ff-provider';
import { ChatProvider } from './chat-provider';
import { ErrorBoundary } from './error-boundary';
import { MaintenanceModeWrapper } from './maintenance-mode-wrapper';

const App = (): React.ReactElement => {
  useTitle();
  return (
    <GlobalLoaderProvider>
      <NotificationsProvider>
        <GoogleAuthProvider>
          <AuthProvider>
            <WrappingFeatureFlagProvider>
              <MaintenanceModeWrapper>
                <ClientProvider>
                  <ChatProvider>
                    <ErrorBoundary>
                      <Routes />
                    </ErrorBoundary>
                  </ChatProvider>
                </ClientProvider>
              </MaintenanceModeWrapper>
            </WrappingFeatureFlagProvider>
          </AuthProvider>
        </GoogleAuthProvider>
      </NotificationsProvider>
    </GlobalLoaderProvider>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
