import type { ReactElement } from 'react';
import React from 'react';
import clsx from 'clsx';

type Option = { label: string; value: string; description?: string };

type ToggleTabsOptions = [Option, Option, ...Option[]];

type ToggleTabsProps = {
  options: ToggleTabsOptions;
  onOptionSelect: (value: string) => void;
  value?: string;
};

export const ToggleTabs = ({
  options,
  onOptionSelect,
  value,
}: ToggleTabsProps): ReactElement => {
  return (
    <div className="flex">
      {options.map(({ value: v, label, description }) => (
        <button
          key={v}
          onClick={() => onOptionSelect(v)}
          className={clsx(
            'w-full p-2 first:rounded-l last:rounded-r border border-r-0 last:border-r border-blue-300',
            {
              'bg-blue-50': value === v,
            },
          )}
        >
          <span className="text-sm font-semibold text-blue-500">{label}</span>
          {description && (
            <>
              <br />
              <span className="text-sm">{description}</span>
            </>
          )}
        </button>
      ))}
    </div>
  );
};
