import { gql, useMutation } from '@apollo/client';
import { Button } from 'components/button';
import {
  DeprioritiseBookingMutation,
  DeprioritiseBookingMutationVariables,
  PractitionerBookingsTableRowFragment,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import { FaRegStar } from 'react-icons/fa';

export const DeprioritiseBookingButton: React.FC<{
  bookings: PractitionerBookingsTableRowFragment[];
  onComplete: () => void;
}> = ({ bookings, onComplete }) => {
  const showNotification = useNotifications();

  const [deprioritiseBooking, { loading }] = useMutation<
    DeprioritiseBookingMutation,
    DeprioritiseBookingMutationVariables
  >(
    gql`
      mutation DeprioritiseBooking(
        $input: UpdatePractitionerBookingPriorityInput!
      ) {
        updatePractitionerBookingPriority(input: $input) {
          practitionerBooking {
            id
            priority
          }
        }
      }
    `,
    {
      onError: () => {
        showNotification({
          type: 'error',
          message: 'Unable to update booking priority',
        });
        onComplete();
      },
    },
  );

  if (!bookings.length || bookings.every((b) => b.priority === 0)) {
    return null;
  }

  const handleClick = async () => {
    await Promise.all(
      bookings
        .filter((b) => b.priority > 0)
        .map((b) =>
          deprioritiseBooking({
            variables: {
              input: {
                bookingId: b.id,
                priority: b.priority - 1,
              },
            },
          }),
        ),
    );

    showNotification({
      type: 'success',
      message: 'Booking priorities updated',
    });
    onComplete();
  };

  return (
    <div>
      <Button variant="outline" loading={loading} onClick={handleClick}>
        <div className="flex items-center gap-2">
          <FaRegStar />
          Deprioritise
        </div>
      </Button>
    </div>
  );
};
