import React from 'react';
import { CellProps, Column, useTable } from 'react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/table';
import { Tag } from 'components/tag';
import { ContentItemStatus } from 'graphql/types';
import { Colors } from '../../utils/misc';
import { ContentItemType } from '../../pages/content/items';
import { Link } from 'react-router-dom';
import { config } from 'config';
import { useNotifications } from 'notifications';
import { CopyToClipboardButton } from 'components/copy-to-clipboard-button';

export type ContentItemsTableData = {
  id: string;
  title: string;
  contentfulId: string;
  status: ContentItemStatus;
  type: ContentItemType;
  deepLinks: string;
};

const StatusCell = (
  cell: CellProps<ContentItemsTableData, ContentItemsTableData['status']>,
): React.ReactElement => {
  return (
    <Tag size="small" color={cell.value === 'PRIVATE' ? 'gray' : 'green'}>
      {cell.value}
    </Tag>
  );
};
const IdCell = (
  cell: CellProps<ContentItemsTableData, ContentItemsTableData['id']>,
): React.ReactElement => {
  return (
    <div>
      <CopyToClipboardButton value={cell.value} />
      {cell.value}
    </div>
  );
};

const colorMap: Record<ContentItemType, Colors> = {
  VideoContentItem: 'teal',
  VideoWithReadingContentItem: 'yellow',
  ReadingContentItem: 'red',
  RecipeContentItem: 'green',
  FaqContentItem: 'blue',
};

const TypeCell = (
  cell: CellProps<ContentItemsTableData, ContentItemsTableData['type']>,
): React.ReactElement => {
  return (
    <Tag size="small" color={colorMap[cell.value] ?? 'gray'}>
      {cell.value?.replace(/ContentItem/g, '')}
    </Tag>
  );
};

const DeepLinksCell = (
  cell: CellProps<ContentItemsTableData, ContentItemsTableData['deepLinks']>,
): React.ReactElement | null => {
  const showNotification = useNotifications();
  const arr = cell.value.split(':');
  const itemId = arr[0];
  const itemType = arr[1];
  let itemTypePathFragment = '';
  switch (itemType) {
    case 'FaqContentItem':
      return null;
    case 'VideoContentItem':
    case 'VideoWithReadingContentItem':
      itemTypePathFragment = 'video';
      break;
    case 'RecipeContentItem':
      itemTypePathFragment = 'recipe';
      break;
    case 'ReadingContentItem':
      itemTypePathFragment = 'article';
      break;
    default:
      throw Error(`Unsupported content type ${itemType}`);
  }
  const externalDeepLink =
    `${config.userAppUrl}/mobile-link/${itemTypePathFragment}/${itemId}`.toLocaleLowerCase();
  const internalDeepLink =
    `juniper://${itemTypePathFragment}/${itemId}`.toLocaleLowerCase();
  return (
    <div className="space-x-2">
      <Link
        className="bg-blue-500 border-gray-900 rounded-md border text-white text-xs p-1"
        onClick={(e) => {
          navigator.clipboard.writeText(`${externalDeepLink}`);
          e.currentTarget.innerText = 'External Copied';
          e.currentTarget.style.backgroundColor = 'darkgray';
          e.stopPropagation();
          e.preventDefault();
          showNotification({
            type: 'info',
            message: `External deep link copied: ${externalDeepLink}`,
          });
        }}
        to={''}
      >
        Copy External
      </Link>
      <Link
        className="bg-blue-500 border-gray-900 rounded-md border text-white text-xs p-1"
        onClick={(e) => {
          navigator.clipboard.writeText(`${internalDeepLink}`);
          e.currentTarget.innerText = 'Internal Copied';
          e.currentTarget.style.backgroundColor = 'darkgray';
          e.stopPropagation();
          e.preventDefault();
          showNotification({
            type: 'info',
            message: `Internal deep link copied: ${internalDeepLink}`,
          });
        }}
        to={''}
      >
        Copy Internal
      </Link>
    </div>
  );
};

const contentItemsTableColumns: Column<ContentItemsTableData>[] = [
  {
    Header: 'Type',
    accessor: 'type',
    Cell: TypeCell,
  },
  {
    Header: 'ID',
    accessor: 'id',
    Cell: IdCell,
  },
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'Deep Links',
    accessor: 'deepLinks',
    Cell: DeepLinksCell,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: StatusCell,
  },
];

type ContentItemsTableProps = {
  data: ContentItemsTableData[];
  onRowClick: (id: string) => void;
};

export const ContentItemsTable = ({
  data,
  onRowClick,
}: ContentItemsTableProps): React.ReactElement => {
  const tableInstance = useTable({
    columns: contentItemsTableColumns,
    data,
  });

  return (
    <Table tableInstance={tableInstance}>
      <TableHead />
      <TableBody>
        {tableInstance.rows.map((row) => {
          tableInstance.prepareRow(row);
          return (
            <TableRow key={row.id} row={row}>
              {row.cells.map((cell) => (
                <TableCell
                  key={`${cell.row.original.id}-${cell.column.id}`}
                  cell={cell}
                  onClick={(): void => onRowClick(cell.row.original.id)}
                />
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
