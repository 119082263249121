import { useCallback } from 'react';

// custom session storage hook with access via getter rather than direct value
export const useSessionStorage = <T = string>(
  key: string,
): { get: () => T | undefined; set: (value: T | undefined) => void } => ({
  get: useCallback(() => {
    try {
      return JSON.parse(sessionStorage.getItem(key) ?? '');
    } catch {
      // do not throw
      return undefined;
    }
  }, [key]),
  set: useCallback(
    (value) => sessionStorage.setItem(key, JSON.stringify(value)),
    [key],
  ),
});
