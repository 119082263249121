import {
  ConsultationStatus,
  ProblemType,
  TreatmentStatus,
} from 'graphql/types';

export const isPathologyProblemType = (problemType: ProblemType): boolean => {
  return ['STI', 'FERTILITY_HORMONE', 'WEIGHT_LOSS', 'HAIR'].includes(
    problemType,
  );
};

export const isProblemTypeWithOptionalPathology = (
  problemType: ProblemType,
): boolean => {
  return ['WEIGHT_LOSS', 'HAIR'].includes(problemType);
};

export const canReleasePathologyResults = ({
  problemType,
  consultationStatus,
  treatmentStatus,
}: {
  problemType?: ProblemType;
  consultationStatus?: ConsultationStatus;
  treatmentStatus?: TreatmentStatus;
}): boolean => {
  if (!problemType || !consultationStatus) {
    return false;
  }

  // This is to support WEIGHT_LOSS customers going through legacy flow,
  // as well as all STI and FERTILITY_HORMONE customers
  if (consultationStatus === 'AWAITING_RESULTS') {
    return true;
  }

  if (!isProblemTypeWithOptionalPathology(problemType) || !treatmentStatus) {
    return false;
  }

  // Allow reassigning back to clinician before payment
  if (
    consultationStatus === 'DOCTOR_COMPLETED' &&
    treatmentStatus === 'CREATED'
  ) {
    return true;
  }

  // Allow trigger review flow for active treatments
  if (
    ['ORDER_COMPLETED', 'CUSTOMER_COMPLETED'].includes(consultationStatus) &&
    treatmentStatus === 'ACTIVE'
  ) {
    return true;
  }

  return false;
};
