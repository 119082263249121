export function buildUrl(
  url: string,
  params: Record<string, unknown | unknown[]>,
): string {
  if (params) {
    const temp = Object.entries(params)
      .filter((entry) => entry[1] !== undefined && entry[1] !== null)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value
            .map((it) => `${encodeURIComponent(key)}=${encodeURIComponent(it)}`)
            .join('&');
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(value + '')}`;
      })
      .join('&');
    if (url.endsWith('?')) {
      return `${url}${temp}`;
    }
    if (url.indexOf('?') >= 0 && !url.endsWith('?')) {
      return `${url}&${temp}`;
    }
    return `${url}?${temp}`;
  }

  return url;
}
