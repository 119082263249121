import { Button } from 'components/button';
import { useState } from 'react';
import { EditNoteModal, EditNoteModalProps } from './edit-note-modal';

export const EditNoteModalButton: React.FC<
  Omit<EditNoteModalProps, 'show' | 'onClose'>
> = (props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button size="small" variant="outline" onClick={() => setShowModal(true)}>
        Edit note
      </Button>
      <EditNoteModal
        show={showModal}
        onClose={() => setShowModal(false)}
        {...props}
      />
    </>
  );
};
