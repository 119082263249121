import { gql, useMutation } from '@apollo/client';
import {
  FileCreateWithoutAvatarOfInput,
  MutationCreateSubscriptionPlanV2Args,
  SubscriptionPlanV2,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import { ReactElement, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'utils/routes';
import SubscriptionPlanForm, { Fields } from './subscription-plan-form';

const createSubscriptionPlanMutation = gql`
  mutation createSubscriptionPlanV2(
    $name: String!
    $description: String!
    $photo: SubscriptionPhotoInput!
    $interval: SubscriptionPlanInterval!
    $intervalCount: Int!
    $price: Int!
    $subscriptionLineItems: [SubscriptionLineItemInput!]!
  ) {
    createSubscriptionPlanV2(
      name: $name
      description: $description
      photo: $photo
      interval: $interval
      intervalCount: $intervalCount
      price: $price
      subscriptionLineItems: $subscriptionLineItems
    ) {
      id
    }
  }
`;

const mapFieldsToNewSubscriptionPlanData = (
  fields: Fields,
): MutationCreateSubscriptionPlanV2Args => ({
  name: fields.name,
  price: Number(fields.amount),
  description: fields.description,
  interval: fields.interval,
  intervalCount: Number(fields.intervalCount),
  photo: JSON.parse(fields.photo) as FileCreateWithoutAvatarOfInput,
  // at this stage, lineItems only has one product with quantity 1
  subscriptionLineItems: [{ productId: fields.lineItem, quantity: 1 }],
});

const CreateSubscriptionPlan = (): ReactElement => {
  const showNotification = useNotifications();
  const history = useHistory();

  const [createSubscriptionPlan, { loading }] = useMutation<{
    createSubscriptionPlanV2: SubscriptionPlanV2;
  }>(createSubscriptionPlanMutation, {
    onCompleted: ({ createSubscriptionPlanV2: subcriptionPlan }) => {
      showNotification({
        type: 'success',
        message: 'Plan created successfully',
      });
      history.push(`${routes.subscriptionPlans}/${subcriptionPlan.id}`);
    },
    onError: () => {
      showNotification({
        type: 'error',
        message: 'Error creating subscription plan',
      });
    },
  });

  const handleSubmit = useCallback(
    (fields: Fields) =>
      createSubscriptionPlan({
        variables: mapFieldsToNewSubscriptionPlanData(fields),
      }),
    [createSubscriptionPlan],
  );

  return (
    <SubscriptionPlanForm
      type="create"
      onSubmit={handleSubmit}
      submitting={loading}
    />
  );
};

export default CreateSubscriptionPlan;
