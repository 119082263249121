import { gql, useMutation } from '@apollo/client';
import {
  DeleteConsultationMutation,
  DeleteConsultationMutationVariables,
} from 'graphql/types';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { useNotifications } from 'notifications';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'utils/routes';

export function DeleteConsultationButton({
  consultationId,
  customerId,
  disabled,
}: {
  consultationId: string;
  customerId: string;
  disabled: boolean;
}) {
  const featureFlagClient = useFeatureFlagClient();
  const [showConfirm, setShowConfirm] = useState(false);
  const history = useHistory();
  const showNotification = useNotifications();
  const enableAdminDelete = featureFlagClient.getBoolean('admin-deletes');
  const [deleteConsultation, { loading }] = useMutation<
    DeleteConsultationMutation,
    DeleteConsultationMutationVariables
  >(gql`
    mutation deleteConsultation($id: String!) {
      deleteConsultation(id: $id)
    }
  `);
  const onDeleteConsultation = async (): Promise<void> => {
    await deleteConsultation({
      variables: {
        id: consultationId,
      },
    });
    showNotification({
      message: 'Consultation has been deleted',
      type: 'success',
    });
    history.replace(`${routes.customers}/${customerId}`);
  };
  if (!enableAdminDelete) {
    return null;
  }
  return (
    <>
      <Button
        fullWidth
        variant="solid"
        color="danger"
        disabled={disabled}
        onClick={(): void => {
          setShowConfirm(true);
        }}
      >
        Delete Consultation
      </Button>
      {showConfirm && (
        <Modal
          show
          width="w-96"
          onClose={(): void => {
            setShowConfirm(false);
          }}
        >
          <div className="bg-white rounded p-4 space-y-4">
            <p className="mb-3 font-bold">
              Are you sure you want to delete this consultation?
            </p>
            <hr />
            <p>
              This action will permanently delete the consultation. Do not
              proceed if the patient has exchanged any messages with a
              practitioner.
              <br />
              <span className="text-red-500 font-bold">
                Warning: This action cannot be undone.
              </span>
            </p>
            <div className="flex gap-x-4 bg-gray-100">
              <Button
                fullWidth
                autoFocus={true}
                variant="outline"
                onClick={(): void => {
                  setShowConfirm(false);
                }}
              >
                Go back
              </Button>
              <Button
                fullWidth
                color="danger"
                onClick={onDeleteConsultation}
                loading={loading}
                disabled={loading}
              >
                Yes, delete
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
