import clsx from 'clsx';
import { ImageResponse } from 'components/image-response';
import { QuizFlagLevel } from 'graphql/types';
import React from 'react';
import { FaCheckSquare, FaRegSquare } from 'react-icons/fa';
import { formatDob } from 'utils/misc';
import { QuizResponseExtended } from './types';

export const Questionnaire = ({
  quizResponses,
  isNonEnglish,
  languageDisplayName,
}: {
  quizResponses?: Array<QuizResponseExtended>;
  isNonEnglish?: boolean;
  languageDisplayName?: string | null;
}): React.ReactElement | null => {
  if (!quizResponses?.length) {
    return (
      <div className="px-4 pb-4">
        <p>No quiz responses recorded.</p>
      </div>
    );
  }

  return (
    <div>
      {isNonEnglish && (
        <div className="flex flex-row text-center font-bold mb-4">
          <div className="flex-1 ">English</div>
          <div className="flex-1 capitalize">{languageDisplayName}</div>
        </div>
      )}

      {quizResponses.map((res, i) => (
        <QuizResponseRow
          res={res}
          key={`${res.question?.id} ${i}`}
          isNonEnglish={isNonEnglish}
          rowNumber={i}
        />
      ))}
    </div>
  );
};

const QuizResponseRow: React.FC<{
  res: QuizResponseExtended;
  isNonEnglish?: boolean;
  rowNumber: number;
}> = ({ res, isNonEnglish, rowNumber }) => {
  const body = [];

  switch (res.question?.type) {
    case 'IMAGEUPLOAD':
      body.push(
        <ImageUploadAnswer
          imageUrl={res.response?.answers?.[0].imageUrl}
          flag={res.response?.flag?.level}
        />,
      );
      break;
    case 'DATE':
      body.push(
        <DateAnswer
          flag={res.response?.flag?.level}
          answer={res.response?.answers?.[0]?.value}
        />,
      );
      break;
    case 'RADIO':
    case 'CHECKBOX':
    case 'DROPDOWN':
      body.push(
        <MultipleOptionAnswer
          answers={res.response?.answers}
          flagLevel={res.response?.flag?.level}
          options={res.question?.options?.map((o) => o.value)}
        />,
      );
      if (isNonEnglish) {
        body.push(
          <MultipleOptionAnswer
            answers={res.response?.originalAnswers}
            flagLevel={res.response?.flag?.level}
            options={res.question?.options?.map((o) => o.originalValue)}
          />,
        );
      }
      break;
    default:
      body.push(
        <SingleAnswer
          content={res.response?.answers?.[0]?.value}
          flagLevel={res.response?.flag?.level}
        />,
      );
      if (isNonEnglish) {
        body.push(
          <SingleAnswer
            content={res.response?.originalAnswers?.[0]?.value}
            flagLevel={res.response?.flag?.level}
          />,
        );
      }
      break;
  }
  const shouldNotBeTranslated = ['DATE', 'IMAGEUPLOAD', 'RADIO_IMG', 'NUMBER'];
  return (
    <div className={clsx('px-4 pb-4', { 'bg-gray-200': rowNumber % 2 })}>
      <div className="flex flex-row">
        <QuestionTitle
          questionId={res?.question?.id}
          shortcode={res.question?.shortcode}
          title={res.question?.title}
        />
        {isNonEnglish &&
          !shouldNotBeTranslated.includes(res?.question?.type ?? '') && (
            <QuestionTitle
              questionId={res?.question?.id}
              shortcode={res.question?.shortcode}
              title={res.question?.originalTitle}
            />
          )}
      </div>
      <div className="flex flex-row">{body}</div>
    </div>
  );
};

const QuestionTitle: React.FC<{
  questionId?: string | null;
  shortcode?: string | null;
  title?: string | null;
}> = ({ questionId, shortcode, title }) => {
  return (
    <div
      className={clsx('font-semibold py-1 flex-1', {
        'text-red-500': !questionId,
      })}
      title={`Question ID: ${questionId}, Shortcode: ${shortcode || 'None'}`}
    >
      {title ?? 'Question has been deleted'}
    </div>
  );
};

const ImageUploadAnswer: React.FC<{
  flag?: QuizFlagLevel;
  imageUrl?: string | null;
}> = ({ flag, imageUrl }) => {
  return (
    <div
      className={clsx(
        'text-gray-800 whitespace-pre-line flex-1',
        {
          'bg-yellow-200': flag === 'MODERATE',
        },
        {
          'bg-red-300': flag === 'CRITICAL',
        },
      )}
    >
      <ImageResponse imageUrl={imageUrl} />
    </div>
  );
};

const DateAnswer: React.FC<{
  answer?: string | null;
  flag?: QuizFlagLevel;
}> = ({ answer, flag }) => {
  const date = answer ? formatDob(answer) : '';
  return (
    <div
      className={clsx(
        'text-gray-800 whitespace-pre-line flex-1',
        {
          'bg-yellow-200': flag === 'MODERATE',
        },
        {
          'bg-red-300': flag === 'CRITICAL',
        },
      )}
    >
      <div className="text-gray-800">{date}</div>
    </div>
  );
};

const SingleAnswer: React.FC<{
  flagLevel?: QuizFlagLevel | null;
  content?: string | null;
}> = ({
  flagLevel,
  content,
}: {
  flagLevel?: QuizFlagLevel | null;
  content?: string | null;
}) => {
  return (
    <div
      className={clsx(
        'text-gray-800 whitespace-pre-line flex-1',
        {
          'bg-yellow-200': flagLevel === 'MODERATE',
        },
        {
          'bg-red-300': flagLevel === 'CRITICAL',
        },
      )}
    >
      {content}
    </div>
  );
};

const MultipleOptionAnswer = ({
  answers,
  options,
  flagLevel,
}: {
  answers?: { value: string; imageUrl?: string | null }[] | null;
  options?: string[] | null;
  flagLevel?: QuizFlagLevel;
}): JSX.Element => {
  // If there are multiple options then we need to display all the options
  // and highlight selected
  // CHECKBOX answers come in the answersArray and radio comes in answer
  // but both have question options to display, so handle both by making an array

  // If the user's answer is not included in the options,
  // then it means question answer options have been changed
  const changedAnswers = answers?.filter(
    (answer) => !options?.includes(answer.value),
  );
  const flagClassName = clsx(
    {
      'bg-yellow-200': flagLevel === 'MODERATE',
    },
    {
      'bg-red-300': flagLevel === 'CRITICAL',
    },
  );
  return (
    <div className="flex-1">
      {options?.map((option) => (
        <div key={option}>
          {answers?.find((a) => a.value === option) ? (
            <div className={flagClassName}>
              <SelectedAnswer value={option ?? undefined} />
            </div>
          ) : (
            <UnselectedAnswer value={option ?? undefined} />
          )}
        </div>
      ))}
      {changedAnswers?.map((answer) => (
        <div key={answer.value} className={flagClassName}>
          <SelectedAnswer value={`${answer.value} (Answer was renamed)`} />
        </div>
      ))}
    </div>
  );
};

const SelectedAnswer = ({ value }: { value?: string }): JSX.Element => (
  <div className="flex items-center text-gray-800">
    <FaCheckSquare className="text-blue-500" />
    <span className="ml-2">{value}</span>
  </div>
);

const UnselectedAnswer = ({ value }: { value?: string }): JSX.Element => (
  <div className="flex items-center text-gray-500">
    <FaRegSquare />
    <span className="ml-2">{value}</span>
  </div>
);
