import { Button } from 'components/button';
import { useState } from 'react';
import { ArchiveNoteModal, ArchiveNoteModalProps } from './archive-note-modal';

export const ArchiveNoteModalButton: React.FC<
  Omit<ArchiveNoteModalProps, 'show' | 'onClose'>
> = (props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button size="small" variant="outline" onClick={() => setShowModal(true)}>
        Archive Note
      </Button>
      <ArchiveNoteModal
        show={showModal}
        onClose={() => setShowModal(false)}
        {...props}
      />
    </>
  );
};
