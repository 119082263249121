import type React from 'react';
import type { DotProps, TooltipProps } from 'recharts';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Dot,
  Label,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import type {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import type { Props as RechartLabelProps } from 'recharts/types/component/Label';
import { FiCalendar } from 'react-icons/fi';
import { ReactComponent as WeightScale } from '../assets/weight-scale.svg';
import { CustomGraphCursor } from '../graph/custom-graph-cursor';
import { formatDate, formatDateShortMonthShortYear } from '../../utils/misc';

const CustomCenterMilestoneLabel = (props: RechartLabelProps) => {
  if (
    !props.viewBox ||
    !('x' in props.viewBox) ||
    !props.viewBox.x ||
    !('y' in props.viewBox) ||
    !props.viewBox.y ||
    !('width' in props.viewBox) ||
    !props.viewBox.width
  ) {
    return null;
  }

  const labelHeight = 22;
  const labelWidth = 80;

  return (
    <g>
      <rect
        fill="#ED8936"
        rx="4px"
        x={props.viewBox.x + props.viewBox.width / 2 - labelWidth / 2}
        y={props.viewBox.y - labelHeight / 2}
        width={labelWidth}
        height={labelHeight}
      />
      <text
        x={props.viewBox.x + props.viewBox.width / 2}
        y={props.viewBox.y + 4}
        fill="white"
        textAnchor="middle"
      >
        {props.value}
      </text>
    </g>
  );
};

const CustomLeftMilestoneLabel = (props: RechartLabelProps) => {
  if (
    !props.viewBox ||
    !('x' in props.viewBox) ||
    !props.viewBox.x ||
    !('y' in props.viewBox) ||
    !props.viewBox.y
  ) {
    return null;
  }

  const labelHeight = 18;
  const labelWidth = 54;
  const leftMargin = 4;
  const labelLeftPadding = 18;

  return (
    <>
      <svg
        x={leftMargin}
        y={props.viewBox.y - labelHeight / 2}
        width={labelWidth}
        height={labelHeight}
        viewBox="0 0 43 18"
        preserveAspectRatio="none"
      >
        <path
          d={`M0 2C0 0.895431 0.895431 0 2 0H35.6374C36.2888 0 36.8994 0.317222 37.2738 0.850206L42.1921 7.85021C42.6769 8.5401 42.6769 9.4599 42.1921 10.1498L37.2738 17.1498C36.8994 17.6828 36.2888 18 35.6374 18H2C0.895428 18 0 17.1046 0 16V2Z`}
          fill="#ED8936"
        />
      </svg>
      <svg
        x={40}
        y={props.viewBox.y - 5}
        width="10"
        height="10"
        viewBox="0 0 10 10"
      >
        <path
          d="M4.62152 4.62136C4.52119 4.7218 4.46484 4.85796 4.46484 4.99993C4.46484 5.14189 4.52119 5.27805 4.62152 5.3785C4.72196 5.47882 4.85812 5.53517 5.00009 5.53517C5.14205 5.53517 5.27821 5.47882 5.37866 5.3785L7.02866 3.7285C7.04996 3.70771 7.07632 3.69282 7.10512 3.6853C7.13393 3.67779 7.1642 3.67791 7.19294 3.68564L8.12866 3.91421C8.15681 3.92117 8.18622 3.92117 8.21437 3.91421C8.26121 3.91479 8.30765 3.90555 8.35071 3.8871C8.39376 3.86864 8.43248 3.84138 8.46437 3.80707L9.89294 2.3785C9.93827 2.33475 9.97095 2.27958 9.98754 2.2188C10.0041 2.15803 10.004 2.09391 9.98717 2.0332C9.97035 1.97249 9.93745 1.91744 9.89195 1.87387C9.84645 1.8303 9.79004 1.79982 9.72866 1.78564L8.61437 1.49993C8.58304 1.49271 8.55437 1.47683 8.53164 1.45409C8.5089 1.43136 8.49302 1.40269 8.4858 1.37136L8.20723 0.271357C8.19363 0.206772 8.16241 0.147218 8.11702 0.0993042C8.07162 0.0513909 8.01384 0.0169929 7.95009 -7.16932e-05C7.88991 -0.0173791 7.82614 -0.0178749 7.7657 -0.00150535C7.70525 0.0148642 7.65045 0.0474739 7.60723 0.0927854L6.17866 1.52136C6.13397 1.5636 6.10119 1.61687 6.08364 1.6758C6.06608 1.73474 6.06437 1.79726 6.07866 1.85707L6.31437 2.79279C6.32211 2.82153 6.32223 2.8518 6.31471 2.88061C6.3072 2.90941 6.29231 2.93577 6.27152 2.95707L4.62152 4.62136Z"
          fill="white"
        />
        <path
          d="M4.10734 3.1714C4.01267 3.06583 3.88045 3.00149 3.73896 2.99215C3.59746 2.9828 3.45793 3.0292 3.3502 3.1214C3.08899 3.35374 2.87942 3.63827 2.73501 3.95663C2.59059 4.27499 2.51455 4.62009 2.5118 4.96967C2.50904 5.31924 2.57963 5.6655 2.719 5.9861C2.85837 6.3067 3.06344 6.5945 3.32094 6.83092C3.57845 7.06735 3.88268 7.24714 4.21399 7.35869C4.5453 7.47024 4.89632 7.51106 5.24438 7.47853C5.59245 7.446 5.92982 7.34083 6.23472 7.16981C6.53961 6.9988 6.80525 6.76574 7.01448 6.48569C7.05875 6.42796 7.09097 6.36193 7.10922 6.29151C7.12747 6.22109 7.13138 6.14773 7.12072 6.07577C7.11006 6.00381 7.08505 5.93473 7.04716 5.87262C7.00928 5.81052 6.9593 5.75667 6.9002 5.71426C6.84384 5.67149 6.77947 5.64046 6.71091 5.62303C6.64234 5.60559 6.57097 5.6021 6.50103 5.61276C6.43108 5.62342 6.36399 5.64801 6.30373 5.68507C6.24347 5.72214 6.19126 5.77093 6.1502 5.82854C6.03588 5.99894 5.88619 6.1427 5.71132 6.25006C5.53646 6.35741 5.34052 6.42584 5.13684 6.45068C4.93315 6.47552 4.72651 6.45619 4.53097 6.39401C4.33543 6.33183 4.15558 6.22825 4.00365 6.09033C3.85173 5.9524 3.7313 5.78337 3.65056 5.59473C3.56982 5.40609 3.53067 5.20228 3.53576 4.99715C3.54086 4.79202 3.59008 4.5904 3.68009 4.406C3.77009 4.2216 3.89876 4.05875 4.05734 3.92854C4.16291 3.83387 4.22725 3.70165 4.23659 3.56016C4.24594 3.41866 4.19955 3.27913 4.10734 3.1714Z"
          fill="white"
        />
        <path
          d="M9.49327 4.9141C9.35146 4.90213 9.21062 4.94632 9.10107 5.03717C8.99152 5.12801 8.92203 5.25824 8.90756 5.39982C8.83722 6.14454 8.55572 6.85371 8.09615 7.44394C7.63659 8.03416 7.01807 8.48092 6.31331 8.73167C5.60855 8.98243 4.84685 9.02676 4.11776 8.85946C3.38867 8.69216 2.72249 8.32018 2.19756 7.78725C1.67263 7.25433 1.31076 6.58261 1.15449 5.85107C0.99823 5.11953 1.05407 4.35859 1.31545 3.6577C1.57682 2.95681 2.03287 2.34511 2.62998 1.89452C3.22708 1.44393 3.94042 1.17318 4.68613 1.1141C4.75887 1.10793 4.82962 1.08714 4.89415 1.05298C4.95867 1.01883 5.01564 0.972011 5.06165 0.91533C5.10767 0.858648 5.14177 0.793269 5.16193 0.723101C5.18209 0.652934 5.18789 0.579422 5.17898 0.506961C5.17539 0.435289 5.15744 0.365069 5.12619 0.300469C5.09494 0.235869 5.05103 0.178206 4.99706 0.130903C4.9431 0.0836007 4.88018 0.0476224 4.81204 0.0251043C4.7439 0.00258627 4.67194 -0.00601259 4.60041 -0.000181788C3.65525 0.0808183 2.75263 0.429025 1.99794 1.00378C1.24325 1.57854 0.667641 2.35613 0.338315 3.24576C0.00898876 4.13539 -0.0604628 5.10035 0.138069 6.02797C0.336601 6.9556 0.794924 7.8076 1.45952 8.48451C2.12413 9.16142 2.96758 9.63529 3.8914 9.85081C4.81523 10.0663 5.7813 10.0146 6.67682 9.70164C7.57234 9.3887 8.36036 8.82745 8.94887 8.08343C9.53738 7.33942 9.90208 6.44334 10.0004 5.49982C10.0083 5.42753 10.0012 5.35439 9.97957 5.28497C9.95792 5.21555 9.92217 5.15134 9.87457 5.09637C9.82697 5.04139 9.76854 4.99683 9.70293 4.96547C9.63732 4.93411 9.56594 4.91662 9.49327 4.9141Z"
          fill="white"
        />
      </svg>
      <text
        textAnchor="middle"
        y={props.viewBox.y + 4}
        x={leftMargin + labelLeftPadding}
        fill="#fff"
      >
        {props.value}
      </text>
    </>
  );
};

const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>): React.ReactElement | null => {
  if (!active || !payload || payload.length === 0) {
    return null;
  }

  return (
    <div className="bg-indigo-500 rounded-md px-2 py-1 text-white">
      <div className="flex space-x-2 items-center">
        <WeightScale className="stroke-2" />
        <div className="text-sm">{payload[0]?.value} kg</div>
        <div className="text-sm">|</div>
        <FiCalendar className="stroke-2" />
        <div className="text-sm">{formatDate(label)}</div>
      </div>
    </div>
  );
};

type WeightGraphProps = {
  data: {
    timestamp: Date;
    value: number;
  }[];
  currentMilestone?: {
    completionKg: number;
    order: number;
    isGoalWeight: boolean;
  };
};

const WeightGraph = ({
  data,
  currentMilestone,
}: WeightGraphProps): React.ReactElement => {
  const transformedAreaChartDataPoints = data.map((d) => ({
    date: d.timestamp.getTime(),
    value: d.value,
  }));

  const minXValue = data[data.length - 1]?.timestamp.getTime() ?? 0;
  const maxXValue = data[0]?.timestamp.getTime() ?? 0;

  const yDomain = data.map((d) => d.value);
  const maxYValue = Math.max(...yDomain);

  let minYValue;
  if (currentMilestone && typeof currentMilestone.completionKg === 'number') {
    minYValue = Math.min(...yDomain, currentMilestone.completionKg);
  } else {
    minYValue = Math.min(...yDomain);
  }

  // To keep weight graph and average weight line's axes in sync
  const commonXAxisProps = {
    dataKey: 'date',
    domain: [minXValue, maxXValue],
    padding: { right: 30 },
  };

  const commonYAxisProps = {
    dataKey: 'value',
    domain: [minYValue - 5, maxYValue + 5],
    tickLine: false,
  };

  return (
    <div className="h-96 text-xs relative">
      <div className="h-full">
        <ResponsiveContainer>
          <ComposedChart
            margin={{ left: -12, right: 0 }}
            data={transformedAreaChartDataPoints}
          >
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <XAxis
              {...commonXAxisProps}
              type="number"
              tickFormatter={(value: string): string =>
                formatDateShortMonthShortYear(value)
              }
              ticks={[minXValue, maxXValue]}
              tickLine={false}
            />
            <YAxis {...commonYAxisProps} />
            <Area
              dataKey="value"
              isAnimationActive={false}
              fill="url(#areaGradient)"
            />
            <defs>
              <linearGradient id="areaGradient" gradientTransform="rotate(90)">
                <stop offset="0.38%" stopColor="#667EEA" />
                <stop offset="102.46%" stopColor="rgba(102, 126, 234, 0)" />
              </linearGradient>
            </defs>
            <Tooltip
              content={<CustomTooltip />}
              cursor={<CustomGraphCursor />}
              wrapperStyle={{ outline: 'none' }}
            />
            {currentMilestone && currentMilestone.completionKg && (
              <ReferenceLine y={currentMilestone.completionKg} stroke="#ED8936">
                <Label
                  value={currentMilestone.completionKg}
                  content={CustomLeftMilestoneLabel}
                />
                <Label
                  value={
                    currentMilestone.isGoalWeight
                      ? 'Goal weight'
                      : `Milestone ${currentMilestone.order}`
                  }
                  content={CustomCenterMilestoneLabel}
                />
              </ReferenceLine>
            )}
            <Line
              dataKey="value"
              stroke="#667EEA"
              strokeWidth={2}
              dot={({ cx, cy }: DotProps): React.ReactElement => (
                <Dot cx={cx} cy={cy} r={5} fill="#667EEA" />
              )}
              isAnimationActive={false}
              activeDot={({ cx, cy }: DotProps): React.ReactElement => (
                <>
                  <Dot cx={cx} cy={cy} r={14} fill="#D9CAF7" />
                  <Dot cx={cx} cy={cy} r={10} fill="#667EEA" />
                  <Dot cx={cx} cy={cy} r={7} fill="#ffffff" />
                </>
              )}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default WeightGraph;
