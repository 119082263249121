import { TemplateStatus } from '../../graphql/types';
import { Colors } from '../../utils/misc';
import { Tag } from '../tag';

const templateStatusColor: Record<TemplateStatus, Colors> = {
  CHANGED: 'purple',
  LIVE: 'green',
  DRAFT: 'orange',
};

const statusDisplay: Record<TemplateStatus, string> = {
  CHANGED: 'Changed',
  LIVE: 'Live',
  DRAFT: 'Draft',
};

export const TemplateStatusTag = (props: { status: TemplateStatus }) => (
  <div>
    <Tag size="small" color={templateStatusColor[props.status]}>
      {statusDisplay[props.status]}
    </Tag>
  </div>
);
