import { VFC } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/table';
import { EducationReading } from 'graphql/types';
import { Column, useTable } from 'react-table';
import { getAdminReadingPageRoute } from './utils';

const readingTableColumns: Column<EducationReading>[] = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Contentful ID',
    accessor: 'contentfulId',
  },
];

type ReadingsTableProps = {
  readings: EducationReading[];
};

export const ReadingsTable: VFC<ReadingsTableProps> = ({ readings }) => {
  const history = useHistory();
  const tableInstance = useTable({
    columns: readingTableColumns,
    data: readings,
  });

  return (
    <Table tableInstance={tableInstance}>
      <TableHead />
      <TableBody>
        {tableInstance.rows.map((row) => {
          tableInstance.prepareRow(row);
          return (
            <TableRow key={row.id} row={row}>
              {row.cells.map((cell) => (
                <TableCell
                  key={`${cell.row.original.id}-${cell.column.id}`}
                  cell={cell}
                  onClick={(): void => {
                    history.push(
                      getAdminReadingPageRoute(cell.row.original.id),
                    );
                  }}
                />
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
