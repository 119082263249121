import { Card } from 'components/card';
import { EducationReading } from 'graphql/types';
import { VFC } from 'react';
import { AddNewReadingButtonModal } from '../readings/add-new-reading-button-modal';
import { getCardOutlineClassName } from '../utils';
import { ReadingsTable } from './readings-table';

type ReadingsTableCardProps = {
  name?: string;
  description?: string;
  readings: EducationReading[];
  unsavedChanges: boolean;
  addReading: (educationReading: EducationReading) => void;
  removeReading: (readingId: string) => void;
  reorderReadings: (educationReadings: EducationReading[]) => void;
};

export const ReadingsTableCard: VFC<ReadingsTableCardProps> = ({
  name,
  description,
  readings,
  addReading,
  unsavedChanges,
  removeReading,
  reorderReadings,
}) => {
  const onCreatedReading = (newReading: EducationReading): void => {
    addReading(newReading);
  };

  const onRemoveReading = (readingId: string): void => {
    removeReading(readingId);
  };

  const onReorderReadings = (readings: EducationReading[]): void => {
    reorderReadings(readings);
  };

  return (
    <Card className={`p-4 ${getCardOutlineClassName(unsavedChanges)}`}>
      <div>
        {name && <h2 className="text-xl mb-2 font-semibold">{name}</h2>}
        {description && (
          <div className="mb-2">
            <span className="font-semibold text-lg">Description:</span>{' '}
            {description}
          </div>
        )}
        <Card className="p-4">
          <>
            <h2 className="font-semibold leading-tight text-lg mb-4">
              Readings
            </h2>
            <div className="flex flex-col space-y-2">
              <div className="flex flex-col">
                {readings && readings.length > 0 && (
                  <ReadingsTable
                    readings={readings}
                    onRemoveReading={onRemoveReading}
                    onReorderReadings={onReorderReadings}
                  />
                )}
              </div>
              <div className="flex flex-col items-end">
                <div className="w-52">
                  <AddNewReadingButtonModal
                    onCreatedReading={onCreatedReading}
                  />
                </div>
              </div>
            </div>
          </>
        </Card>
      </div>
    </Card>
  );
};
