import React, { useRef } from 'react';

export const useDebouncedEffect = (
  effect: () => void,
  delay: number,
  deps: React.DependencyList,
  ignoreFirstTime = true,
): void => {
  // Not fire for the first time.
  // Removed the extra page flushing and the extra browser history
  const first = useRef(ignoreFirstTime);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = React.useCallback(effect, deps);
  React.useEffect(() => {
    if (!first.current) {
      const handler = setTimeout(() => {
        callback();
      }, delay);
      return (): void => {
        clearTimeout(handler);
      };
    } else {
      first.current = false;
    }
  }, [callback, delay]);
};
