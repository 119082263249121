import {
  ConsultationStatus,
  FulfillmentStatus,
  OrderPaymentStatus,
  OrderStatus,
} from 'graphql/types';

export const paymentStatuses: OrderPaymentStatus[] = [
  'PAID',
  'PARTIALLY',
  'PENDING',
  'REFUNDED',
  'UNPAID',
  'VOIDED',
];

export const orderStatuses: OrderStatus[] = [
  'AWAITING_SCRIPT',
  'CREATED',
  'CANCELED',
  'COMPLETED',
  'FAILED_SCRIPT',
  'FAILED_SHOPIFY',
  'FAILED_VENDOR_CREATION',
  'PENDING_VENDOR_CREATION',
];

export const fulfillmentStatuses: FulfillmentStatus[] = [
  'FULFILLED',
  'UNFULFILLED',
];

export const consultationStatuses: ConsultationStatus[] = [
  'AWAITING_SURVEY',
  'AWAITING_PAYMENT',
  'AWAITING_DOCTOR',
  'DOCTOR_ASSIGNED',
  'AWAITING_ANSWERS',
  'AWAITING_RESULTS',
  'DOCTOR_COMPLETED',
  'CUSTOMER_COMPLETED',
  'AWAITING_VERIFY_ID',
  'ORDER_COMPLETED',
  'CANCELED',
];
