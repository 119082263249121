import React, { useEffect, useRef, useState } from 'react';

export function Copyable(props: {
  text: string;
  children: React.ReactNode | ((copied: boolean) => React.ReactNode);
}) {
  const [copied, setCopied] = useState(false);
  const timeout = useRef<NodeJS.Timeout>();

  const copy = () => {
    navigator.clipboard.writeText(props.text);
    setCopied(true);
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => setCopied(false), 1000);
  };

  useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, []);

  return (
    <div onClick={copy}>
      {typeof props.children === 'function'
        ? props.children(copied)
        : props.children}
    </div>
  );
}
