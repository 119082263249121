import mermaid from 'mermaid';
import { useEffect, useRef } from 'react';
import { v4 } from 'uuid';

mermaid.initialize({
  startOnLoad: true,
  flowchart: {
    useMaxWidth: false,
  },
});

export function Mermaid(props: { children: string }) {
  const id = useRef(`svg-${v4()}`);
  const elem = useRef<HTMLPreElement>(null);

  useEffect(() => {
    if (props.children) {
      mermaid.render(id.current, props.children).then(({ svg }) => {
        if (elem.current) {
          elem.current.innerHTML = svg;
        }
      });
    }
  }, [props.children]);

  return <pre className="w-full h-full overflow-scroll" ref={elem} />;
}
