import { CustomerTreatmentJobView } from '../types';
import { ConsultationJobView } from '../../consultation/types';

type Status = 'Scheduled' | 'Failed' | 'Success' | 'Canceled';

const resolveJobStatus = (
  job: CustomerTreatmentJobView | ConsultationJobView,
): Status => {
  if (job.canceled) {
    return 'Canceled';
  }

  if (!job.doneAt) {
    return 'Scheduled';
  }

  return job.failed ? 'Failed' : 'Success';
};

const getStatusColors = (status: Status): string => {
  switch (status) {
    case 'Success':
      return 'bg-green-100 text-green-900';
    case 'Failed':
      return 'bg-red-500 text-white';
    case 'Canceled':
      return 'bg-yellow-200 text-black';
    default:
      return 'bg-gray-100';
  }
};

export const JobStatus = ({
  job,
}: {
  job: CustomerTreatmentJobView | ConsultationJobView;
}): React.ReactElement => {
  const status = resolveJobStatus(job);
  const colors = getStatusColors(status);

  return (
    <span
      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${colors}`}
    >
      {status}
    </span>
  );
};
