import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/table';
import { Column, useTable } from 'react-table';
import { Button } from '../../components/button';
import { Loading } from '../../components/loading';
import { TemplateStatusTag } from '../../components/pal-levels/template-status-tag';
import {
  DefaultLevelTreeGoalTemplate,
  GoalsPageQuery,
  GoalsPageQueryVariables,
  InputMaybe,
  TemplateStatus,
} from '../../graphql/types';
import { PillarTag } from 'components/pal-levels/pillar-tag';
import {
  GoalsFilter,
  GoalsFilterPillarTemplatesFragmentDoc,
} from './goals-filter';
import { useGoalsSearchParams } from './hooks';
import { useState } from 'react';
import { useUrlQuery } from 'utils/use-url-query';
import {
  CreateGoalModalPillarTemplatesFragmentDoc,
  CreateGoalModal,
} from './create-goal-modal';
import { useHistory } from 'react-router-dom';
import { buildRoute } from 'utils/routes';
import { useNotifications } from 'notifications';

type GoalsTableData = {
  id: string;
  defaultGoal: string;
  status: TemplateStatus;
  level: number;
  pillar: {
    name: string;
    position: number;
  };
  variants: number;
};

const columns: Column<GoalsTableData>[] = [
  {
    Header: 'Default Goal',
    accessor: 'defaultGoal',
    Cell: (c) => <span className="font-semibold">{c.value}</span>,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: (c) => <TemplateStatusTag status={c.value} />,
  },
  { Header: 'Level', accessor: 'level' },
  {
    Header: 'Pillar',
    accessor: 'pillar',
    Cell: (c) => <PillarTag name={c.value.name} position={c.value.position} />,
  },
  { Header: 'Variants', accessor: 'variants' },
];

const GoalsPage = (): React.ReactElement => {
  const history = useHistory();
  const urlQuery = useUrlQuery();
  const showNotification = useNotifications();
  const [showModal, setShowModal] = useState(!!urlQuery.get('createGoal'));
  const searchParams = useGoalsSearchParams();
  const { data, loading } = useQuery<GoalsPageQuery, GoalsPageQueryVariables>(
    gql`
      query GoalsPage(
        $goalId: ID
        $levelId: ID
        $pillarId: ID
        $problemTypes: [ProblemType!]
        $publishedState: LevelTreePublishedState
        $searchTerm: String
      ) {
        levelTreeGoalTemplates(
          goalId: $goalId
          levelId: $levelId
          pillarId: $pillarId
          problemTypes: $problemTypes
          publishedState: $publishedState
          searchTerm: $searchTerm
        ) {
          id
          name
          status
          ... on DefaultLevelTreeGoalTemplate {
            id
            level {
              id
              levelNumber
              pillar {
                id
                name
                position
              }
            }
            variants {
              id
            }
          }
        }
        levelTreePillarTemplates {
          ...GoalsFilterPillarTemplates
          ...CreateGoalModalPillarTemplates
        }
      }
      ${CreateGoalModalPillarTemplatesFragmentDoc}
      ${GoalsFilterPillarTemplatesFragmentDoc}
    `,
    {
      variables: searchParams,
      fetchPolicy: 'cache-first',
    },
  );

  const [createGoal] = useMutation(
    gql`
      mutation CreateGoal($input: CreateLevelTreeGoalTemplateInput!) {
        createLevelTreeGoalTemplate(input: $input) {
          goalTemplate {
            id
            ... on DefaultLevelTreeGoalTemplate {
              level {
                id
                goals {
                  id
                }
              }
            }
          }
        }
      }
    `,
    {
      refetchQueries: ['GoalsPage'],
      awaitRefetchQueries: true,
      onCompleted: ({ createLevelTreeGoalTemplate }) => {
        showNotification({
          type: 'success',
          message: 'Goal created successfully',
        });
        history.push(
          buildRoute.goal(createLevelTreeGoalTemplate.goalTemplate.id),
        );
      },
      onError: () => {
        showNotification({
          type: 'error',
          message: 'Error creating goal',
        });
      },
    },
  );

  const handleCreateGoal = async (levelTemplateId: InputMaybe<string>) => {
    await createGoal({
      variables: {
        input: {
          levelTemplateId,
        },
      },
    });
  };

  const table = useTable({
    columns,
    data:
      data?.levelTreeGoalTemplates
        ?.filter(
          (goal): goal is DefaultLevelTreeGoalTemplate =>
            !!goal && goal.__typename === 'DefaultLevelTreeGoalTemplate',
        )
        .map((goal) => {
          if (!goal.status || !goal.level || !goal.level.pillar?.name) {
            throw new Error('Expected goal to have status, level and pillar');
          }
          return {
            id: goal.id,
            defaultGoal: goal.name ?? 'Unnamed goal',
            status: goal.status,
            variants: goal.variants?.length ?? 0,
            level: goal.level.levelNumber,
            pillar: {
              name: goal.level.pillar.name,
              position: goal.level.pillar.position,
            },
          };
        }) || [],
  });

  return (
    <>
      <div className="flex flex-col space-y-8">
        <div className="flex justify-end">
          <div>
            <Button onClick={() => setShowModal(true)}>Create Goal</Button>
          </div>
        </div>
        <GoalsFilter pillars={data?.levelTreePillarTemplates} />
        {loading ? (
          <div className="w-full flex justify-center p-5">
            <Loading />
          </div>
        ) : (
          <Table tableInstance={table}>
            <TableHead />
            <TableBody>
              {table.rows.map((row) => {
                table.prepareRow(row);
                return (
                  <TableRow key={row.id} row={row}>
                    {row.cells.map((cell) => (
                      <TableCell
                        key={`${cell.column.id}-${cell.row.original.id}`}
                        cell={cell}
                        onClick={() =>
                          history.push(buildRoute.goal(cell.row.original.id))
                        }
                      />
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </div>
      {showModal && (
        <CreateGoalModal
          onClose={() => setShowModal(false)}
          onCreateGoal={handleCreateGoal}
          pillars={data?.levelTreePillarTemplates}
        />
      )}
    </>
  );
};

export default GoalsPage;
