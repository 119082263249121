import { Colors } from '../../utils/misc';
import { Tag } from '../tag';

const pillarColors: Colors[] = [
  'red',
  'orange',
  'green',
  'teal',
  'blue',
  'indigo',
  'purple',
  'pink',
  'dark-blue',
  'yellow',
];

export const PillarTag = (props: { name: string; position: number }) => {
  const color = pillarColors[props.position % pillarColors.length];
  return (
    <div>
      <Tag size="small" color={color}>
        {props.name}
      </Tag>
    </div>
  );
};
