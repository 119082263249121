import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useSelectablePaginatingSortingTable,
} from 'components/table';
import React, { useMemo, useState } from 'react';

import { gql, useQuery } from '@apollo/client';
import { Button } from 'components/button';
import { formatDuration, intervalToDuration, isFuture } from 'date-fns';
import {
  BookingRescheduleModalPractitionerBookingWindowFragment,
  PractitionerBookingsTableQuery,
  PractitionerBookingsTableQueryVariables,
  PractitionerBookingsTableRowFragment,
  ProblemType,
} from 'graphql/types';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import { routes } from 'utils/routes';
import { Tag } from '../../components/tag';
import { ProblemTypeTag } from '../../components/tags/problem-type';
import {
  formatPatientName,
  getConsultationStageColor,
  upperSnakeCaseToCapitalCase,
} from '../../utils/misc';
import { BookingRescheduleModal } from './booking-reschedule-modal';
import { Loading } from 'components/loading';
import { AssignModal } from 'components/assign-modal';
import { Modal } from 'components/modal';
import {
  formatWindowDay,
  formatWindowInterval,
  formattedWindowTimezone,
} from 'utils/queues';
import { PrioritiseBookingsButton } from './priority/prioritise-bookings-button';
import { DeprioritiseBookingButton } from './priority/deprioritise-bookings-button';
import { BookingPriorityCount } from './priority/booking-priority-count';
import { useQueueQueryFilterValues } from './summary/queue-filter';

type PractitionerBookingRow = NonNullable<PractitionerBookingsTableRowFragment>;

const TABLE_PAGE_SIZE = 30;

type PractitionerBookingsTableProps = {
  problemType: ProblemType;
  practitionerBookingWindows: NonNullable<
    BookingRescheduleModalPractitionerBookingWindowFragment[]
  >;
  onBookingUpdated: () => Promise<void>;
  totalBookings: number;
};

const PractitionerBookingsTable: React.FC<PractitionerBookingsTableProps> = ({
  problemType,
  practitionerBookingWindows,
  onBookingUpdated,
  totalBookings,
}) => {
  const history = useHistory();
  const { reviewReason, pageIndex } = useQueueQueryFilterValues();
  const reviewReasons = reviewReason ? [reviewReason] : [];

  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [consultationIds, setConsultationIds] = useState<string[]>([]);

  const { data, loading, refetch } = useQuery<
    PractitionerBookingsTableQuery,
    PractitionerBookingsTableQueryVariables
  >(
    gql`
      query PractitionerBookingsTable(
        $problemType: ProblemType!
        $reviewReasons: [ReviewReason!]
        $pagination: Pagination!
      ) {
        practitionerBookings(
          problemTypes: [$problemType]
          reviewReasons: $reviewReasons
          pagination: $pagination
        ) {
          ...PractitionerBookingsTableRow
        }
      }

      fragment PractitionerBookingsTableRow on PractitionerBooking {
        id
        windowStartAt
        windowEndAt
        customer {
          id
          fullName
        }
        consultation {
          id
          type
          stage
          reviewReason
          doctor {
            id
            clinicianName
          }
        }
        timezone
        joinedQueueAt
        priority
      }
    `,
    {
      variables: {
        problemType,
        reviewReasons,
        pagination: {
          pageSize: TABLE_PAGE_SIZE,
          skip: pageIndex * TABLE_PAGE_SIZE,
        },
      },
    },
  );

  // Row data needs to be memoised so that the reference to the data remains the same
  const practitionerBookings = useMemo(
    () => data?.practitionerBookings ?? [],
    [data],
  );

  // Columns needs to be memoised so that the reference to the columns remains the same
  const practitionerBookingsTableColumns = React.useMemo(
    () => practitionerBookingsColumns(setShowAssignModal, setConsultationIds),
    [setShowAssignModal, setConsultationIds],
  );

  // TODO: This is a smelly way to make pagination work well with filters.
  // The main issue is that we grab totalBookings in a different graph call
  // This means we need to add the filtering in 2 places for every additional filter.
  const total =
    reviewReasons.length > 0 ? practitionerBookings.length : totalBookings;

  const pageNumber = Math.ceil((total ?? 1) / TABLE_PAGE_SIZE);

  const practitionerBookingsTableInstance = useSelectablePaginatingSortingTable(
    {
      columns: practitionerBookingsTableColumns,
      data: practitionerBookings,
      disableSelectAll: true,
      pageIndex,
      pageNumber,
    },
  );

  const selectedBookings = useMemo(
    () =>
      practitionerBookingsTableInstance.selectedFlatRows.map((r) => r.original),
    [practitionerBookingsTableInstance.selectedFlatRows],
  );

  const bookingIdsToReschedule = selectedBookings.map((b) => b.id);

  if (loading) {
    return <Loading />;
  }

  if (practitionerBookings.length === 0) {
    return (
      <div className="text-center font-medium pt-8 text-gray-600">
        No bookings found
      </div>
    );
  }

  return (
    <>
      {selectedBookings.length > 0 && (
        <div className="flex space-x-4">
          <div className="uppercase border border-black font-medium bg-secondary-100 px-4 py-2">
            {selectedBookings.length} selected
          </div>
          <div>
            <Button
              fullWidth
              onClick={() => {
                setShowRescheduleModal(true);
              }}
            >
              Change Window
            </Button>
          </div>
          <PrioritiseBookingsButton
            bookings={selectedBookings}
            onComplete={refetch}
          />
          <DeprioritiseBookingButton
            bookings={selectedBookings}
            onComplete={refetch}
          />
          <div>
            <Button
              fullWidth
              onClick={() => {
                setConsultationIds(
                  selectedBookings.map((b) => b.consultation?.id ?? ''),
                );
                setShowAssignModal(true);
              }}
            >
              Assign Selected
            </Button>
          </div>
          <div>
            <Button
              fullWidth
              variant="link"
              onClick={() => {
                practitionerBookingsTableInstance.toggleAllRowsSelected(false);
              }}
            >
              Unselect all
            </Button>
          </div>
        </div>
      )}
      <BookingRescheduleModal
        show={showRescheduleModal}
        practitionerBookingWindows={practitionerBookingWindows}
        onClose={() => {
          setShowRescheduleModal(false);
        }}
        bookingIdsToReschedule={bookingIdsToReschedule}
        onComplete={async () => {
          setShowRescheduleModal(false);
          await onBookingUpdated();
        }}
      />
      <Modal
        show={showAssignModal}
        isAutoOverflow={false}
        onClose={() => {
          setShowAssignModal(false);
        }}
      >
        <AssignModal
          consultationIds={consultationIds}
          onAssigned={async (): Promise<void> => {
            setShowAssignModal(false);
            await refetch();
            await onBookingUpdated();
          }}
        />
      </Modal>
      <Table tableInstance={practitionerBookingsTableInstance}>
        <TableHead />
        <TableBody>
          {practitionerBookingsTableInstance.page?.map((row) => {
            practitionerBookingsTableInstance.prepareRow(row);
            return (
              <TableRow row={row} key={row.id}>
                {row.cells.map((cell) => (
                  <TableCell
                    key={`${cell.column.id}-${cell.row.original.id}`}
                    cell={cell}
                    onClick={() => {
                      if (
                        !['assign-practitioner', 'selection'].includes(
                          cell.column.id,
                        )
                      ) {
                        history.push(
                          `${routes.consultations}/${cell.row.original.consultation?.id}`,
                        );
                      }
                    }}
                  />
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <div className="mr-auto">
        <Pagination
          total={total}
          tableInstance={practitionerBookingsTableInstance}
        />
      </div>
    </>
  );
};

const practitionerBookingsColumns = (
  triggerAssignModal: React.Dispatch<React.SetStateAction<boolean>>,
  setConsultationIds: React.Dispatch<React.SetStateAction<string[]>>,
): Column<PractitionerBookingRow>[] => {
  const cols: Column<PractitionerBookingRow>[] = [
    {
      Header: 'Patient',
      Cell: (c) => {
        const customer = c.row.original.customer;
        return customer ? (
          <div>
            <div className="whitespace-nowrap">
              {formatPatientName(customer)}
            </div>
            <pre className="text-gray-600 text-xs leading-5">
              {customer.id.slice(-6)}
            </pre>
          </div>
        ) : (
          <div />
        );
      },
      disableSortBy: true,
    },
    {
      Header: 'Problem Type',
      Cell: (c) => (
        <ProblemTypeTag problemType={c.row.original.consultation?.type} />
      ),
      disableSortBy: true,
    },
    {
      Header: 'Stage',
      disableSortBy: true,
      Cell: (c) => {
        if (!c.row.original.consultation) {
          return <div>N/A</div>;
        }
        return (
          <Tag
            size="small"
            color={getConsultationStageColor(c.row.original.consultation.stage)}
          >
            {c.row.original.consultation.stage}
          </Tag>
        );
      },
    },
    {
      Header: 'Reason',
      disableSortBy: true,
      Cell: (c) => (
        <div>
          {!c.row.original.consultation?.reviewReason ||
          c.row.original.consultation?.reviewReason === 'LEGACY_MIGRATED'
            ? '-'
            : upperSnakeCaseToCapitalCase(
                c.row.original.consultation.reviewReason,
              )}
        </div>
      ),
    },
    {
      Header: 'Call window',
      Cell: (c) => {
        const booking = c.row.original;
        const startAt = new Date(booking.windowStartAt);
        const endAt = new Date(booking.windowEndAt);

        const day = formatWindowDay({ startAt });

        const interval = formatWindowInterval({ startAt, endAt });

        return (
          <div className="space-y-1">
            <div>{day}</div>
            <div className="text-gray-600 text-xs whitespace-nowrap">
              {interval} {formattedWindowTimezone}
            </div>
          </div>
        );
      },
      disableSortBy: true,
    },
    {
      Header: 'Priority',
      Cell: (c) => <BookingPriorityCount priority={c.row.original.priority} />,
    },
    {
      Header: 'Waiting Time',
      Cell: (c) => {
        if (
          !c.row.original.joinedQueueAt ||
          isFuture(new Date(c.row.original.joinedQueueAt))
        ) {
          return 'N/A';
        }

        return formatDuration(
          intervalToDuration({
            start: new Date(c.row.original.joinedQueueAt),
            end: new Date(),
          }),
          {
            format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'],
          },
        );
      },
      disableSortBy: true,
    },
    {
      Header: 'Prac. Assigned',
      Cell: (c) => {
        if (!c.row.original.consultation) {
          return <div>N/A</div>;
        }
        if (c.row.original.consultation.doctor?.clinicianName) {
          return <div>{c.row.original.consultation.doctor.clinicianName}</div>;
        }
        return (
          <div className="w-24">
            <Button
              fullWidth
              variant="outline"
              onClick={() => {
                setConsultationIds([c.row.original.consultation?.id || '']);
                triggerAssignModal(true);
              }}
            >
              Assign
            </Button>
          </div>
        );
      },
      id: 'assign-practitioner',
      disableSortBy: true,
    },
  ];

  return cols;
};

export default PractitionerBookingsTable;
