import { FaRegCopy, FaPlus, FaRegTrashAlt } from 'react-icons/fa';
import { ReactComponent as DraggableIcon } from 'components/assets/draggable.svg';
import { ReactComponent as OpenInNewIcon } from 'components/assets/open-in-new.svg';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { AiOutlineEdit } from 'react-icons/ai';
import { IoMdAlert } from 'react-icons/io';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  HealthCoachingFlowTemplateQuery,
  HealthCoachingFlowTemplateQueryVariables,
  UpdateHealthCoachingFlowTemplateMutation,
  UpdateHealthCoachingFlowTemplateMutationVariables,
  UpdateHealthCoachingFlowTemplateStatusMutation,
  UpdateHealthCoachingFlowTemplateStatusMutationVariables,
  UpsertHealthCoachingFlowTemplateFragment,
  UpsertHealthCoachingFlowTemplateInput,
} from 'graphql/types';
import { Prompt, useParams } from 'react-router-dom';
import { Loading } from 'components/loading';
import { v4 } from 'uuid';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { Input } from 'components/input';
import { useForm } from 'react-hook-form';
import { requiredValidation } from 'utils/form-validation';
import { Modal } from 'components/modal';
import { ReactComponent as CloseIcon } from 'components/assets/close.svg';
import { Button } from 'components/button';
import { DraggableProvided } from 'react-beautiful-dnd';
import { isEqual } from 'lodash';
import { useNotifications } from 'notifications';
import { Tag } from 'components/tag';

const defaultNewContentfulItemContent = '-';

type FlowMessageNodeTemplate = Extract<
  NonNullable<
    NonNullable<UpsertHealthCoachingFlowTemplateFragment>['nodeTemplates']
  >[0],
  { __typename?: 'HealthCoachingFlowMessageNodeTemplate' }
>;

type FlowMessageNodeTemplateContentfulMessage =
  FlowMessageNodeTemplate['contentfulMessages'][0];

type FlowTemplateMessageNodePlaceholderProps = {
  addContentItem: () => void;
};

function FlowTemplateMessageNodePlaceholder({
  addContentItem,
}: FlowTemplateMessageNodePlaceholderProps) {
  return (
    <div className="rounded border border-slate-200 py-20">
      <p className="text-sm font-semibold text-center mt-1.5">
        This message doesn&apos;t contain any content
      </p>
      <p className="text-sm text-center mt-1">
        For a message to work, you will need to add at least one content item.
      </p>
      <button
        className="flex items-center justify-center mx-auto px-4 py-2.5 rounded border border-gray-700 hover:shadow-md transition-shadow text-sm font-semibold mt-3 mb-1.5"
        onClick={addContentItem}
      >
        <FaPlus className="mr-1 text-gray-700" size={14} />
        <span>Add content</span>
      </button>
    </div>
  );
}

type EditContentfulItemProps = {
  contentfulId: string;
  saveContentfulItem: (contentfulId: string) => void;
};

function EditContentfulItem({
  contentfulId,
  saveContentfulItem,
}: EditContentfulItemProps) {
  const [editContentItem, setEditContentItem] = useState(false);
  const { register, errors, formState, trigger, handleSubmit } = useForm<{
    contentfulId: string;
  }>({
    defaultValues: {
      contentfulId,
    },
  });

  const saveContent = handleSubmit(async (data) => {
    const isValid = await trigger();

    if (!isValid) {
      return;
    }

    saveContentfulItem(data.contentfulId);
    setEditContentItem(false);
  });

  return (
    <div>
      <button
        className="h-8 w-8 flex items-center justify-center"
        onClick={() => setEditContentItem(true)}
      >
        <AiOutlineEdit size={20} className="fill-slate-500" />
      </button>

      <Modal
        show={editContentItem}
        onClose={() => setEditContentItem(false)}
        width="w-[500px]"
      >
        <div className="bg-white rounded-lg p-6 flex flex-col gap-5">
          <div className="flex flex-col gap-5">
            <div className="flex items-center justify-between">
              <p className="text-base uppercase font-semibold">
                {contentfulId.length ? 'Edit content' : 'Add content'}
              </p>

              <button className="p-2" onClick={() => setEditContentItem(false)}>
                <CloseIcon width={16} height={16} className="fill-black" />
              </button>
            </div>

            <Input
              name="contentfulId"
              label="Contentful ID"
              autoFocus
              ref={register(requiredValidation('contentfulId'))}
              errorMessage={errors.contentfulId?.message}
            />

            <div className="flex justify-end gap-5">
              <Button
                variant="outline"
                onClick={() => setEditContentItem(false)}
              >
                Close
              </Button>

              <Button loading={formState.isSubmitting} onClick={saveContent}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

type FlowTemplateMessageNodeProps = {
  index: number;
  contentfulMessages: FlowMessageNodeTemplateContentfulMessage[];
  draggableProvided: DraggableProvided;
  duplicateMessageNode: (messageNodeIndex: number) => void;
  deleteMessageNode: (messageNodeIndex: number) => void;
  addContentItem: (messageNodeIndex: number) => void;
  updateContentItem: (updateProps: {
    messageNodeIndex: number;
    itemId: string;
    contentfulId: string;
  }) => void;
  deleteContentItem: (deleteProps: {
    messageNodeIndex: number;
    itemId: string;
  }) => void;
};

function FlowTemplateMessageNode({
  index,
  contentfulMessages,
  draggableProvided,
  duplicateMessageNode,
  deleteMessageNode,
  addContentItem,
  updateContentItem,
  deleteContentItem,
}: FlowTemplateMessageNodeProps) {
  const [confirmMessageNodeDeletion, setConfirmMessageNodeDeletion] =
    useState(false);
  const [confirmContentfulItemDeletion, setConfirmContentfulItemDeletion] =
    useState(false);

  const [focusedContentfulItemId, setFocusedContentfulItemId] = useState<
    string | undefined
  >();

  const closeContentItemDeleteModal = () => {
    setConfirmContentfulItemDeletion(false);
    setFocusedContentfulItemId(undefined);
  };

  return (
    <div
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
      className="bg-white rounded border border-slate-300 overflow-hidden mt-8"
    >
      <div className="px-5 py-3 bg-slate-50 border-b border-slate-300 flex items-center">
        <div
          className="h-5 w-5 flex items-center justify-center"
          {...draggableProvided.dragHandleProps}
        >
          <DraggableIcon className="fill-slate-300 w-5 h-5" />
        </div>

        <div className="h-6 w-6 rounded-full border border-slate-300 ml-3 flex items-center justify-center">
          <span className="text-sm font-semibold">{index + 1}</span>
        </div>

        <p className="ml-2 text-base font-semibold text-slate-700 grow">
          Message node
        </p>

        <button
          className="h-10 w-10 flex items-center justify-center cursor-pointer"
          onClick={() => duplicateMessageNode(index)}
        >
          <FaRegCopy size={16} className="fill-slate-500" />
        </button>
        <button
          className="h-10 w-10 flex items-center justify-center cursor-pointer"
          onClick={() => setConfirmMessageNodeDeletion(true)}
        >
          <FaRegTrashAlt size={16} className="fill-slate-500" />
        </button>
      </div>
      <div className="p-6">
        {!contentfulMessages.length ? (
          <FlowTemplateMessageNodePlaceholder
            addContentItem={() => addContentItem(index)}
          />
        ) : (
          <div>
            <div className="rounded border border-slate-200 overflow-auto">
              <div className="w-full table">
                <div className="table-row bg-slate-50">
                  {['Name', 'Preview', 'Contentful ID', ''].map(
                    (colTitle, index) => (
                      <div
                        key={index}
                        className="table-cell uppercase text-xs font-semibold py-3 px-4 text-left"
                      >
                        {colTitle}
                      </div>
                    ),
                  )}
                </div>

                {contentfulMessages.map((cm) => (
                  <div key={cm.id} className="w-full table-row">
                    <div className="table-cell px-4 py-3 w-72">
                      <p className="text-sm font-semibold py-1.5">{cm.title}</p>
                    </div>
                    <div className="table-cell px-4 py-3 text-sm">
                      {cm.message === defaultNewContentfulItemContent ? (
                        defaultNewContentfulItemContent
                      ) : (
                        <div
                          className="line-clamp-1"
                          dangerouslySetInnerHTML={{
                            __html: documentToHtmlString(cm.message),
                          }}
                        />
                      )}
                    </div>
                    <div className="table-cell px-4 py-3 text-sm w-80 ml-auto mr-6">
                      {cm.contentfulId.length ? (
                        <div className="flex items-center justify-start gap-2">
                          <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                            {cm.contentfulId}
                          </p>
                          <a
                            href={`https://app.contentful.com/spaces/1p96mcpzbbp4/entries/${cm.contentfulId}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <OpenInNewIcon className="h-3 w-3 fill-slate-500" />
                          </a>
                        </div>
                      ) : (
                        defaultNewContentfulItemContent
                      )}
                    </div>
                    <div className="table-cell px-4 py-3 w-28 ml-auto mr-0">
                      <div className="flex items-center gap-2">
                        <EditContentfulItem
                          contentfulId={cm.contentfulId}
                          saveContentfulItem={(contentfulId) =>
                            updateContentItem({
                              messageNodeIndex: index,
                              itemId: cm.id,
                              contentfulId,
                            })
                          }
                        />

                        <button
                          className="h-8 w-8 flex items-center justify-center"
                          onClick={() => {
                            setFocusedContentfulItemId(cm.id);
                            setConfirmContentfulItemDeletion(true);
                          }}
                        >
                          <FaRegTrashAlt size={16} className="fill-slate-500" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <button
              className="flex items-center justify-center ml-auto px-4 py-2.5 rounded border border-gray-700 hover:shadow-md transition-shadow text-sm font-semibold mt-5"
              onClick={() => addContentItem(index)}
            >
              <FaPlus className="mr-1 text-gray-700" size={14} />
              <span>Add content variant</span>
            </button>
          </div>
        )}
      </div>

      {/* TODO: ENG-2163 */}
      <Modal
        show={confirmMessageNodeDeletion}
        onClose={() => setConfirmMessageNodeDeletion(false)}
        width="w-[500px]"
      >
        <div className="bg-white rounded-lg p-6 flex flex-col gap-5">
          <div className="flex flex-col gap-5">
            <div className="flex items-center justify-between">
              <p className="text-base uppercase font-semibold">
                Delete message node?
              </p>

              <button
                className="p-2"
                onClick={() => setConfirmMessageNodeDeletion(false)}
              >
                <CloseIcon width={16} height={16} className="fill-black" />
              </button>
            </div>

            <p className="text-base mb-4">
              This will delete the message node, and all of its contents.
            </p>

            <div className="flex justify-end gap-5">
              <Button
                variant="outline"
                onClick={() => setConfirmMessageNodeDeletion(false)}
              >
                Close
              </Button>

              <Button
                color="danger"
                onClick={() => {
                  deleteMessageNode(index);
                  setConfirmMessageNodeDeletion(false);
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      {/* TODO: ENG-2163 */}
      {focusedContentfulItemId && confirmContentfulItemDeletion && (
        <Modal show onClose={closeContentItemDeleteModal} width="w-[500px]">
          <div className="bg-white rounded-lg p-6 flex flex-col gap-5">
            <div className="flex flex-col gap-5">
              <div className="flex items-center justify-between">
                <p className="text-base uppercase font-semibold">
                  Delete message node?
                </p>

                <button className="p-2" onClick={closeContentItemDeleteModal}>
                  <CloseIcon width={16} height={16} className="fill-black" />
                </button>
              </div>

              <p className="text-base mb-4">
                This will delete the message node, and all of it’s contents.
              </p>

              <div className="flex justify-end gap-5">
                <Button variant="outline" onClick={closeContentItemDeleteModal}>
                  Close
                </Button>

                <Button
                  color="danger"
                  onClick={() => {
                    deleteContentItem({
                      messageNodeIndex: index,
                      itemId: focusedContentfulItemId,
                    });
                    setConfirmContentfulItemDeletion(false);
                  }}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

const upsertHealthCoachingFlowTemplateFragment = gql`
  fragment upsertHealthCoachingFlowTemplate on HealthCoachingFlowTemplate {
    id
    name
    repeatable
    status
    nodeTemplates {
      id
      ... on HealthCoachingFlowMessageNodeTemplate {
        contentfulMessages {
          id
          contentfulId
          message
          title
        }
      }
      ... on HealthCoachingFlowWaitNodeTemplate {
        count
        interval
      }
    }
  }
`;

const healthCoachingFlowTemplateQueryDocument = gql`
  query HealthCoachingFlowTemplate($id: ID!) {
    healthCoachingFlowTemplate(id: $id) {
      ...upsertHealthCoachingFlowTemplate
    }
  }
  ${upsertHealthCoachingFlowTemplateFragment}
`;

const updateHealthCoachingFlowTemplateMutationDocument = gql`
  mutation UpdateHealthCoachingFlowTemplate(
    $input: UpsertHealthCoachingFlowTemplateInput!
  ) {
    upsertHealthCoachingFlowTemplate(input: $input) {
      healthCoachingFlowTemplate {
        ...upsertHealthCoachingFlowTemplate
      }
    }
  }
  ${upsertHealthCoachingFlowTemplateFragment}
`;

const updateHealthCoachingFlowTemplateStatusMutationDocument = gql`
  mutation UpdateHealthCoachingFlowTemplateStatus(
    $input: UpdateHealthCoachingFlowTemplateStatusInput!
  ) {
    updateHealthCoachingFlowTemplateStatus(input: $input) {
      healthCoachingFlowTemplate {
        ...upsertHealthCoachingFlowTemplate
      }
    }
  }
  ${upsertHealthCoachingFlowTemplateFragment}
`;

const flowTemplateStatusColorMap = {
  PUBLISHED: 'green',
  UNPUBLISHED: 'red',
  DRAFT: 'orange',
} as const;

const handleBeforeUnloadWindowEvent = (event: BeforeUnloadEvent) =>
  event.preventDefault();

export default function HealthCoachingFlowTemplate() {
  const { flowId } = useParams<{ flowId: string }>();
  const [messageNodes, setMessageNodes] = useState<FlowMessageNodeTemplate[]>(
    [],
  );
  const [existingMessageNodes, setExistingMessageNodes] = useState<
    FlowMessageNodeTemplate[]
  >([]);
  const showNotification = useNotifications();

  const updateMessageNodes = (
    nodeTemplates: UpsertHealthCoachingFlowTemplateFragment['nodeTemplates'],
  ) => {
    if (!nodeTemplates?.length) {
      setExistingMessageNodes([]);
      setMessageNodes([]);
      return;
    }

    const messageNodes = nodeTemplates.filter(
      (nt): nt is FlowMessageNodeTemplate =>
        nt.__typename === 'HealthCoachingFlowMessageNodeTemplate',
    );

    setExistingMessageNodes([...messageNodes]);
    setMessageNodes([...messageNodes]);
  };

  const { data, loading } = useQuery<
    HealthCoachingFlowTemplateQuery,
    HealthCoachingFlowTemplateQueryVariables
  >(healthCoachingFlowTemplateQueryDocument, {
    variables: {
      id: flowId,
    },
    onCompleted: (data) =>
      updateMessageNodes(data?.healthCoachingFlowTemplate?.nodeTemplates),
  });
  const [
    updateHealthCoachingFlowTemplateMutation,
    { loading: updatingFlowTemplate },
  ] = useMutation<
    UpdateHealthCoachingFlowTemplateMutation,
    UpdateHealthCoachingFlowTemplateMutationVariables
  >(updateHealthCoachingFlowTemplateMutationDocument, {
    onCompleted: (data) =>
      updateMessageNodes(
        data?.upsertHealthCoachingFlowTemplate?.healthCoachingFlowTemplate
          ?.nodeTemplates,
      ),
  });
  const [
    updateHealthCoachingFlowTemplateStatusMutation,
    { loading: updatingFlowTemplateStatus },
  ] = useMutation<
    UpdateHealthCoachingFlowTemplateStatusMutation,
    UpdateHealthCoachingFlowTemplateStatusMutationVariables
  >(updateHealthCoachingFlowTemplateStatusMutationDocument);

  const handleOnDragEnd = useCallback(
    ({ source, destination }: DropResult): void => {
      if (!destination) {
        return;
      }

      const [draggedMessageNode] = messageNodes.splice(source.index, 1);
      messageNodes.splice(destination.index, 0, draggedMessageNode);
      setMessageNodes([...messageNodes]);
    },
    [messageNodes, setMessageNodes],
  );

  const needsToSaveFlow = useMemo(
    () => !isEqual(existingMessageNodes, messageNodes),
    [existingMessageNodes, messageNodes],
  );

  useEffect(() => {
    if (needsToSaveFlow) {
      window.addEventListener('beforeunload', handleBeforeUnloadWindowEvent);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnloadWindowEvent);
    }

    return () =>
      window.removeEventListener('beforeunload', handleBeforeUnloadWindowEvent);
  }, [needsToSaveFlow]);

  if (loading && !data) {
    return (
      <div className="flex justify-center text-lg">
        <Loading />
      </div>
    );
  }

  if (!data?.healthCoachingFlowTemplate) {
    throw new Error(
      `Unable to find health coaching flow template with id: ${flowId}`,
    );
  }

  const { id, name, repeatable, status } = data.healthCoachingFlowTemplate;
  const statusColor = flowTemplateStatusColorMap[status];

  const addNewLocalMessageNode = () =>
    setMessageNodes([...messageNodes, { id: v4(), contentfulMessages: [] }]);

  const duplicateLocalMessageNode = (messageNodeIndex: number) => {
    const messageNode = messageNodes[messageNodeIndex];
    const contentfulItems = messageNode.contentfulMessages;

    messageNodes.splice(messageNodeIndex, 0, {
      id: v4(),
      contentfulMessages: contentfulItems,
    });
    setMessageNodes([...messageNodes]);
  };

  const deleteLocalMessageNode = (messageNodeIndex: number) => {
    messageNodes.splice(messageNodeIndex, 1);
    setMessageNodes([...messageNodes]);
  };

  const addLocalContentfulMessage = (messageNodeIndex: number) => {
    const messageNode = messageNodes[messageNodeIndex];
    const updatedContentfulMessages = [...messageNode.contentfulMessages];
    updatedContentfulMessages.push({
      id: v4(),
      contentfulId: '',
      message: defaultNewContentfulItemContent,
      title: defaultNewContentfulItemContent,
    });

    setMessageNodes(
      messageNodes.map((mn, i) => {
        if (i === messageNodeIndex) {
          return {
            ...mn,
            contentfulMessages: updatedContentfulMessages,
          };
        }

        return mn;
      }),
    );
  };

  const updateLocalContentfulMessage = ({
    messageNodeIndex,
    itemId,
    contentfulId,
  }: {
    messageNodeIndex: number;
    itemId: string;
    contentfulId: string;
  }) => {
    const messageNode = messageNodes[messageNodeIndex];
    const updatedContentfulMessages = messageNode.contentfulMessages.map(
      (cm) => {
        if (cm.id === itemId) {
          return {
            ...cm,
            contentfulId,
          };
        }

        return cm;
      },
    );

    messageNodes.splice(messageNodeIndex, 1, {
      ...messageNode,
      contentfulMessages: updatedContentfulMessages,
    });
    setMessageNodes([...messageNodes]);
  };

  const deleteLocalContentfulMessage = ({
    messageNodeIndex,
    itemId,
  }: {
    messageNodeIndex: number;
    itemId: string;
  }) => {
    const messageNode = messageNodes[messageNodeIndex];
    const filteredContentfulMessages = messageNode.contentfulMessages.filter(
      (cm) => cm.id !== itemId,
    );

    messageNodes.splice(messageNodeIndex, 1, {
      ...messageNode,
      contentfulMessages: filteredContentfulMessages,
    });
    setMessageNodes([...messageNodes]);
  };

  const saveFlowTemplate = async () => {
    const upsertHealthCoachingFlowTemplateInput: UpsertHealthCoachingFlowTemplateInput =
      {
        id,
        name,
        repeatable,
        nodeTemplates: [],
      };

    const filteredMessageNodes = messageNodes
      .map((mn) => ({
        ...mn,
        contentfulMessages: mn.contentfulMessages.filter(
          (cm) => cm.contentfulId.length,
        ),
      }))
      .filter((mn) => mn.contentfulMessages.length);

    if (status === 'PUBLISHED' && !filteredMessageNodes.length) {
      showNotification({
        type: 'error',
        message: 'Published flows must have at least one message node',
      });
      return;
    }

    filteredMessageNodes.forEach((mn) => {
      upsertHealthCoachingFlowTemplateInput.nodeTemplates.push({
        message: {
          id: mn.id,
          contentfulIds: mn.contentfulMessages.map((ci) => ci.contentfulId),
        },
      });

      upsertHealthCoachingFlowTemplateInput.nodeTemplates.push({
        wait: {
          id: v4(),
          count: 1,
          interval: 'DAYS',
        },
      });
    });
    upsertHealthCoachingFlowTemplateInput.nodeTemplates.pop();

    await updateHealthCoachingFlowTemplateMutation({
      variables: {
        input: upsertHealthCoachingFlowTemplateInput,
      },
    });

    showNotification({
      type: 'success',
      message: 'Flow updated',
    });
  };

  const updateFlowTemplateStatus = async (
    newStatus: 'PUBLISHED' | 'UNPUBLISHED',
  ) => {
    await updateHealthCoachingFlowTemplateStatusMutation({
      variables: {
        input: {
          id: flowId,
          status: newStatus,
        },
      },
    });

    showNotification({
      type: 'success',
      message:
        newStatus === 'PUBLISHED' ? 'Flow published' : 'Flow unpublished',
    });
  };

  return (
    <Fragment>
      <div className="flex justify-between">
        <Tag shape="box" color={statusColor} size="large">
          {status}
        </Tag>

        <div className="flex justify-end gap-2">
          <Button
            disabled={!needsToSaveFlow}
            loading={updatingFlowTemplate}
            onClick={saveFlowTemplate}
          >
            Save
          </Button>

          {(status === 'DRAFT' || status === 'UNPUBLISHED') && (
            <Button
              variant="outline"
              loading={updatingFlowTemplateStatus}
              onClick={() => {
                void updateFlowTemplateStatus('PUBLISHED');
              }}
            >
              Publish
            </Button>
          )}

          {status === 'PUBLISHED' && (
            <Button
              variant="outline"
              onClick={() => {
                void updateFlowTemplateStatus('UNPUBLISHED');
              }}
            >
              Unpublish
            </Button>
          )}
        </div>
      </div>

      {/* TODO: ENG-2163 */}
      {needsToSaveFlow && (
        <div className="mt-8 p-3 rounded flex items-start gap-1 border border-red-300 bg-red-50">
          <div className="w-6 h-6 flex items-center justify-center">
            <IoMdAlert size={16} className="fill-red-600" />
          </div>

          <div className="grow">
            <p className="text-base font-semibold text-slate-700">
              You have unpublished changes
            </p>
            <p className="text-base text-slate-700">
              Saving will publish your changes to live coaching action. If you
              wish to make changes offline, please unpublish the flow first.
            </p>
          </div>
        </div>
      )}

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="flowTemplateMessageNodes">
          {(provided): JSX.Element => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {messageNodes.map((messageNode, index) => (
                <Draggable
                  key={messageNode.id}
                  draggableId={messageNode.id}
                  index={index}
                >
                  {(provided): JSX.Element => (
                    <FlowTemplateMessageNode
                      index={index}
                      draggableProvided={provided}
                      contentfulMessages={messageNode.contentfulMessages}
                      duplicateMessageNode={duplicateLocalMessageNode}
                      deleteMessageNode={deleteLocalMessageNode}
                      addContentItem={addLocalContentfulMessage}
                      updateContentItem={updateLocalContentfulMessage}
                      deleteContentItem={deleteLocalContentfulMessage}
                    />
                  )}
                </Draggable>
              ))}

              {/* maintains height of container during drag */}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="px-5 py-0.5 rounded border border-slate-200 bg-white/50 mt-8">
        <button
          className="flex items-center justify-center mx-auto px-4 py-2.5 rounded border border-gray-700 hover:shadow-md transition-shadow text-sm font-semibold my-32 w-[212px]"
          onClick={addNewLocalMessageNode}
        >
          <FaPlus className="mr-1 text-gray-700" size={14} />
          <span>Add message node</span>
        </button>
      </div>

      <Prompt
        when={needsToSaveFlow}
        message="Changes that you made may not be saved."
      />
    </Fragment>
  );
}
