import React from 'react';
import { useNotifications } from '../../../notifications';
import { ContentItemType, contentTypeSearchOptions } from '.';
import { useForm } from 'react-hook-form';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  CreateItemMutation,
  CreateItemMutationVariables,
  CreateVideoScreenQuery,
} from '../../../graphql/types';
import { requiredValidation } from '../../../utils/form-validation';
import { Input } from '../../../components/input';
import { Dropdown } from '../../../components/dropdown';
import { Button } from '../../../components/button';

type CreateVideoScreenProps = {
  onCreateSuccess: (id: string) => void;
  onCancel: () => void;
};

export const CreateItemModal: React.FC<CreateVideoScreenProps> = ({
  onCancel,
  onCreateSuccess,
}) => {
  const [loading, setLoading] = React.useState(false);
  const showNotification = useNotifications();
  const { handleSubmit, register, errors, control } = useForm<{
    contentfulId: string;
    tagIds: string[];
    type: ContentItemType;
  }>({ defaultValues: { contentfulId: '', tagIds: [] } });

  const resp = useQuery<CreateVideoScreenQuery>(gql`
    query CreateVideoScreen {
      contentTags {
        id
        name
      }
    }
  `);

  const [createItem] = useMutation<
    CreateItemMutation,
    CreateItemMutationVariables
  >(gql`
    mutation CreateItem($input: CreateContentItemInput!) {
      createContentItem(input: $input) {
        item {
          id
          status
        }
      }
    }
  `);

  const onCreateItem = handleSubmit(
    async ({ contentfulId, tagIds, type }): Promise<void> => {
      try {
        setLoading(true);
        const { data } = await createItem({
          variables: {
            input: {
              tagIds,
              ...(type === 'VideoContentItem' && {
                video: {
                  contentfulId: contentfulId.trim(),
                },
              }),
              ...(type === 'ReadingContentItem' && {
                reading: {
                  contentfulId: contentfulId.trim(),
                },
              }),
              ...(type === 'VideoWithReadingContentItem' && {
                videoWithReading: {
                  contentfulId: contentfulId.trim(),
                },
              }),
              ...(type === 'RecipeContentItem' && {
                recipe: {
                  contentfulId: contentfulId.trim(),
                },
              }),
              ...(type === 'FaqContentItem' && {
                faq: {
                  contentfulId: contentfulId.trim(),
                },
              }),
            },
          },
        });
        const id = data?.createContentItem?.item.id;

        if (!id) {
          throw new Error('Could not create video, please try again');
        }

        onCreateSuccess(id);
      } catch (e) {
        if (e instanceof Error) {
          showNotification({
            message: e.message,
            type: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
  );

  return (
    <div>
      <h2 className="heading-md mb-5">Add Item</h2>
      <form onSubmit={onCreateItem}>
        <div className="flex flex-col space-y-8">
          <div>
            <Input
              ref={register(requiredValidation('contentfulId'))}
              label="Contentful ID"
              name="contentfulId"
              errorMessage={errors.contentfulId?.message}
            />
          </div>
          <div>
            <Dropdown
              name="tagIds"
              label="Tags"
              control={control}
              isMulti
              options={
                resp.data?.contentTags?.map((t) => ({
                  label: t.name,
                  value: t.id,
                })) ?? []
              }
            />
          </div>
          <div>
            <Dropdown
              name="type"
              label="type"
              control={control}
              options={contentTypeSearchOptions}
              rules={requiredValidation('type')}
              errorMessage={errors.type?.message}
            />
          </div>
          <div className="flex flex-row space-x-4">
            <Button fullWidth variant="outline" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              fullWidth
              color="success"
              loading={loading}
              disabled={loading}
              type="submit"
            >
              Add and preview
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
