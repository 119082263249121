import { useMemo, type FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { RequestReviewLayout } from './request-review-layout';
import type { RequestReviewStepTransitionProps } from './types';
import { gql, useQuery } from '@apollo/client';
import type {
  CustomerReviewQueuesQuery,
  CustomerReviewQueuesQueryVariables,
  PractitionerBookingWindow,
  ProblemType,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import {
  formatWindowDay,
  formatWindowInterval,
  usePracBookingWindowDates,
} from 'utils/queues';
import { Loading } from 'components/loading';
import { RadioButton } from 'components/radio-button';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';

export type BookingWindow = Pick<
  PractitionerBookingWindow,
  | 'id'
  | 'windowId'
  | 'available'
  | 'startAt'
  | 'endAt'
  | 'capacity'
  | 'unassignedBookingsCount'
>;

export type BookingWindowAssignStepProps = RequestReviewStepTransitionProps & {
  bookingWindow?: BookingWindow;
  setBookingWindow: (bookingWindow: BookingWindow) => void;
  problemType: ProblemType;
};

export const BookingWindowAssignStep: FunctionComponent<
  BookingWindowAssignStepProps
> = ({
  previousStep,
  nextStep,
  bookingWindow,
  setBookingWindow,
  problemType,
}) => {
  const showNotification = useNotifications();
  const featureFlagClient = useFeatureFlagClient();

  const dates = usePracBookingWindowDates();

  const { data, loading } = useQuery<
    CustomerReviewQueuesQuery,
    CustomerReviewQueuesQueryVariables
  >(
    gql`
      query CustomerReviewQueues(
        $problemTypes: [ProblemType!]!
        $dates: [String!]!
      ) {
        practitionerBookingWindows(
          input: { problemTypes: $problemTypes, dates: $dates }
        ) {
          id
          windowId
          available
          startAt
          endAt
          capacity
          unassignedBookingsCount
          totalBookingsCount
        }
      }
    `,
    {
      variables: {
        problemTypes: [problemType],
        dates,
      },
    },
  );

  const ffEnableWindowTotalBookingsCount = featureFlagClient.getBoolean(
    'ff-enable-window-total-bookings-count',
  );

  const { bookingWindows, options } = useMemo(() => {
    const bookingWindows = data?.practitionerBookingWindows ?? [];

    const options = bookingWindows.map(
      ({
        windowId,
        available,
        unassignedBookingsCount,
        totalBookingsCount,
        capacity,
        ...window
      }) => {
        const day = formatWindowDay(window);
        const interval = formatWindowInterval(window);

        const availableCapacity = ffEnableWindowTotalBookingsCount
          ? `${unassignedBookingsCount} (${totalBookingsCount}/${capacity})`
          : `(${unassignedBookingsCount}/${capacity})`;

        return {
          label: `${day} ${interval} - ${availableCapacity}`,
          value: windowId,
          disabled: !available,
        };
      },
    );

    return {
      bookingWindows,
      options,
    };
  }, [data, ffEnableWindowTotalBookingsCount]);

  const { register, handleSubmit, watch } = useForm<{
    bookingWindowId: string;
  }>({ defaultValues: { bookingWindowId: bookingWindow?.windowId } });

  const fieldValue = watch('bookingWindowId');

  const onSubmit = handleSubmit(({ bookingWindowId }) => {
    const bookingWindow = bookingWindows.find(
      ({ windowId }) => bookingWindowId === windowId,
    );

    if (!bookingWindow) {
      showNotification({
        type: 'error',
        message: 'Please select a valid call window',
      });
      return;
    }

    setBookingWindow(bookingWindow);
    nextStep();
  });

  return (
    <RequestReviewLayout
      title="Select a call window"
      previous={previousStep}
      nextDisabled={!fieldValue}
      onSubmit={onSubmit}
    >
      {loading ? (
        <Loading />
      ) : !options.length ? (
        <div className="text-sm">
          Could not load any call windows. Please go back a step and assign the
          consultation directly to a practitioner.
        </div>
      ) : (
        <>
          <div className="text-sm">
            Closed call windows are disabled from the list below
          </div>

          <div className="columns-3 gap-4 space-y-4">
            {options.map(({ label, value, disabled }) => (
              <RadioButton
                key={value}
                ref={register()}
                id={value}
                name="bookingWindowId"
                label={label}
                disabled={disabled}
              />
            ))}
          </div>
        </>
      )}
    </RequestReviewLayout>
  );
};
