import { config } from 'config';
import { generatePath } from 'react-router';
import { routes } from 'utils/routes';

export const getAdminReadingPageRoute = (readingId: string): string => {
  return generatePath(routes.educationReading, { readingId });
};

export const getAppReadingUrl = (readingId: string): string => {
  return `${config.userAppUrl}/education/readings/${readingId}`;
};
