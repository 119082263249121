import { ProblemTypeTag } from 'components/tags/problem-type';
import { TrackerPatient } from 'graphql/types';
import { CellProps, Column } from 'react-table';
import { formatDateAndTime, isValidProblemType } from 'utils/misc';

const DateCell = (cell: CellProps<TrackerPatient>): React.ReactElement => (
  <div>{formatDateAndTime(cell.value)}</div>
);

export const columns: Column<TrackerPatient>[] = [
  {
    Header: 'Patient Id',
    accessor: 'customerId',
    disableSortBy: true,
  },
  {
    Header: 'Name',
    accessor: (row) => row.fullName,
    disableSortBy: true,
  },
  {
    Header: 'Email',
    accessor: 'email',
    disableSortBy: true,
  },
  {
    Header: 'Last Check-in',
    accessor: 'lastCheckIn',
    Cell: DateCell,
    sortDescFirst: true,
  },
  {
    Header: 'Problem Type',
    accessor: 'problemType',
    Cell: (c) => {
      if (isValidProblemType(c.value)) {
        return <ProblemTypeTag problemType={c.value} />;
      }
      return 'Invalid problem type';
    },
  },
  {
    Header: 'Count',
    accessor: 'count',
  },
];
