import React from 'react';
import { useEffect } from 'react';
import { useMutation, gql, useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { useFieldArray, useForm } from 'react-hook-form';

import {
  PharmacyEnum,
  ShippingProviderEnum,
  TriggerCreatePackingBundleJobMutation,
  TriggerCreatePackingBundleJobMutationVariables,
  SignedPackingBundleUploadUrlQuery,
  SignedPackingBundleUploadUrlQueryVariables,
  ShippingLabelsUploadDetailsInput,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import { routes } from 'utils/routes';
import { Dropdown } from 'components/dropdown';
import { Button } from 'components/button';
import { requiredValidation } from 'utils/form-validation';
import { Label } from 'components/label';
import { InputError } from 'components/input-error';

const triggerCreateBundleJobMutation = gql`
  mutation TriggerCreatePackingBundleJob(
    $input: TriggerCreatePackingBundleJobInput!
  ) {
    triggerCreatePackingBundleJob(input: $input) {
      packingBundle {
        id
      }
    }
  }
`;

const shippingProviderOptions: Array<{
  value: ShippingProviderEnum;
  label: string;
}> = [
  {
    label: 'Arc Tech',
    value: 'SHIPPING_PROVIDER_HDS_ARCTECH',
  },
  {
    label: 'Starshipit',
    value: 'SHIPPING_PROVIDER_STARSHIPIT',
  },
  {
    label: 'ShipStation',
    value: 'SHIPPING_PROVIDER_SHIPSTATION',
  },
];

const fulfilmentCentreOptions: Array<{
  value: PharmacyEnum;
  label: string;
}> = [
  {
    value: 'CLOUD',
    label: 'Cloud Brisbane',
  },
  {
    value: 'IWG',
    label: 'IWG Adelaide',
  },
  {
    value: 'UK_PHARMACY',
    label: 'UK Pharmacy',
  },
  {
    value: 'YSCP_BNE',
    label: 'YSCP Brisbane',
  },
  {
    value: 'YSCP_MEL',
    label: 'YSCP Melbourne',
  },
];

type FormValues = {
  fulfilmentCentre: PharmacyEnum;
  labels: Array<{
    integration: ShippingProviderEnum;
    file: FileList;
  }>;
};

export default function CreatePackingBundle(): React.ReactElement {
  const showNotification = useNotifications();
  const history = useHistory();

  const {
    control,
    errors,
    register,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  const { fields, append, remove } = useFieldArray<
    FormValues['labels'][number]
  >({ control, name: 'labels' });

  // theres no way to set defaults in a field array, this just ensures that
  // on load at least one field is added to the "labels" field array
  useEffect(() => {
    if (fields.length === 0) {
      append({ file: undefined, integration: undefined });
    }
    // append doesn't need to be part of deps, also causes render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  const [createPackingBundleJob, { loading }] = useMutation<
    TriggerCreatePackingBundleJobMutation,
    TriggerCreatePackingBundleJobMutationVariables
  >(triggerCreateBundleJobMutation, {
    onCompleted(data) {
      showNotification({
        type: 'success',
        message: 'Packing bundle creation has begun',
      });

      if (!data.triggerCreatePackingBundleJob?.packingBundle.id) {
        history.push(routes.packingBundles);
        return;
      }

      history.push(
        `${routes.packingBundles}/${data.triggerCreatePackingBundleJob?.packingBundle.id}`,
      );
    },
    onError() {
      showNotification({
        type: 'error',
        message: 'An error occurred while creating a packing bundle job',
      });
    },
  });

  const [getSignedPackingBundleUploadUrls] = useLazyQuery<
    SignedPackingBundleUploadUrlQuery,
    SignedPackingBundleUploadUrlQueryVariables
  >(gql`
    query SignedPackingBundleUploadUrl($fileNames: [String!]!) {
      signedPackingBundleUploadUrl(fileNames: $fileNames) {
        id
        uploadBucketKey
        uploadSignedUrl
      }
    }
  `);

  const hasDuplicates = (arr: string[]) => arr.length !== new Set(arr).size;

  const handleFormSubmit = async (values: FormValues): Promise<void> => {
    const fileNames = values.labels.map((l) => l.file[0].name);
    if (hasDuplicates(fileNames)) {
      showNotification({
        type: 'error',
        message: `Cannot create packing bundle due to duplicate shipping label file names`,
      });
      return;
    }
    const { data } = await getSignedPackingBundleUploadUrls({
      variables: {
        fileNames,
      },
    });

    const inputLabels: ShippingLabelsUploadDetailsInput[] = [];
    const promiseArray: Promise<Response>[] = [];
    for (const label of values.labels) {
      const signedUrlItem = data?.signedPackingBundleUploadUrl.find(
        (o) => o.id === label.file[0]?.name,
      );
      if (!signedUrlItem) {
        showNotification({
          type: 'error',
          message: `Could not find signedUploadUrl for file : ${label.file[0].name}`,
        });
        return;
      }

      const signedUrl = new URL(signedUrlItem.uploadSignedUrl);

      promiseArray.push(
        fetch(`${signedUrl}`, {
          method: 'PUT',
          headers: new Headers({ 'Content-Type': 'application/pdf' }),
          body: label.file[0],
        }),
      );
      inputLabels.push({
        shippingProvider: label.integration,
        bucketKey: signedUrlItem.uploadBucketKey,
      });
    }

    try {
      const responses = await Promise.all(promiseArray);
      for (const resp of responses) {
        if (!resp.ok) {
          showNotification({
            type: 'error',
            message: `File upload failed status : ${resp.status}`,
          });
          return;
        }
      }
    } catch (err) {
      showNotification({
        type: 'error',
        message: `File upload failed reason : ${err}`,
      });
      return;
    }

    createPackingBundleJob({
      variables: {
        input: {
          pharmacyId: values.fulfilmentCentre,
          labels: inputLabels,
        },
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="flex flex-col gap-4">
        <div className="w-80">
          <Dropdown
            label="Fulfilment Centre"
            name="fulfilmentCentre"
            control={control}
            rules={requiredValidation('fulfilmentCentre')}
            errorMessage={errors?.fulfilmentCentre?.message}
            options={fulfilmentCentreOptions}
          />
        </div>
        <ul className="flex flex-row gap-4 flex-wrap">
          {fields.map((field, index) => {
            return (
              <li
                key={field.id}
                className="p-2 border-primary rounded bg-gray-300"
              >
                <div className="mb-1 flex justify-between items-center">
                  <div className="capitalize font-semibold text-primary-darker">
                    Shipping Label
                  </div>

                  <div className="w-1/8">
                    <Button
                      fullWidth
                      color="danger"
                      disabled={index === 0}
                      onClick={(): void => remove(index)}
                    >
                      <FaTrash />
                    </Button>
                  </div>
                </div>

                <Dropdown
                  label="Provider"
                  control={control}
                  options={shippingProviderOptions}
                  name={`labels.${index}.integration`}
                  rules={requiredValidation(`labels.${index}.integration`)}
                  errorMessage={
                    errors.labels
                      ? errors.labels[index]?.integration?.message
                      : ''
                  }
                />

                <div className="mt-2">
                  <Label htmlFor={`labels.${index}.file`}>
                    <div>File</div>
                    <input
                      type="file"
                      accept="application/pdf"
                      multiple={false}
                      name={`labels.${index}.file`}
                      className="my-3"
                      ref={register({
                        required: true,
                        validate: (f: FileList) => {
                          const file = f[0];

                          if (!file) {
                            return 'Expected a file';
                          }

                          const files = watch()
                            .labels.filter((l) => !!l.file)
                            .map((l) => l.file[0]); // will only ever have one file per upload, so safe to pull the first

                          if (file.size <= 0) {
                            return 'File must not be empty';
                          }

                          // 20MB -> file.size is in bytes
                          if (file.size >= 20_000_000) {
                            return 'File is too large, must be less than 20MB';
                          }

                          // validate that they haven't uploaded the same pdf
                          if (
                            files.find(
                              (f, i) =>
                                f.name === file.name &&
                                f.size === file.size &&
                                index !== i,
                            )
                          ) {
                            return 'Cannot upload the same file';
                          }

                          return true;
                        },
                      })}
                    />
                    <InputError>
                      {errors.labels ? errors.labels[index]?.file?.message : ''}
                    </InputError>
                  </Label>
                </div>
              </li>
            );
          })}

          <li className="w-28">
            <Button
              fullWidth
              color="success"
              type="button"
              fillHeight
              onClick={(): void => {
                // adds an extra field to the field array so the user can
                // upload more labels
                append({});
              }}
            >
              <FaPlus />
            </Button>
          </li>
        </ul>
        <div className="w-80 mt-4">
          <Button
            fullWidth
            color="primary"
            type="submit"
            loading={loading}
            disabled={loading || !isValid}
          >
            Trigger Bundle Creation
          </Button>
        </div>
      </div>
    </form>
  );
}
