import { ReactElement, useState } from 'react';
import { parseISO, format } from 'date-fns';
import { Button } from 'components/button';
import { useNotifications } from 'notifications';
import { gql, useMutation } from '@apollo/client';
import {
  CancelConsultationReminderMutation,
  CancelConsultationReminderMutationVariables,
} from 'graphql/types';
import { Modal } from 'components/modal';

export const ConsultationReminder = ({
  consultationId,
  reminderScheduledAt,
}: {
  consultationId: string;
  reminderScheduledAt?: string;
}): ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const showNotifications = useNotifications();

  const [cancelConsultationReminderMutation, { loading }] = useMutation<
    CancelConsultationReminderMutation,
    CancelConsultationReminderMutationVariables
  >(
    gql`
      mutation CancelConsultationReminder($consultationId: String!) {
        cancelConsultationReminder(consultationId: $consultationId) {
          id
          scheduledAt
          doneAt
          canceled
        }
      }
    `,
    {
      variables: { consultationId },
      onCompleted: () => {
        setIsModalOpen(false);
        showNotifications({
          type: 'success',
          message: 'Reminder canceled',
        });
      },
    },
  );

  const handleCancelConsultationReminder = (): void => {
    cancelConsultationReminderMutation();
  };

  return (
    <>
      <div className="border-t border-gray-300" />
      <div className="my-2">
        <h3 className="heading-sm mb-2">Patient Reminder</h3>
        {reminderScheduledAt ? (
          <>
            <div className="mb-2">
              <p className="paragraph mb-2">
                Scheduled for{' '}
                {format(parseISO(reminderScheduledAt), 'EEEE, MMMM d yyyy')}
              </p>
              <p className="text-sm text-gray-600">
                The practitioner can prescribe or decline once the reminder has
                been sent, or by canceling the reminder
              </p>
            </div>
            <Button
              fullWidth
              variant="outline"
              color="danger"
              onClick={(): void => setIsModalOpen(true)}
            >
              Cancel reminder
            </Button>
            <div className="mt-4 mb-2 border-t border-gray-300" />
            <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
              <section className="bg-gray-200 p-6">
                <h3 className="heading-md mb-2">Cancel reminder</h3>
                <div className="mb-4">
                  <p className="text-gray-800">
                    It&apos;s recommended to cancel if one of the following
                    events has occured:
                  </p>
                  <br />
                  <ul className="text-gray-800 list-disc pl-4">
                    <li>The patient wants to cancel the reminder</li>
                    <li>The patient wants to proceed with treatment</li>
                    <li>The patient is no longer interested in treatment</li>
                  </ul>
                  <br />
                  <p className="text-gray-800">
                    The patient will be notified you have cancelled the
                    reminder.
                  </p>
                </div>
                <div className="flex justify-between">
                  <div>
                    <Button
                      fullWidth
                      variant="outline"
                      onClick={() => setIsModalOpen(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div>
                    <Button
                      fullWidth
                      loading={loading}
                      color="danger"
                      onClick={handleCancelConsultationReminder}
                    >
                      Confirm cancellation
                    </Button>
                  </div>
                </div>
              </section>
            </Modal>
          </>
        ) : (
          <>
            <p>No reminder scheduled</p>
            <div className="my-2 border-t border-gray-300" />
          </>
        )}
      </div>
    </>
  );
};
