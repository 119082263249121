import { gql } from '@apollo/client';

export const create_medication_manually_option = {
  value: null,
  label: '+ NOT FOUND: ADD MANUALLY',
};

export const searchMimsQuery = gql`
  query mimsProductSearch($name: String!) {
    mimsProductSearch(name: $name) {
      productName
      productId
    }
  }
`;

export const getMimsProductQuery = gql`
  query getMimsProduct($mimsProductId: String!) {
    getMimsProduct(mimsProductId: $mimsProductId) {
      productName
      tradeName
      genericName
      itemForm
      itemStrength
      routeAdministration
      isExtemp
    }
  }
`;
