import React from 'react';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { config } from 'config';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { routes } from 'utils/routes';
import { Avatar } from 'components/avatar';
import { Button } from 'components/button';
import { useAuth } from 'auth';
import { Breadcrumbs } from './breadcrumbs';
import { Brand } from 'config/types';
import { useHasOneOfPermissions, useHasPermissions } from './permissions';
import { BigBrainBar } from './BigBrainBar';
import { FaSearch } from 'react-icons/fa';
import { gql, useQuery } from '@apollo/client';
import { BrandConditionsQuery } from 'graphql/types';
import { validate } from 'uuid';

const BreadcrumbsSection = (): React.ReactElement => {
  const location = useLocation();
  const paths: string[] = location.pathname.split('/').filter((route) => route);

  const breadcrumbRoutes = paths.map((route, index) => {
    const path = index > 0 ? `${generatePath(index)}${route}` : `/${route}`;

    return {
      name: route,
      path,
    };
  });
  function generatePath(routeIndex: number): string {
    let path = `/`;
    for (let index = 0; index < routeIndex; index += 1) {
      path += `${paths[index]}/`;
    }
    return path;
  }

  return (
    <Breadcrumbs>
      {breadcrumbRoutes.map(({ name, path }, index) => {
        let n: JSX.Element | string = name;
        const lastOne = breadcrumbRoutes.length - 1 === index;

        if ((n[0] === 'c' && n.length >= 25 && n.length <= 28) || validate(n)) {
          n = <pre key={path}>{n}</pre>;
        } else {
          n = (
            <span className="capitalize" key={path}>
              {n}
            </span>
          );
        }

        if (lastOne) {
          return n;
        }

        return (
          <Link to={path} key={path}>
            {n}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

const isBrand = (...showInBrands: Brand[]): boolean => {
  return showInBrands.includes(config.brand);
};

interface NavGroup {
  name: string;
  items: {
    route: keyof typeof routes;
    label: string;
    hidden?: boolean;
  }[];
  hidden?: boolean;
}

export function MainLayout(props: {
  children: React.ReactNode;
}): React.ReactElement {
  const { data: brandConditionsData } = useQuery<BrandConditionsQuery>(gql`
    query BrandConditions {
      brandConditions {
        id
        type
        supportsFlexiPlans
      }
    }
  `);

  const { user, logout } = useAuth();
  const featureFlagClient = useFeatureFlagClient();
  const canEditRolePermissions = useHasPermissions(['ADD_ROLE_PERMISSION']);
  const canEditUserRoles = useHasPermissions(['ADD_USER_ROLE']);
  const canEditColdShipping = useHasPermissions([
    'UPDATE_COLD_SHIPPING_PROVIDER',
  ]);
  const canViewColdShipping = useHasPermissions([
    'VIEW_COLD_SHIPPING_PROVIDER',
  ]);
  const canViewScripts = useHasPermissions(['VIEW_SCRIPTS']);
  const canViewPackingBundles = useHasPermissions(['VIEW_PACKING_BUNDLES']);
  const canCreateBulkActions = useHasOneOfPermissions([
    'BULK_CREATE_CONSULTATION',
    'BULK_PAUSE_PURCHASE',
  ]);
  const packingBundlesUiEnabled = featureFlagClient.getBoolean(
    'packing_bundles_ui_enabled',
    {
      defaultValue: false,
    },
  );

  const patientQueuesUiEnabled = featureFlagClient.getBoolean(
    'admin_patient_queues_ui_enabled',
    {
      defaultValue: false,
    },
  );

  const navGroups: NavGroup[] = [
    {
      name: 'Patient',
      items: [
        { route: 'consultations', label: 'consultations' },
        { route: 'customers', label: 'Patients' },
        { route: 'pathologies', label: 'Pathology' },
        { route: 'awaitingScripts', label: 'Awaiting Scripts' },
        {
          route: 'tracking',
          label: 'Tracking',
          hidden: !isBrand(
            'pilot',
            'juniper',
            'juniper-uk',
            'compound-uk',
            'juniper-de',
            'juniper-jp',
            'software',
          ),
        },
        {
          route: 'queues' as const,
          label: 'Queues',
          hidden: !patientQueuesUiEnabled,
        },
        {
          route: 'bulkActions',
          label: 'Bulk Actions',
          hidden: !(
            canCreateBulkActions &&
            brandConditionsData?.brandConditions?.some(
              (c) => c.supportsFlexiPlans,
            )
          ),
        },
      ],
    },
    {
      name: 'Manage',
      items: [
        { route: 'flows', label: 'Flows' },
        { route: 'clinicians', label: 'Practitioners' },
        {
          route: 'healthCoaches',
          label: 'Health Coaches',
          hidden: !config.healthCoachCreation,
        },
        {
          route: 'pillars',
          label: 'Pillars',
          hidden: !config.lgaEnabled,
        },
        {
          route: 'goals',
          label: 'Goals & Actions',
          hidden: !config.lgaEnabled,
        },
        {
          route: 'permissions',
          label: 'Permissions',
          hidden: !(canEditRolePermissions || canEditUserRoles),
        },
        {
          route: 'delivery',
          label: 'Delivery Providers',
          hidden:
            !(canEditColdShipping || canViewColdShipping) ||
            !isBrand('juniper', 'pilot'),
        },
      ],
    },
    {
      name: 'Health coaching',
      items: [
        {
          route: 'healthCoachingFlows',
          label: 'Automated flows',
        },
      ],
      hidden: !config.healthCoachingFlows,
    },
    {
      name: 'eCommerce',
      items: [
        { route: 'products', label: 'Products' },
        { route: 'plans', label: 'Plans' },
        { route: 'sequences', label: 'Sequences' },
        { route: 'offerings', label: 'Offerings' },
        {
          route: 'salesChannels',
          label: 'Sales channels',
          hidden: !config.shopEnabled,
        },
        { route: 'discounts', label: 'Discounts' },
        { route: 'orders', label: 'Orders' },
        {
          route: 'scripts',
          label: 'Scripts',
          hidden: !canViewScripts,
        },
        { route: 'subscriptionPlans', label: 'Subscription Plans' },
        {
          route: 'packingBundles',
          label: 'Packing Bundles',
          hidden: !canViewPackingBundles || !packingBundlesUiEnabled,
        },
      ],
    },
    {
      name: 'Education',
      items: [
        { route: 'educationPrograms', label: 'Programs' },
        { route: 'educationReadings', label: 'Readings' },
        { route: 'educationCollections', label: 'Collections' },
      ],
      hidden: isBrand('kin'),
    },
    {
      name: 'Content',
      items: [
        { route: 'contentItems', label: 'Items' },
        { route: 'contentCollections', label: 'Collections' },
      ],
      hidden: !isBrand('juniper', 'juniper-uk', 'compound-uk', 'pilot'),
    },
  ];

  const activeNavStyles =
    'font-semibold text-primary-darker bg-primary-lighter hover:bg-primary-lighter';

  return (
    <>
      <BigBrainBar />
      <div className="w-screen max-w-full min-h-screen h-full grid grid-cols-main-layout">
        <nav className="bg-white shadow-card rounded py-8 px-6 flex flex-col justify-between h-screen overflow-y-auto sticky top-0">
          <div className="space-y-8 flex-none">
            <Link to={routes.home}>
              <img src={config.logoUrl} className="h-8" alt={config.brand} />
            </Link>
            <div>
              <button
                onClick={(): void => {
                  document
                    .querySelector<HTMLButtonElement>('#show-big-brain')
                    ?.click();
                }}
                className="py-1 px-2 w-full cursor-pointer opacity-60 flex items-center space-x-2 rounded border border-gray-700 hover:shadow-md transition-shadow"
              >
                <FaSearch className="transform scale-90" />
                <span>Search</span>
              </button>
            </div>
            {navGroups.map((group) => {
              if (group.hidden) {
                return null;
              }
              return (
                <div key={group.name}>
                  <h3 className="heading-sm mb-3">{group.name}</h3>
                  <ul>
                    {group.items
                      .filter((item) => !item.hidden)
                      .map((item) => (
                        <li key={item.route}>
                          <NavLink
                            activeClassName={activeNavStyles}
                            to={routes[item.route]}
                            className="capitalize block -ml-6 pl-6 py-1 rounded-r-full hover:text-primary-darker hover:bg-gray-100"
                          >
                            {item.label}
                          </NavLink>
                        </li>
                      ))}
                  </ul>
                </div>
              );
            })}
          </div>
          <div className="flex items-center mt-8">
            <div className="mr-2">
              {user && <Avatar size="small" user={user} />}
            </div>
            <Button fullWidth variant="text" onClick={logout}>
              Logout
            </Button>
          </div>
        </nav>
        <div className="flex flex-col w-full p-6 bg-background-light h-full">
          <header className="w-full mb-5">
            <BreadcrumbsSection />
          </header>
          <main className="w-full">
            <div>{props.children}</div>
          </main>
        </div>
      </div>
    </>
  );
}
