import { Maybe } from 'graphql/types';
import clsx from 'clsx';
import React, { ReactChild } from 'react';

export const QuestionFormatter = ({
  children,
}: {
  children?: Maybe<ReactChild | ReactChild[]>;
}): React.ReactElement => {
  return (
    <div
      className={clsx('font-semibold py-1', {
        'text-red-500': !children,
      })}
    >
      {children ?? 'Question has been deleted'}
    </div>
  );
};
