import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { addDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { config } from 'config';

export const usePracBookingWindowDates = () => {
  const featureFlagClient = useFeatureFlagClient();
  const ffPracBookingWindowDays = featureFlagClient.getNumber(
    'cfg-prac-booking-window-days',
    {
      defaultValue: 4,
    },
  );

  if (typeof ffPracBookingWindowDays !== 'number') {
    throw new Error(
      'Expected a number for feature flag: cfg-prac-booking-window-days',
    );
  }

  const dates = Array.from({ length: ffPracBookingWindowDays }, (_, i) =>
    formatInTimeZone(
      addDays(new Date(), i),
      config.defaultTimezone,
      'yyyy-MM-dd',
    ),
  );

  return dates;
};
