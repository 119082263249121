import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  from,
  gql,
  useMutation,
  useQuery,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import { config } from 'config';
import {
  AdminPermission,
  AuthPayload,
  User,
  UserInfoQuery,
  UserInfoQueryVariables,
} from 'graphql/types';
import jwtDecode from 'jwt-decode';
import { logger, setUser as setUserForLogging } from 'logging';
import { useNotifications } from 'notifications';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useSharedTabStorage } from 'utils/use-shared-tab-storage';
import { userInfoFragment } from './fragments';

interface Auth {
  user?: UserInfoQuery['profile'];
  isPermissionLoaded: boolean;
  permissions: Array<AdminPermission>;
  accessToken?: string;
  login?: (idToken: string) => Promise<void>;
  logout?: () => void;
}

const AuthContext = React.createContext<Auth>({
  isPermissionLoaded: false,
  permissions: [],
});

export const useAuth = (): Auth => React.useContext(AuthContext);

export function calcMillisUntilExpiry(token: string): number {
  const decodedToken = jwtDecode<{ [key: string]: number }>(token);
  return decodedToken.exp * 1000 - new Date().getTime();
}

const isJWTValid = (token?: string | null): boolean => {
  if (!token) {
    return false;
  }

  const decodedToken = jwtDecode<{ [key: string]: number }>(token);

  if (!decodedToken) {
    return false;
  }

  const expiresAt = decodedToken.exp * 1000;
  const expiryOffsetSeconds = 15 * 1000;

  const now = new Date().getTime();

  // offset the expiry to force refresh the token *before* it expires
  // preventing an "UNAUTHENTICATED" response
  if (expiresAt > now + expiryOffsetSeconds) {
    return true;
  }

  return false;
};

const loginSSODocument = gql`
  mutation LoginSSO($idToken: String!) {
    loginSSO(id_token: $idToken) {
      token
      user {
        ...userInfo
      }
    }
  }
  ${userInfoFragment}
`;

const userQuery = gql`
  query UserInfo {
    profile {
      ...userInfo
    }
  }
  ${userInfoFragment}
`;

export const AuthProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const showNotification = useNotifications();

  const {
    value: accessToken,
    setValue: setAccessToken,
    clearValue: clearAccessToken,
    loading: loadingAccessToken,
  } = useSharedTabStorage('access-token');

  const tokenRef = useRef(accessToken);
  tokenRef.current = accessToken;

  const client = useMemo(() => {
    const httpLink = new HttpLink({ uri: `${config.apiUrl}/graphql` });
    const errorLink = onError(({ operation, networkError, graphQLErrors }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((err) => {
          showNotification({
            type: 'error',
            message: err.message,
          });
          logger.error(err.message, {
            operation: operation.operationName,
            from: 'apollo',
          });
        });
      }

      if (networkError) {
        showNotification({
          type: 'warning',
          message: networkError.message,
        });
        logger.error(networkError.message, {
          operation: operation.operationName,
          from: 'apollo',
        });
      }
    });
    const withHeaders = setContext(async (_, { headers }) => {
      return {
        headers: {
          ...headers,
          brand: config.brand,
          authorization: tokenRef.current ? `Bearer ${tokenRef.current}` : '',
        },
      };
    });
    return new ApolloClient({
      cache: new InMemoryCache(),
      link: from([withHeaders, errorLink, httpLink]),
      connectToDevTools: false,
    });
  }, [showNotification]);

  const [loginMutation] = useMutation<{
    loginSSO: AuthPayload;
  }>(loginSSODocument, {
    client,
  });

  const { data, loading: userInfoLoading } = useQuery<
    UserInfoQuery,
    UserInfoQueryVariables
  >(userQuery, {
    skip: !isJWTValid(accessToken),
    client,
  });

  const [triggerReAuth, setTriggerReAuth] = useState(false);
  const reAuthTimeoutRef = useRef<NodeJS.Timeout>();

  const logout = useCallback(() => {
    if (reAuthTimeoutRef.current) clearTimeout(reAuthTimeoutRef.current);
    clearAccessToken();
    googleLogout();
    client.clearStore();
  }, [clearAccessToken, client]);

  // perform admin token exchange, exchanging a Google id_token for an access_token
  const performTokenExchange = React.useCallback(
    async (idToken: string): Promise<{ accessToken: string; user: User }> => {
      const result = await loginMutation({
        variables: {
          idToken,
        },
      });

      const { user, token } = result?.data?.loginSSO ?? {};

      if (!user || !token) {
        throw new Error('Token exchange failed.');
      }

      return { user, accessToken: token };
    },
    [loginMutation],
  );

  // perform Google login + token exchange
  const login = React.useCallback(
    async (idToken: string): Promise<void> => {
      const { accessToken } = await performTokenExchange(idToken);
      setAccessToken(accessToken);

      setTriggerReAuth(false);

      const reAuthDelay = Math.max(
        // 30 minutes before expiry
        calcMillisUntilExpiry(accessToken) - 30 * 60 * 1000,
        // 30 seconds away
        30_000,
      );

      reAuthTimeoutRef.current = setTimeout(() => {
        if (reAuthTimeoutRef.current) clearTimeout(reAuthTimeoutRef.current);
        setTriggerReAuth(true);
      }, reAuthDelay);
    },
    [performTokenExchange, setAccessToken],
  );

  const permissions: Array<AdminPermission> = [];
  if (accessToken) {
    const decoded = jwtDecode<{ permissions?: Array<AdminPermission> }>(
      accessToken,
    );

    if (decoded.permissions) {
      permissions.push(...decoded.permissions);
    }
  }

  // Make sure log context reflects our logged in user.
  React.useEffect(() => {
    // Intentionally set to undefined to clear key.
    setUserForLogging({
      email: data?.profile?.email ?? undefined,
      userId: data?.profile?.id ?? undefined,
    });
  }, [data?.profile?.email, data?.profile?.id]);

  return (
    <AuthContext.Provider
      value={{
        permissions,
        isPermissionLoaded: !!accessToken,
        user: data?.profile,
        accessToken: accessToken || undefined,
        login,
        logout,
      }}
    >
      {triggerReAuth && (
        <div className="hidden">
          <GoogleLogin
            onSuccess={async ({ credential: idToken }): Promise<void> => {
              if (idToken) {
                login(idToken);
              } else {
                throw new Error('Unable to login.');
              }
            }}
            onError={(): void => {
              throw new Error('Unable to login.');
            }}
            useOneTap
            auto_select
            cancel_on_tap_outside={false}
          />
        </div>
      )}
      {loadingAccessToken || userInfoLoading ? undefined : children}
    </AuthContext.Provider>
  );
};
