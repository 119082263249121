import { Brand } from 'config/types';
import { ProblemType } from 'graphql/types';

const PILOT_DEFAULT_REASONS = [
  'I have some left',
  'Not getting the results I wanted',
  "It's too expensive",
  "I'm experiencing side effects",
  'Not satisfied with the service',
];

const PILOT_SEX_REASONS = [
  "No longer need it - i'm better now :)",
  "I have some left / Don't need it as often",
  'Not getting the results I wanted',
  "It's too expensive",
  "I'm experiencing side effects",
  'Not satisfied with the service',
];

const getReasonsByProblemType = (problemType: ProblemType): string[] => {
  switch (problemType) {
    case 'SKIN_GENERAL':
    case 'ANTI_AGEING':
      return [
        'I have cream left over',
        'Not seeing results',
        'My financial situation has changed',
        "I've experienced side effects",
        'My skin is better now :)',
        'I only intended to buy one',
        'I am using something else to treat my skin',
      ];
    case 'MENOPAUSE':
      return [
        'I have some left',
        'Not getting the results I wanted',
        "It's too expensive",
        "I'm experiencing side effects",
        'Not satisfied with the service',
      ];
    case 'WEIGHT_LOSS':
      return [
        "I've reached my goal",
        'I have some left',
        'Not getting the results I wanted',
        "It's too expensive",
        "I'm experiencing side effects",
        'Not satisfied with the service',
      ];
    case 'CONTRACEPTION':
      return [
        "I'm switching to another contraceptive",
        "I'm starting a family",
        "I'm already pregnant",
        "I'm experiencing side effects",
        'My health conditions have changed',
        'The membership is too expensive',
        "I'm taking a break from the pill",
      ];
    case 'ERECTILE':
    case 'PREMATURE':
      return PILOT_SEX_REASONS;
    case 'ACNE':
    case 'COLD_SORES':
    case 'HAIR':
    case 'HERPES':
    case 'SLEEP':
      return PILOT_DEFAULT_REASONS;
    default:
      return [];
  }
};

export const getPauseReasons = (
  problemType: ProblemType,
  brand: Brand,
): string[] => {
  const reasons = getReasonsByProblemType(problemType);

  switch (brand) {
    // Software does not accept 'Other' reason type in customer-frontend so copying this behavior to keep the data clean
    case 'software':
      return reasons;
    default:
      reasons.push('Other');
      return reasons;
  }
};
