import { useMemo, type FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { gql, useQuery } from '@apollo/client';
import type {
  CustomerReviewQueuesQuery,
  CustomerReviewQueuesQueryVariables,
  ProblemType,
} from 'graphql/types';
import {
  formatWindowDay,
  formatWindowInterval,
  usePracBookingWindowDates,
} from 'utils/queues';
import { Loading } from 'components/loading';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { ModalLayout } from './modal-layout';
import { DispatchBatchStepTransitionProps } from './types';
import { Checkbox } from 'components/checkbox';
import { InputError } from 'components/input-error';

export type BookingWindowAssignStepProps = DispatchBatchStepTransitionProps & {
  bookingWindows: { id: string; name: string }[];
  setBookingWindows: (bookingWindows: { id: string; name: string }[]) => void;
  problemType: ProblemType;
  jobCount: number;
};

export const BookingWindowAssignStep: FunctionComponent<
  BookingWindowAssignStepProps
> = ({
  previousStep,
  nextStep,
  bookingWindows: previouslySelectedBookingWindows,
  setBookingWindows,
  problemType,
  jobCount,
}) => {
  const featureFlagClient = useFeatureFlagClient();
  const dates = usePracBookingWindowDates();

  const { data, loading } = useQuery<
    CustomerReviewQueuesQuery,
    CustomerReviewQueuesQueryVariables
  >(
    gql`
      query CustomerReviewQueues(
        $problemTypes: [ProblemType!]!
        $dates: [String!]!
      ) {
        practitionerBookingWindows(
          input: { problemTypes: $problemTypes, dates: $dates }
        ) {
          id
          windowId
          available
          startAt
          endAt
          capacity
          unassignedBookingsCount
          totalBookingsCount
        }
      }
    `,
    {
      variables: {
        problemTypes: [problemType],
        dates,
      },
    },
  );

  const ffEnableWindowTotalBookingsCount = featureFlagClient.getBoolean(
    'ff-enable-window-total-bookings-count',
  );

  const { options } = useMemo(() => {
    const bookingWindows = data?.practitionerBookingWindows ?? [];

    const options = bookingWindows.map(
      ({
        id,
        available,
        unassignedBookingsCount,
        totalBookingsCount,
        capacity,
        ...window
      }) => {
        const day = formatWindowDay(window);
        const interval = formatWindowInterval(window);

        const availableCapacity = ffEnableWindowTotalBookingsCount
          ? `${unassignedBookingsCount} (${totalBookingsCount}/${capacity})`
          : `(${unassignedBookingsCount}/${capacity})`;

        return {
          label: `${day} ${interval} - ${availableCapacity}`,
          value: id,
          disabled: !available,
          availableSpace:
            capacity - Math.max(unassignedBookingsCount, totalBookingsCount),
          name: `${day} ${interval}`,
        };
      },
    );

    return { options };
  }, [data, ffEnableWindowTotalBookingsCount]);

  const defaultValues = useMemo(() => {
    return previouslySelectedBookingWindows.reduce(
      (acc, window) => ({
        ...acc,
        [window.id]: true,
      }),
      {},
    );
  }, [previouslySelectedBookingWindows]);

  const { register, handleSubmit, watch } = useForm<
    Record<string, string | boolean>
  >({
    defaultValues,
  });

  const selectedWindows = Object.entries(watch())
    .filter(([, selected]) => !!selected)
    .map(([id]) => {
      const { name, value, availableSpace } =
        options.find(({ value }) => id === value) || {};
      if (name && value && availableSpace) {
        return { name, id: value, availableSpace };
      }
      return undefined;
    })
    .filter((bw): bw is { id: string; name: string; availableSpace: number } =>
      Boolean(bw),
    );

  let windowSelectionError = '';
  if (selectedWindows.length === 0) {
    windowSelectionError = 'Please select at least one window.';
  } else {
    const totalAvailableSpaceInSelections = selectedWindows.reduce(
      (acc, w) => acc + w.availableSpace,
      0,
    );
    if (totalAvailableSpaceInSelections < jobCount) {
      windowSelectionError = `Selected capacity: ${totalAvailableSpaceInSelections}. Additional spaces required: ${
        jobCount - totalAvailableSpaceInSelections
      }.`;
    }
  }

  const onSubmit = handleSubmit((fieldValues) => {
    const bookingWindows = Object.entries(fieldValues)
      .filter(([, selected]) => !!selected)
      .map(([id]) => {
        const { name, value } = options.find(({ value }) => id === value) || {};
        if (name && value) {
          return { name, id: value };
        }
        return undefined;
      })
      .filter((bw): bw is NonNullable<typeof bw> => Boolean(bw));

    setBookingWindows(bookingWindows);
    nextStep();
  });

  if (loading) {
    return <Loading />;
  }

  if (!options.length) {
    return (
      <ModalLayout back={previousStep} nextDisabled={true} next={onSubmit}>
        <div className="text-sm">
          Could not load any call windows. Please go back a step and assign the
          consultation directly to a practitioner.
        </div>
      </ModalLayout>
    );
  }

  return (
    <ModalLayout
      back={previousStep}
      nextDisabled={!!windowSelectionError}
      next={onSubmit}
    >
      <h3 className="heading-sm mb-2">Confirm call windows</h3>
      <strong>{jobCount} patients will be added to the queue.</strong>
      <p>select the call window/s these patients should be added to.</p>
      <ul className="list-disc pl-6">
        <li>
          If you select multiple windows, patients will be distributed evenly
          between them.
        </li>
        <li>
          Customers will be evenly distributed into selected windows with spare
          capacity. Bookings overflow evenly into remaining windows once a
          window is full.
        </li>
        <li>Closed call windows are disabled from the list below</li>
      </ul>

      <div className="my-4"></div>

      <div className="columns-3 gap-4 space-y-2">
        {options.map(({ label, value, disabled }) => (
          <Checkbox
            key={value}
            ref={register()}
            name={value}
            label={label}
            disabled={disabled}
          />
        ))}
      </div>
      <InputError>{windowSelectionError}</InputError>
    </ModalLayout>
  );
};
