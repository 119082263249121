import React from 'react';

type ContentImageProps = {
  label: string;
  imgUrl: string;
  title: string;
};

export const ContentImage: React.FC<ContentImageProps> = ({
  label,
  imgUrl,
  title,
}: ContentImageProps) => {
  return (
    <div className="space-y-1">
      <h3 className="font-medium">{label}</h3>
      <a
        href={imgUrl}
        target="_blank"
        rel="noreferrer"
        className="max-h-80 w-52 flex"
      >
        <img src={imgUrl} alt={`Thumbnail for ${title} video`} />
      </a>
    </div>
  );
};
