import React, { useState } from 'react';
import { formatDateAndTime } from '../../utils/misc';
import { CustomerOtcScheduleView } from './types';
import { useNotifications } from 'notifications';
import { Modal } from 'components/modal';
import { Button, ProtectedButton } from 'components/button';
import { Link } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import {
  RemoveOtcScheduleFromTreatmentMutation,
  RemoveOtcScheduleFromTreatmentMutationVariables,
} from 'graphql/types';

type DeleteOtcScheduleCommand = {
  treatmentId: string;
  productId: string;
};

const DeleteOtcScheduleModal = ({
  where,
  close,
}: {
  where: DeleteOtcScheduleCommand | null;
  close: () => void;
}): React.ReactElement => {
  const showNotification = useNotifications();
  const [removeOtcScheduleMutation, { loading }] = useMutation<
    RemoveOtcScheduleFromTreatmentMutation,
    RemoveOtcScheduleFromTreatmentMutationVariables
  >(
    gql`
      mutation RemoveOtcScheduleFromTreatment(
        $id: String!
        $productId: String!
      ) {
        removeOtcScheduleFromTreatment(id: $id, productId: $productId) {
          id
        }
      }
    `,
    {
      refetchQueries: ['Customer'],
      awaitRefetchQueries: true,
    },
  );

  const { treatmentId, productId } = where ?? {
    treatmentId: '',
    productId: '',
  };

  return (
    <Modal show={!!where} onClose={(): void => close()}>
      <div
        className="bg-white shadow overflow-hidden rounded px-4 py-5 max-w-lg mr-auto ml-auto"
        onClick={(
          event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        ): void => {
          event.stopPropagation();
        }}
      >
        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-8">
          Are you sure you would like to remove this schedule?
        </h3>
        <div className="flex bg-gray-100">
          <Button
            fullWidth
            size="small"
            variant="outline"
            onClick={(): void => close()}
          >
            No
          </Button>
          <div className="mx-2" />
          <Button
            fullWidth
            size="small"
            color="danger"
            loading={loading}
            onClick={async (): Promise<void> => {
              await removeOtcScheduleMutation({
                variables: {
                  id: treatmentId,
                  productId,
                },
              });
              showNotification({
                type: 'success',
                message: 'OTC Schedule Removed',
              });
              close();
            }}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const OtcSchedulesTable = ({
  schedules,
}: {
  schedules: Array<CustomerOtcScheduleView>;
}): React.ReactElement => {
  const [deleteScheduleCommand, setDeleteScheduleCommand] =
    useState<DeleteOtcScheduleCommand | null>(null);

  return (
    <>
      <div className="border-t border-gray-200">
        <div className="px-4 py-5 border-b border-gray-200">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            OTC Schedules{' '}
            <span className="ml-2 text-gray-500">{schedules.length}</span>
          </h3>
        </div>
        <div className="">
          <div className="-mt-2 overflow-x-auto">
            <div className="pt-2 min-w-full">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    {[
                      'ID',
                      'Product',
                      'Active',
                      'Cadence',
                      'Quantity',
                      'Created',
                      'Updated',
                      '',
                    ].map((h) => (
                      <th
                        key={h}
                        className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {h}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {schedules.map((s) => (
                    <tr key={s.id}>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 text-gray-500">
                          {s.id}
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 text-gray-500 underline">
                          <Link to={`/products/${s.product.id}`}>
                            {s.product.name}
                          </Link>
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 text-gray-500">
                          {s.isActive ? 'Yes' : 'No'}
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 text-gray-500">
                          {s.cadence}
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 text-gray-500">
                          {s.quantity}
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 text-gray-500">
                          {formatDateAndTime(s.createdAt)}
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 text-gray-500">
                          {formatDateAndTime(s.updatedAt)}
                        </div>
                      </td>
                      <td className="px-6 py-2">
                        {s.isActive && (
                          <ProtectedButton
                            size="small"
                            color="danger"
                            requiredPermissions={['EDIT_TREATMENTS']}
                            onClick={() =>
                              setDeleteScheduleCommand({
                                treatmentId: s.treatmentId,
                                productId: s.product.id,
                              })
                            }
                          >
                            Remove
                          </ProtectedButton>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <DeleteOtcScheduleModal
        where={deleteScheduleCommand}
        close={() => setDeleteScheduleCommand(null)}
      />
    </>
  );
};
