import React from 'react';
import clsx from 'clsx';
import { tinykeys } from 'tinykeys';

interface Props {
  children: React.ReactElement;
  show: boolean;
  onClose?: () => void;
  format?: 'default' | 'wide';
}

export const Drawer = ({
  children,
  show,
  onClose,
  format = 'default',
}: Props): JSX.Element | null => {
  React.useEffect(() => {
    return tinykeys(window, {
      Escape: () => {
        onClose?.();
      },
    });
  }, [onClose]);

  const widthFormatClasses = clsx('w-full max-w-full', {
    'md:w-2/5 md:max-w-2/5': format === 'default',
    'md:w-3/4 md:max-w-3/4': format === 'wide',
  });

  return (
    <div
      className={clsx(
        'fixed overflow-hidden z-50 inset-0 transform',
        { 'translate-x-0': show },
        { 'delay-500 translate-x-full': !show },
      )}
    >
      <section
        className={clsx(
          widthFormatClasses,
          'right-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform z-10',
          { 'translate-x-0': show },
          { 'translate-x-full': !show },
        )}
      >
        <div className="relative w-full h-full">{children}</div>
      </section>
      <section
        className={clsx(
          'w-screen h-full cursor-pointer bg-gray-900 bg-opacity-25 ease-in-out transition-all duration-500 z-0',
          { 'opacity-100': show },
          { 'opacity-0': !show },
        )}
        onClick={onClose}
      ></section>
    </div>
  );
};
