import { Button } from 'components/button';
import { useState } from 'react';
import {
  EditMedicalRecordDrawer,
  EditMedicalRecordDrawerProps,
} from './edit-medical-record-drawer';
import { useHasOneOfPermissions } from 'components/permissions';

export const EditMedicalRecordDrawerButton: React.FC<
  Omit<EditMedicalRecordDrawerProps, 'show' | 'onClose'>
> = (props) => {
  const [showModal, setShowModal] = useState(false);

  const canEditMedicalRecord = useHasOneOfPermissions([
    'EDIT_MEDICAL_RECORD_ALLERGIES',
    'EDIT_MEDICAL_RECORD_CURRENT_MEDICATIONS',
    'EDIT_MEDICAL_RECORD_DOB',
    'EDIT_MEDICAL_RECORD_HEIGHT',
    'EDIT_MEDICAL_RECORD_MEDICAL_CONDITIONS',
    'EDIT_MEDICAL_RECORD_PREGNANCY_STATUS',
    'EDIT_MEDICAL_RECORD_SEX',
    'EDIT_MEDICAL_RECORD_WEIGHT',
  ]);

  if (!canEditMedicalRecord) {
    return null;
  }

  return (
    <>
      <Button size="small" variant="outline" onClick={() => setShowModal(true)}>
        Edit
      </Button>
      <EditMedicalRecordDrawer
        show={showModal}
        onClose={() => setShowModal(false)}
        {...props}
      />
    </>
  );
};
