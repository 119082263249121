import { gql, useMutation } from '@apollo/client';
import { DeleteUserMutation, DeleteUserMutationVariables } from 'graphql/types';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { useNotifications } from 'notifications';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'utils/routes';
import { useHasPermissions } from './permissions';
import { Customer } from 'pages/customer/types';

interface Props {
  customer: Customer;
}

export const DeleteCustomerButton: React.FC<Props> = ({ customer }: Props) => {
  const featureFlagClient = useFeatureFlagClient();
  const [showConfirm, setShowConfirm] = useState(false);
  const history = useHistory();
  const showNotification = useNotifications();
  const canEditPatients = useHasPermissions(['EDIT_PATIENTS']);
  const enableAdminDelete = featureFlagClient.getBoolean('admin-deletes');
  const [deleteUser, { loading }] = useMutation<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >(gql`
    mutation DeleteUser($id: String!) {
      deleteUser(id: $id)
    }
  `);
  const onDeleteUser = async (): Promise<void> => {
    await deleteUser({
      variables: {
        id: customer.id,
      },
    });
    showNotification({
      message: 'Patient has been deleted',
      type: 'success',
    });
    history.replace(`${routes.customers}`);
  };
  if (!enableAdminDelete) {
    return null;
  }
  return (
    <>
      <Button
        fullWidth
        variant="solid"
        color="danger"
        disabled={!customer.canDeleteUser || !canEditPatients}
        onClick={(): void => {
          setShowConfirm(true);
        }}
      >
        DELETE PATIENT
      </Button>
      {showConfirm && (
        <Modal
          show
          width="w-96"
          onClose={(): void => {
            setShowConfirm(false);
          }}
        >
          <div className="bg-white rounded p-4 space-y-4">
            <p className="font-bold mb-3">
              Are you sure you want to delete this patient?
            </p>
            <hr />
            <p>
              This action will permanently delete the patient record. Do not
              proceed if the patient has exchanged any messages with a
              practitioner.
              <br />
              <span className="text-red-500 font-bold">
                This action cannot be undone.
              </span>
            </p>
            <div className="flex gap-x-4 bg-gray-100">
              <Button
                fullWidth
                autoFocus={true}
                variant="outline"
                onClick={(): void => {
                  setShowConfirm(false);
                }}
              >
                Go back
              </Button>
              <Button
                fullWidth
                color="danger"
                onClick={onDeleteUser}
                loading={loading}
                disabled={loading}
              >
                Yes, delete
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
