import { Button } from 'components/button';
import React, { ReactNode } from 'react';

type RequestReviewLayoutProps = {
  title: string;
  description?: string;
  previous: () => void;
  onSubmit: () => void;
  nextDisabled?: boolean;
  children: ReactNode;
};

export const RequestReviewLayout: React.FC<RequestReviewLayoutProps> = ({
  previous,
  children,
  title,
  description,
  onSubmit,
  nextDisabled,
}) => {
  return (
    <form className="w-full space-y-4" onSubmit={onSubmit}>
      <h3 className="heading-md mb-2">{title}</h3>
      {description && <p>{description}</p>}
      {children}
      <div className="w-full flex flex-row justify-end gap-4 mt-4">
        <div>
          <Button fullWidth variant="outline" type="button" onClick={previous}>
            Back
          </Button>
        </div>
        <div>
          <Button fullWidth type="submit" disabled={nextDisabled}>
            Next
          </Button>
        </div>
      </div>
    </form>
  );
};
