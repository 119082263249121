import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { Loading } from 'components/loading';
import { Button } from 'components/button';
import { Tag } from 'components/tag';
import { ProblemType, ConsultationsQuery } from 'graphql/types';
import {
  formatDateAndTime,
  getConsultationStageColor,
  upperSnakeCaseToCapitalCase,
} from 'utils/misc';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { routes } from 'utils/routes';
import { CellProps, Cell, Column, Row } from 'react-table';
import {
  Pagination,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  useSelectablePaginatingSortingTable,
  useOrderBy,
  usePageIndex,
} from 'components/table';
import { Modal } from 'components/modal';
import {
  ConsultationFilter,
  useBuildConsultationQueryFilter,
} from './consultation-filter';
import { ProblemTypeTag } from 'components/tags/problem-type';
import { scriptTags } from 'pages/script/script-tag';
import { AssignModal } from 'components/assign-modal';

const TABLE_PAGE_SIZE = 30;

type Consultation = ConsultationsQuery['consultations'][number];

const isNotAssignable = (consultation: Row<Consultation>): boolean =>
  consultation.original.type === 'STI' &&
  consultation.original.status === 'AWAITING_RESULTS';

const Consultations = (): React.ReactElement => {
  const history = useHistory();
  const activeFilter = useBuildConsultationQueryFilter();
  const orderBy = useOrderBy({ defaultValue: 'updatedAt_desc' });
  const pageIndex = usePageIndex();
  const [assignModal, setAssignModal] = React.useState(false);
  const featureFlagClient = useFeatureFlagClient();

  const { data, loading, refetch } = useQuery<ConsultationsQuery>(
    gql`
      query Consultations(
        $orderBy: [ConsultationOrderByInput!]!
        $where: ConsultationWhereInput
        $pageSize: Int
        $skip: Int
      ) {
        consultations(
          orderBy: $orderBy
          where: $where
          take: $pageSize
          skip: $skip
        ) {
          id
          createdAt
          updatedAt
          type
          reviewReason
          status
          stage
          prescribingExperience

          appointment {
            calendarId
            calendarName
          }

          customer {
            id
            phone
            email
            consultations {
              id
              status
              completedAt
              doctor {
                id
                lastName
                email
                clinicianName
              }
            }
            individualHealthcareIdentifier {
              id
              ihi
            }
          }

          previousDoctor {
            id
            email
            firstName
            lastName
            clinicianName
          }

          doctor {
            id
            email
            firstName
            lastName
            fullName
            clinicianName
          }
        }
        consultationsCount(where: $where)
      }
    `,
    {
      variables: {
        where: activeFilter,
        pageSize: TABLE_PAGE_SIZE,
        skip: pageIndex * TABLE_PAGE_SIZE,
        orderBy,
      },
    },
  );

  const total = data?.consultationsCount ?? 0;

  const pageNumber = Math.ceil((total ?? 1) / TABLE_PAGE_SIZE);

  // Row data needs to be memoised so that the reference to the data remains the same
  const consultations = React.useMemo(() => {
    return data?.consultations || [];
  }, [data]);

  const escriptsEnabled = featureFlagClient.getBoolean(
    'enable_erx_features_doctors_consult_approve',
    { defaultValue: false },
  );
  // Columns needs to be memoised so that the reference to the columns remains the same
  const tableColumns = React.useMemo(
    () => columns(setAssignModal, escriptsEnabled),
    [escriptsEnabled],
  );

  const tableInstance = useSelectablePaginatingSortingTable({
    columns: tableColumns,
    data: consultations,
    pageNumber,
    orderBy,
    pageIndex,
  });

  const { selectedFlatRows } = tableInstance;

  return (
    <div>
      {!!selectedFlatRows.length && !selectedFlatRows.some(isNotAssignable) && (
        <div className="flex mb-4 justify-between">
          <div className="w-1/4">
            <Button
              fullWidth
              variant="outline"
              onClick={(): void => {
                setAssignModal(true);
              }}
            >
              Assign {selectedFlatRows.length} selected
            </Button>
          </div>
        </div>
      )}
      <Modal
        show={assignModal}
        isAutoOverflow={false}
        onClose={(): void => {
          setAssignModal(false);
        }}
      >
        <AssignModal
          consultationIds={selectedFlatRows.map((row) => row.original.id)}
          onAssigned={(): void => {
            refetch();
            setAssignModal(false);
          }}
        />
      </Modal>
      <ConsultationFilter />
      <div
        className={clsx(
          { 'visually-hidden': !consultations.length },
          'overflow-y-auto',
        )}
      >
        <Table tableInstance={tableInstance}>
          <TableHead />
          <TableBody>
            {tableInstance.page?.map((row) => {
              tableInstance.prepareRow(row);
              return (
                <TableRow row={row} key={row.id}>
                  {row.cells.map((cell) => (
                    <TableCell
                      key={`${cell.row.original.id}-${cell.column.id}`}
                      cell={cell}
                      onClick={(): void => {
                        if (
                          cell.column.id !== 'selection' &&
                          cell.column.id !== 'doctor'
                        ) {
                          history.push(
                            `${routes.consultations}/${cell.row.original.id}`,
                          );
                        }
                      }}
                    />
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      {!consultations.length && !loading && (
        <div className="text-center font-medium pt-8">
          No consultations found
        </div>
      )}
      {loading && (
        <div className="flex justify-center text-lg">
          <Loading />
        </div>
      )}
      <Pagination total={total} tableInstance={tableInstance} />
    </div>
  );
};

const PatientCell = (
  cell: CellProps<Consultation, string>,
): React.ReactElement => {
  const { customer } = cell.row.original;
  return <div>{customer?.email}</div>;
};

const ProblemCell = (
  cell: CellProps<Consultation, ProblemType>,
): React.ReactNode => <ProblemTypeTag problemType={cell.value} />;

const ConsultationStageCell = (
  cell: CellProps<Consultation, string>,
): React.ReactElement => (
  <Tag size="small" color={getConsultationStageColor(cell.row.original.stage)}>
    {upperSnakeCaseToCapitalCase(cell.row.original.stage)}
  </Tag>
);

const ReviewReasonCell = (
  cell: CellProps<Consultation, string>,
): React.ReactElement => (
  <div className="w-44 truncate">
    {!cell.row.original.reviewReason ||
    cell.row.original.reviewReason === 'LEGACY_MIGRATED'
      ? '-'
      : upperSnakeCaseToCapitalCase(cell.row.original.reviewReason)}
  </div>
);

const DoctorCell = ({
  cell,
  setAssignModal,
}: {
  cell: Cell<Consultation>;
  setAssignModal: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement => {
  if (cell.row.original.status === 'AWAITING_DOCTOR') {
    return (
      <div className="w-24">
        <Button
          fullWidth
          variant="outline"
          onClick={(): void => {
            if (!cell.row.isSelected) {
              cell.row.toggleRowSelected();
            }
            setAssignModal(true);
          }}
        >
          Assign
        </Button>
      </div>
    );
  }
  return <div> {cell.value ? cell.value.clinicianName : `N/A`}</div>;
};

const columns = (
  triggerAssignModal: React.Dispatch<React.SetStateAction<boolean>>,
  escriptsEnabled: boolean,
): Column<Consultation>[] => {
  const cols: Column<Consultation>[] = [
    {
      Header: 'ID',
      accessor: 'id',
      Cell: (c) => <pre className="text-slate-500">{c.value.slice(-6)}</pre>,
      disableSortBy: true,
    },
    {
      Header: 'Patient',
      Cell: PatientCell,
      disableSortBy: true,
    },
    {
      Header: 'Last Update',
      accessor: 'updatedAt',
      Cell: (c) => <div className="w-36">{formatDateAndTime(c.value)}</div>,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: true,
      Cell: (c) => <div>{c.value}</div>,
      className: 'w-44',
    },
    {
      Header: 'Type',
      disableSortBy: true,
      Cell: ConsultationStageCell,
      className: 'w-24',
    },
    {
      Header: 'Reason',
      disableSortBy: true,
      Cell: ReviewReasonCell,
    },
    {
      Header: 'Problem Type',
      accessor: 'type',
      Cell: ProblemCell,
      className: 'w-24',
    },
    {
      Header: 'Script Type',
      accessor: 'customer',
      Cell: (c) => (
        <div>
          {c.value.individualHealthcareIdentifier?.ihi
            ? scriptTags.escript()
            : scriptTags.paper()}
        </div>
      ),
      className: 'w-24',
      id: 'script-type',
      disableSortBy: true,
    },
    {
      Header: 'Practitioner Assigned',
      accessor: 'doctor',
      disableSortBy: true,
      Cell: (props): React.ReactElement => {
        return <DoctorCell {...props} setAssignModal={triggerAssignModal} />;
      },
    },
    {
      Header: 'Prev. Practitioner',
      accessor: 'previousDoctor',
      disableSortBy: true,
      Cell: (c) => {
        return c.value ? <div>{c.value?.clinicianName}</div> : <div>N/A</div>;
      },
    },
    {
      Header: 'Appointment',
      accessor: 'appointment',
      disableSortBy: true,
      Cell: (c) => {
        return c.value ? (
          <div>
            <p>ID: {c.value.calendarId}</p>
            <p>{c.value.calendarName}</p>
          </div>
        ) : (
          <div>N/A</div>
        );
      },
    },
    {
      Header: 'Prescribing Experience',
      accessor: 'prescribingExperience',
      disableSortBy: true,
      Cell: (c) => {
        return (
          <Tag
            size="small"
            color={
              c.value
                ? c.value.toUpperCase() === 'FLEXI'
                  ? 'blue'
                  : 'teal'
                : 'gray'
            }
          >
            {c.value ?? 'N/A'}
          </Tag>
        );
      },
    },
  ];

  if (!escriptsEnabled) {
    return cols.filter((col) => col.id !== 'script-type');
  }

  return cols;
};

export default Consultations;
