import React from 'react';
import { FeatureFlagProvider, FlagValue } from '@eucalyptusvc/react-ff-client';
import { useAuth } from 'auth';
import { useNotifications } from 'notifications';
import { config } from './config';

export const WrappingFeatureFlagProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const { user } = useAuth();
  const showNotification = useNotifications();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const emitExposureEvent = (flagKey: string, flagValue: FlagValue): void => {
    // TODO: Implement analytics in admins ui
  };
  const showInitialisationFailed = (message: string): void => {
    showNotification({ type: 'error', message });
  };
  return (
    <FeatureFlagProvider
      userId={user?.id}
      track={emitExposureEvent}
      showInitialisationFailed={showInitialisationFailed}
      launchDarklyClientID={config.launchDarklyClientId}
      additionalAttributes={{
        brand: config.brand,
      }}
    >
      {children}
    </FeatureFlagProvider>
  );
};
