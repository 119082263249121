import { gql, useMutation } from '@apollo/client';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { DeleteQuizMutation, DeleteQuizMutationVariables } from 'graphql/types';
import { useNotifications } from 'notifications';
import { Questionnaire } from 'pages/consultation/questionnaire';
import { ReactElement, useState } from 'react';
import { upperSnakeCaseToCapitalCase, getProblemTypeEmoji } from 'utils/misc';
import { QuizApplications } from '../types';

export const QuizModal = ({
  quiz,
  closeHandler,
}: {
  quiz: NonNullable<QuizApplications>[number];
  closeHandler: () => void;
}): ReactElement => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteQuizApplication, { loading }] = useMutation<
    DeleteQuizMutation,
    DeleteQuizMutationVariables
  >(
    gql`
      mutation deleteQuiz($id: String!) {
        deleteQuizApplication(id: $id)
      }
    `,
    {
      refetchQueries: ['Customer'],
    },
  );
  const showNotification = useNotifications();
  const featureFlagClient = useFeatureFlagClient();
  const onDeleteQuizApplication = async (): Promise<void> => {
    await deleteQuizApplication({
      variables: {
        id: quiz.id,
      },
    });
    showNotification({
      message: 'Quiz has been deleted',
      type: 'success',
    });
  };
  const enableAdminDelete = featureFlagClient.getBoolean('admin-deletes');

  return (
    <>
      <Modal width="w-5/6" show onClose={closeHandler}>
        <div className="text-sm bg-white p-2">
          <div className="text-black grid grid-cols-6 p-2 border-b-2">
            <div className="col-span-2">
              <p className=" pl-3 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Quiz Application ID
              </p>
              <p className="pl-3">{quiz.id}</p>
            </div>
            <div className="col-span-2">
              <p className=" pl-3 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                QuizId
              </p>
              <p className="pl-3">{quiz.quizId}</p>
            </div>
            <div>
              <p className=" pl-3 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Problem Type
              </p>
              <p className="pl-3 bg-blue-500 whitespace-nowrap w-min p-1 text-white rounded-md">
                {getProblemTypeEmoji(quiz.problemType)}{' '}
                {upperSnakeCaseToCapitalCase(quiz.problemType)}
              </p>
            </div>
            <div>
              <p className=" pl-3 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Purpose
              </p>
              <p className="pl-3">{quiz.purpose}</p>
            </div>
          </div>
          {quiz.responses && (
            <Questionnaire
              quizResponses={quiz.responses}
              isNonEnglish={quiz.isNonEnglish}
              languageDisplayName={quiz.languageDisplayName}
            />
          )}
          {enableAdminDelete && (
            <div className="w-1/6 pt-2">
              <Button
                fullWidth
                variant="solid"
                color="danger"
                disabled={!quiz.canBeDeleted}
                onClick={(): void => {
                  setShowDeleteConfirmation(true);
                }}
              >
                Delete Quiz
              </Button>
            </div>
          )}
        </div>
      </Modal>
      <Modal
        show={showDeleteConfirmation}
        width="w-96"
        onClose={(): void => {
          setShowDeleteConfirmation(false);
        }}
      >
        <div className="bg-white rounded p-4 space-y-4">
          <p className="mb-3 font-bold">
            Are you sure you want to delete this quiz?
          </p>
          <hr />
          <p>
            This action will permanently delete the quiz. Do not proceed if the
            patient has exchanged any messages with a practitioner.
            <br />
            <span className="text-red-500 font-bold">
              Warning: This action cannot be undone.
            </span>
          </p>
          <div className="flex gap-x-4 bg-gray-100">
            <Button
              fullWidth
              autoFocus={true}
              variant="outline"
              onClick={(): void => {
                setShowDeleteConfirmation(false);
              }}
            >
              Go back
            </Button>
            <Button
              fullWidth
              color="danger"
              onClick={onDeleteQuizApplication}
              loading={loading}
            >
              Yes, delete
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
