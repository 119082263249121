import { Button } from 'components/button';
import { EducationReading } from 'graphql/types';
import { useState, VFC } from 'react';
import { AddReadingModal } from './add-reading-modal';

type AddNewReadingButtonModalProps = {
  onCreatedReading: (newReading: EducationReading) => void;
};

export const AddNewReadingButtonModal: VFC<AddNewReadingButtonModalProps> = ({
  onCreatedReading,
}) => {
  const [showAddReadingModal, setShowAddReadingModal] = useState(false);

  const openAddReadingModal = (): void => {
    setShowAddReadingModal(true);
  };
  const closeAddReadingModal = (): void => {
    setShowAddReadingModal(false);
  };

  return (
    <>
      <Button fullWidth variant="outline" onClick={openAddReadingModal}>
        Add new reading
      </Button>
      <AddReadingModal
        show={showAddReadingModal}
        onClose={closeAddReadingModal}
        onCreatedReading={(newReading): void => {
          onCreatedReading(newReading);
          closeAddReadingModal();
        }}
      />
    </>
  );
};
