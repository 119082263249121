import React from 'react';
import Select, { Styles } from 'react-select';
import { Controller, Control, RegisterOptions } from 'react-hook-form';
import { Label } from 'components/label';
import { InputError } from 'components/input-error';
import { TooltipProps } from './tooltip';

export interface Option<T = string> {
  value?: T;
  label: string | JSX.Element;
}

export interface DropdownProps<T = string> {
  name: string;
  options: Option<T>[];
  control: Control;
  label?: string;
  disabled?: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  errorMessage?: string | JSX.Element;
  rules?: RegisterOptions;
  isMulti?: boolean;
  defaultValue?: string;
  customStyles?: Styles<Option<string>, boolean>;
  handleSingleSelect?: (option: Option<T>) => void;
  placeholder?: string;
  tooltipProps?: TooltipProps;
  description?: string;
  closeMenuOnSelect?: boolean;
}

export const Dropdown = ({
  name,
  options,
  control,
  label,
  isMulti = false,
  disabled = false,
  menuPlacement = 'auto',
  errorMessage,
  rules,
  customStyles,
  defaultValue,
  placeholder,
  handleSingleSelect,
  description,
  tooltipProps,
  closeMenuOnSelect = true,
}: DropdownProps): JSX.Element => (
  <div>
    {(label || description) && (
      <div className="mb-3 flex flex-col gap-1">
        {label && (
          <Label htmlFor={name} tooltip={tooltipProps ?? undefined}>
            {label}
          </Label>
        )}
        {description && <p className="text-sm text-gray-700">{description}</p>}
      </div>
    )}
    <Controller
      defaultValue=""
      render={({ onChange, onBlur, value, name, ref }) => (
        <Select
          closeMenuOnSelect={closeMenuOnSelect}
          isMulti={isMulti}
          inputId={name}
          options={options}
          isDisabled={disabled}
          className="dropdown"
          classNamePrefix="dd"
          placeholder={placeholder}
          menuPlacement={menuPlacement}
          styles={{
            control: (base): React.CSSProperties => ({
              ...base,
              borderColor: errorMessage ? '#f56565' : '#e2e8f0',
            }),
            ...customStyles,
          }}
          innerRef={ref}
          onBlur={onBlur}
          defaultInputValue={defaultValue}
          value={
            isMulti && Array.isArray(value)
              ? options.filter((it) => value.includes(it.value))
              : options.find((it) => it.value === value)
          }
          onChange={(v) => {
            // Multi-select dropdowns
            if (isMulti) {
              if (Array.isArray(v) && v.every((option) => 'value' in option)) {
                onChange(v.map((option) => option.value));
              } else if (v === null) {
                onChange(null);
              }
            }

            // Single-select dropdowns
            if (v && 'value' in v) {
              onChange(v.value);
              if (handleSingleSelect != undefined) {
                handleSingleSelect(v);
              }
            }
          }}
        />
      )}
      name={name}
      control={control}
      rules={rules}
    />
    <InputError>{errorMessage}</InputError>
  </div>
);
