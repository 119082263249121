import {
  ApolloCache,
  DefaultContext,
  gql,
  MutationUpdaterFunction,
  useMutation,
  useQuery,
} from '@apollo/client';
import { ContextMenu } from 'components/context-menu';
import { Loading } from 'components/loading';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/table';
import { Tag } from 'components/tag';
import {
  HealthCoachingFlowsQuery,
  HealthCoachingFlowsQueryVariables,
  HealthCoachingFlowTemplateStatus,
  CreateOrEditFlowTemplateMutation,
  CreateOrEditFlowTemplateMutationVariables,
  UpsertHealthCoachingFlowTemplateInput,
  Exact,
  UpsertHealthCoachingFlowNodeTemplateInput,
  HealthCoachingFlowTemplateInfoFragment,
} from 'graphql/types';
import { CellProps, Column, useTable } from 'react-table';
import { Colors } from 'utils/misc';
import { ReactComponent as MoreHorizontalIcon } from '../../components/assets/more-horizontal.svg';
import { FaQuestionCircle, FaPlus } from 'react-icons/fa';
import { ReactComponent as CloseIcon } from 'components/assets/close.svg';
import { Modal } from 'components/modal';
import { useState } from 'react';
import { DefaultValues, useForm } from 'react-hook-form';
import { Input } from 'components/input';
import { requiredValidation } from 'utils/form-validation';
import { Checkbox } from 'components/checkbox';
import { Button } from 'components/button';
import { useHistory } from 'react-router-dom';
import { buildRoute } from 'utils/routes';
import { v4 } from 'uuid';
import { useNotifications } from 'notifications';

const healthCoachingFlowTemplateInfoFragment = gql`
  fragment healthCoachingFlowTemplateInfo on HealthCoachingFlowTemplate {
    id
    name
    repeatable
    status
    nodeTemplates {
      id
      ... on HealthCoachingFlowMessageNodeTemplate {
        contentfulMessages {
          id
          contentfulId
        }
      }
      ... on HealthCoachingFlowWaitNodeTemplate {
        count
        interval
      }
    }
  }
`;

const healthCoachingFlowsQuery = gql`
  query HealthCoachingFlows {
    healthCoachingFlowTemplates {
      ...healthCoachingFlowTemplateInfo
    }
  }
  ${healthCoachingFlowTemplateInfoFragment}
`;

const createOrEditHealthCoachingFlowTemplateMutation = gql`
  mutation CreateOrEditFlowTemplate(
    $input: UpsertHealthCoachingFlowTemplateInput!
  ) {
    upsertHealthCoachingFlowTemplate(input: $input) {
      healthCoachingFlowTemplates {
        ...healthCoachingFlowTemplateInfo
      }
    }
  }
  ${healthCoachingFlowTemplateInfoFragment}
`;

const healthCoachingFlowsCacheUpdaterFunction: MutationUpdaterFunction<
  CreateOrEditFlowTemplateMutation,
  Exact<{
    input: UpsertHealthCoachingFlowTemplateInput;
  }>,
  DefaultContext,
  ApolloCache<HealthCoachingFlowsQuery>
> = (cache, { data }) => {
  if (!data?.upsertHealthCoachingFlowTemplate?.healthCoachingFlowTemplates) {
    return;
  }

  cache.writeQuery<HealthCoachingFlowsQuery>({
    query: healthCoachingFlowsQuery,
    data: {
      healthCoachingFlowTemplates:
        data.upsertHealthCoachingFlowTemplate.healthCoachingFlowTemplates,
    },
  });
};

const nodeTemplatesToUpsertFlowTemplateNodeInput = ({
  nodeTemplates,
  useNewIds = false,
}: {
  nodeTemplates: HealthCoachingFlowTemplateInfoFragment['nodeTemplates'];
  useNewIds?: boolean;
}): UpsertHealthCoachingFlowNodeTemplateInput[] => {
  if (!nodeTemplates) {
    return [];
  }

  return nodeTemplates.map((nodeTemplate) => {
    if (nodeTemplate.__typename === 'HealthCoachingFlowMessageNodeTemplate') {
      return {
        message: {
          id: useNewIds ? v4() : nodeTemplate.id,
          contentfulIds: nodeTemplate.contentfulMessages.map(
            (cm) => cm.contentfulId,
          ),
        },
      };
    }

    if (nodeTemplate.__typename === 'HealthCoachingFlowWaitNodeTemplate') {
      return {
        wait: {
          id: useNewIds ? v4() : nodeTemplate.id,
          count: nodeTemplate.count,
          interval: nodeTemplate.interval,
        },
      };
    }

    throw new Error(
      `received unexpected flow node of type: ${nodeTemplate.__typename}`,
    );
  });
};

type CreateOrEditFlowModalProps = {
  visible: boolean;
  flowRow?: HealthCoachingFlowTemplateInfoFragment;
  onClose: () => void;
};

type CreateOrEditFlowFormValues = {
  name: string;
  repeatable: boolean;
};

const defaultCreateFlowFormValues: DefaultValues<CreateOrEditFlowFormValues> = {
  name: '',
  repeatable: false,
};

function CreateOrEditFlowModal({
  visible,
  flowRow,
  onClose,
}: CreateOrEditFlowModalProps) {
  const { formState, errors, register, trigger, handleSubmit, reset } =
    useForm<CreateOrEditFlowFormValues>({
      defaultValues: flowRow
        ? {
            name: flowRow.name,
            repeatable: flowRow.repeatable,
          }
        : defaultCreateFlowFormValues,
    });
  const showNotification = useNotifications();

  const [createOrEditFlowTemplateMutation] = useMutation<
    CreateOrEditFlowTemplateMutation,
    CreateOrEditFlowTemplateMutationVariables
  >(createOrEditHealthCoachingFlowTemplateMutation, {
    update: healthCoachingFlowsCacheUpdaterFunction,
    onCompleted: () => {
      if (flowRow) {
        reset({
          name: flowRow.name,
          repeatable: flowRow.repeatable,
        });
      } else {
        reset(defaultCreateFlowFormValues);
      }

      onClose();
    },
  });

  const saveFlow = handleSubmit(async (data) => {
    const isValid = await trigger();

    if (!isValid) {
      return;
    }

    await createOrEditFlowTemplateMutation({
      variables: {
        input: {
          ...data,
          id: flowRow?.id ?? v4(),
          nodeTemplates: nodeTemplatesToUpsertFlowTemplateNodeInput({
            nodeTemplates: flowRow?.nodeTemplates,
          }),
        },
      },
    });

    showNotification({
      message: flowRow ? 'Flow updated' : 'Flow created',
      type: 'success',
    });
  });

  return (
    <Modal show={visible} onClose={onClose} width="w-[500px]">
      <div className="bg-white rounded-lg p-6 flex flex-col gap-5">
        <div className="flex flex-col gap-6">
          <div className="flex items-center justify-between">
            <p className="text-base uppercase font-semibold">
              {flowRow ? 'Update flow settings' : 'Create a flow'}
            </p>

            <button className="p-2" onClick={onClose}>
              <CloseIcon width={16} height={16} className="fill-black" />
            </button>
          </div>

          <Input
            name="name"
            label="Title"
            autoFocus
            ref={register(requiredValidation('name'))}
            errorMessage={errors.name?.message}
          />

          <Checkbox label="Is repeatable" ref={register()} name="repeatable" />
        </div>

        <div className="flex justify-end gap-5">
          <Button variant="outline" onClick={onClose}>
            Close
          </Button>

          <Button loading={formState.isSubmitting} onClick={saveFlow}>
            {flowRow ? 'Save' : 'Create flow'}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

const TitleCell = (
  cell: CellProps<HealthCoachingFlowTemplateInfoFragment, string>,
): React.ReactElement => <div className="font-semibold">{cell.value}</div>;

const BooleanCell = (
  cell: CellProps<HealthCoachingFlowTemplateInfoFragment, boolean>,
): React.ReactElement => <div>{cell.value ? 'Yes' : 'No'}</div>;

const StatusCell = (
  cell: CellProps<
    HealthCoachingFlowTemplateInfoFragment,
    HealthCoachingFlowTemplateStatus
  >,
): React.ReactElement => {
  let statusColor: Colors;
  switch (cell.value) {
    case 'PUBLISHED':
      statusColor = 'green';
      break;
    case 'UNPUBLISHED':
      statusColor = 'red';
      break;
    default:
      statusColor = 'orange';
      break;
  }

  return (
    <Tag size="small" color={statusColor}>
      {cell.value}
    </Tag>
  );
};

const ActionCell = (
  cell: CellProps<HealthCoachingFlowTemplateInfoFragment>,
): React.ReactElement => {
  const [showEditFlowModal, setShowEditFlowModal] = useState(false);
  const history = useHistory();
  const showNotification = useNotifications();

  const [createOrEditFlowTemplateMutation] = useMutation<
    CreateOrEditFlowTemplateMutation,
    CreateOrEditFlowTemplateMutationVariables
  >(createOrEditHealthCoachingFlowTemplateMutation, {
    update: healthCoachingFlowsCacheUpdaterFunction,
  });

  const flowRow = cell.row.original;

  const duplicateFlowTemplate = async () => {
    await createOrEditFlowTemplateMutation({
      variables: {
        input: {
          id: v4(),
          name: `${flowRow.name} - Duplicate`,
          repeatable: flowRow.repeatable,
          nodeTemplates: nodeTemplatesToUpsertFlowTemplateNodeInput({
            nodeTemplates: flowRow.nodeTemplates,
            useNewIds: true,
          }),
        },
      },
    });

    showNotification({
      message: 'Duplicate flow created',
      type: 'success',
    });
  };

  return (
    <div>
      <ContextMenu
        button={
          <button className="w-8 h-8 flex items-center justify-center mx-auto">
            <MoreHorizontalIcon
              width={16}
              height={6}
              className="fill-slate-400"
            />
          </button>
        }
        options={[
          {
            copy: 'Edit flow',
            onClick: () =>
              history.push(buildRoute.healthCoachingFlow(cell.row.original.id)),
          },
          {
            copy: 'Update settings',
            onClick: () => setShowEditFlowModal(true),
          },
          {
            copy: 'Duplicate',
            onClick: () => {
              void duplicateFlowTemplate();
            },
          },
        ]}
      />

      <CreateOrEditFlowModal
        visible={showEditFlowModal}
        flowRow={flowRow}
        onClose={() => setShowEditFlowModal(false)}
      />
    </div>
  );
};

const columns: Column<HealthCoachingFlowTemplateInfoFragment>[] = [
  {
    Header: 'Title',
    accessor: 'name',
    Cell: TitleCell,
  },
  {
    Header: (
      <div className="flex items-center">
        <span className="mr-2">Repeatable</span>
        <FaQuestionCircle size={16} />
      </div>
    ),
    accessor: 'repeatable',
    Cell: BooleanCell,
  },
  {
    Header: (
      <div className="flex items-center">
        <span className="mr-2">Status</span>
        <FaQuestionCircle size={16} />
      </div>
    ),
    accessor: 'status',
    Cell: StatusCell,
  },
  {
    Header: 'Actions',
    className: 'w-12',
    Cell: ActionCell,
  },
];

export default function HealthCoachingFlows() {
  const { data, loading } = useQuery<
    HealthCoachingFlowsQuery,
    HealthCoachingFlowsQueryVariables
  >(healthCoachingFlowsQuery);
  const history = useHistory();
  const [showCreateFlowModal, setShowCreateFlowModal] = useState(false);

  const flowTemplates =
    data?.healthCoachingFlowTemplates?.slice(0).sort((a, b) => {
      const flowAName = a.name;
      const flowBName = b.name;

      if (flowAName < flowBName) {
        return -1;
      }

      if (flowAName > flowBName) {
        return 1;
      }

      return 0;
    }) ?? [];

  const tableInstance = useTable({
    columns,
    data: flowTemplates,
  });

  if (loading && !data) {
    return (
      <div className="flex justify-center text-lg">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-end">
        <Button onClick={() => setShowCreateFlowModal(true)}>
          <span className="flex items-center">
            <FaPlus size={14} className="fill-white mr-1" />
            <span>Create a flow</span>
          </span>
        </Button>
      </div>

      {tableInstance.flatRows.length ? (
        <div className="mt-8">
          <Table tableInstance={tableInstance}>
            <TableHead />
            <TableBody>
              {tableInstance.flatRows.map((row) => {
                tableInstance.prepareRow(row);

                return (
                  <TableRow row={row} key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell
                        key={`${cell.row.original.id}-${cell.column.id}`}
                        cell={cell}
                        className="px-4 h-[72px]"
                        onClick={(): void => {
                          if (cell.column.id !== 'Actions') {
                            history.push(
                              buildRoute.healthCoachingFlow(
                                cell.row.original.id,
                              ),
                            );
                          }
                        }}
                      />
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      ) : (
        <div className="text-center py-20 mt-8">
          <p className="font-semibold text-gray-700">
            You haven&apos;t created any flows
          </p>
          <p className="mt-1 text-gray-700">
            For a flow to work, you will need to add at least one message
            containing at least one content item.
          </p>

          <button
            className="flex items-center mx-auto px-4 py-2.5 rounded mt-3 border border-gray-700 hover:shadow-md transition-shadow text-sm font-semibold"
            onClick={() => setShowCreateFlowModal(true)}
          >
            <FaPlus size={14} className="fill-gray-700 mr-1" />
            <span>Create a flow</span>
          </button>
        </div>
      )}

      <CreateOrEditFlowModal
        visible={showCreateFlowModal}
        onClose={() => setShowCreateFlowModal(false)}
      />
    </div>
  );
}
