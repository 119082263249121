import { NoteRowFragment } from 'graphql/types';

export const LegacyReason: React.FC<{ note: NoteRowFragment }> = ({ note }) => {
  if (!note.legacy) {
    return null;
  }

  return (
    <div className="px-2 py-1 bg-orange-200 outline outline-1 outline-orange-500 text-sm space-y mx-4 mt-1">
      <div className="flex gap-2 italic items-center">
        *This is a legacy note. Some fields (e.g. Author, Date) may be
        unavailable.
      </div>
    </div>
  );
};
