import { gql, useMutation } from '@apollo/client';
import { Button } from 'components/button';
import {
  CriticalNoteFragment,
  UpsertEmptyCriticalNoteMutation,
  UpsertEmptyCriticalNoteMutationVariables,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import { criticalNoteDocument } from './critical-note-card';

export const RemoveCriticalNoteButton: React.FC<{
  note: NonNullable<CriticalNoteFragment>;
  customerId: string;
}> = ({ note, customerId }) => {
  const showNotification = useNotifications();

  const [upsertEmptyCriticalNote, { loading }] = useMutation<
    UpsertEmptyCriticalNoteMutation,
    UpsertEmptyCriticalNoteMutationVariables
  >(
    gql`
      mutation upsertEmptyCriticalNote($input: UpsertNoteInput!) {
        upsertNote(input: $input) {
          note {
            id
            ...CriticalNote
          }
        }
      }

      ${criticalNoteDocument}
    `,
    {
      variables: {
        input: {
          noteId: note.id,
          targetId: customerId,
          targetType: 'CUSTOMER',
          domain: 'CUSTOMER_CRITICAL',
          body: '',
        },
      },
      onCompleted: () => {
        showNotification({
          type: 'success',
          message: 'Critical note removed',
        });
      },
      onError: () => {
        showNotification({
          type: 'error',
          message: 'Unable to remove critical note',
        });
      },
    },
  );

  return (
    <Button
      variant="outline"
      color="danger"
      size="small"
      loading={loading}
      onClick={() => upsertEmptyCriticalNote()}
    >
      Remove
    </Button>
  );
};
