import { gql } from '@apollo/client';
import {
  FileCreateWithoutAvatarOfInput,
  HealthCoachQuery,
  HealthCoachUpdateInput,
} from 'graphql/types';
import { DeepMap } from 'react-hook-form';
import { Fields } from './types';

export const healthCoachQuery = gql`
  query HealthCoach($healthCoachId: String!) {
    user(where: { id: $healthCoachId }) {
      id
      firstName
      lastName
      fullName
      email
      bio
      avatar {
        id
        url
        filename
        mimetype
      }
    }
  }
`;

export const updateHealthCoachMutation = gql`
  mutation UpdateHealthCoach($healthCoach: HealthCoachUpdateInput!) {
    updateHealthCoach(healthCoach: $healthCoach) {
      id
      firstName
      lastName
      fullName
      email
      bio
      avatar {
        id
        url
        filename
        mimetype
      }
    }
  }
`;

export const deleteHealthCoachMutation = gql`
  mutation DeleteHealthCoach($id: String!) {
    deleteOneUser(where: { id: $id }) {
      id
    }
  }
`;

export const mapFieldsToGraphQLVariables = (
  id: string,
  fields: Fields,
  dirtyFields: DeepMap<Fields, true>,
): HealthCoachUpdateInput => {
  const modifiedFields: Fields = Object.assign(
    {},
    ...Object.keys(dirtyFields).map((key) => {
      return { [key as keyof Fields]: fields[key as keyof Fields] };
    }),
  );

  return {
    id,
    ...modifiedFields,
    avatar: modifiedFields.avatar
      ? (JSON.parse(modifiedFields.avatar) as FileCreateWithoutAvatarOfInput)
      : undefined,
  };
};

export const mapGraphQLSchemaToFields = (
  healthCoach: HealthCoachQuery['user'],
): Fields => {
  return {
    firstName: healthCoach?.firstName || '',
    lastName: healthCoach?.lastName || '',
    email: healthCoach?.email || '',
    bio: healthCoach?.bio || '',
    avatar: healthCoach?.avatar
      ? JSON.stringify({
          url: healthCoach.avatar.url,
          filename: healthCoach.avatar.filename,
          mimetype: healthCoach.avatar.mimetype,
        })
      : '',
  };
};
