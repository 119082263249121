import { gql } from '@apollo/client';

export const userInfoFragment = gql`
  fragment userInfo on User {
    id
    email
    firstName
    lastName
    fullName
    phone
    role
    roles {
      id
      value
    }
    address {
      id
      line1
      line2
      city
      postalCode
      state
      country
      company
      building
    }
    avatar {
      id
      filename
      mimetype
      url
    }
  }
`;

export const pathologyRequestFragment = gql`
  fragment pathologyRequestInfo on PathologyRequest {
    id
    status
    reference
    createdAt
    customerStatedCollectedAt
    customerStatedCollectedDate
    consultation {
      id
      updatedAt
      type
      status
      doctor {
        id
        firstName
        lastName
        fullName
        clinicianName
      }
      treatment {
        id
        status
      }
    }
    referral {
      id
      signedUrl
    }
    result {
      id
      lab
      collectedDate
      file {
        id
        url
      }
    }
    panels {
      id
      tests {
        id
        name
        formName
        displayName
        result {
          id
          result {
            type
            value
            number
            unit
            range {
              low
              high
            }
          }
          note
        }
        pathologyPanelId
      }
    }
    patient {
      id
      email
      firstName
      lastName
      fullName
      phone
      avatar {
        id
        url
      }
    }
    doctor {
      id
      firstName
      lastName
      fullName
      clinicianName
    }
  }
`;

export const quizResponseFragment = gql`
  fragment quizResponse on QuizResponse {
    question {
      id
      type
      title(languageCode: EN_AU)
      originalTitle: title
      shortcode
      options {
        value(languageCode: EN_AU)
        originalValue: value
      }
    }
    response {
      answers(languageCode: EN_AU) {
        value
        imageUrl
      }
      originalAnswers: answers {
        value
        imageUrl
      }
      flag {
        level
      }
    }
  }
`;

export const orderInfoFragment = gql`
  fragment orderInfo on Order {
    id
    createdAt
    updatedAt
    lineItems {
      id
      variant {
        id
        name
        product {
          id
          name
          productType
          problemTypes
        }
      }
    }
    status
    fulfillment
    consultation {
      id
    }
    payments {
      id
      gateway
    }
  }
`;
