import React from 'react';
import { ProblemType, TrackerDetails } from 'graphql/types';
import { gql, useQuery } from '@apollo/client';
import { routes } from 'utils/routes';
import { Link } from 'react-router-dom';
import { Customer } from './types';
import { WeightObservations } from '../../components/weight/weight';
import { Milestone } from '../../components/milestones';

const trackingQuizQuery = gql`
  query GetTrackerDetailIdsForCustomerQuery(
    $customerId: String!
    $problemType: ProblemType!
  ) {
    trackerDetailsForCustomerQuery(
      customerId: $customerId
      problemType: $problemType
    ) {
      id
    }
  }
`;

const trackingProblemTypes = new Set<ProblemType>([
  'WEIGHT_LOSS',
  'SKIN_GENERAL',
  'ACNE',
  'ANTI_AGEING',
]);

export const TrackingSection = ({
  customer,
  problemType,
}: {
  customer: Customer;
  problemType: ProblemType;
}): React.ReactElement | null => {
  const isTrackingQuizProblemType = React.useMemo(
    () => trackingProblemTypes.has(problemType),
    [problemType],
  );

  const { data } = useQuery<
    { trackerDetailsForCustomerQuery: TrackerDetails[] },
    { customerId: string; problemType: ProblemType }
  >(trackingQuizQuery, {
    variables: {
      customerId: customer.id,
      problemType,
    },
    skip: !isTrackingQuizProblemType,
  });

  if (!isTrackingQuizProblemType) {
    return null;
  }

  const milestones = customer.tracker?.weightLoss.weightGoal?.milestones;
  const currentMilestone = [...(milestones ?? [])]
    .sort((a: Milestone, b: Milestone) => (a.order || 0) - (b.order || 0))
    .find(
      (milestone: Milestone) =>
        milestone.currentPercentageComplete !== null &&
        milestone.currentPercentageComplete !== undefined &&
        milestone.currentPercentageComplete < 1,
    );

  return (
    <div className="overflow-hidden rounded shadow my-4 bg-white px-4 py-5 border-b border-gray-200">
      <div className="items-center flex justify-between">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Tracking
          <span className="ml-2 text-gray-500">
            {data?.trackerDetailsForCustomerQuery?.length ?? '-'}
          </span>
        </h3>
        <Link to={`${routes.tracking}/${customer.id}?pt=${problemType}`}>
          <button
            type="button"
            className="inline-flex  items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded text-gray-700 bg-white focus:outline-none focus:ring-blue focus:border-blue-300 active:text-gray-800 active:bg-white transition duration-150 ease-in-out"
          >
            View tracking
          </button>
        </Link>
      </div>
      {problemType === 'WEIGHT_LOSS' && (
        <WeightObservations
          weightObservations={{
            initialWeightTrackedDate:
              customer.tracker?.weightLoss.initialWeightTrackedDate,
            currentWeightValueKg:
              customer.tracker?.weightLoss.currentWeightValueKg,
            initialWeightValueKg:
              customer.tracker?.weightLoss.initialWeightValueKg,
            mostRecentWeightTrackedDate:
              customer.tracker?.weightLoss.mostRecentWeightTrackedDate,
            trackingCountPerWeek:
              customer.tracker?.weightLoss.trackingCountPerWeek,
            trackingCountTotal: customer.tracker?.weightLoss.trackingCountTotal,
            timedObservations: customer.tracker?.weightLoss.timedObservations,
          }}
          currentMilestone={
            currentMilestone &&
            currentMilestone.completionKg &&
            currentMilestone.order
              ? {
                  completionKg: currentMilestone.completionKg,
                  order: currentMilestone.order,
                  isGoalWeight: currentMilestone.order === milestones?.length,
                }
              : undefined
          }
        />
      )}
    </div>
  );
};
