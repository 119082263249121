import { gql, useQuery } from '@apollo/client';
import clsx from 'clsx';
import { z } from 'zod';
import { Loading } from 'components/loading';
import { config } from 'config';
import { ProblemType, QueuesQuery, QueuesQueryVariables } from 'graphql/types';
import React, { useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';
import NoPickupQueueTable from './no-pickup-queue-table';
import PractitionerBookingsTable from './practitioner-bookings-table';
import {
  QueueSummary,
  QueueSummaryWindowFragmentDoc,
} from './summary/queue-summary';
import { upperSnakeCaseToCapitalCase } from 'utils/misc';
import { isWithinInterval } from 'date-fns';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { BookingRescheduleModalPractitionerBookingWindowDoc } from './booking-reschedule-modal';
import { useHistory } from 'react-router';
import {
  formattedWindowTimezone,
  usePracBookingWindowDates,
} from 'utils/queues';
import { QueueFilter } from './summary/queue-filter';
import { brandFromEngineBrand } from '@eucalyptusvc/lib-adapters';

const pracBookingQueuesConfigFlagSchema = z.record(
  z.string(),
  z.array(z.string()),
);
type PracBookingQueuesConfigFlagValue = z.infer<
  typeof pracBookingQueuesConfigFlagSchema
>;

enum QueueView {
  NO_PICKUP_QUEUE = 'NO_PICKUP_QUEUE',
  PRACTITIONER_BOOKINGS_QUEUE = 'PRACTITIONER_BOOKINGS_QUEUE',
}

const Queues = (): React.ReactElement => {
  const featureFlagClient = useFeatureFlagClient();
  const history = useHistory();
  const [queueView, setQueueView] = useState<QueueView>(
    QueueView.PRACTITIONER_BOOKINGS_QUEUE,
  );
  const [selectedProblemType, setSelectedProblemType] = useLocalStorage<
    ProblemType | undefined
  >('admin-prac-booking-queue-problem-type');

  const pracBookingQueuesConfigFlagData =
    featureFlagClient.getJson<PracBookingQueuesConfigFlagValue>(
      'cfg-prac-booking-queues',
    );
  const pracBookingQueuesConfig = pracBookingQueuesConfigFlagSchema.safeParse(
    pracBookingQueuesConfigFlagData,
  );

  if (!pracBookingQueuesConfig.success) {
    throw new Error(
      `Invalid config flag value set for cfg-prac-booking-queues`,
    );
  }

  const pracBookingQueueConfigForBrand = pracBookingQueuesConfig.data[
    brandFromEngineBrand(config.brand)
  ] as [ProblemType];

  useEffect(() => {
    if (!selectedProblemType) {
      setSelectedProblemType(pracBookingQueueConfigForBrand[0]);
    }
  }, [
    pracBookingQueueConfigForBrand,
    selectedProblemType,
    setSelectedProblemType,
  ]);

  const dates = usePracBookingWindowDates();

  const {
    data,
    loading,
    refetch: refetchQueuesQuery,
  } = useQuery<QueuesQuery, QueuesQueryVariables>(
    gql`
      ${QueueSummaryWindowFragmentDoc}
      ${BookingRescheduleModalPractitionerBookingWindowDoc}
      query Queues($problemTypes: [ProblemType!]!, $dates: [String!]!) {
        practitionerBookingWindows(
          input: { problemTypes: $problemTypes, dates: $dates }
        ) {
          ...BookingRescheduleModalPractitionerBookingWindow
          ...QueueSummaryWindow
        }
      }
    `,
    {
      variables: {
        problemTypes: pracBookingQueueConfigForBrand,
        dates,
      },
    },
  );

  const windowsForSelectedProblemType = React.useMemo(() => {
    return (
      data?.practitionerBookingWindows?.filter(
        (pbw) => pbw.problemType === selectedProblemType,
      ) || []
    );
  }, [data?.practitionerBookingWindows, selectedProblemType]);

  const selectedTabClasses = 'border border-green-900 bg-white';
  const deselectedTabClasses = 'border border-gray-400 bg-inherit';

  if (loading && !data) {
    return (
      <div className="flex justify-center text-lg">
        <Loading />
      </div>
    );
  }

  const totalBookingsForSelectedProblemType =
    windowsForSelectedProblemType.reduce(
      (sum, w) => sum + w.unassignedBookingsCount,
      0,
    );

  if (!selectedProblemType) {
    return <Loading />;
  }

  return (
    <div>
      <div className="flex justify-between">
        <div className="text-sm text-gray-700">
          Dates and times below are in {formattedWindowTimezone}.
        </div>
      </div>
      <div className="border-t border-gray-300 my-4" />
      <div className="flex justify-between">
        <ul className="flex space-x-4 font-semibold">
          {pracBookingQueueConfigForBrand.map((pt, idx) => {
            const isActive = pt === selectedProblemType;
            const activeWindowForProblemType =
              data?.practitionerBookingWindows?.find(
                (w) =>
                  w.problemType === pt &&
                  isWithinInterval(new Date(), {
                    start: new Date(w.startAt),
                    end: new Date(w.endAt),
                  }),
              );
            return (
              <li
                key={pt}
                className="cursor-pointer"
                onClick={(): void => {
                  const newSelectedProblemType =
                    pracBookingQueueConfigForBrand[idx];
                  setSelectedProblemType(newSelectedProblemType);
                  history.replace({ search: '' }); // clear pageIndex search param
                }}
              >
                <div>
                  <p
                    className={clsx({
                      'text-primary': isActive,
                      'text-primary-300': !isActive,
                    })}
                  >
                    {upperSnakeCaseToCapitalCase(pt)} (
                    {activeWindowForProblemType?.unassignedBookingsCount ?? 0})
                  </p>
                  {isActive && (
                    <div className=" border-b-2 border-primary pt-2" />
                  )}
                </div>
              </li>
            );
          })}
        </ul>
        <div className="flex font-light">
          <button
            className={clsx(
              'text-center cursor-pointer px-4 py-3 text-gray-900 rounded-l-sm',
              queueView === QueueView.PRACTITIONER_BOOKINGS_QUEUE
                ? selectedTabClasses
                : deselectedTabClasses,
            )}
            onClick={() => setQueueView(QueueView.PRACTITIONER_BOOKINGS_QUEUE)}
          >
            Prescriber queue
          </button>
          <button
            className={clsx(
              'text-center cursor-pointer px-4 py-3 text-gray-900 rounded-r-sm',
              queueView === QueueView.NO_PICKUP_QUEUE
                ? selectedTabClasses
                : deselectedTabClasses,
            )}
            onClick={() => setQueueView(QueueView.NO_PICKUP_QUEUE)}
          >
            No pickup queue
          </button>
        </div>
      </div>
      <div className="space-y-8">
        <div className="flex space-x-6 mt-2">
          <div className="flex-auto w-2/3">
            {windowsForSelectedProblemType && selectedProblemType && (
              <QueueSummary
                windows={windowsForSelectedProblemType}
                problemType={selectedProblemType}
                onUpdate={refetchQueuesQuery}
              />
            )}
          </div>
        </div>
        {queueView === QueueView.PRACTITIONER_BOOKINGS_QUEUE && (
          <div>
            <QueueFilter />
            <PractitionerBookingsTable
              problemType={selectedProblemType}
              practitionerBookingWindows={windowsForSelectedProblemType}
              onBookingUpdated={async () => {
                await refetchQueuesQuery();
              }}
              totalBookings={totalBookingsForSelectedProblemType}
            />
          </div>
        )}
        {queueView === QueueView.NO_PICKUP_QUEUE && (
          <NoPickupQueueTable problemType={selectedProblemType} />
        )}
      </div>
    </div>
  );
};

export default Queues;
