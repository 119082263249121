import clsx from 'clsx';
import { FaRegTrashAlt, FaBars, FaSpinner } from 'react-icons/fa';
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  useForm,
} from 'react-hook-form';
import { Input } from 'components/input';
import { RadioButton } from 'components/radio-button';
import { DraggableProvided } from 'react-beautiful-dnd';
import { Switch } from 'components/switch';
import {
  ActionMediaAttachmentRequirement,
  ActionContentSelectQuery,
  ContentItemStatus,
} from 'graphql/types';
import { maxValueValidation, requiredValidation } from 'utils/form-validation';
import { CopyToClipboardButton } from 'components/copy-to-clipboard-button';
import { gql, useQuery } from '@apollo/client';
import { Label } from 'components/label';
import { useMemo } from 'react';
import Select from 'react-select';

export type ActionForm = {
  id: string;
  name: string;
  mediaRequirement: ActionMediaAttachmentRequirement;
  canAttachMedia: boolean;
  isRepeatAction: boolean;
  repeatCount: string;
  hasLinkToResource: boolean;
  contentId?: string;
};

interface ActionCardProps {
  index: number;
  formId: string;
  action: ActionForm;
  formValues: ActionForm;
  register: ReturnType<typeof useForm>['register'];
  errors?: DeepMap<ActionForm, FieldError> | undefined;
  provided: DraggableProvided;
  onActionDelete: () => Promise<void>;
  control: Control;
  actionIdDeleteLoading: string | null;
  onContentItemsLoaded: () => void;
}

const ContentSelect = ({
  name,
  label,
  contentItemId,
  control,
  onContentItemsLoaded,
}: {
  name: string;
  label?: string;
  contentItemId?: string;
  control: Control;
  onContentItemsLoaded: () => void;
}): JSX.Element => {
  const { data, loading } = useQuery<ActionContentSelectQuery>(
    gql`
      query ActionContentSelect {
        contentItems {
          __typename
          id
          status
          ... on VideoContentItem {
            title
          }
          ... on ReadingContentItem {
            title
          }
          ... on VideoWithReadingContentItem {
            title
          }
          ... on RecipeContentItem {
            title
          }
        }
      }
    `,
    {
      fetchPolicy: 'cache-first',
      onCompleted: () => onContentItemsLoaded(),
    },
  );

  const options = useMemo(
    () =>
      data?.contentItems?.reduce<
        { label: string; value: string; isDisabled: boolean }[]
      >((acc, curr) => {
        if (curr.__typename === 'FaqContentItem') {
          return acc;
        }

        let contentType: string = curr.__typename ?? '';
        if (curr.__typename === 'ReadingContentItem') {
          contentType = 'Reading';
        } else if (curr.__typename === 'RecipeContentItem') {
          contentType = 'Recipe';
        } else if (curr.__typename === 'VideoContentItem') {
          contentType = 'Video';
        } else if (curr.__typename === 'VideoWithReadingContentItem') {
          contentType = 'Video with reading';
        }

        acc.push({
          label: `${curr.title} (${contentType})`,
          value: curr.id,
          isDisabled: curr.status !== 'PUBLISHED',
        });

        return acc;
      }, []) ?? [],
    [data],
  );

  const contentIdToContentItemStatus: Record<string, ContentItemStatus> =
    useMemo(() => {
      if (!data?.contentItems) {
        return {};
      }
      return data.contentItems.reduce((acc, curr) => {
        return { ...acc, [curr.id]: curr.status };
      }, {});
    }, [data]);

  return (
    <div className="flex flex-col gap-4">
      <Label htmlFor={name}>{label}</Label>
      <Controller
        name={name}
        control={control}
        defaultValue={contentItemId}
        rules={{
          ...requiredValidation('name'),
          validate(v) {
            return contentIdToContentItemStatus[v] === 'PUBLISHED';
          },
        }}
        render={({ value, onChange }) => (
          <Select
            className="dropdown"
            classNamePrefix="dd"
            value={options.find((o) => o.value === value)}
            isLoading={loading}
            isSearchable
            name={name}
            options={options}
            onChange={(option) => onChange(option?.value)}
          />
        )}
      />
    </div>
  );
};

export const ActionCard = ({
  index,
  formId,
  action,
  formValues,
  register,
  errors,
  provided,
  onActionDelete,
  control,
  actionIdDeleteLoading,
  onContentItemsLoaded,
}: ActionCardProps): React.ReactElement => (
  <div
    ref={provided.innerRef}
    {...provided.draggableProps}
    key={action.id}
    className="bg-gray-200 rounded border border-gray-400 p-4 mt-5 flex flex-col space-y-2"
  >
    <div className="flex justify-between">
      <p className="text-lg text-gray-900 font-semibold">#{index + 1}</p>
      <div className="flex gap-2">
        <CopyToClipboardButton value={action.id} variant="svg-icon" />
        <button
          disabled={!!actionIdDeleteLoading}
          onClick={onActionDelete}
          className={clsx('inline-flex text-sm leading-5 font-medium')}
        >
          {actionIdDeleteLoading === action.id ? (
            <FaSpinner className="text-primary-500 animate-spin" />
          ) : (
            <FaRegTrashAlt className="text-primary-500" />
          )}
        </button>
        <div className="pt-0.5" {...provided.dragHandleProps}>
          <FaBars className="text-sm" />
        </div>
      </div>
    </div>
    <div className="flex flex-col space-y-4">
      <input
        name={`${formId}.id`}
        className="hidden"
        defaultValue={action?.id}
        ref={register()}
        readOnly
      />
      <Input
        label="ACTION*"
        name={`${formId}.name`}
        defaultValue={action?.name}
        ref={register({
          validate: {
            required: (v) => !!v || 'Please enter a name',
          },
        })}
        errorMessage={errors?.name?.message}
      />
      <div className="flex flex-col space-y-3">
        <Switch
          label="Link to resource"
          labelPosition="right"
          ref={register()}
          checked={formValues?.hasLinkToResource}
          value={`${formId}.hasLinkToResource`}
          name={`${formId}.hasLinkToResource`}
        />
        {!!formValues?.hasLinkToResource && (
          <div className="pb-2">
            <ContentSelect
              name={`${formId}.contentId`}
              control={control}
              label="Content ID"
              contentItemId={formValues.contentId}
              onContentItemsLoaded={onContentItemsLoaded}
            />
          </div>
        )}
        <Switch
          label="Repeat action"
          labelPosition="right"
          ref={register()}
          checked={formValues?.isRepeatAction}
          value={`${formId}.isRepeatAction`}
          name={`${formId}.isRepeatAction`}
        />
        {!!formValues?.isRepeatAction && (
          <div className="pb-2">
            <Input
              type="number"
              label="Number of times until complete"
              name={`${formId}.repeatCount`}
              defaultValue={action?.repeatCount}
              ref={register(maxValueValidation('repeats', 14))}
              errorMessage={errors?.repeatCount?.message}
            />
          </div>
        )}
        <Switch
          label="Request photo/video"
          labelPosition="right"
          ref={register()}
          checked={formValues?.canAttachMedia}
          value={`${formId}.canAttachMedia`}
          name={`${formId}.canAttachMedia`}
        />
        <div
          className={clsx('pb-2', {
            hidden: !formValues?.canAttachMedia,
          })}
        >
          <div className="rounded border border-gray-400 p-4 bg-white ">
            <RadioButton
              ref={register()}
              id="OPTIONAL"
              label="Optional (can upload after completing)"
              name={`${formId}.mediaRequirement`}
              defaultChecked={action?.mediaRequirement === 'OPTIONAL'}
            />
            <RadioButton
              ref={register()}
              id="MANDATORY"
              label="Mandatory (must upload to complete)"
              name={`${formId}.mediaRequirement`}
              defaultChecked={action?.mediaRequirement === 'MANDATORY'}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);
