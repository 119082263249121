import { gql, useQuery } from '@apollo/client';
import { Order, OrderOrderByInput, OrderWhereInput } from 'graphql/types';
import { Loading } from 'components/loading';
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useOrderBy,
  usePageIndex,
  usePaginatingSortingTable,
} from 'components/table';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { formatDateAndTime } from 'utils/misc';
import { routes } from 'utils/routes';
import { OrderFilter, useBuildOrderQueryFilter } from './order-filter';

const TABLE_PAGE_SIZE = 30;

const ordersQuery = gql`
  query Orders(
    $orderBy: [OrderOrderByInput!]!
    $where: OrderWhereInput
    $pageSize: Int
    $skip: Int
  ) {
    orders(orderBy: $orderBy, where: $where, take: $pageSize, skip: $skip) {
      id
      createdAt
      paymentStatus
      status
      statusReason
      fulfillment
      shipping {
        ... on ShopifyOrderShippingDetails {
          id
          trackingNumber
        }
      }
      stripeChargeId
      treatment {
        id
        product {
          id
          name
        }
      }
    }
    ordersCount(where: $where)
  }
`;

type OrderRowData = {
  id: string;
  createdAt: Date;
  status?: string;
  fulfillment?: string;
  paymentStatus?: string;
  product?: { id: string; name: string };
};

const Orders = (): React.ReactElement => {
  const history = useHistory();
  const orderBy = useOrderBy({ defaultValue: 'createdAt_desc' });
  const pageIndex = usePageIndex();
  const activeFilter = useBuildOrderQueryFilter();
  const { data, loading } = useQuery<
    { orders: Order[]; ordersCount: number },
    {
      orderBy: OrderOrderByInput[];
      where: OrderWhereInput;
      pageSize: number;
      skip: number;
    }
  >(ordersQuery, {
    variables: {
      orderBy: orderBy,
      where: activeFilter,
      pageSize: TABLE_PAGE_SIZE,
      skip: pageIndex * TABLE_PAGE_SIZE,
    },
  });
  const orderRowData = React.useMemo(
    () => processOrderData(data?.orders),
    [data],
  );

  const totalOrdersCount = data?.ordersCount ?? 0;
  const pageNumber = Math.ceil((totalOrdersCount ?? 1) / TABLE_PAGE_SIZE);

  const tableInstance = usePaginatingSortingTable({
    columns,
    data: orderRowData,
    pageNumber,
    orderBy,
    pageIndex,
  });

  return (
    <div>
      <OrderFilter />
      <Table tableInstance={tableInstance}>
        <>
          <TableHead />
          <TableBody>
            <>
              {tableInstance.page.map((row) => {
                tableInstance.prepareRow(row);
                return (
                  <TableRow row={row} key={row.id}>
                    <>
                      {row.cells.map((cell) => (
                        <TableCell
                          key={`${cell.row.original.id}-${cell.column.id}`}
                          cell={cell}
                          onClick={(): void => {
                            if (cell.column.id === 'selection') return;
                            history.push(
                              `${routes.orders}/${cell.row.original.id}`,
                            );
                          }}
                        />
                      ))}
                    </>
                  </TableRow>
                );
              })}
            </>
          </TableBody>
        </>
      </Table>
      {!orderRowData.length && !loading && (
        <div className="text-center font-medium pt-8">No orders found</div>
      )}
      {loading && (
        <div className="flex justify-center text-lg">
          <Loading />
        </div>
      )}
      <Pagination total={totalOrdersCount} tableInstance={tableInstance} />
    </div>
  );
};

const columns: Column<OrderRowData>[] = [
  {
    Header: 'Created',
    accessor: 'createdAt',
    Cell: (
      cell: CellProps<OrderRowData, OrderRowData['createdAt']>,
    ): React.ReactElement => <div>{formatDateAndTime(cell.value)}</div>,
    className: 'w-1/6',
  },
  {
    Header: 'ID',
    accessor: 'id',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-1/6',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-1/6',
  },
  {
    Header: 'Fulfillment',
    accessor: 'fulfillment',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-1/6',
  },
  {
    Header: 'Payment',
    accessor: 'paymentStatus',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-1/6',
  },
  {
    Header: 'Product',
    accessor: 'product',
    disableSortBy: true,
    Cell: (
      cell: CellProps<OrderRowData, OrderRowData['product']>,
    ): React.ReactElement => (
      <Link
        to={`${routes.products}/${cell.value?.id}`}
        onClickCapture={(x): void => x.stopPropagation()}
      >
        {cell.value?.name}
      </Link>
    ),
    className: 'w-1/6',
  },
];

const processOrderData = (data: Order[] = []): OrderRowData[] =>
  data.map(
    (order): OrderRowData => ({
      id: order.id,
      createdAt: new Date(order.createdAt),
      status: order.status || undefined,
      fulfillment: order.fulfillment || undefined,
      paymentStatus: order.paymentStatus || undefined,
      product: order.treatment
        ? { id: order.treatment.product.id, name: order.treatment.product.name }
        : undefined,
    }),
  );

export default Orders;
