import { gql } from '@apollo/client';
import {
  DiscountCodeAllowedCustomerType,
  DiscountStage,
  DiscountType,
  Maybe,
  UpsertDiscountCodeInput,
} from 'graphql/types';
import { DiscountCodeFormFields } from './main';

export const upsertDiscountCodeMutation = gql`
  mutation UpsertDiscountCode($input: UpsertDiscountCodeInput!) {
    upsertDiscountCode(input: $input) {
      discountCode {
        id
        name
        code
        type
        amount
        maxAllowedUses
        allowedCustomerType
        stages
        expiresAt
        startsAt
        products {
          id
          name
        }
      }
    }
  }
`;

export const mapGraphQLDiscountCodeToDiscountCodeFormFields = (discountCode: {
  allowedCustomerType?: Maybe<DiscountCodeAllowedCustomerType>;
  amount: number;
  code: string;
  expiresAt?: Maybe<string>;
  maxAllowedUses?: Maybe<number>;
  name: string;
  products: Array<{ id: string; name: string }>;
  stages: Array<DiscountStage>;
  startsAt?: Maybe<string>;
  type: DiscountType;
}): DiscountCodeFormFields => ({
  amount: discountCode.amount,
  code: discountCode.code,
  name: discountCode.name,
  type: discountCode.type,
  stages: discountCode.stages,
  products: discountCode.products?.map((product) => product.id),
  expiresAt: discountCode.expiresAt ?? undefined,
  startsAt: discountCode.startsAt ?? undefined,
  maxAllowedUses: `${discountCode.maxAllowedUses}`,
  allowedCustomerType: discountCode.allowedCustomerType ?? '',
});

export const mapDiscountCodeFormFieldsToGraphQLUpsertData = (
  fields: DiscountCodeFormFields,
): UpsertDiscountCodeInput => ({
  amount: Number(fields.amount),
  code: fields.code.toUpperCase(),
  name: fields.name,
  type: fields.type,
  stages: fields.stages || [],
  productIds: fields.products
    ? fields.products.map((productId) => productId)
    : [],
  expiresAt: fields.expiresAt ? new Date(fields.expiresAt).toISOString() : null,
  startsAt: fields.startsAt ? new Date(fields.startsAt).toISOString() : null,
  maxAllowedUses: fields.maxAllowedUses
    ? parseInt(fields.maxAllowedUses)
    : null,
  allowedCustomerType: fields.allowedCustomerType
    ? fields.allowedCustomerType
    : null,
});
