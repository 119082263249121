import React from 'react';
import clsx from 'clsx';
import { Label } from 'components/label';

interface Props {
  name: string;
  label?: string;
  labelPosition?: 'top' | 'right';
  value: string;
  checked?: boolean;
}

const BaseSwitch = (
  { name, label, labelPosition = 'right', value, checked = false }: Props,
  ref: React.Ref<HTMLInputElement>,
): React.ReactElement => (
  <div
    className={clsx('flex', {
      'flex-col items-start': labelPosition === 'top',
      'flex-row items-center': labelPosition === 'right',
    })}
  >
    {label && labelPosition === 'top' && (
      <div className="mb-3">
        <Label>{label}</Label>
      </div>
    )}
    <label
      htmlFor={value}
      className={clsx(
        'w-7 rounded-full focus-within:ring ring-slate-700 ring-opacity-50 cursor-pointer p-0.5',
        {
          'bg-red-600': !checked,
          'bg-green-700': checked,
        },
      )}
    >
      <input
        ref={ref}
        name={name}
        id={name}
        value={value}
        type="checkbox"
        className="visually-hidden"
      />
      <div
        className={clsx(
          'w-3 h-3 rounded-full bg-white transform transition-transform duration-200',
          {
            'translate-x-0': !checked,
            'translate-x-full': checked,
          },
        )}
      />
    </label>
    {label && labelPosition === 'right' && (
      <div className="ml-4 font-semibold text-xs">{label}</div>
    )}
  </div>
);

export const OverrideSwitch = React.forwardRef<HTMLInputElement, Props>(
  BaseSwitch,
);
