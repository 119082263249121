import { gql, useQuery } from '@apollo/client';
import { Loading } from 'components/loading';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'components/table';
import {
  ContentCollectionsTableQuery,
  ContentCollectionsTableQueryVariables,
} from 'graphql/types';
import { useHistory } from 'react-router-dom';
import { Column, useTable } from 'react-table';
import { buildRoute } from 'utils/routes';

type CollectionTableData = {
  id: string;
  name: string;
  description: string;
};

const columns: Column<CollectionTableData>[] = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
];

export const contentCollectionsTableQuery = gql`
  query ContentCollectionsTable {
    contentCollections {
      id
      name
      description
    }
  }
`;

export function ContentCollectionsTable(): JSX.Element {
  const history = useHistory();
  const { data, loading } = useQuery<
    ContentCollectionsTableQuery,
    ContentCollectionsTableQueryVariables
  >(contentCollectionsTableQuery);

  const tableItems =
    data?.contentCollections?.map(
      (item): CollectionTableData => ({
        id: item.id,
        name: item.name,
        description: item.description,
      }),
    ) ?? [];

  const tableInstance = useTable({
    columns,
    data: tableItems || [],
  });

  return loading ? (
    <Loading />
  ) : (
    <Table tableInstance={tableInstance}>
      <TableHead />
      <TableBody>
        {tableInstance.rows.map((row) => {
          tableInstance.prepareRow(row);
          return (
            <TableRow key={row.id} row={row}>
              {row.cells.map((cell) => (
                <TableCell
                  key={cell.row.original.id}
                  cell={cell}
                  onClick={(): void =>
                    history.push(
                      buildRoute.contentCollection(cell.row.original.id),
                    )
                  }
                />
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
