import React from 'react';
import { gql, useLazyQuery } from '@apollo/client';

import { Button } from 'components/button';
import {
  GetPackingBundleWithFilePasswordsQuery,
  GetPackingBundleWithFilePasswordsQueryVariables,
} from 'graphql/types';
import { DataField } from '../../components/data-field';
import { CopyToClipboardButton } from 'components/copy-to-clipboard-button';

type PackingBundle = NonNullable<
  GetPackingBundleWithFilePasswordsQuery['packingBundles']
>[number];

const bundlesWithFilePasswordsQuery = gql`
  query GetPackingBundleWithFilePasswords($input: PackingBundleFilterInput!) {
    packingBundles(input: $input) {
      id
      files {
        id
        name
        password
      }
    }
  }
`;

export function PackingBundleFiles({
  bundleId,
  files,
}: {
  bundleId: string;
  files: PackingBundle['files'];
}): React.ReactElement {
  const [getBundleFilesWithPasswords, { data, loading, error, called }] =
    useLazyQuery<
      GetPackingBundleWithFilePasswordsQuery,
      GetPackingBundleWithFilePasswordsQueryVariables
    >(bundlesWithFilePasswordsQuery, {
      // cache-first as we should always use the file passwords from the cache if they exist
      fetchPolicy: 'cache-first',
    });

  if (!files || files.length <= 0) {
    return (
      <div className="flex h-full p-2 text-center justify-center">
        No files in bundle.
      </div>
    );
  }

  if (!called || loading) {
    return (
      <div className="flex">
        <div className="w-1/2">
          {files.map((f) => (
            <DataField label="Name" key={f.name}>
              <span className="break-all">{f.name}</span>
            </DataField>
          ))}
        </div>
        <div className="w-1/2 h-full p-2">
          <Button
            fullWidth
            fillHeight
            loading={loading}
            onClick={(): void => {
              getBundleFilesWithPasswords({
                variables: { input: { ids: [bundleId] } },
              });
            }}
          >
            Click to reveal passwords
          </Button>
        </div>
      </div>
    );
  }

  const bundlesFilesWithPasswords =
    data?.packingBundles &&
    data.packingBundles[0] &&
    data.packingBundles[0].files;

  if (error || !bundlesFilesWithPasswords) {
    return (
      <div className="flex h-full p-2 text-center items-center">
        Error generating bundle passwords. Please try refreshing the page, if
        the issue persists then contact #help-engineering.
      </div>
    );
  }

  return (
    <div className="flex">
      <div className="w-1/2">
        {bundlesFilesWithPasswords.map((file) => {
          return (
            <DataField label="Name" key={file.id}>
              <span className="break-all">{file.name}</span>
            </DataField>
          );
        })}
      </div>
      <div className="w-1/2">
        {bundlesFilesWithPasswords.map((file) => {
          return (
            <DataField label="Password" key={file.id}>
              <span className="break-all">
                {file.password ?? ''}
                <CopyToClipboardButton value={file.password ?? ''} />
              </span>
            </DataField>
          );
        })}
      </div>
    </div>
  );
}
