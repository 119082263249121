import { gql, useMutation, useQuery } from '@apollo/client';
import { Input } from 'components/input';
import AddressInput from '../../components/address-input';
import { config } from 'config';
import {
  GenerateNewScriptModalQuery,
  GenerateNewScriptModalQueryVariables,
  GenerateScriptMutationVariables,
} from 'graphql/types';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  combineRules,
  dateValidation,
  maxDateValidation,
  minDateValidation,
  requiredValidation,
  positiveIntegerValidation,
} from 'utils/form-validation';
import { Switch } from 'components/switch';
import { useNotifications } from 'notifications';
import { Loading } from 'components/loading';
import { Button } from 'components/button';
import { Modal } from 'components/modal';

const generateScriptMutation = gql`
  mutation generateScript(
    $scriptId: String!
    $firstName: String!
    $lastName: String!
    $dob: String!
    $reg24Enabled: Boolean!
    $repeats: Int!
    $residentialAddress: ResidentialAddressCreateInput!
  ) {
    generateScript(
      scriptId: $scriptId
      firstName: $firstName
      lastName: $lastName
      dob: $dob
      reg24Enabled: $reg24Enabled
      repeats: $repeats
      residentialAddress: $residentialAddress
    )
  }
`;

const query = gql`
  query GenerateNewScriptModal($scriptId: String!) {
    script(where: { id: $scriptId }) {
      id
      dob
      repeats
      currentMedications
      allergies
      existingConditions
      isDirect
      reg24
      type
      createdAt
      patient {
        id
        legalFirstName
        lastName
        fullName
        email
        phone
        address {
          id
          line1
          line2
          city
          postalCode
          state
          country
        }
        residentialAddress {
          id
          line1
          line2
          city
          postalCode
          state
          country
          company
          building
        }
      }
    }
  }
`;

export const GenerateNewScriptModal = ({
  scriptId,
  show,
  onClose,
  onConfirm,
}: {
  show: boolean;
  scriptId: string;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const [generateScript, { loading: loadingGenerateScript }] =
    useMutation<GenerateScriptMutationVariables>(generateScriptMutation);

  const {
    data: scriptData,
    loading: loadingScriptData,
    refetch: refetchScriptData,
  } = useQuery<
    GenerateNewScriptModalQuery,
    GenerateNewScriptModalQueryVariables
  >(query, {
    variables: {
      scriptId,
    },
  });

  const { register, watch, handleSubmit, errors, reset, control } = useForm<{
    firstName: string;
    lastName: string;
    line1: string;
    line2: string;
    city: string;
    postalCode: string;
    state: string;
    country: string;
    email: string;
    phone: string;
    dob: string;
    repeats: string;
    currentMedications: string;
    allergies: string;
    existingConditions: string;
    isDirect: boolean;
    reg24: boolean;
  }>();

  const {
    legalFirstName,
    lastName,
    email,
    phone,
    address,
    residentialAddress,
  } = scriptData?.script?.patient ?? {};

  const { line1, line2, city, postalCode, state, country } =
    residentialAddress ?? address ?? {};

  const {
    dob,
    repeats,
    currentMedications,
    allergies,
    existingConditions,
    isDirect,
    reg24,
  } = scriptData?.script ?? {};

  useEffect(() => {
    reset({
      firstName: legalFirstName ?? '',
      lastName: lastName ?? '',
      line1: line1 ?? '',
      line2: line2 ?? '',
      city: city ?? '',
      postalCode: postalCode ?? '',
      state: state ?? '',
      country: country ?? config.country,
      email: email ?? '',
      phone: phone ?? '',
      dob: dob ?? '',
      repeats: repeats?.toString() ?? '0',
      currentMedications: currentMedications ?? '',
      allergies: allergies ?? '',
      existingConditions: existingConditions ?? '',
      isDirect: isDirect ?? false,
      reg24: reg24 ?? false,
    });
  }, [
    reset,
    legalFirstName,
    lastName,
    address,
    email,
    phone,
    line1,
    line2,
    city,
    postalCode,
    state,
    country,
    dob,
    repeats,
    currentMedications,
    allergies,
    existingConditions,
    isDirect,
    reg24,
  ]);

  const showNotification = useNotifications();

  const handleTriggerGenerateScript = handleSubmit(async (formData) => {
    const updateData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      dob: formData.dob,
      repeats: parseInt(formData.repeats),
      residentialAddress: {
        line1: formData.line1 ?? undefined,
        line2: formData.line2 || undefined,
        city: formData.city ?? undefined,
        postalCode: formData.postalCode ?? undefined,
        state: formData.state ?? undefined,
        country: formData.country ?? undefined,
      },
      reg24Enabled: !!formData.reg24,
    };

    try {
      await generateScript({
        variables: {
          scriptId,
          ...updateData,
        },
      });

      refetchScriptData();

      showNotification({
        type: 'success',
        message: 'Script generated',
      });

      onConfirm();
    } catch (error) {
      showNotification({
        type: 'error',
        message: `Unable to generate script: ${
          error instanceof Error ? error.message : 'no error message'
        }`,
      });
    }
  });

  if (loadingScriptData) {
    return (
      <div className="flex justify-center text-lg">
        <Loading />
      </div>
    );
  }

  if (scriptData?.script?.type === 'ESCRIPT') {
    return null;
  }

  const gridColumnStyles = 'grid grid-cols-2 gap-x-5';

  return (
    <Modal show={show} onClose={onClose}>
      <div className="bg-white rounded p-4">
        <form onSubmit={handleTriggerGenerateScript}>
          <div className={gridColumnStyles}>
            <div className="space-y-5">
              <h2 className="heading-md">Patient details</h2>
              <div className={gridColumnStyles}>
                <Input
                  label="First name"
                  name="firstName"
                  ref={register(requiredValidation('First name'))}
                  errorMessage={errors?.firstName?.message}
                />
                <Input
                  label="Last name"
                  name="lastName"
                  ref={register(requiredValidation('Last name'))}
                  errorMessage={errors?.lastName?.message}
                />
              </div>
              <div className={gridColumnStyles}>
                <Input
                  label="Email"
                  name="email"
                  ref={register}
                  disabled
                  errorMessage={errors?.email?.message}
                />
                <Input label="Phone" ref={register} name="phone" />
              </div>
              <AddressInput
                registerField={register}
                errors={errors}
                control={control}
              />
            </div>
            <div>
              <div className="border-l-2 pl-5 space-y-5">
                <h2 className="heading-md">Script Details</h2>
                <div className={gridColumnStyles}>
                  <Input
                    label="DOB"
                    ref={register(
                      combineRules(
                        requiredValidation('dob'),
                        dateValidation(),
                        maxDateValidation(new Date()),
                        minDateValidation(new Date('1900-01-01')),
                      ),
                    )}
                    errorMessage={errors?.dob?.message}
                    name="dob"
                  />
                  <Input
                    label="Repeats"
                    type="text"
                    ref={register({
                      ...requiredValidation('Repeats'),
                      ...positiveIntegerValidation('repeats'),
                    })}
                    errorMessage={errors?.repeats?.message}
                    name="repeats"
                  />
                </div>
                <Input
                  label="Current medications"
                  ref={register}
                  name="currentMedications"
                  disabled={true}
                />
                <Input
                  label="Allergies"
                  ref={register}
                  name="allergies"
                  disabled={true}
                />
                <Input
                  label="Existing conditions"
                  ref={register}
                  name="existingConditions"
                  disabled={true}
                />
                <div className={gridColumnStyles}>
                  <Switch
                    label="Is direct"
                    labelPosition="top"
                    ref={register}
                    checked={isDirect ?? false}
                    value="isDirect"
                    name="isDirect"
                    // can't update isDirect
                    disabled
                  />
                  <Switch
                    label="Reg 24"
                    labelPosition="top"
                    ref={register}
                    checked={!!watch().reg24}
                    value="reg24"
                    name="reg24"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pt-4 flex space-x-4">
            <Button
              fullWidth
              variant="outline"
              onClick={onClose}
              disabled={loadingGenerateScript}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              loading={loadingGenerateScript}
              color="danger"
              type="submit"
            >
              Generate
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
