import { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

/* returns a durable id for use as an idempotency key */
export const useId = (
  initialId?: string,
): [
  string,
  {
    setId: (newId: string) => void;
    refreshId: () => void;
  },
] => {
  const id = useRef(initialId || uuidv4());

  const setId = (newId: string): void => {
    id.current = newId;
  };

  const refreshId = (): void => {
    id.current = uuidv4();
  };

  return [id.current, { setId, refreshId }];
};
