import {
  Control,
  DeepMap,
  FieldError,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useCallback } from 'react';
import { ReorderLevelTreeActionTemplateInput } from 'graphql/types';
import { Input } from 'components/input';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { Accordion } from 'components/accordion';
import { Label } from 'components/label';
import { TextArea } from 'components/text-area';
import { ActionCard, ActionForm } from './action-card';
import { Button } from 'components/button';
import { useNotifications } from 'notifications';

export type VariantForm = {
  id: string;
  name: string;
  description: string;
  useCase: string;
  actions: ActionForm[];
};

interface VariantCardProps {
  index: number;
  variant: VariantForm;
  formValues: VariantForm;
  control: Control<{
    defaultGoal: {
      name: string;
      description: string;
      actions: ActionForm[];
    };
    variants: VariantForm[];
  }>;
  goalDescriptionCharacterLimit: number;
  goalNameCharacterLimit: number;
  register: ReturnType<typeof useForm>['register'];
  errors?: DeepMap<VariantForm, FieldError>;
  onCreateAction: (goalId: string) => Promise<{ actionId?: string }>;
  onDeleteVariant: () => void;
  onDeleteAction: (actionId: string, onCompleted: () => void) => Promise<void>;
  onReorderActions: (
    actions: ReorderLevelTreeActionTemplateInput[],
    onError: () => void,
  ) => Promise<void>;
  actionIdDeleteLoading: string | null;
  onContentItemsLoaded: () => void;
}

export const VariantGoalCard = ({
  index,
  variant,
  formValues,
  register,
  control,
  errors,
  goalDescriptionCharacterLimit,
  goalNameCharacterLimit,
  onCreateAction,
  onDeleteVariant,
  onDeleteAction,
  onReorderActions,
  actionIdDeleteLoading,
  onContentItemsLoaded,
}: VariantCardProps): React.ReactElement => {
  const showNotification = useNotifications();
  const {
    fields: actions,
    append: appendAction,
    remove: removeAction,
    move: moveAction,
  } = useFieldArray({
    control,
    name: `variants[${index}].actions`,
  });

  const handleOnDragEnd = useCallback(
    async ({ source, destination }: DropResult): Promise<void> => {
      const movedActionId = actions[source.index].id;
      if (
        !movedActionId ||
        !destination ||
        destination.index === source.index
      ) {
        return;
      }

      const actionIds = actions
        .map((a) => a.id)
        .filter((id): id is string => id !== movedActionId && !!id);

      actionIds.splice(destination.index, 0, movedActionId);

      moveAction(source.index, destination.index);

      await onReorderActions(
        actionIds.map((id, i) => ({
          levelTreeActionTemplateId: id,
          position: i,
        })),
        () => moveAction(destination.index, source.index),
      );
    },
    [actions, onReorderActions, moveAction],
  );

  const handleCreateAction = async () => {
    const resp = await onCreateAction(variant.id);
    appendAction({
      id: resp.actionId,
      name: '',
      canAttachMedia: false,
      mediaRequirement: 'NONE',
      repeatCount: undefined,
      isRepeatAction: false,
      contentId: '',
      hasLinkToResource: false,
    });
  };

  return (
    <div className="bg-white">
      <Accordion title={variant.useCase}>
        <hr className="border-t border-gray-400" />
        <div className="bg-white p-4 flex flex-col gap-4">
          <input
            name={`variants[${index}].id`}
            className="hidden"
            defaultValue={variant.id}
            ref={register()}
            readOnly
          />
          <Input
            label="Use case*"
            name={`variants[${index}].useCase`}
            description="Internal use only"
            ref={register({
              validate: {
                trailingWhitespace: (v) =>
                  v.trim() === v || 'Cannot have trailing whitespace',
                required: (v) => !!v || 'Please enter a useCase',
              },
            })}
            defaultValue={variant.useCase}
            errorMessage={errors?.useCase?.message}
            maxLength={goalNameCharacterLimit}
          />
          <Input
            label="Goal name*"
            name={`variants[${index}].name`}
            description="Visible to patients"
            ref={register({
              validate: {
                trailingWhitespace: (v) =>
                  v.trim() === v || 'Cannot have trailing whitespace',
                required: (v) => !!v || 'Please enter a name',
              },
            })}
            defaultValue={variant.name}
            hint={`${
              goalNameCharacterLimit - formValues?.name.length
            } characters remaining`}
            errorMessage={errors?.name?.message}
            maxLength={goalNameCharacterLimit}
          />
          <div>
            <div className="mb-1">
              <Label htmlFor="description">Description*</Label>
            </div>
            <div className="flex flex-col gap-2">
              <p className="text-sm text-gray-700">Visible to patients</p>
              <p className="text-sm text-gray-700">
                A short summary of this goal and how achieving it will benefit
                the patient.
              </p>
            </div>
            <TextArea
              name={`variants[${index}].description`}
              ref={register({
                validate: {
                  trailingWhitespace: (v) =>
                    v.trim() === v || 'Cannot have trailing whitespace',
                },
              })}
              defaultValue={variant.description}
              placeholder=""
              rows={6}
              hint={`${
                goalDescriptionCharacterLimit - formValues?.description.length
              } characters remaining`}
              errorMessage={errors?.description?.message}
              maxLength={goalDescriptionCharacterLimit}
            />
          </div>
          <hr className="border-t -mb-5 border-gray-400" />
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="variantActions">
              {(provided): JSX.Element => (
                <div
                  className="flex flex-col"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {variant.actions
                    .filter((v): v is ActionForm => !!v.id)
                    .map((action, actionIndex) => (
                      <Draggable
                        key={action.id}
                        draggableId={action.id}
                        index={actionIndex}
                      >
                        {(provided): JSX.Element => (
                          <ActionCard
                            index={actionIndex}
                            formId={`variants[${index}].actions[${actionIndex}]`}
                            action={action}
                            provided={provided}
                            register={register}
                            formValues={formValues?.actions?.[actionIndex]}
                            errors={errors?.actions?.[actionIndex]}
                            onActionDelete={async () => {
                              await onDeleteAction(action.id, () => {
                                removeAction(actionIndex);
                                showNotification({
                                  type: 'success',
                                  message: 'Action deleted successfully',
                                });
                              });
                            }}
                            control={control}
                            actionIdDeleteLoading={actionIdDeleteLoading}
                            onContentItemsLoaded={onContentItemsLoaded}
                          />
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div>
            <Button onClick={handleCreateAction} size="small" variant="outline">
              Add action
            </Button>
          </div>
          <div>
            <Button onClick={onDeleteVariant} size="small" variant="outline">
              Delete variant
            </Button>
          </div>
        </div>
      </Accordion>
    </div>
  );
};
