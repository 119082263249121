import { gql, useMutation, useQuery } from '@apollo/client';
import { Button } from 'components/button';
import type {
  ConfirmStepAllDoctorsQuery,
  ConfirmStepAllDoctorsQueryVariables,
  ProblemType,
  RequestTreatmentReviewAndAddPractitionerBookingMutation,
  RequestTreatmentReviewAndAddPractitionerBookingMutationVariables,
  RequestTreatmentReviewAndAssignToDoctorMutation,
  RequestTreatmentReviewAndAssignToDoctorMutationVariables,
  RequestTreatmentReviewMutation,
  RequestTreatmentReviewMutationVariables,
  AddAdminNotesMutation,
  AddAdminNotesMutationVariables,
  ReviewReason,
} from 'graphql/types';
import {
  requestTreatmentReviewAndAddPractitionerBookingDocument,
  requestTreatmentReviewAndAssignToDoctorDocument,
  requestTreatmentReviewDocument,
} from 'pages/customer/treatmentActions/graphql-documents';
import React from 'react';
import type { RequestReviewStepTransitionProps } from './types';
import type { AssignOption } from './assign-option-step';
import type { BookingWindow } from './booking-window-assign-step';
import { formatWindowDay, formatWindowInterval } from 'utils/queues';
import { reasonOptions } from 'utils/dropdown-options';

type ConfirmStepProps = RequestReviewStepTransitionProps & {
  skipQuiz: boolean;
  adminNotes?: string | null;
  assignOption?: AssignOption;
  doctorId?: string;
  bookingWindow?: BookingWindow;
  customerId: string;
  problemType: ProblemType;
  treatmentId: string;
  notes?: string | null;
  onError?: () => void;
  reason: ReviewReason | undefined;
};

export const ConfirmStep: React.FC<ConfirmStepProps> = ({
  previousStep,
  nextStep: onCompleted,
  skipQuiz,
  adminNotes,
  assignOption,
  doctorId,
  bookingWindow,
  reason,
  treatmentId,
  onError,
}) => {
  const { data } = useQuery<
    ConfirmStepAllDoctorsQuery,
    ConfirmStepAllDoctorsQueryVariables
  >(
    gql`
      query ConfirmStepAllDoctors {
        allDoctors {
          id
          clinicianName
        }
      }
    `,
    {
      skip: !doctorId,
      fetchPolicy: 'cache-first',
    },
  );

  const [addAdminNotes] = useMutation<
    AddAdminNotesMutation,
    AddAdminNotesMutationVariables
  >(
    gql`
      mutation AddAdminNotes($consultationId: String!, $notes: String!) {
        updateOneConsultation(
          where: { id: $consultationId }
          data: { adminNotes: { set: $notes } }
        ) {
          id
        }
      }
    `,
    {
      onCompleted,
      onError,
    },
  );

  const clinicianName =
    data?.allDoctors.find(({ id }) => id === doctorId)?.clinicianName ?? '-';
  const bookingWindowName = bookingWindow
    ? `${formatWindowDay(bookingWindow)} ${formatWindowInterval(bookingWindow)}`
    : '-';

  const [requestTreatmentReview, { loading: requestTreatmentReviewLoading }] =
    useMutation<
      RequestTreatmentReviewMutation,
      RequestTreatmentReviewMutationVariables
    >(requestTreatmentReviewDocument, {
      onCompleted,
    });

  const [
    requestTreatmentReviewAndAssignToDoctor,
    { loading: requestTreatmentReviewAndAssignToDoctorLoading },
  ] = useMutation<
    RequestTreatmentReviewAndAssignToDoctorMutation,
    RequestTreatmentReviewAndAssignToDoctorMutationVariables
  >(requestTreatmentReviewAndAssignToDoctorDocument, {
    onCompleted,
  });

  const [
    requestTreatmentReviewAndAddPractitionerBooking,
    { loading: requestTreatmentReviewAndAddPractitionerBookingLoading },
  ] = useMutation<
    RequestTreatmentReviewAndAddPractitionerBookingMutation,
    RequestTreatmentReviewAndAddPractitionerBookingMutationVariables
  >(requestTreatmentReviewAndAddPractitionerBookingDocument, { onCompleted });

  const onConfirm = async (): Promise<void> => {
    let updatedTreatment;

    if (!reason) {
      throw new Error('reason is required');
    }
    if (skipQuiz && assignOption === 'queue' && bookingWindow) {
      const result = await requestTreatmentReviewAndAddPractitionerBooking({
        variables: {
          input: {
            treatmentId,
            windowId: bookingWindow.id,
            reason,
          },
        },
      });
      if (result.data?.requestTreatmentReviewAndAddPractitionerBooking)
        updatedTreatment =
          result.data.requestTreatmentReviewAndAddPractitionerBooking.treatment;
    } else if (skipQuiz && doctorId) {
      const result = await requestTreatmentReviewAndAssignToDoctor({
        variables: {
          input: { treatmentId, doctorId, reason },
        },
      });
      if (result.data?.requestTreatmentReviewAndAssignToDoctor) {
        updatedTreatment = result.data.requestTreatmentReviewAndAssignToDoctor;
      }
    } else {
      const result = await requestTreatmentReview({
        variables: {
          input: { treatmentId, reason, skipQuiz: false },
        },
      });
      if (result.data?.requestTreatmentReview) {
        updatedTreatment = result.data.requestTreatmentReview;
      }
    }

    if (adminNotes) {
      if (!updatedTreatment?.consultation?.id) {
        throw new Error(
          `expected consultation id to be returned for updated treatment ${updatedTreatment?.id}`,
        );
      }
      addAdminNotes({
        variables: {
          notes: adminNotes,
          consultationId: updatedTreatment.consultation.id,
        },
      });
    }
  };

  return (
    <div className="w-full space-y-4">
      <h3 className="heading-md mb-2">Confirm review consult</h3>
      <ul className="space-y-4">
        <li>
          <strong>Can skip quiz?:</strong> {skipQuiz ? 'Yes' : 'No'}
        </li>
        <li>
          <strong>Review reason:</strong>{' '}
          {reasonOptions.find((o) => o.value === reason)?.label}
        </li>
        <li>
          <strong>Has admin notes:</strong> {adminNotes ? 'Yes' : 'No'}
        </li>
        <li>
          <strong>Practitioner assigning:</strong>{' '}
          {assignOption === 'queue'
            ? `Sync consult queue (Window: ${bookingWindowName})`
            : `Assigned directly to ${clinicianName}`}
        </li>
      </ul>
      <div className="w-full flex flex-row justify-end gap-4 mt-4">
        <div>
          <Button
            fullWidth
            variant="outline"
            type="button"
            onClick={previousStep}
          >
            Back
          </Button>
        </div>
        <div>
          <Button
            fullWidth
            loading={
              requestTreatmentReviewLoading ||
              requestTreatmentReviewAndAssignToDoctorLoading ||
              requestTreatmentReviewAndAddPractitionerBookingLoading
            }
            type="button"
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};
