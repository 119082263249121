import { Card } from 'components/card';
import { Label } from 'components/label';
import { EducationReading } from 'graphql/types';
import { VFC } from 'react';
import { useForm } from 'react-hook-form';
import { Input } from 'components/input';
import { getAppReadingUrl } from './utils';

type ReadingValues = {
  id: string;
  name: string;
  contentfulId: string;
};

type ReadingProps = {
  reading: EducationReading;
};

export const ReadingForm: VFC<ReadingProps> = ({ reading }) => {
  const { register } = useForm<ReadingValues>({ defaultValues: reading });

  const appReadingLink = getAppReadingUrl(reading.id);

  return (
    <Card className="p-5">
      <div className="flex flex-col space-y-6">
        <div>
          <Input ref={register} label="Reading ID" name="id" disabled />
        </div>
        <div>
          <Input ref={register} label="Reading name" name="name" disabled />
        </div>
        <div>
          <Input
            ref={register}
            label="Contentful ID"
            name="contentfulId"
            disabled
          />
        </div>
        <div className="flex flex-col space-y-2">
          <Label>Link to article</Label>
          <a
            href={appReadingLink}
            className="text-sm underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          >
            {appReadingLink}
          </a>
        </div>
      </div>
    </Card>
  );
};
