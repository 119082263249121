import { PiCaretCircleDown, PiCaretCircleUp } from 'react-icons/pi';

export const ExpandToggle: React.FC<{
  isOpen: boolean;
  onClick: () => void;
}> = ({ isOpen, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="w-full flex items-center justify-center"
    >
      {isOpen ? <PiCaretCircleUp /> : <PiCaretCircleDown />}
    </button>
  );
};
