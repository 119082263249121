import { Dropdown, Option } from 'components/dropdown';
import { ControlledFileUpload } from 'components/file-upload';
import { Input } from 'components/input';
import React from 'react';
import { Control, useForm } from 'react-hook-form';
import { requiredValidation } from 'utils/form-validation';

const labOptions: Option[] = [
  {
    label: 'ACL',
    value: 'ACL',
  },
  {
    label: 'Healius',
    value: 'Healius',
  },
  {
    label: 'Sonic',
    value: 'Sonic',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

const PathologyLab = ({
  control,
  labDisabled,
  errors,
  register,
}: {
  control: Control;
  labDisabled: boolean;
  errors: ReturnType<typeof useForm>['errors'];
  register: ReturnType<typeof useForm>['register'];
}): React.ReactElement => {
  return (
    <section className="space-y-5">
      <h2 className="heading-md mb-3">Lab Details</h2>
      <div className="w-1/2">
        <Dropdown
          name="lab"
          disabled={labDisabled}
          control={control}
          options={labOptions}
          label="🔬 Lab"
          rules={requiredValidation('lab')}
          errorMessage={errors?.lab?.message}
        />
      </div>
      <div className="w-1/2">
        <ControlledFileUpload
          name="resultsUrl"
          control={control}
          type="pdf"
          label="📎 Attach file"
          rules={requiredValidation('file')}
          errorMessage={errors?.resultsUrl?.message}
          bucket="PATHOLOGY"
        />
      </div>
      <div className="w-1/2">
        <Input
          name="collectedDate"
          type="date"
          ref={register(requiredValidation('Collected At'))}
          label="Collected At"
          errorMessage={errors?.collectedDate?.message}
        />
      </div>
    </section>
  );
};

export default PathologyLab;
