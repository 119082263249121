import { gql, useQuery } from '@apollo/client';
import { SubscriptionPlanV2 } from 'graphql/types';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { Button } from 'components/button';
import { Loading } from 'components/loading';
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  usePageIndex,
  usePaginatingSortingTable,
} from 'components/table';
import { ReactElement, useCallback, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CellProps, Column, TableInstance } from 'react-table';
import { formatCurrencyAmountCents } from 'utils/misc';
import { routes } from 'utils/routes';

const TABLE_PAGE_SIZE = 30;

const subscriptionPlansListQuery = gql`
  query SubscriptionPlansListQuery {
    subscriptionPlansV2 {
      id
      price
      name
      photo {
        id
        url
      }
      interval
      intervalCount
    }
  }
`;

const SubscriptionPlans = (): ReactElement => {
  const history = useHistory();
  const pageIndex = usePageIndex();

  const { data, loading } = useQuery<{
    subscriptionPlansV2: SubscriptionPlanV2[];
  }>(subscriptionPlansListQuery);
  const total = data?.subscriptionPlansV2.length || 0;
  const subscriptionPlansV2 = useMemo(
    () => data?.subscriptionPlansV2 || [],
    [data],
  );

  const tableInstance = usePaginatingSortingTable({
    columns,
    data: subscriptionPlansV2.slice(
      pageIndex * TABLE_PAGE_SIZE,
      (pageIndex + 1) * TABLE_PAGE_SIZE,
    ),
    pageNumber: Math.ceil(total / TABLE_PAGE_SIZE),
    pageIndex,
    disableSortBy: true,
  });

  const handleCellClick = useCallback(
    (subscriptionPlanId: string) => {
      history.push(`${routes.subscriptionPlans}/${subscriptionPlanId}`);
    },
    [history],
  );

  return (
    <SubscriptionPlansRenderer
      total={total}
      loading={loading}
      tableInstance={tableInstance}
      onCellClick={handleCellClick}
    />
  );
};

interface PlansRendererProps {
  loading: boolean;
  tableInstance: TableInstance<SubscriptionPlanV2>;
  onCellClick: (subscriptionPlanId: string) => void;
  total: number | string;
}

const SubscriptionPlansRenderer = ({
  loading,
  tableInstance,
  onCellClick,
  total,
}: PlansRendererProps): ReactElement => {
  const featureFlagClient = useFeatureFlagClient();
  const enableAdminSubscriptionsV2 = featureFlagClient.getBoolean(
    'enable-admin-subscriptions-v2',
  );

  if (loading) {
    return (
      <div className="flex justify-center text-lg">
        <Loading />
      </div>
    );
  }

  if (!enableAdminSubscriptionsV2) {
    return (
      <div>
        <p>This feature has not been released yet.</p>
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-end mb-8">
        <Link to={routes.createSubscriptionPlan}>
          <Button fullWidth variant="outline">
            Create New
          </Button>
        </Link>
      </div>
      <Table tableInstance={tableInstance}>
        <TableHead />
        <TableBody>
          {tableInstance.page.map(
            (row) => (
              tableInstance.prepareRow(row),
              (
                <TableRow row={row} key={row.id}>
                  {row.cells.map((cell) => (
                    <TableCell
                      key={`${cell.row.original.id}-${cell.column.id}`}
                      cell={cell}
                      onClick={(): void => {
                        if (cell.column.id !== 'selection') {
                          onCellClick(cell.row.original.id);
                        }
                      }}
                    />
                  ))}
                </TableRow>
              )
            ),
          )}
        </TableBody>
      </Table>
      <Pagination total={total} tableInstance={tableInstance} />
    </>
  );
};

const AmountCell = (
  cell: CellProps<SubscriptionPlanV2, number>,
): ReactElement => <div>{formatCurrencyAmountCents(cell.value)}</div>;

const columns: Column<SubscriptionPlanV2>[] = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-1/5',
  },
  {
    Header: 'Amount',
    accessor: 'price',
    Cell: AmountCell,
    className: 'w-1/5',
  },
  {
    Header: 'Interval',
    accessor: 'interval',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-1/5',
  },
  {
    Header: 'Interval Count',
    accessor: 'intervalCount',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-1/5',
  },
];

export default SubscriptionPlans;
