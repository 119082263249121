import React, { ReactElement } from 'react';
import type { GoalFeedbackType, WeightMilestone } from 'graphql/types';
import clsx from 'clsx';
import { FaFlag } from 'react-icons/fa';
import { formatDate } from '../../utils/misc';
import { Tooltip } from '../../components/tooltip';

type DeltaSentiment = 'NEGATIVE' | 'NEUTRAL' | 'POSITIVE';

type ProgressDelta = {
  id: string;
  deltaSentiment?: DeltaSentiment | null;
  difference?: string | null;
  initial?: string | null;
  mostRecent?: string | null;
};

type MeasurementsProps = {
  bmi?: ProgressDelta | null;
  waist?: ProgressDelta | null;
  weight?: ProgressDelta | null;
  initialDateString?: string | null;
  mostRecentDateString?: string | null;
  currentWeightKg?: number | null;
  goalFeedbackType?: GoalFeedbackType | null;
  goalWeightKg?: number | null;
  goalWeightBmi?: number | null;
  initialWeightKg?: number | null;
  milestones?: WeightMilestone[] | null;
};

const SentimentToColorClass: Record<DeltaSentiment, string> = {
  POSITIVE: 'text-green-500',
  NEUTRAL: 'text-yellow-500',
  NEGATIVE: 'text-red-500',
};

export const WeightProgressOverview = ({
  bmi,
  weight,
  waist,
  initialDateString,
  mostRecentDateString,
  currentWeightKg,
  goalFeedbackType,
  goalWeightKg,
  goalWeightBmi,
  initialWeightKg,
  milestones,
}: MeasurementsProps): ReactElement | null => {
  if (!bmi && !weight && !waist) {
    return null;
  }

  const goalWeightDifferenceKg =
    currentWeightKg && goalWeightKg
      ? Number.parseFloat((currentWeightKg - goalWeightKg).toFixed(1))
      : null;
  const isCurrentWeightMoreThanStartingWeight =
    currentWeightKg && initialWeightKg
      ? currentWeightKg > initialWeightKg
      : false;
  const isInitialGoalWeightAggressive =
    goalFeedbackType === 'LOSS_MORE_THAN_52KG_IN_12_MONTHS';
  const isReached = goalWeightDifferenceKg && goalWeightDifferenceKg <= 0;
  const milestonesComplete = milestones?.filter((m) => m.status === 'COMPLETE')
    .length;
  const milestonesTotal = milestones?.length;
  const milestoneString = `${milestonesComplete} / ${milestonesTotal}`;

  return (
    <>
      <div className="grid grid-cols-12">
        <div className="flex flex-col col-span-5 space-y-1 striped">
          <div className="text-sm">&nbsp;</div>
          <div className="text-sm">
            Started {initialDateString && formatDate(initialDateString)}
          </div>
          <div className="text-sm">
            As of {mostRecentDateString && formatDate(mostRecentDateString)}
          </div>
          <div className="text-sm">Difference</div>
        </div>
        <div className="col-span-7">
          <div className="grid grid-cols-3 justify-between">
            {[
              {
                title: 'Weight',
                delta: weight,
              },
              {
                title: 'BMI',
                delta: bmi,
              },
              {
                title: 'Waist',
                delta: waist,
              },
            ].map(({ title, delta }) => (
              <div
                key={delta?.id}
                className="col-span-1 space-y-1 striped text-right"
              >
                <div className="font-semibold text-sm">{title}</div>
                <div className="text-slate-700 text-sm">
                  {delta?.initial ?? '-'}
                </div>
                <div className="text-sm">{delta?.mostRecent ?? '-'}</div>
                <div
                  className={clsx(
                    delta?.difference &&
                      SentimentToColorClass[delta?.deltaSentiment ?? 'NEUTRAL'],
                    'font-semibold',
                    'text-sm',
                  )}
                >
                  {delta?.difference ?? '-'}
                </div>
              </div>
            ))}
          </div>
        </div>
        {goalWeightKg ? (
          <>
            <div className="flex flex-col col-span-5 space-y-1 pt-6 striped">
              <div className="text-sm">
                <div className="flex gap-1 items-baseline">
                  Goal weight
                  {isInitialGoalWeightAggressive && (
                    <div className="flex">
                      <a data-tip data-for="goalWeightAggressiveTooltip">
                        <FaFlag
                          className="inline align-top text-sm"
                          fill="#F56565"
                        />
                      </a>
                      <Tooltip
                        hoverText={
                          '**Patient may need extra support**\n' +
                          'Achieving their goal weight would require them to\n' +
                          'exceed a weight loss pace of 1kg per week.'
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="text-sm">Difference to GW</div>
              <div className="text-sm">Milestones completed</div>
            </div>
            <div className="col-span-7 pt-6">
              <div className="grid grid-cols-3 justify-between">
                {[
                  {
                    delta: {
                      id: 'weight',
                      difference: goalWeightKg
                        ? `${goalWeightDifferenceKg}kg`
                        : '-',
                      goalValue: goalWeightKg ? `${goalWeightKg}kg` : '-',
                      isMoreThan: isCurrentWeightMoreThanStartingWeight,
                      isReached,
                      milestones: goalWeightKg ? milestoneString : '-',
                    },
                  },
                  {
                    delta: {
                      id: 'bmi',
                      difference: '-',
                      goalValue: goalWeightKg ? goalWeightBmi : '-',
                      isMoreThan: null,
                      isReached: null,
                      milestones: '-',
                    },
                  },
                  {
                    delta: {
                      id: 'waist',
                      difference: '-',
                      goalValue: '-',
                      isMoreThan: null,
                      isReached: null,
                      milestones: '-',
                    },
                  },
                ].map(({ delta }) => (
                  <div
                    key={delta.id}
                    className="col-span-1 space-y-1 striped text-right"
                  >
                    <div className="text-slate-700 text-sm">
                      {delta.goalValue}
                    </div>
                    <div
                      className={clsx(
                        delta.isMoreThan
                          ? 'text-red-500'
                          : delta.isReached
                          ? 'text-green-500'
                          : 'text-blue-500',
                        'font-semibold',
                        'text-sm',
                      )}
                    >
                      {delta.difference}
                    </div>
                    <div className="text-sm">{delta.milestones}</div>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <div className="col-span-12 italic pt-4">
            <div className="text-sm">
              Patient does not have goal weight/milestones data.
            </div>
          </div>
        )}
      </div>
    </>
  );
};
