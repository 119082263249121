import { Maybe, UserRole } from 'graphql/types';

export const getAuthorDisplayName = (
  author: Maybe<{
    role: UserRole;
    fullName: string;
    clinicianName?: string | null | undefined;
  }>,
  legacy?: Maybe<boolean>,
) => {
  if (legacy) {
    return 'Unknown*';
  }

  if (!author) {
    return 'Unknown';
  }

  if (author.role === 'DOCTOR' && author.clinicianName) {
    return author.clinicianName;
  }

  if (author.fullName) {
    return author.fullName;
  }

  return 'Unknown';
};
