import { Button } from 'components/button';
import { CriticalNoteFragment } from 'graphql/types';
import { useState } from 'react';
import { UpsertCriticalNoteModal } from './upsert-critical-note-modal';

export const EditCriticalNoteModalButton: React.FC<{
  customerId: string;
  note: NonNullable<CriticalNoteFragment>;
}> = ({ customerId, note }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        onClick={() => setShowModal(true)}
        size="small"
        variant="outline"
        color="danger"
      >
        Edit
      </Button>
      <UpsertCriticalNoteModal
        show={showModal}
        onClose={() => setShowModal(false)}
        defaultValues={{
          customerId,
          noteId: note.id,
          body: note.body,
        }}
      />
    </>
  );
};
