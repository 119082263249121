import { ProblemType } from 'graphql/types';
import { Config } from 'config/types';

export const brandProblemTypes: Record<Config['brand'], ProblemType[]> = {
  pilot: [
    'ACNE',
    'COLD_SORES',
    'ERECTILE',
    'HAIR',
    'HERPES',
    'MENTAL_HEALTH_GOOD',
    'MENTAL_HEALTH_MEH',
    'MENTAL_HEALTH_SAD',
    'PREMATURE',
    'SLEEP',
    'UTI',
    'STI',
    'WEIGHT_LOSS',
  ],
  kin: [
    'CONTRACEPTION',
    'FERTILITY',
    'FERTILITY_HORMONE',
    'PRECONCEPTION_FERTILITY',
    'PRECONCEPTION_NUTRITION',
    'PRECONCEPTION_MENTAL_HEALTH',
    'PRECONCEPTION_PHYSIO',
    'PREGNANCY',
    'PREGNANCY_NUTRITION',
    'PREGNANCY_MENTAL_HEALTH',
    'PREGNANCY_PHYSIO',
    'POSTPARTUM',
    'POSTPARTUM_NUTRITION',
    'POSTPARTUM_MENTAL_HEALTH',
    'POSTPARTUM_PHYSIO',
    'BREASTFEEDING',
    'INFANT_SLEEP',
    'GENETIC_TEST',
    'WOMENS_HEALTH',
  ],
  software: ['ACNE', 'ANTI_AGEING', 'SKIN_GENERAL', 'HAIR'],
  juniper: ['MENOPAUSE', 'WEIGHT_LOSS'],
  'juniper-uk': ['WEIGHT_LOSS'],
  'juniper-de': ['WEIGHT_LOSS'],
  'juniper-jp': ['WEIGHT_LOSS'],
  'compound-uk': ['WEIGHT_LOSS'],
};
