import React from 'react';
import clsx from 'clsx';
import { Label } from 'components/label';
import { InputError } from 'components/input-error';
import { FaSearch } from 'react-icons/fa';

interface Props {
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  type?: string;
  name: string;
  errorMessage?: string | JSX.Element;
  onSearch?: (value: string | null) => void;
}

const BaseInput = (
  {
    label,
    placeholder,
    type = 'text',
    name,
    errorMessage,
    defaultValue,
    onSearch = (): void => {},
  }: Props,
  ref: React.Ref<HTMLInputElement>,
): React.ReactElement => {
  return (
    <>
      {label && (
        <div className="mb-3">
          <Label htmlFor={name}>{label}</Label>
        </div>
      )}
      <div className="pt-2 relative mx-auto">
        <input
          id={name}
          ref={ref}
          name={name}
          className={clsx(
            'appearance-none',
            'border',
            'rounded-md',
            'w-full',
            'px-4',
            'py-3',
            'focus:outline-none',
            'transition-shadow',
            'duration-200',
            'bg-white',
            'focus:bg-white',
          )}
          type={type}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>): void => {
            if (event.key === 'Enter') {
              event.preventDefault();
              onSearch((event.target as HTMLInputElement).value);
            }
          }}
        />
        <button
          type="button"
          className="absolute right-0 top-0 mt-6 mr-4"
          onClick={(): void =>
            onSearch((document.getElementById(name) as HTMLInputElement).value)
          }
        >
          <FaSearch />
        </button>
      </div>
      <InputError>{errorMessage}</InputError>
    </>
  );
};

export const SearchBar = React.forwardRef<HTMLInputElement, Props>(BaseInput);
