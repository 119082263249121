import React from 'react';
import {
  QueryLevelTreeGoalTemplatesArgs,
  GoalsFilterPillarTemplatesFragment,
} from 'graphql/types';
import { Option, Dropdown } from 'components/dropdown';
import { useForm } from 'react-hook-form';
import { useDebouncedEffect } from 'utils/use-debounce-effect';
import { useChangeUrl } from 'utils/user-change-url';
import { Input } from 'components/input';
import { useGoalsSearchParams, usePillarAndLevelOptions } from './hooks';
import { gql } from '@apollo/client';

const statusOptions: Option[] = [
  { label: 'All', value: '' },
  { label: 'Draft', value: 'DRAFT' },
  { label: 'Published', value: 'PUBLISHED' },
];

const FILTER_CHANGE_DEBOUNCE_MS = 500;

export const GoalsFilterPillarTemplatesFragmentDoc = gql`
  fragment GoalsFilterPillarTemplates on LevelTreePillarTemplate {
    id
    name
    levels {
      id
      levelNumber
    }
  }
`;

export const GoalsFilter = ({
  pillars,
}: {
  pillars: GoalsFilterPillarTemplatesFragment[] | null | undefined;
}): React.ReactElement => {
  const query = useGoalsSearchParams();

  const { control, watch, register } = useForm<QueryLevelTreeGoalTemplatesArgs>(
    {
      mode: 'onChange',
      defaultValues: query,
    },
  );

  const searchTerm = watch().searchTerm || undefined;
  const status = watch().publishedState || undefined;
  const pillarId = watch().pillarId || undefined;
  const levelId = watch().levelId || undefined;

  const { pillarOptions, levelOptions } = usePillarAndLevelOptions(
    pillars,
    pillarId,
  );

  const changeUrl = useChangeUrl();
  useDebouncedEffect(
    () => {
      if (
        query.searchTerm !== searchTerm ||
        query.pillarId !== pillarId ||
        query.levelId !== levelId ||
        query.publishedState !== status
      ) {
        changeUrl({
          params: {
            searchTerm,
            status,
            pillarId,
            levelId,
          },
        });
      }
    },
    FILTER_CHANGE_DEBOUNCE_MS,
    [query, searchTerm, status, pillarId, levelId],
  );

  return (
    <form className="flex mb-8 flex-1 space-x-4">
      <div className="w-1/4">
        <Input
          ref={register}
          name="searchTerm"
          label="Search"
          placeholder="Search by name or ID"
        />
      </div>
      <div className="w-1/4">
        <Dropdown
          name="pillarId"
          label="Pillar"
          options={[{ label: 'All', value: '' }, ...pillarOptions]}
          control={control}
        />
      </div>
      <div className="w-1/4">
        <Dropdown
          name="levelId"
          label="Level"
          options={[{ label: 'All', value: '' }, ...levelOptions]}
          control={control}
          disabled={!pillarId}
        />
      </div>
      <div className="w-1/4">
        <Dropdown
          name="publishedState"
          label="Status"
          options={statusOptions}
          control={control}
        />
      </div>
    </form>
  );
};
