import { OrderWhereInput } from 'graphql/types';
import { Dropdown, Option } from 'components/dropdown';
import { Input } from 'components/input';
import React from 'react';
import { useForm } from 'react-hook-form';
import { isOrderStatus, parseOrderStatus } from 'utils/misc';
import { useDebouncedEffect } from 'utils/use-debounce-effect';
import { useUrlQuery } from 'utils/use-url-query';
import { useChangeUrl } from 'utils/user-change-url';

export const OrderFilter = (): React.ReactElement => {
  const query = useGetNativeQuery();

  const { control, register, watch } = useForm<OrderUrlQueryParams>({
    mode: 'onChange',
    defaultValues: query,
  });

  const status = parseOrderStatus(watch().status);
  const search = watch().search;

  const changeUrl = useChangeUrl();
  useDebouncedEffect(() => changeUrl({ params: { status, search } }), 0, [
    status,
    search,
  ]);

  return (
    <form>
      <div className="flex space-x-2 mb-8">
        <div className="w-1/2">
          <Dropdown
            name="status"
            label="Status"
            options={statusOptions}
            control={control}
          />
        </div>
        <div className="w-1/2">
          <Input
            ref={register}
            name="search"
            label="Search"
            placeholder="Search by Status or ID"
          />
        </div>
      </div>
    </form>
  );
};

const statusOptions: Option[] = [
  { label: 'All', value: '' },
  { label: 'Awaiting Script', value: 'AWAITING_SCRIPT' },
  { label: 'Canceled', value: 'CANCELED' },
  { label: 'Completed', value: 'COMPLETED' },
  { label: 'Created', value: 'CREATED' },
  { label: 'Failed Script', value: 'FAILED_SCRIPT' },
  { label: 'Failed Shopify', value: 'FAILED_SHOPIFY' },
  { label: 'Failed Vendor Creation', value: 'FAILED_VENDOR_CREATION' },
  { label: 'Pending Vendor Creation', value: 'PENDING_VENDOR_CREATION' },
];

export type OrderUrlQueryParams = {
  status: string;
  search: string;
};

const useGetNativeQuery = (): OrderUrlQueryParams => {
  const urlQuery = useUrlQuery();
  return {
    status: urlQuery.get('status') ?? '',
    search: urlQuery.get('search')?.trim() ?? '',
  };
};

export const useBuildOrderQueryFilter = (): OrderWhereInput => {
  const { status, search } = useGetNativeQuery();
  return React.useMemo(() => {
    const statusFilter: OrderWhereInput =
      status !== undefined && isOrderStatus(status)
        ? { status: { equals: status } }
        : {};
    const searchFilter: OrderWhereInput = search
      ? {
          OR: [
            { id: { equals: search } },
            {
              shopifyOrderId: {
                equals: search,
              },
            },
            {
              webflowOrderId: {
                equals: search,
              },
            },
            {
              customerId: {
                equals: search,
              },
            },
          ],
        }
      : {};
    return {
      AND: [statusFilter, searchFilter],
    };
  }, [status, search]);
};
