import { Tag } from 'components/tag';
import React from 'react';

export const scriptTags: Record<string, () => React.ReactElement> = {
  legacy: () => <Tag color="teal">Legacy</Tag>,
  paper: () => <Tag color="teal">Paper</Tag>,
  escript: () => <Tag color="primary">Escript</Tag>,
  unsigned: () => <Tag color="yellow">Unsigned</Tag>,
  signed: () => <Tag color="green">Signed</Tag>,
};
