import React from 'react';
import { useDynamicBooleanFlag } from '@eucalyptusvc/react-ff-client';

export const MaintenanceModeWrapper = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const isOffline = useDynamicBooleanFlag('admins-ui-maintenance-offline');

  if (isOffline) {
    return (
      <section className="h-screen flex">
        <div className="max-w-lg mx-auto my-auto space-y-8 p-5">
          <div className="space-y-4 text-center">
            <p className="font-semibold">
              Sorry, we&rsquo;re down for maintenance
            </p>
            <p>
              There is an issue with our system, and we are trying to fix it.
              <br />
              Please refer to #help-engineering for status updates
            </p>
          </div>
        </div>
      </section>
    );
  }

  return <>{children}</>;
};
