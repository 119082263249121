import { gql } from '@apollo/client';

export const refillTreatmentDocument = gql`
  mutation refillTreatment($treatmentId: String!, $free: Boolean) {
    refillTreatment(treatmentId: $treatmentId, free: $free) {
      id
    }
  }
`;

export const pauseTreatmentDocument = gql`
  mutation pauseTreatment(
    $id: String!
    $reason: String
    $reasonExtended: String
  ) {
    pauseTreatment(id: $id, reason: $reason, reasonExtended: $reasonExtended) {
      id
    }
  }
`;

export const unpauseTreatmentDocument = gql`
  mutation unpauseTreatment($id: String!) {
    unpauseTreatment(id: $id) {
      id
    }
  }
`;

export const requestTreatmentReviewDocument = gql`
  mutation requestTreatmentReview($input: RequestTreatmentReviewInput!) {
    requestTreatmentReview(input: $input) {
      id
      consultation {
        id
      }
    }
  }
`;

export const requestTreatmentReviewAndAssignToDoctorDocument = gql`
  mutation RequestTreatmentReviewAndAssignToDoctor(
    $input: RequestTreatmentReviewAndAssignToDoctorInput!
  ) {
    requestTreatmentReviewAndAssignToDoctor(input: $input) {
      id
      consultation {
        id
      }
    }
  }
`;

export const requestTreatmentReviewAndAddPractitionerBookingDocument = gql`
  mutation requestTreatmentReviewAndAddPractitionerBooking(
    $input: RequestTreatmentReviewAndAddPractitionerBookingInput!
  ) {
    requestTreatmentReviewAndAddPractitionerBooking(input: $input) {
      treatment {
        id
        consultation {
          id
        }
      }
    }
  }
`;

export const revertReviewTreatmentDocument = gql`
  mutation revertReviewTreatment($treatmentId: String!) {
    revertReviewTreatment(treatmentId: $treatmentId) {
      id
    }
  }
`;
