import React from 'react';
import clsx from 'clsx';

interface Props {
  name: string;
  id: string;
  label?: string;
  selected?: boolean;
  disabled?: boolean;
  customIcon?: JSX.Element;
  defaultChecked?: boolean;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
}

const BaseRadioButton = (
  {
    id,
    label,
    name,
    selected = false,
    disabled = false,
    onChange,
    customIcon,
    defaultChecked,
  }: Props,
  ref: React.Ref<HTMLInputElement>,
): React.ReactElement => (
  <label
    className={clsx(
      'focus:bg-red-500',
      'select-none',
      'focus-within:border-primary',
      !!label && 'flex items-center',
      disabled ? ' text-gray-500 cursor-not-allowed' : 'cursor-pointer',
      selected ? 'border-primary-lighter' : 'border-primary-300',
    )}
  >
    <input
      ref={ref}
      name={name}
      id={id}
      type="radio"
      className={clsx({ 'visually-hidden': !!customIcon })}
      value={id}
      disabled={disabled}
      onChange={onChange}
      defaultChecked={defaultChecked}
    />
    {customIcon && (
      <div className="inline-flex w-5 h-5 items-center justify-center flex-none">
        {disabled ? (
          <div className="w-full h-full border rounded border-gray-500 bg-gray-400" />
        ) : selected ? (
          <div className="w-full h-full border rounded text-primary-100 border-primary-500 bg-primary-500 line pt-0.5">
            {customIcon}
          </div>
        ) : (
          <div className="w-full h-full border rounded border-primary-300 bg-white hover:bg-gray-300" />
        )}
      </div>
    )}
    {label && <div className="ml-3 text-sm sm:text-base">{label}</div>}
  </label>
);

export const RadioButton = React.forwardRef<HTMLInputElement, Props>(
  BaseRadioButton,
);
