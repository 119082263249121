import { useFieldArray, useForm } from 'react-hook-form';
import React from 'react';
import { useAlert } from '../../../alert';
import { Button } from '../../../components/button';
import {
  DefaultVariantInfoFields,
  AccessoryVariantInfoFields,
} from './variant-info-fields';
import { useVariantCrud } from '../../../utils/use-variant-crud';
import { useVariantInventoryCrud } from '../../../utils/use-variant-inventory-crud';
import { useNotifications } from '../../../notifications';
import { ProductType, Variant } from 'graphql/types';

export const VariantSection = ({
  register,
  control,
  errors,
  orderLineItemsCount,
  originalVariants,
  watchedVariants,
  productId,
  productType,
  refetch,
}: {
  register: ReturnType<typeof useForm>['register'];
  control: ReturnType<typeof useForm>['control'];
  errors: ReturnType<typeof useForm>['errors'];
  orderLineItemsCount: number | undefined;
  originalVariants: Variant[] | undefined;
  watchedVariants: Partial<Variant>[];
  productId: string;
  productType: ProductType | undefined;
  refetch: () => Promise<void>;
}): React.ReactElement => {
  const showAlert = useAlert();
  const showNotification = useNotifications();

  const { fields, append } = useFieldArray({
    control,
    name: 'variants',
    keyName: 'key',
  });

  const { createVariantWithSku, updateVariant, deleteVariant } =
    useVariantCrud();
  const { updateVariantInventory, createVariantInventory } =
    useVariantInventoryCrud();

  const handleDelete = async (variantNumber: number): Promise<void> => {
    const variant = watchedVariants[variantNumber];
    const variantId = variant.id;
    if (variant || variantId) {
      const result = await showAlert({
        content: `Are you sure you want to delete this variant?`,
      });
      if (!result) return;
    }
    if (variantId) {
      try {
        await deleteVariant(variantId);
        showNotification({
          message: `Deleted variant`,
          type: 'success',
        });
      } catch {
        showNotification({
          message: `Failed to delete the variant`,
          type: 'error',
        });
      }
    }
    await refetch();
  };

  const handleCreate = async (variantNumber: number): Promise<void> => {
    const variant = watchedVariants[variantNumber];
    // TODO: Use proper form checks
    if (!variant.name) {
      showNotification({
        message: `Can not create a variant without name`,
        type: 'warning',
      });
      return;
    }
    try {
      await createVariantWithSku({
        productId,
        name: variant.name,
        slug: variant.slug || undefined,
        price: Number(variant.price) || 0,
        public: !!variant.public,
        isRefill: !!variant.isRefill,
        shopifyVariantId: variant.shopifyVariantId || undefined,
        sku: variant.inventory?.sku || undefined,
      });
      showNotification({
        message: `Created variant`,
        type: 'success',
      });
    } catch {
      showNotification({
        message: `Failed to create variant`,
        type: 'error',
      });
    }
    await refetch();
  };

  const handleSave = async (variantNumber: number): Promise<void> => {
    const variant = watchedVariants[variantNumber];

    // TODO: Use proper form checks
    if (!variant.name) {
      showNotification({
        message: `Can not update a variant without name`,
        type: 'warning',
      });
      return;
    }
    // We cannot change the price if there are orderLineItems that uses it.
    // Checking to see if we've changed the price compared to the original variants.
    const orderLineItemsExists = !!orderLineItemsCount;
    const variantPriceModified = !originalVariants?.every(
      (variant, i) => variant.price === Number(watchedVariants[i].price),
    );
    if (orderLineItemsExists && variantPriceModified) {
      showNotification({
        type: 'error',
        message: `You can not update the price since it is being used!`,
      });
      return;
    }

    try {
      await updateVariant({
        id: variant.id || '',
        productId,
        name: variant.name || undefined,
        slug: variant.slug || undefined,
        price: Number(variant.price) || 0,
        public: !!variant.public,
        isRefill: !!variant.isRefill,
        shopifyVariantId: variant.shopifyVariantId || undefined,
      });
      const variantInventoryId = fields[variantNumber].inventory?.id;
      if (variant.inventory?.sku && !variantInventoryId) {
        await createVariantInventory({
          variantId: variant.id || '',
          sku: variant.inventory?.sku,
        });
      } else if (variantInventoryId) {
        await updateVariantInventory({
          id: variantInventoryId,
          sku: variant.inventory?.sku,
        });
      }
      showNotification({
        message: `Updated variant`,
        type: 'success',
      });
    } catch {
      showNotification({
        message: `Failed to save the variant`,
        type: 'error',
      });
    }
    await refetch();
  };

  return (
    <>
      <ul className="space-y-5">
        {fields.map((variant, i) => {
          if (productType === 'ACCESSORY') {
            return (
              <AccessoryVariantInfoFields
                key={variant.key}
                register={register}
                control={control}
                errors={errors}
                defaultValues={{
                  id: variant.id,
                  name: variant.name,
                  slug: variant.slug,
                  price: 0,
                  public: variant.public,
                  shopifyVariantId: variant.shopifyVariantId,
                  isRefill: false,
                  sku: variant.inventory?.sku,
                }}
                variantNumber={i}
                onDelete={handleDelete}
                onCreate={handleCreate}
                onSave={handleSave}
              />
            );
          }

          return (
            <DefaultVariantInfoFields
              key={variant.key}
              register={register}
              control={control}
              errors={errors}
              defaultValues={{
                id: variant.id,
                name: variant.name,
                slug: variant.slug,
                price: variant.price,
                public: variant.public,
                shopifyVariantId: variant.shopifyVariantId,
                isRefill: variant.isRefill,
                sku: variant.inventory?.sku,
              }}
              variantNumber={i}
              onDelete={handleDelete}
              onCreate={handleCreate}
              onSave={handleSave}
            />
          );
        })}
      </ul>
      <div className="flex justify-end">
        <div>
          <Button
            fullWidth
            onClick={(): void => {
              append({
                name: `Variant ${fields.length + 1}`,
              });
            }}
          >
            Add variant
          </Button>
        </div>
      </div>
    </>
  );
};
