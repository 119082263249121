import { NoteRowFragment } from 'graphql/types';
import { formatDateAndTime } from 'utils/misc';
import { getAuthorDisplayName } from 'utils/get-author-display-name';
import { Markdown } from 'components/markdown';

export const Addendums: React.FC<{ note: NoteRowFragment }> = ({ note }) => {
  if (!('addendums' in note)) {
    return null;
  }

  const addendums = note.addendums ?? [];

  return (
    <div className="space-y-4">
      {addendums.map((a) => (
        <div key={a.id} className="bg-white border border-gray-200">
          <div className="border-l-16 border-orange-200">
            <Markdown src={a.body} className="px-4 py-2" />
            <div className="bg-gray-100 px-4 py-2 flex justify-between font-semibold text-xs">
              <div>{getAuthorDisplayName(a.author)}</div>
              <div>{formatDateAndTime(a.updatedAt)}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
