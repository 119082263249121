import { gql, useQuery } from '@apollo/client';
import { ModifyProgram } from 'components/education/programs/modify-program';
import { Loading } from 'components/loading';
import {
  GetEducationProgramsQuery,
  GetEducationProgramsQueryVariables,
} from 'graphql/types';
import { VFC } from 'react';

const ProgramsPage: VFC = () => {
  const { data, loading } = useQuery<
    GetEducationProgramsQuery,
    GetEducationProgramsQueryVariables
  >(gql`
    query GetEducationPrograms {
      educationPrograms {
        id
        name
        description
        modules {
          id
          name
          description
          readings {
            id
            name
            contentfulId
          }
        }
      }
    }
  `);

  if (loading && !data) {
    return <Loading />;
  }

  const program = data?.educationPrograms?.[0];

  if (!program) {
    return <>No education programs exist in brand.</>;
  }

  return <ModifyProgram program={program} />;
};

export default ProgramsPage;
