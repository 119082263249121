import { AdminRole, Maybe, NoteDomain, UserRole } from 'graphql/types';

export const getNoteDomain = (
  author: Maybe<{
    role?: UserRole;
    roles?: Maybe<Array<{ value: AdminRole }>>;
  }>,
): NoteDomain => {
  if (!author || !author.role) {
    throw new Error(
      'Unable to determine note domain, author role is undefined',
    );
  }

  if (['DOCTOR', 'PHARMACY'].includes(author.role)) {
    return 'PRESCRIBER';
  }

  if (author.role === 'HEALTH_COACH') {
    return 'HEALTH_COACH';
  }

  if (author.role === 'SUPERADMIN') {
    const rbacRoles = author.roles?.map((r) => r.value) ?? [];

    if (rbacRoles.includes('CLINICAL_OPS')) {
      return 'CLINICAL_OPS';
    }

    return 'ADMIN';
  }

  throw new Error('Unable to determine note domain');
};
