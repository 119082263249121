import { AuditLogsQuery } from 'graphql/types';
import { Customer } from 'pages/customer/types';
import { LogTableValues } from './types';
import { Link } from 'react-router-dom';
import { upperSnakeCaseToCapitalCase } from 'utils/misc';
import { EntityLink } from './entity-link';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const flattenObject = (
  obj: Record<string, any>,
  parentKey?: string,
): Record<string, any> => {
  let result: Record<string, any> = {};
  if (obj === null) {
    return {};
  }
  Object.entries(obj).forEach(([key, value]) => {
    const _key = parentKey ? parentKey + '.' + key : key;
    if (typeof value === 'object') {
      result = { ...result, ...flattenObject(value, _key) };
    } else {
      result[_key] = value;
    }
  });

  return result;
};

export const isRecord = (
  obj: unknown,
): obj is Record<
  string,
  Record<string, string | number | boolean | Record<string, unknown>>
> => {
  if (obj === null) return false;
  if (obj === undefined) return false;
  if (typeof obj !== 'object') return false;

  if (Array.isArray(obj)) return false;

  if (Object.getOwnPropertySymbols(obj).length > 0) return false;

  return Object.getOwnPropertyNames(obj).every((prop) =>
    ['string', 'number', 'boolean', 'object'].includes(
      typeof (obj as Record<string, unknown>)[prop],
    ),
  );
};

export const combineLogs = (
  auditLogs: AuditLogsQuery | undefined,
  customer: Customer,
): LogTableValues[] => {
  const logs: LogTableValues[] = [];

  logs.push(
    ...(auditLogs?.auditLogs.map((log) => ({
      id: log.id,
      created: new Date(log.createdAt),
      type: upperSnakeCaseToCapitalCase(log.targetType),
      target: (
        <EntityLink
          id={log.targetId}
          type={upperSnakeCaseToCapitalCase(log.targetType)}
        />
      ),
      action: log.action,
      user: log.user ? `${log.user.fullName} (${log.user.role})` : 'SYSTEM',
      details: log.details,
    })) || []),
  );

  for (const treatment of customer.treatments) {
    for (const log of treatment?.logs ?? []) {
      logs.push({
        id: log.id,
        action: log.event || '',
        created: new Date(log.updatedAt),
        user: log.updatedBy
          ? `${log.updatedBy.fullName} (${log.updatedBy.role})`
          : 'SYSTEM',
        target: (
          <Link to={`/customers/${customer.id}?pt=${treatment.type}`}>
            {treatment.id}
          </Link>
        ),
        type: 'Treatment',
        details: log.data,
      });
    }
  }

  for (const consultation of customer.consultations) {
    for (const log of consultation?.logs ?? []) {
      logs.push({
        id: log.id,
        action: log.event || '',
        created: new Date(log.updatedAt),
        user: log.updatedBy
          ? `${log.updatedBy.fullName} (${log.updatedBy.role})`
          : 'SYSTEM',
        target: <EntityLink id={consultation.id} type={'Consultation'} />,
        type: 'Consultation',
        details: log.data,
      });
    }
  }

  return logs;
};
