import { Maybe, ProblemType } from 'graphql/types';
import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'utils/routes';

const CustomerDetails = ({
  customer,
  problemType,
}: {
  customer: {
    id: string;
    email: string;
    fullName?: Maybe<string>;
    firstName?: Maybe<string>;
    lastName?: Maybe<string>;
    phone?: Maybe<string>;
    avatar?: Maybe<{
      url: string;
    }>;
  };
  problemType?: ProblemType;
}): React.ReactElement => {
  return (
    <div>
      <h3 className="heading-sm mb-3">Patient</h3>
      <div className="bg-white p-4 shadow mb-4">
        {customer && (
          <Link
            to={`${routes.customers}/${customer.id}${
              problemType ? `?pt=${problemType}` : ''
            }`}
          >
            <div className="space-y-1">
              <div className="font-medium">{customer.fullName}</div>
              <div className="break-words text-gray-700">{customer.email}</div>
              <div className="text-gray-700">{customer.phone}</div>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default CustomerDetails;
