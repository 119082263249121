import {
  EducationModule,
  EducationProgram,
  EducationReading,
  Maybe,
  UpdateEducationModuleInput,
} from 'graphql/types';

export const updateLocalProgramWithNewReadingInModule = (
  educationProgram: EducationProgram,
  moduleId: string,
  newEducationReading: EducationReading,
): EducationProgram => {
  const modules = getArrayOrEmptyArray(educationProgram.modules);
  const { moduleToChange, moduleToChangeIndex } = findModuleByModuleId(
    modules,
    moduleId,
  );
  const newReadingsForModule = getArrayOrEmptyArray(moduleToChange.readings);
  newReadingsForModule.push(newEducationReading);
  modules[moduleToChangeIndex] = replaceReadingsInModule(
    moduleToChange,
    newReadingsForModule,
  );
  return { ...educationProgram, modules };
};

export const getCardOutlineClassName = (unsavedChanges: boolean): string => {
  if (unsavedChanges) {
    return 'ring-orange-500 ring-2';
  }
  return '';
};

export const prepareUpdateEducationModuleInputs = (
  modules: Maybe<EducationModule[]> | undefined,
): UpdateEducationModuleInput[] =>
  modules?.map((module) => ({
    id: module.id,
    name: module.name,
    description: module.description,
    readingIds: module.readings?.map((reading) => reading.id) || [],
  })) || [];

export const updateLocalProgramWithReadingRemovedFromModule = (
  educationProgram: EducationProgram,
  moduleId: string,
  readingId: string,
): EducationProgram => {
  const modules = getArrayOrEmptyArray(educationProgram.modules);
  const { moduleToChange, moduleToChangeIndex } = findModuleByModuleId(
    modules,
    moduleId,
  );
  const newReadingsForModule =
    moduleToChange?.readings?.filter((reading) => reading.id !== readingId) ||
    [];
  modules[moduleToChangeIndex] = replaceReadingsInModule(
    moduleToChange,
    newReadingsForModule,
  );
  return { ...educationProgram, modules };
};

const findModuleByModuleId = (
  modules: EducationModule[],
  moduleId: string,
): { moduleToChange: EducationModule; moduleToChangeIndex: number } => {
  const moduleToChangeIndex = modules.findIndex(
    (module) => module.id === moduleId,
  );
  const moduleToChange = modules[moduleToChangeIndex];
  if (!moduleToChange) {
    throw new Error(
      'No module could be found when trying to add a reading to a program.',
    );
  }
  return { moduleToChange, moduleToChangeIndex };
};

const getArrayOrEmptyArray = <T>(arr: T[] | null | undefined): T[] => {
  return [...(arr || [])];
};

const replaceReadingsInModule = (
  module: EducationModule,
  newReadings: EducationReading[],
): EducationModule => ({
  ...module,
  readings: newReadings,
});

export const updateLocalProgramWithReorderedReadings = (
  educationProgram: EducationProgram,
  moduleId: string,
  educationReadings: EducationReading[],
): EducationProgram => {
  const modules = getArrayOrEmptyArray(educationProgram.modules);
  const { moduleToChange, moduleToChangeIndex } = findModuleByModuleId(
    modules,
    moduleId,
  );

  const sameReadingsInModule =
    moduleToChange.readings?.length === educationReadings.length &&
    moduleToChange.readings.every((reading) =>
      educationReadings.includes(reading),
    );

  if (!sameReadingsInModule) {
    throw new Error('Cannot change readings in module while reordering');
  }

  modules[moduleToChangeIndex] = replaceReadingsInModule(
    moduleToChange,
    educationReadings,
  );
  return { ...educationProgram, modules };
};
