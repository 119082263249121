import React from 'react';
import { logger } from './logging';
import { Button } from './components/button';
import { config } from './config';

type Props = {
  children: React.ReactNode;
};

type State = {
  error?: Error;
};

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    logger.error('Error caught in ErrorBoundary', {
      error,
      errorInfo,
    });
    if (config.environment !== 'production') {
      // eslint-disable-next-line no-console
      console.error({ error, errorInfo });
    }
  }

  render() {
    if (this.state.error === undefined) {
      return <>{this.props.children}</>;
    }

    return (
      <section className="h-screen flex">
        <div className="max-w-md mx-auto my-auto space-y-8 p-5">
          <div className="space-y-4 text-center">
            <p className="font-semibold">Something has gone wrong.</p>
            <p>
              Please try refreshing the page, or should the issue persist
              contact the engineering team for help.
            </p>
          </div>
          <Button fullWidth onClick={() => window.location.reload()}>
            Refresh
          </Button>
        </div>
      </section>
    );
  }
}
