import React from 'react';
import { Modal } from 'components/modal';
import { Button } from 'components/button';

export const ConfirmModal = ({
  children,
  show,
  onConfirm,
  onClose,
}: {
  children: React.ReactElement;
  show: boolean;
  onConfirm: () => void;
  onClose: () => void;
}): React.ReactElement => (
  <Modal show={show} onClose={onClose}>
    <div className="bg-white rounded p-4">
      <div>{children}</div>
      <div className="pt-4 flex space-x-4">
        <Button fullWidth variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button fullWidth color="danger" onClick={onConfirm}>
          Confirm
        </Button>
      </div>
    </div>
  </Modal>
);
