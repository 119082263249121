import { Pagination } from 'graphql/types';

export const calculateTablePaginationArgs = (
  pageIndex: number,
  tableSize: number,
): Pagination => {
  return {
    skip: pageIndex * tableSize,
    pageSize: tableSize,
  };
};
