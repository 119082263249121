import React, { useRef, useState } from 'react';
import {
  formatDateAndTime,
  upperSnakeCaseToCapitalCase,
} from '../../utils/misc';
import { JobStatus } from './job/jobStatus';
import { CustomerTreatmentJobView } from './types';
import { ConsultationJobView } from '../consultation/types';
import { useNotifications } from 'notifications';
import { gql, useMutation } from '@apollo/client';
import { Modal } from 'components/modal';
import { Button } from 'components/button';
import {
  RescheduleJobMutation,
  RescheduleJobMutationVariables,
} from 'graphql/types';
import add from 'date-fns/add';
import { formatISO } from 'date-fns/esm';

export const RescheduleModal = ({
  jobId,
  close,
}: {
  close: () => void;
  jobId: string;
}): React.ReactElement => {
  const showNotification = useNotifications();
  const scheduledAtInput = useRef<HTMLInputElement>(null);
  const [rescheduleMutation, { loading }] = useMutation<
    RescheduleJobMutation,
    RescheduleJobMutationVariables
  >(
    gql`
      mutation RescheduleJob($jobId: ID!, $scheduledAt: DateTime!) {
        rescheduleJob(jobId: $jobId, scheduledAt: $scheduledAt) {
          job {
            id
            failed
            scheduledAt
            doneAt
            treatment {
              id
              logs {
                id
                updatedAt
                updatedBy {
                  id
                  email
                  firstName
                  lastName
                  fullName
                  role
                }
                data
                event
                status
              }
            }
          }
        }
      }
    `,
    {
      refetchQueries: ['Customer'],
      awaitRefetchQueries: true,
    },
  );

  return (
    <Modal show={!!jobId} onClose={(): void => close()}>
      <div
        className="bg-white shadow overflow-hidden rounded px-4 py-5 max-w-lg mr-auto ml-auto"
        onClick={(
          event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        ): void => {
          event.stopPropagation();
        }}
      >
        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-8">
          Reschedule job
        </h3>
        <input
          type="date"
          name="scheduledAt"
          id="scheduledAt"
          className="mb-8 text-lg border border-gray-400 hover:border-gray-500 rounded p-2"
          ref={scheduledAtInput}
          min={formatISO(add(new Date(), { days: 1 }), {
            representation: 'date',
          })}
        />
        <div className="flex bg-gray-100">
          <Button fullWidth variant="outline" onClick={(): void => close()}>
            Cancel
          </Button>
          <div className="mx-2" />
          <Button
            fullWidth
            color="success"
            loading={loading}
            onClick={async (): Promise<void> => {
              const scheduledAt = scheduledAtInput.current?.value;
              if (!scheduledAt) {
                throw new Error(
                  'unexpected invalid value from payment date input',
                );
              }
              await rescheduleMutation({
                variables: {
                  jobId,
                  scheduledAt: new Date(scheduledAt).toISOString(),
                },
              });
              showNotification({
                type: 'success',
                message: 'Job rescheduled',
              });
              close();
            }}
          >
            Confirm Reschedule
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const JobTable = ({
  jobs,
}: {
  jobs: Array<CustomerTreatmentJobView | ConsultationJobView>;
}): React.ReactElement => {
  const [rescheduleJobId, setRescheduleJobId] = useState<string | null>(null);
  return (
    <>
      <div className="border-t border-gray-200">
        <div className="px-4 py-5 border-b border-gray-200">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Jobs <span className="ml-2 text-gray-500">{jobs.length}</span>
          </h3>
        </div>
        <div className="">
          <div className="-mt-2 overflow-x-auto">
            <div className="pt-2 min-w-full">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    {[
                      'ID',
                      'Updated',
                      'Type',
                      'Status',
                      'Scheduled For',
                      'Done At',
                      'Message',
                      'Source',
                      'Created At',
                      'Reapprove',
                      'Order Id',
                      'Consultation Id',
                      'Data',
                      'Actions',
                    ].map((h) => (
                      <th
                        key={h}
                        className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {h}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {jobs.map((j) => (
                    <tr key={j.id}>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 text-gray-500">
                          {j.id}
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="text-sm leading-5 text-gray-900">
                            {formatDateAndTime(j.updatedAt)}
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 text-gray-900">
                          {upperSnakeCaseToCapitalCase(j.type)}
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap text-sm">
                        <JobStatus job={j} />
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="text-sm leading-5 text-gray-900">
                            {j.scheduledAt
                              ? formatDateAndTime(j.scheduledAt)
                              : ''}
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="text-sm leading-5 text-gray-900">
                            {j.doneAt ? formatDateAndTime(j.doneAt) : ''}
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap text-sm">
                        {j.message}
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap text-sm">
                        {j.source}
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap text-sm">
                        {formatDateAndTime(j.createdAt)}
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap text-sm">
                        {j.isReApprove && 'TRUE'}
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap text-sm">
                        {j.orderId}
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap text-sm">
                        {j.consultationId}
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap text-sm">
                        {j.data && JSON.stringify(j.data)}
                      </td>
                      <td className="px-6 py-2">
                        {j.canReschedule && (
                          <button
                            type="button"
                            className="inline-flex disabled:cursor-not-allowed disabled:opacity-25 items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded text-gray-700 bg-white focus:outline-none focus:ring-blue focus:border-blue-300 active:text-gray-800 active:bg-white transition duration-150 ease-in-out"
                            onClick={() => setRescheduleJobId(j.id)}
                          >
                            Reschedule
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <RescheduleModal
        jobId={rescheduleJobId ?? ''}
        close={() => setRescheduleJobId(null)}
      />
    </>
  );
};
