import { useTitle as useTitleLib } from 'react-use';
import { capitaliseString } from './misc';
import { config } from 'config';

export const useTitle = (title?: string): void => {
  const brand = capitaliseString(config.brand);
  let fullTitle = `${brand} Admins`;

  if (title) {
    fullTitle = `${title} | ${fullTitle}`;
  }

  if (config.environment === 'development') {
    fullTitle = `[dev] ${fullTitle} `;
  }

  useTitleLib(fullTitle, { restoreOnUnmount: true });
};
