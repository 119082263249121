import { useState } from 'react';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { Input } from 'components/input';
import { useForm } from 'react-hook-form';
import { useNotifications } from 'notifications';
import { gql, useMutation } from '@apollo/client';
import { requiredValidation } from 'utils/form-validation';
import {
  ContentCollectionsTableQuery,
  CreateCollectionMutation,
  CreateCollectionMutationVariables,
} from 'graphql/types';
import { contentCollectionsTableQuery } from './content-collections-table';

function CreateCollectionModal(props: {
  onClose: () => void;
  onCreate: () => void;
}): JSX.Element {
  const showNotification = useNotifications();
  const { handleSubmit, register, errors } = useForm<{
    name: string;
    description: string;
  }>();

  const [createCollection, { loading }] = useMutation<
    CreateCollectionMutation,
    CreateCollectionMutationVariables
  >(
    gql`
      mutation CreateCollection($input: CreateContentCollectionInput!) {
        createContentCollection(input: $input) {
          collection {
            id
            name
            description
          }
        }
      }
    `,
    {
      update(cache, { data }) {
        if (!data?.createContentCollection?.collection) return;

        const existingCollections =
          cache.readQuery<ContentCollectionsTableQuery>({
            query: contentCollectionsTableQuery,
          });

        if (!existingCollections?.contentCollections) return;

        cache.writeQuery<ContentCollectionsTableQuery>({
          query: contentCollectionsTableQuery,
          data: {
            contentCollections: existingCollections.contentCollections.concat(
              data.createContentCollection.collection,
            ),
          },
        });
      },
    },
  );

  const handleFormSubmit = handleSubmit(
    async ({ name, description }): Promise<void> => {
      try {
        const { data } = await createCollection({
          variables: {
            input: {
              name: name.trim(),
              description: description.trim(),
              itemIds: [],
            },
          },
        });
        const id = data?.createContentCollection?.collection.id;

        if (!id) {
          throw new Error('Could not create collection, please try again');
        }

        props.onCreate();
      } catch (e) {
        if (e instanceof Error) {
          showNotification({
            message: e.message,
            type: 'error',
          });
        }
      }
    },
  );

  return (
    <div className="bg-white rounded p-5">
      <h2 className="heading-md mb-5">Create Collection</h2>
      <form onSubmit={handleFormSubmit}>
        <div className="flex flex-col space-y-8">
          <div>
            <Input
              ref={register(requiredValidation('name'))}
              label="Name"
              name="name"
              errorMessage={errors.name?.message}
            />
          </div>
          <div>
            <Input
              ref={register(requiredValidation('description'))}
              label="Description"
              name="description"
              errorMessage={errors.description?.message}
            />
          </div>
          <div className="flex flex-row space-x-4">
            <Button fullWidth variant="outline" onClick={props.onClose}>
              Cancel
            </Button>
            <Button
              fullWidth
              color="success"
              loading={loading}
              disabled={loading}
              type="submit"
            >
              Create
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

export function CreateCollection(): JSX.Element {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        fullWidth
        variant="outline"
        onClick={(): void => setShowModal(true)}
      >
        New Collection
      </Button>
      <Modal
        show={showModal}
        width="max-w-screen-sm"
        onClose={(): void => setShowModal(false)}
      >
        <CreateCollectionModal
          onClose={(): void => setShowModal(false)}
          onCreate={(): void => {
            setShowModal(false);
          }}
        />
      </Modal>
    </>
  );
}
