import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/table';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Column, useTable } from 'react-table';
import { Button } from '../../components/button';
import { Input } from '../../components/input';
import { Loading } from '../../components/loading';
import { Modal } from '../../components/modal';
import { TemplateStatusTag } from '../../components/pal-levels/template-status-tag';
import {
  CreatePillarMutation,
  CreatePillarMutationVariables,
  PillarsPageQuery,
  TemplateStatus,
} from '../../graphql/types';
import { buildRoute } from '../../utils/routes';

export const pillarNameCharacterLimit = 15;

type PillarsTableData = {
  id: string;
  name: string;
  status: TemplateStatus;
  levels: number;
};

const columns: Column<PillarsTableData>[] = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: (c) => <span className="font-semibold">{c.value}</span>,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: (c) => <TemplateStatusTag status={c.value} />,
  },
  { Header: 'Levels', accessor: 'levels' },
];

const PillarsPage = (): React.ReactElement => {
  const { data, loading, refetch } = useQuery<PillarsPageQuery>(
    gql`
      query PillarsPage {
        levelTreePillarTemplates(problemTypes: [WEIGHT_LOSS]) {
          id
          name
          status
          levels {
            id
          }
        }
      }
    `,
    {
      fetchPolicy: 'cache-first',
    },
  );
  const [createPillar] = useMutation<
    CreatePillarMutation,
    CreatePillarMutationVariables
  >(gql`
    mutation CreatePillar($input: CreateLevelTreePillarTemplateInput!) {
      createLevelTreePillarTemplate(input: $input) {
        pillarTemplate {
          id
        }
      }
    }
  `);
  const [showModal, setShowModal] = useState(false);
  const form = useForm<{ pillarName: string }>({
    defaultValues: { pillarName: '' },
    mode: 'onChange',
  });
  const handleSubmit = form.handleSubmit(async (data) => {
    await createPillar({
      variables: {
        input: {
          name: data.pillarName,
          problemType: 'WEIGHT_LOSS',
        },
      },
    });
    await refetch();
    setShowModal(false);
  });
  const history = useHistory();

  const table = useTable({
    columns,
    data:
      data?.levelTreePillarTemplates?.map((pillar) => {
        if (!pillar.name || !pillar.status || !pillar.levels) {
          throw new Error('Expected pillar to have name, status, and levels');
        }
        return {
          id: pillar.id,
          name: pillar.name,
          status: pillar.status,
          levels: pillar.levels.length,
        };
      }) || [],
  });

  if (loading) {
    return <Loading />;
  }

  const { pillarName } = form.watch();

  return (
    <>
      <div>
        <div className="flex justify-end">
          <div>
            <Button
              fullWidth
              onClick={() => setShowModal(true)}
              disabled={(data?.levelTreePillarTemplates?.length ?? 0) >= 3}
            >
              Create Pillar
            </Button>
          </div>
        </div>
        <div className="w-1/2">
          <p className="text-gray-700 mb-6">
            A maximum of 3 pillars can be added.
          </p>
          <Table tableInstance={table}>
            <TableHead />
            <TableBody>
              {table.rows.map((row) => {
                table.prepareRow(row);
                return (
                  <TableRow key={row.id} row={row}>
                    {row.cells.map((cell) => (
                      <TableCell
                        key={`${cell.column.id}-${cell.row.original.id}`}
                        cell={cell}
                        onClick={() =>
                          history.push(buildRoute.pillar(cell.row.original.id))
                        }
                      />
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <form onSubmit={handleSubmit}>
          <div className="bg-gray-200 rounded p-8 px-6 space-y-4 flex flex-col gap-5">
            <div className="flex flex-col gap-2">
              <p className="text-lg font-semibold">Create a pillar</p>
              <p>
                Get started by naming the pillar (you can change this later if
                necessary). A new pillar can remain a draft until you want to
                publish it, but it can&apos;t be deleted.
              </p>
            </div>
            <div>
              <Input
                name="pillarName"
                label="Name"
                ref={form.register({
                  validate: {
                    trailingWhitespace: (v) =>
                      v.trim() === v || 'Cannot have trailing whitespace',
                    required: (v) => !!v || 'Please enter a name',
                  },
                })}
                errorMessage={form.errors.pillarName?.message}
                hint={`${
                  pillarNameCharacterLimit -
                  (pillarName ? pillarName.length : 0)
                } characters remaining`}
                maxLength={pillarNameCharacterLimit}
              />
            </div>
            <div className="flex gap-5">
              <Button
                fullWidth
                color="danger"
                variant="outline"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
              <Button
                fullWidth
                type="submit"
                color="success"
                loading={form.formState.isSubmitting}
                disabled={!form.formState.isValid}
              >
                Create pillar
              </Button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default PillarsPage;
