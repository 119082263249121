import { ProductType } from 'graphql/types';
import React from 'react';
import { Dropdown, DropdownProps } from './dropdown';

const AVAILABLE_PRODUCT_TYPES: ProductType[] = ['RX', 'OTC', 'ACCESSORY'];

type Props = Omit<DropdownProps, 'options' | 'isMulti' | 'handleSingleSelect'>;

const OPTIONS = AVAILABLE_PRODUCT_TYPES.map((productType) => ({
  value: productType,
  label: productType,
}));

export const ProductTypeSelect = (props: Props): JSX.Element => (
  <Dropdown {...props} options={OPTIONS} />
);
