import {
  ConsultationStatus,
  CustomerQuery,
  FulfillmentStatus,
  JobType,
  MembershipStatus,
  MembershipSubscriptionStatus,
  OrderStatus,
  OtcScheduleCadence,
  PaymentGateway,
  ProblemType,
  PurchaseStatus,
  SequenceContextStatus,
  TreatmentStatus,
  PurchaseGroupActivationRequirement,
  PurchaseCancelReason,
  Maybe,
} from 'graphql/types';

export type Customer = NonNullable<CustomerQuery['customer']>;
export type Consultation = Customer['consultations'][number];
export type Treatment = Customer['treatments'][number];
export type QuizApplications = Customer['quizApplications'];
export type Tracker = Customer['tracker'];

export enum ConsultDoctorCompletedStatus {
  Incomplete = 'Incomplete',
  Approved = 'Approved',
  Declined = 'Declined',
}

export type CustomerConsultationView = {
  id: string;
  status: ConsultationStatus;
  completed?: Date;
  createdAt: Date;
  doctor?: string;
  isApproved: boolean;
  callRecorded: boolean;
  prescribedSequences: Consultation['prescribedSequences'];
  purchasePrompt: Consultation['purchasePrompt'];
  stage: Consultation['stage'];
  reviewReason: Consultation['reviewReason'];
};

export type OrdersTableItem = {
  id: string;
  created: Date;
  updated: Date;
  product: string;
  status?: OrderStatus;
  fulfillment?: FulfillmentStatus;
  consultId: string;
  gateway?: PaymentGateway;
};

export type CustomerTreatmentJobView = {
  id: string;
  updatedAt: Date;
  type: JobType;
  scheduledAt?: Date;
  doneAt?: Date;
  message?: string;
  source?: string;
  failed: boolean;
  createdAt: Date;
  data: JSON;
  isReApprove?: boolean;
  orderId?: string;
  consultationId?: string;
  canceled: boolean;
  canReschedule: boolean;
};

export type CustomerOtcScheduleView = {
  cadence: OtcScheduleCadence;
  createdAt: Date;
  id: string;
  isActive: boolean;
  product: { id: string; name: string };
  quantity: number;
  treatmentId: string;
  updatedAt: Date;
};

export type CustomerTreatmentView = {
  id: string;
  status: TreatmentStatus;
  canReschedule?: boolean | null;
  name: string;
  productId: string;
  plan: string;
  planId: string;
  nextPaymentDue?: Date;
  supersededByFlexiPlans?: boolean | null;
  amount: number;
  repeats?: number;
  refillsLeft?: number;
  created: Date;
  updated: Date;
  completed?: Date;
  completedOrders: OrdersTableItem[];
  jobs: CustomerTreatmentJobView[];
  canRefill: boolean;
  canPause: boolean;
  canReview: boolean;
  canRevertReview: boolean;
  type: ProblemType;
  logs: Treatment['logs'];
  experimentPaymentPlan?: Treatment['experimentPaymentPlan'];
  experimentAvailablePaymentPlans: Treatment['experimentAvailablePaymentPlans'];
  scriptId: string;
  scriptExpiresAt?: string;
  customerStatedTreatmentStartedAt?: Date;
  consultation?: {
    id: string;
  };
  otcSchedules: CustomerOtcScheduleView[];
};

export type CustomerMembershipView = {
  id: string;
  userId: string;
  status: MembershipStatus;
  subscriptionStatus: MembershipSubscriptionStatus;
  isValid: boolean;
  type: ProblemType;
  amount: number;
  endDate?: Date;
  startDate?: Date;
  nextPaymentDue?: Date;
  jobs: CustomerTreatmentJobView[];
};

export type CustomerOfferingPurchaseGroupView = {
  id: string;
  unmetActivationRequirements: PurchaseGroupActivationRequirement[];
  purchases: {
    id: string;
    name: string;
    advertisedName: string;
    status: PurchaseStatus;
    cancelReason: Maybe<PurchaseCancelReason>;
    sequenceContexts: {
      id: string;
      name: string;
      status: SequenceContextStatus;
      addon: boolean;
      prescribedSequences: Maybe<
        Array<{
          id: string;
        }>
      >;
    }[];
  }[];
  overridden: Omit<CustomerOfferingPurchaseGroupView, 'overridden'>[];
};

export type CustomerViewProblemType = {
  name: string;
  type: ProblemType;
  consultations: CustomerConsultationView[];
  purchaseGroups: CustomerOfferingPurchaseGroupView[];
  treatments: CustomerTreatmentView[];
  membership?: CustomerMembershipView;
  shopfrontOrders: OrdersTableItem[];
  quizApplication: QuizApplications;
};

export type CustomerDetailsSubmit = {
  id: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  password: string;
  shippingAddress: {
    line1?: string;
    line2?: string;
    city?: string;
    postalCode?: string;
    prefecture?: string;
    municipality?: string;
    townArea?: string;
    state?: string;
    country?: string;
    deliveryInstructions?: string;
  };
};
