import { Maybe } from 'graphql/types';
import React, { useState } from 'react';
import photoRedactedPlaceholder from './assets/photo-redacted-placeholder.png';
import { Button } from 'components/button';

const NO_IMAGE_FALLBACK = 'No image found.';

export const ImageResponse = ({
  imageUrl,
}: {
  imageUrl: Maybe<string>;
}): React.ReactElement => {
  const [isBlurred, setIsBlurred] = useState(true);

  if (!imageUrl) {
    return (
      <div className="max-w-sm my-4">
        <h6>{NO_IMAGE_FALLBACK}</h6>
      </div>
    );
  }

  if (imageUrl === 'redacted.png') {
    return (
      <div className="max-w-sm my-4">
        <img src={photoRedactedPlaceholder} alt="Photo Redacted" />
      </div>
    );
  }

  return (
    <div className="max-w-sm my-4 relative cursor-pointer">
      <img
        src={imageUrl}
        className={`filter ${isBlurred ? 'blur-xl' : 'blur-none'}`}
        alt="Patient Image Answer"
      />
      <div className="absolute top-0 h-full w-full flex justify-center transition-opacity opacity-0 hover:opacity-100">
        <div className="self-center">
          <Button
            fullWidth
            onClick={(): void => {
              setIsBlurred(!isBlurred);
            }}
          >
            {`${isBlurred ? 'Reveal' : 'Blur'} image`}
          </Button>
        </div>
      </div>
    </div>
  );
};
