import { Button } from 'components/button';
import React, { useCallback } from 'react';
import { Link, generatePath, useHistory } from 'react-router-dom';
import { routes } from '../../utils/routes';
import { useHasOneOfPermissions } from 'components/permissions';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  usePaginatingSortingTable,
} from 'components/table';
import { Cell, Column } from 'react-table';
import { gql, useQuery } from '@apollo/client';
import {
  GetStockoutToolingBatchesQuery,
  StockoutToolingBatchStatus,
} from 'graphql/types';
import { Loading } from 'components/loading';

export const batchStatusMap = new Map<StockoutToolingBatchStatus, string>([
  ['BUILDING', 'Loading'],
  ['DISPATCHED', 'Complete'],
  ['ERROR_BUILDING', 'Error'],
  ['PENDING_DISPATCH', 'Awaiting Action'],
]);

export const ListBatchJobs = (): React.ReactElement => {
  const history = useHistory();

  const showCreateButton = useHasOneOfPermissions([
    'BULK_CREATE_CONSULTATION',
    'BULK_PAUSE_PURCHASE',
  ]);

  const { data, loading, error } = useQuery<GetStockoutToolingBatchesQuery>(gql`
    query getStockoutToolingBatches {
      stockoutToolingBatches {
        id
        status
        completedPercentage
        author {
          id
          fullName
        }
      }
    }
  `);

  const goToBatch = useCallback(
    (cell: Cell<Batch, unknown>) => {
      if (cell.column.id !== 'selection') {
        history.push(
          generatePath(routes.bulkAction, {
            batchJobId: cell.row.original.id,
          }),
        );
      }
    },
    [history],
  );

  const tableInstance = usePaginatingSortingTable({
    columns,
    data: data?.stockoutToolingBatches ?? [],
    pageNumber: 1,
    disableSortBy: true,
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div>
        Error - Unable to load bulk actions, please contact #help-engineering.
      </div>
    );
  }

  return (
    <>
      {showCreateButton && (
        <div className="flex justify-end">
          <div className="mb-5">
            <Link to={routes.createBulkAction}>
              <Button fullWidth variant="outline">
                Create New
              </Button>
            </Link>
          </div>
        </div>
      )}
      <Table tableInstance={tableInstance}>
        <TableHead />
        <TableBody>
          {tableInstance.page.map(
            (row) => (
              tableInstance.prepareRow(row),
              (
                <TableRow row={row} key={row.id}>
                  {row.cells.map((cell) => (
                    <TableCell
                      key={`${cell.row.original.id}-${cell.column.id}`}
                      cell={cell}
                      onClick={goToBatch}
                    />
                  ))}
                </TableRow>
              )
            ),
          )}
        </TableBody>
      </Table>
    </>
  );
};

type Batch = NonNullable<
  GetStockoutToolingBatchesQuery['stockoutToolingBatches']
>[number];

function batchTypeName(typeName: Batch['__typename']) {
  if (typeName === 'PausePurchaseStockoutToolingBatch') {
    return 'Pause Purchases';
  } else if (typeName === 'CreateReviewConsultationStockoutToolingBatch') {
    return 'Create Review Consultations';
  }
  return 'Unknown batch';
}

const columns: Column<Batch>[] = [
  {
    Header: 'author',
    Cell: (c) => (
      <div>
        <div>{c.row.original.author?.fullName ?? 'Failed to load'}</div>
        <pre className="text-gray-600 text-xs leading-5">
          {c.row.original.id.slice(-6)}
        </pre>
      </div>
    ),
    className: 'w-3/8',
  },
  {
    Header: 'Type',
    Cell: (c) => <div>{batchTypeName(c.row.original.__typename)}</div>,
    className: 'w-2/8',
  },
  {
    Header: 'Status',
    Cell: (c) => <div>{batchStatusMap.get(c.row.original.status)}</div>,
    className: 'w-2/8',
  },
  {
    Header: 'completed %',
    Cell: (c) => <div>{c.row.original.completedPercentage}</div>,
    className: 'w-2/8',
  },
];
