import { EducationReading } from 'graphql/types';
import { VFC } from 'react';
import { useHistory } from 'react-router-dom';
import { AddNewReadingButtonModal } from './add-new-reading-button-modal';
import { getAdminReadingPageRoute } from './utils';

export const ReadingsHeader: VFC = () => {
  const history = useHistory();

  const onCreatedReading = (reading: EducationReading): void => {
    const readingPageRoute = getAdminReadingPageRoute(reading.id);
    history.push(readingPageRoute);
  };
  return (
    <div className="flex flex-row">
      <div className="flex-grow">
        <h1 className="text-2xl font-semibold">Readings</h1>
      </div>
      <div>
        <AddNewReadingButtonModal onCreatedReading={onCreatedReading} />
      </div>
    </div>
  );
};
