import { Button } from 'components/button';
import { useState } from 'react';
import {
  ImpersonateCustomerModal,
  ImpersonateCustomerModalProps,
} from './impersonate-customer-modal';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { useHasPermissions } from 'components/permissions';

export const ImpersonateCustomerModalButton: React.FC<
  Omit<ImpersonateCustomerModalProps, 'show' | 'onClose'>
> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const featureFlagClient = useFeatureFlagClient();

  const customerImpersonationEnabled = featureFlagClient.getBoolean(
    'enable-login-as-patient',
  );

  const canImpersonateCustomer = useHasPermissions(['IMPERSONATE_CUSTOMER']);

  if (!customerImpersonationEnabled || !canImpersonateCustomer) {
    return null;
  }

  return (
    <>
      <Button size="small" onClick={() => setShowModal(true)}>
        Impersonate
      </Button>
      <ImpersonateCustomerModal
        show={showModal}
        onClose={() => setShowModal(false)}
        {...props}
      />
    </>
  );
};
