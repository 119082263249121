import { gql, useMutation } from '@apollo/client';
import { useAlert } from 'alert';
import { useNotifications } from 'notifications';
import { ReactElement } from 'react';
import { Button } from './button';
import {
  DeleteUserByIdMutation,
  DeleteUserByIdMutationVariables,
} from 'graphql/types';

interface Props {
  table: string;
  ids: string[] | undefined | null;
  onDeleted?: () => unknown;
}

const userDeleteBatchSize = 3;

export const DeleteManyUsersButton = ({
  table,
  ids,
  onDeleted,
}: Props): ReactElement => {
  const length = ids ? ids.length : 0;
  const showAlert = useAlert();
  const showNotification = useNotifications();

  const [deleteUser] = useMutation<
    DeleteUserByIdMutation,
    DeleteUserByIdMutationVariables
  >(
    gql`
      mutation DeleteUserById($id: String!) {
        deleteUser(id: $id)
      }
    `,
    {
      onCompleted: onDeleted,
    },
  );
  return (
    <Button
      fullWidth
      color="danger"
      disabled={length <= 0}
      onClick={async (): Promise<void> => {
        if (!ids || ids.length === 0) {
          return;
        }
        const result = await showAlert({
          content: `Are you sure you want to delete ${length} record${
            length === 1 ? '' : 's'
          } from ${table}?`,
        });

        if (result) {
          const results = [];
          for (let i = 0; i < ids.length; i += userDeleteBatchSize) {
            const batchIds = ids.slice(i, i + userDeleteBatchSize);
            const batchResults = await Promise.allSettled(
              batchIds.map((id) => deleteUser({ variables: { id } })),
            );
            results.push(...batchResults);
          }

          if (results.some((r) => r.status === 'rejected')) {
            showNotification({
              type: 'error',
              message: `Some or all users couldn't be deleted. Please try again.`,
            });
          } else {
            showNotification({
              type: 'success',
              message: `Successfully deleted ${length} users`,
            });
          }
        }
      }}
    >
      Delete {length} selected
    </Button>
  );
};
