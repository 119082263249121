import React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'components/button';
import { Input } from 'components/input';
import { Modal } from 'components/modal';
import { TrackerActionModalProps } from './types';
import {
  combineRules,
  maxLengthValidation,
  minLengthValidation,
  requiredValidation,
} from 'utils/form-validation';
import {
  DeleteTrackerEntryMutation,
  DeleteTrackerEntryMutationVariables,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import { TrackerDisplayInfo } from './tracker-display-info';
import { gql, useMutation } from '@apollo/client';

export const DeleteModal = ({
  observationId,
  show,
  onClose,
  entryDate,
  lastUpdatedAtDate,
  type,
  value,
  onActionComplete,
}: TrackerActionModalProps): React.ReactElement => {
  const [loading, setLoading] = React.useState(false);
  const showNotification = useNotifications();
  const { register, errors, handleSubmit } = useForm<{
    description: string;
  }>();

  const [deleteTrackerEntry] = useMutation<
    DeleteTrackerEntryMutation,
    DeleteTrackerEntryMutationVariables
  >(gql`
    mutation DeleteTrackerEntry($input: DeleteTrackerEntryInput!) {
      deleteTrackerEntry(input: $input) {
        ok
      }
    }
  `);

  const handleFormSubmit = handleSubmit(async ({ description }) => {
    try {
      setLoading(true);
      await deleteTrackerEntry({
        variables: {
          input: {
            observationId,
            description,
          },
        },
      });
      showNotification({
        type: 'success',
        message: 'Tracker entry deleted',
      });
      await onActionComplete();
    } catch {
      showNotification({
        type: 'success',
        message: 'Deletion failed',
      });
    } finally {
      setLoading(false);
    }
  });

  return (
    <Modal show={show} onClose={onClose} width="max-w-screen-sm">
      <form
        className="bg-white rounded-md p-6 space-y-4"
        onSubmit={handleFormSubmit}
      >
        <h3 className="heading-md">Delete tracker entry</h3>
        <div className="space-y-2 bg-orange-200 rounded-md p-4 text-orange-600">
          <p className="font-medium">
            Are you sure you want to delete the following entry?
          </p>
          <p>
            Once an entry has been deleted, it cannot be edited or recreated.
          </p>
        </div>
        <div className="grid grid-cols-3 gap-x-3 gap-y-4">
          <TrackerDisplayInfo
            {...{ entryDate, lastUpdatedAtDate, type, value }}
          />
          <label htmlFor="description" className="font-medium col-span-1">
            Reason
          </label>
          <div className="col-span-2">
            <Input
              name="description"
              ref={register(
                combineRules(
                  requiredValidation('reason'),
                  minLengthValidation('reason', 5),
                  maxLengthValidation('reason', 100),
                ),
              )}
              errorMessage={errors?.description?.message}
            />
          </div>
        </div>
        <div className="flex space-x-3 max-w-xs ml-auto">
          <Button
            fullWidth
            variant="outline"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            fullWidth
            color="danger"
            type="submit"
            loading={loading}
            disabled={loading}
          >
            Delete
          </Button>
        </div>
      </form>
    </Modal>
  );
};
