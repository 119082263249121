import React from 'react';
import { Input } from 'components/input';
import { useForm } from 'react-hook-form';
import { useDebouncedEffect } from 'utils/use-debounce-effect';
import { useUrlQuery } from 'utils/use-url-query';
import { useChangeUrl } from 'utils/user-change-url';

export type ScriptsUrlQueryParams = {
  search: string;
};

const FILTER_CHANGE_DEBOUNCE_MS = 500;

export const ScriptsFilter = (): React.ReactElement => {
  const searchQuery = useSearchURLQuery();

  const { register, watch } = useForm<ScriptsUrlQueryParams>({
    mode: 'onChange',
    defaultValues: {
      search: searchQuery,
    },
  });

  const search = watch().search;

  const changeUrl = useChangeUrl();
  useDebouncedEffect(
    () =>
      changeUrl({
        params: {
          search,
          pageIndex: 0,
        },
      }),
    FILTER_CHANGE_DEBOUNCE_MS,
    [search],
  );

  return (
    <form>
      <div className="flex space-x-8 mb-8">
        <div className="w-1/3">
          <Input
            ref={register}
            name="search"
            label="Search"
            placeholder="Search by Email"
          />
        </div>
      </div>
    </form>
  );
};

export const useSearchURLQuery = (): string | undefined =>
  useUrlQuery().get('search')?.trim();
