// THIS FILE IS COPIED FROM https://github.com/GetStream/stream-chat-react/blob/develop/src/components/Gallery/Image.tsx
import clsx from 'clsx';
import React, { useState } from 'react';
import {
  Attachment as DefaultAttachment,
  AttachmentProps,
  ImageProps,
  ModalGallery as DefaultModalGallery,
  useComponentContext,
  Modal as StreamModal,
} from 'stream-chat-react';
import { Button } from '../button';
import { Gallery } from './gallery';
import { useHasPermissions } from '../permissions';
import photoRedactedPlaceholder from '../assets/photo-redacted-placeholder.png';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import { gql, useQuery } from '@apollo/client';
import { CustomStreamAttachmentQuery } from 'graphql/types';
import { useHistory } from 'react-router-dom';
import { routes } from 'utils/routes';

type CustomAttachmentProps = AttachmentProps & { customerId: string };

export const CustomAttachment: React.FC<CustomAttachmentProps> = ({
  customerId,
  ...rest
}) => {
  if (rest.attachments[0].type === 'plug') {
    return (
      <PlugAttachment customerId={customerId} attachments={rest.attachments} />
    );
  }
  return <BlurredAttachment {...rest} />;
};

export const BlurredAttachment: React.FC<AttachmentProps> = ({
  Image,
  Gallery: defaultGallery,
  ...rest
}) => {
  return (
    <DefaultAttachment
      {...rest}
      Image={BlurredImage}
      Gallery={Gallery}
      Media={BlurredMedia}
    />
  );
};

const PlugAttachment: React.FC<{
  attachments: AttachmentProps['attachments'];
  customerId: string;
}> = (props) => {
  const history = useHistory();
  const { data } = useQuery<CustomStreamAttachmentQuery>(
    gql`
      query CustomStreamAttachment($where: UserWhereUniqueInput!) {
        customer: user(where: $where) {
          id
          plugRecommendations {
            id
            shopItem {
              id
              title
              otcOffering {
                id
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'cache-first',
      variables: {
        where: { id: props.customerId },
      },
    },
  );

  const plugRecommendationId = props.attachments[0]?.plugRecommendationId;
  if (typeof plugRecommendationId !== 'string') {
    return (
      <div className="str-chat__message-text-inner str-chat__message-simple-text-inner">
        <p>Unable to render product</p>
      </div>
    );
  }

  const shopItem = data?.customer?.plugRecommendations?.find(
    (r) => r.id === plugRecommendationId,
  )?.shopItem;
  if (!shopItem) {
    return (
      <div className="str-chat__message-text-inner str-chat__message-simple-text-inner">
        <p>This product is no longer available</p>
      </div>
    );
  }

  return (
    <div className="str-chat__message-text-inner str-chat__message-simple-text-inner">
      <div className="flex flex-col gap-y-3 p-2">
        <p>{shopItem.title}</p>
        <Button
          onClick={() =>
            history.push(`${routes.offerings}/${shopItem.otcOffering?.id}`)
          }
        >
          View product
        </Button>
      </div>
    </div>
  );
};

const BlurredImage: React.FC<ImageProps> = (props) => {
  const {
    dimensions = {},
    fallback,
    image_url,
    thumb_url,
    innerRef,
    previewUrl,
    style,
  } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isBlurred, setBlurred] = useState(true);
  const { ModalGallery = DefaultModalGallery } =
    useComponentContext('ImageComponent');

  const imageSrc = previewUrl || image_url || thumb_url;

  const toggleModal = () => {
    if (!isBlurred) setModalIsOpen(!modalIsOpen);
  };

  const canViewAttachments = useHasPermissions(['VIEW_PATIENT_ATTACHMENTS']);

  if (!canViewAttachments) {
    return (
      <div className="flex flex-col ">
        <img src={photoRedactedPlaceholder} alt="Photo Redacted" />
      </div>
    );
  }

  return (
    <div className="flex flex-col ">
      <div className="mb-1">
        <img
          alt={fallback}
          className={clsx({
            'filter blur-xl': isBlurred,
            'cursor-zoom-in': !isBlurred,
          })}
          data-testid="image-test"
          onClick={toggleModal}
          src={imageSrc}
          style={style}
          tabIndex={0}
          {...dimensions}
          {...(innerRef && { ref: innerRef })}
        />
        <StreamModal onClose={toggleModal} open={modalIsOpen}>
          <ModalGallery images={[props]} index={0} />
        </StreamModal>
      </div>
      <Button
        fullWidth
        onClick={(): void => {
          setBlurred(!isBlurred);
        }}
      >
        {`${isBlurred ? 'Reveal' : 'Blur'} image`}
      </Button>
    </div>
  );
};

const BlurredMedia: React.FC<ReactPlayerProps> = ({ url }) => {
  const [isHidden, setIsHidden] = useState(true);

  const canViewAttachments = useHasPermissions(['VIEW_PATIENT_ATTACHMENTS']);

  if (!canViewAttachments) {
    return (
      <div className="flex flex-col ">
        <img src={photoRedactedPlaceholder} alt="Video Redacted" />
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center h-full">
      {!isHidden && (
        <div className="mb-1" style={{ height: '80%' }}>
          <ReactPlayer
            className="react-player"
            controls
            height="80%"
            url={url}
            width="100%"
          />
        </div>
      )}
      {isHidden && (
        <p className="text-center pb-2">Patient has submitted a video</p>
      )}
      <Button
        fullWidth
        onClick={(): void => {
          setIsHidden((isHidden) => !isHidden);
        }}
      >
        {`${isHidden ? 'Reveal' : 'Hide'} video`}
      </Button>
    </div>
  );
};
