import { ProblemType } from 'graphql/types';
import { ImageResponse } from 'components/image-response';
import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { ObservationResponse } from './observation-response';
import { Question } from './types';
import { isImageAnswer, isObservationAnswer } from './utils';

export const PatientMessage = ({
  question,
  customerId,
  problemType,
}: {
  question: Question;
  customerId: string;
  problemType: ProblemType;
}): ReactElement => {
  const history = useHistory();
  const handleViewPhotos = (): void => {
    history.push(`/tracking/${customerId}?pt=${problemType}`);
  };
  if (isObservationAnswer(question.data)) {
    return <ObservationResponse onClick={handleViewPhotos} />;
  } else if (isImageAnswer(question.data)) {
    return <ImageResponse imageUrl={question.data.answer?.imageUrl} />;
  } else {
    return <>{question.data.answer?.value}</>;
  }
};
