import React from 'react';
import clsx from 'clsx';
import { InputError } from 'components/input-error';

type Props = {
  name: string;
  errorMessage?: string;
  label: string;
};

const BaseSidebarInput = (
  { errorMessage, name, label }: Props,
  ref: React.Ref<HTMLInputElement>,
): React.ReactElement => {
  return (
    <>
      <div className="md:w-1/3">
        <label
          className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
          htmlFor={name}
        >
          {label}
        </label>
      </div>
      <div className="md:w-2/3">
        <input
          id={name}
          type="text"
          name={name}
          ref={ref}
          className={clsx(
            'bg-gray-200',
            'appearance-none',
            'border-2',
            'border-gray-200',
            'rounded',
            'w-full',
            'py-1',
            'px-2',
            'text-gray-700',
            'leading-tight',
            'focus:outline-none',
            'focus:bg-white',
            'focus:border-purple-500',
            {
              'border-red-500 focus:border-red-500 focus:ring ring-red-500 ring-opacity-50':
                errorMessage,
            },
          )}
        />
        <InputError>{errorMessage}</InputError>
      </div>
    </>
  );
};

export const SidebarInput = React.forwardRef<HTMLInputElement, Props>(
  BaseSidebarInput,
);
