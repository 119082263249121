import { ToggleTabs } from '../toggle-tabs';

export const GraphTableTabs = ({
  selectedTab,
  onSelect,
}: {
  selectedTab: 'graph' | 'table';
  onSelect: (value: 'graph' | 'table') => void;
}): React.ReactElement | null => {
  return (
    <ToggleTabs
      value={selectedTab}
      options={[
        {
          value: 'graph',
          label: 'Graph',
        },
        {
          value: 'table',
          label: 'Table',
        },
      ]}
      onOptionSelect={(value): void =>
        onSelect(value === 'graph' ? 'graph' : 'table')
      }
    />
  );
};
