import { Switch } from 'components/switch';
import React from 'react';
import { useForm } from 'react-hook-form';
import { RequestReviewLayout } from './request-review-layout';
import { RequestReviewStepTransitionProps } from './types';

type SkipQuizStepProps = RequestReviewStepTransitionProps & {
  skipQuiz: boolean;
  setSkipQuiz: (skipQuiz: boolean) => void;
};

export const SkipQuizStep: React.FC<SkipQuizStepProps> = ({
  previousStep,
  nextStep,
  skipQuiz,
  setSkipQuiz,
}) => {
  const { watch, register, handleSubmit } = useForm<{
    skipQuizValue: boolean | string;
  }>({
    defaultValues: {
      skipQuizValue: skipQuiz,
    },
  });

  const onSubmit = handleSubmit(({ skipQuizValue }) => {
    setSkipQuiz(!!skipQuizValue);
    nextStep();
  });

  return (
    <RequestReviewLayout
      title="Can the patient skip the review consultation quiz?"
      description="If you select this option, please make sure you provide the practitioner with all the key notes."
      previous={previousStep}
      onSubmit={onSubmit}
    >
      <Switch
        label="Can skip quiz?"
        labelPosition="right"
        ref={register}
        checked={!!watch().skipQuizValue}
        value="skipQuizValue"
        name="skipQuizValue"
      />
    </RequestReviewLayout>
  );
};
