import React from 'react';
import { TrackerDisplayInfoProps } from './types';

interface TrackerDisplayFieldProps {
  label: string;
  value?: string | number;
}

const TrackerDisplayField = ({
  label,
  value,
}: TrackerDisplayFieldProps): React.ReactElement | null => {
  if (!value) {
    return null;
  }

  return (
    <>
      <div className="font-medium col-span-1">{label}</div>
      <div className="col-span-2">{value}</div>
    </>
  );
};

export const TrackerDisplayInfo = ({
  entryDate,
  lastUpdatedAtDate,
  type,
  value,
}: TrackerDisplayInfoProps): React.ReactElement => {
  return (
    <>
      <TrackerDisplayField label="Date of entry" value={entryDate} />
      <TrackerDisplayField label="Last updated" value={lastUpdatedAtDate} />
      <TrackerDisplayField label="Type" value={type} />
      <TrackerDisplayField label="Current value" value={value} />
    </>
  );
};
