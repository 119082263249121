import { Button } from 'components/button';
import { ControlledFileUpload } from 'components/file-upload';
import { Input } from 'components/input';
import { Label } from 'components/label';
import { TextArea } from 'components/text-area';
import React from 'react';
import { useForm } from 'react-hook-form';
import {
  healthCoachEmailIsValid,
  requiredValidation,
} from 'utils/form-validation';
import { Props, Fields } from '../types';

const HealthCoachForm = (props: Props): React.ReactElement => {
  const { submitting, onSubmit } = props;

  const {
    register,
    handleSubmit,
    control,
    errors,
    formState: { isDirty, dirtyFields },
    reset,
  } = useForm<Fields>({
    defaultValues: props.type === 'update' ? props.initialValues : {},
    mode: 'onBlur',
  });

  return (
    <form
      className="flex flex-col w-full pr-12"
      onSubmit={handleSubmit((fields: Fields) =>
        onSubmit(fields, dirtyFields, reset),
      )}
    >
      <div>
        <div className="flex flex-row w-1/3 justify-end float-right">
          <div className="w-auto">
            <Button
              fullWidth
              type="submit"
              loading={submitting}
              disabled={!isDirty}
            >
              {props.type === 'create' ? 'Create' : 'Save changes'}
            </Button>
          </div>
        </div>
      </div>
      <div className="pt-8 flex flex-row">
        <div className="pb-8 pr-8 flex flex-wrap flex-row w-2/3">
          <span className="pl-2 heading-md">Personal Info</span>
          <div className="flex flex-wrap w-full">
            <div className="w-full lg:w-1/2 p-2">
              <Input
                label="First name"
                name="firstName"
                autoFocus={true}
                errorMessage={errors.firstName?.message}
                ref={register(requiredValidation('firstName'))}
              />
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <Input
                label="Last name"
                name="lastName"
                errorMessage={errors.lastName?.message}
                ref={register(requiredValidation('lastName'))}
              />
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <Input
                label="Email address"
                name="email"
                errorMessage={errors.email?.message}
                ref={register({
                  ...requiredValidation('emailAddress'),
                  ...healthCoachEmailIsValid,
                })}
              />
            </div>
          </div>
          <span className="pl-2 pt-6 heading-md">Profile</span>
          <div className="w-full p-2">
            <TextArea
              name="bio"
              label="Health Coach Bio"
              placeholder="Add a Health Coach bio"
              rows={10}
              ref={register}
            />
          </div>
        </div>
        <div className="pb-8 w-1/3 flex flex-wrap content-start border-l-2 pl-12">
          <span className="heading-md">Health Coach Images</span>
          <div className="w-full pt-2">
            <div className="mb-3">
              <Label htmlFor="avatar">Avatar</Label>
            </div>
            <ControlledFileUpload
              control={control}
              name="avatar"
              errorMessage={errors.avatar?.message}
              rules={requiredValidation('avatar')}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default HealthCoachForm;
