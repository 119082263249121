import { gql, useQuery } from '@apollo/client';
import { Plan, PlanOrderByInput } from 'graphql/types';
import { Button } from 'components/button';
import { Loading } from 'components/loading';
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useOrderBy,
  usePageIndex,
  usePaginatingSortingTable,
} from 'components/table';
import React, { ReactElement, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CellProps, Column, TableInstance } from 'react-table';
import { formatCurrencyAmountCents } from 'utils/misc';
import { routes } from 'utils/routes';

const TABLE_PAGE_SIZE = 30;

const plansListQuery = gql`
  query Plans($orderBy: [PlanOrderByInput!], $pageSize: Int, $skip: Int) {
    plans(orderBy: $orderBy, take: $pageSize, skip: $skip) {
      id
      name
      amount
      interval
      intervalCount
      repeats
    }
    plansCount
  }
`;

const Plans = (): ReactElement => {
  const history = useHistory();
  const orderBy = useOrderBy({ defaultValue: 'createdAt_desc' });
  const pageIndex = usePageIndex();

  const { data, loading } = useQuery<
    { plans: Plan[]; plansCount: number },
    {
      orderBy: PlanOrderByInput[];
      pageSize: number;
      skip: number;
    }
  >(plansListQuery, {
    variables: {
      pageSize: TABLE_PAGE_SIZE,
      skip: pageIndex * TABLE_PAGE_SIZE,
      orderBy,
    },
  });
  const total = data?.plansCount || 0;
  const plans = useMemo(() => data?.plans || [], [data]);

  const tableInstance = usePaginatingSortingTable({
    columns,
    data: plans,
    pageNumber: Math.ceil(total / TABLE_PAGE_SIZE),
    orderBy,
    pageIndex,
  });

  const handleCellClick = useMemo(
    () =>
      (planId: string): void => {
        history.push(`${routes.plans}/${planId}`);
      },
    [history],
  );

  return (
    <PlansRenderer
      total={total}
      loading={loading}
      tableInstance={tableInstance}
      onCellClick={handleCellClick}
    />
  );
};

interface PlansRendererProps {
  loading: boolean;
  tableInstance: TableInstance<Plan>;
  onCellClick: (planId: string) => void;
  total: number | string;
}

const PlansRenderer = ({
  loading,
  tableInstance,
  onCellClick,
  total,
}: PlansRendererProps): ReactElement => {
  if (loading) {
    return (
      <div className="flex justify-center text-lg">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-end">
        <div>
          <Link to={routes.createPlan}>
            <Button fullWidth variant="outline">
              Create New
            </Button>
          </Link>
        </div>
      </div>
      <Table tableInstance={tableInstance}>
        <TableHead />
        <TableBody>
          <>
            {tableInstance.page.map(
              (row) => (
                tableInstance.prepareRow(row),
                (
                  <TableRow row={row} key={row.id}>
                    <>
                      {row.cells.map((cell) => (
                        <TableCell
                          key={`${cell.row.original.id}-${cell.column.id}`}
                          cell={cell}
                          onClick={(): void => {
                            if (cell.column.id !== 'selection') {
                              onCellClick(cell.row.original.id);
                            }
                          }}
                        />
                      ))}
                    </>
                  </TableRow>
                )
              ),
            )}
          </>
        </TableBody>
      </Table>
      <Pagination total={total} tableInstance={tableInstance} />
    </>
  );
};

const AmountCell = (cell: CellProps<Plan, number>): ReactElement => (
  <div>{formatCurrencyAmountCents(cell.value)}</div>
);

const columns: Column<Plan>[] = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-1/5',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: AmountCell,
    className: 'w-1/5',
  },
  {
    Header: 'Interval',
    accessor: 'interval',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-1/5',
  },
  {
    Header: 'IntervalCount',
    accessor: 'intervalCount',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-1/5',
  },
  {
    Header: 'Repeats',
    accessor: 'repeats',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-1/5',
  },
];

export default Plans;
