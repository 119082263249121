import { gql, useMutation } from '@apollo/client';
import {
  CancelTreatmentMutation,
  CancelTreatmentMutationVariables,
  TreatmentStatus,
} from 'graphql/types';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { useNotifications } from 'notifications';
import { useState } from 'react';

interface Props {
  treatmentId: string;
  status: TreatmentStatus;
  refetchCustomer: () => void;
}

// use to generate mutation types
const cancelTreatmentDocument = gql`
  mutation cancelTreatment($treatmentId: String!) {
    cancelTreatment(treatmentId: $treatmentId) {
      id
    }
  }
`;

export const CancelTreatmentButton: React.FC<Props> = ({
  treatmentId,
  status,
  refetchCustomer,
}: Props) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const showNotification = useNotifications();
  const [cancelTreatment, { loading }] = useMutation<
    CancelTreatmentMutation,
    CancelTreatmentMutationVariables
  >(cancelTreatmentDocument);

  const handleCancelTreatmentClick = async (): Promise<void> => {
    await cancelTreatment({
      variables: {
        treatmentId,
      },
    });
    showNotification({
      message: 'Treatment has been canceled',
      type: 'success',
    });
    refetchCustomer();
    setShowConfirm(false);
  };
  return (
    <>
      <button
        type="button"
        className="inline-flex disabled:cursor-not-allowed disabled:opacity-25 items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded text-gray-700 bg-white focus:outline-none focus:ring-blue focus:border-blue-300 active:text-gray-800 active:bg-white transition duration-150 ease-in-out"
        disabled={status === 'CANCELED'}
        onClick={(): void => {
          setShowConfirm(true);
        }}
      >
        🔴 Cancel treatment
      </button>
      {showConfirm && (
        <Modal
          show
          onClose={(): void => {
            setShowConfirm(false);
          }}
        >
          <div className="bg-white rounded p-4 space-y-4">
            <p className="mb-3 font-bold">
              Are you sure you wish to cancel this treatment?
            </p>
            <hr />
            <p>
              &#128680; This action should only be taken in the case of patient
              safety risk, where we don&apos;t want the patient to unpause their
              treatment. In all other cases, please use <strong>Pause</strong>,
              so a patient has the option to resume in the future.
            </p>
            <p>
              Once you confirm, the patient will no longer have access to their
              treatment. Before proceeding:
            </p>
            <ul className="list-disc list-inside">
              <li>
                Explain to the patient why you have cancelled their treatment
              </li>
              <li>Cancel any active memberships</li>
              <li>Unsubscribe from relevant marketing emails</li>
              <li>
                If an order has been paid but not shipped, cancel (in Shopify)
                and refund
              </li>
              <li>{`If an order is in "Awaiting Script", cancel and refund`}</li>
              <li>Process any additional refunds (if necessary)</li>
            </ul>
            <p>
              <span className="text-red-500 font-bold">
                Note: This action cannot be undone.
              </span>
            </p>
            <div className="flex gap-x-4 bg-gray-100">
              <Button
                fullWidth
                autoFocus={true}
                variant="outline"
                onClick={(): void => {
                  setShowConfirm(false);
                }}
              >
                Cancel
              </Button>
              <Button
                fullWidth
                color="primary"
                onClick={handleCancelTreatmentClick}
                loading={loading}
                disabled={loading}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
