import { gql, useMutation } from '@apollo/client';
import { Plan, PlanCreateInput } from 'graphql/types';
import { useNotifications } from 'notifications';
import { ReactElement, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'utils/routes';
import PlanForm, { Fields } from './plan-form';

const createPlanMutation = gql`
  mutation createOnePlan(
    $name: String!
    $amount: Int!
    $description: String!
    $slug: String!
    $interval: BillingInterval!
    $intervalCount: Int!
    $repeats: Int!
  ) {
    createOnePlan(
      data: {
        name: $name
        amount: $amount
        description: $description
        slug: $slug
        interval: $interval
        intervalCount: $intervalCount
        repeats: $repeats
      }
    ) {
      id
    }
  }
`;

const mapFieldsToNewPlanData = (fields: Fields): PlanCreateInput => ({
  name: fields.name,
  amount: Number(fields.amount),
  description: fields.description,
  slug: fields.slug,
  interval: fields.interval,
  intervalCount: Number(fields.intervalCount),
  repeats: Number(fields.repeats),
});

const CreatePlan = (): ReactElement => {
  const showNotification = useNotifications();
  const history = useHistory();

  const [createPlan, { loading }] = useMutation<{ createOnePlan: Plan }>(
    createPlanMutation,
    {
      onCompleted: ({ createOnePlan: plan }) => {
        showNotification({
          type: 'success',
          message: 'Plan created successfully',
        });
        history.push(`${routes.plans}/${plan.id}`);
      },
      onError: () => {
        showNotification({
          type: 'error',
          message: 'Error creating plan',
        });
      },
    },
  );

  const handleSubmit = useMemo(
    () =>
      async (fields: Fields): Promise<void> => {
        await createPlan({ variables: mapFieldsToNewPlanData(fields) });
      },
    [createPlan],
  );

  return (
    <div className="container">
      <PlanForm type="create" onSubmit={handleSubmit} submitting={loading} />
    </div>
  );
};

export default CreatePlan;
