import { gql, useQuery } from '@apollo/client';
import { validate } from 'uuid';
import SubscriptionPlanForm, {
  Fields,
} from '../subscription-plans/new/subscription-plan-form';
import { SubscriptionPlanV2 } from 'graphql/types';
import { Loading } from 'components/loading';
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';

const getSubscriptionPlanByIdQuery = gql`
  query SubscriptionPlanV2ById($id: String!) {
    subscriptionPlanV2ById(id: $id) {
      id
      name
      description
      photo {
        id
        url
      }
      price
      subscriptionLineItems {
        id
        productId
        product {
          id
          name
        }
      }
      interval
      intervalCount
    }
  }
`;

const SubscriptionPlan = (): ReactElement => {
  const { subscriptionPlanId } = useParams<{ subscriptionPlanId: string }>();
  const {
    data: subscriptionPlanData,
    loading,
    error,
  } = useQuery<{
    subscriptionPlanV2ById: SubscriptionPlanV2;
  }>(getSubscriptionPlanByIdQuery, {
    variables: { id: subscriptionPlanId },
    skip: !validate(subscriptionPlanId),
  });

  const subscriptionPlan = subscriptionPlanData?.subscriptionPlanV2ById;
  const lineItemProductId =
    subscriptionPlan?.subscriptionLineItems[0]?.productId;

  if (loading) {
    return <Loading />;
  }

  if (error || !subscriptionPlan) {
    return <div>Failed to retrieve plan.</div>;
  }

  const mapGraphQLSubscriptionPlanToFields = (
    subscriptionPlan: SubscriptionPlanV2,
  ): Fields => ({
    name: subscriptionPlan.name,
    amount: subscriptionPlan.price,
    description: subscriptionPlan.description || '',
    interval: subscriptionPlan.interval,
    intervalCount: subscriptionPlan.intervalCount,
    lineItem: lineItemProductId || '',
    photo: JSON.stringify(subscriptionPlan.photo),
  });

  return (
    <div>
      <div className="space-y-3 mb-8 w-1/2">
        <p className="font-bold uppercase">⚠️ Warning</p>
        <p>
          You cannot edit subscription plans once they have been created. Please
          raise a help-engineering request if you need assistance.
        </p>
      </div>
      <SubscriptionPlanForm
        type="view"
        initialValues={mapGraphQLSubscriptionPlanToFields(subscriptionPlan)}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSubmit={(): void => {}}
        submitting={false}
      />
    </div>
  );
};

export default SubscriptionPlan;
