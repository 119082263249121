import { Button } from 'components/button';
import { useState } from 'react';
import { NoteHistoryModal, NoteHistoryModalProps } from './note-history-modal';

export const NoteHistoryModalButton: React.FC<
  Omit<NoteHistoryModalProps, 'show' | 'onClose'>
> = (props) => {
  const [showModal, setShowModal] = useState(false);

  if (!props.note.history || props.note.history.length <= 1) {
    return null;
  }

  return (
    <>
      <Button size="small" variant="link" onClick={() => setShowModal(true)}>
        View History
      </Button>
      <NoteHistoryModal
        show={showModal}
        onClose={() => setShowModal(false)}
        {...props}
      />
    </>
  );
};
