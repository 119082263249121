import { Button } from 'components/button';
import { FaCheck } from 'react-icons/fa';
import { Modal } from 'components/modal';
import clsx from 'clsx';
import { gql, useMutation } from '@apollo/client';
import {
  BookingRescheduleModalPractitionerBookingWindowFragment,
  ReschedulePractitionerBookingsMutation,
  ReschedulePractitionerBookingsMutationVariables,
} from 'graphql/types';
import { useForm } from 'react-hook-form';
import { RadioButton } from 'components/radio-button';
import { useState } from 'react';
import { formatWindowDay, formatWindowInterval } from 'utils/queues';

export const BookingRescheduleModalPractitionerBookingWindowDoc = gql`
  fragment BookingRescheduleModalPractitionerBookingWindow on PractitionerBookingWindow {
    id
    windowId
    available
    startAt
    endAt
    problemType
    override {
      id
    }
  }
`;

type BookingRescheduleModalProps = {
  show: boolean;
  onClose: () => void;
  practitionerBookingWindows: NonNullable<
    BookingRescheduleModalPractitionerBookingWindowFragment[]
  >;
  bookingIdsToReschedule: string[];
  onComplete: () => Promise<void>;
};

export const BookingRescheduleModal: React.FC<BookingRescheduleModalProps> = ({
  show,
  practitionerBookingWindows,
  bookingIdsToReschedule,
  onClose,
  onComplete,
}) => {
  const firstAvailableWindow = practitionerBookingWindows.find(
    (w) => w.available,
  );
  const { watch, register, handleSubmit, reset } = useForm({
    defaultValues: {
      targetBookingId: firstAvailableWindow?.id,
    },
  });

  const [loading, setLoading] = useState(false);

  const [reschedulePractitionerBookingsMutation] = useMutation<
    ReschedulePractitionerBookingsMutation,
    ReschedulePractitionerBookingsMutationVariables
  >(gql`
    mutation ReschedulePractitionerBookings(
      $bookingIds: [ID!]!
      $windowId: ID!
    ) {
      reschedulePractitionerBookings(
        input: { bookingIds: $bookingIds, windowId: $windowId }
      ) {
        practitionerBookings {
          id
          windowStartAt
          windowEndAt
          bookedAt
          bookedBy {
            id
          }
        }
      }
    }
  `);

  const cellBorderStyle = 'border border-gray-300 py-2.5 px-2';

  const onModalClose = () => {
    onClose();
    reset();
  };

  const submit = handleSubmit(async (formData) => {
    setLoading(true);
    const windowId = formData.targetBookingId;
    if (!windowId) {
      return;
    }
    await reschedulePractitionerBookingsMutation({
      variables: {
        bookingIds: bookingIdsToReschedule,
        windowId: windowId,
      },
    });
    await onComplete();
    onClose();
    setLoading(false);
  });
  return (
    <Modal show={show} onClose={onModalClose}>
      <div className="bg-gray-200 p-6">
        <div className="text-lg font-semibold">Allocate to new window</div>
        <div className="text-sm">
          Please allocate the{' '}
          <span className="font-semibold">
            {bookingIdsToReschedule.length}{' '}
            {bookingIdsToReschedule.length > 1 ? 'patients' : 'patient'}
          </span>{' '}
          to a new time slot.
        </div>
        <table
          className="bg-white shadow w-full text-center border-collapse mt-8"
          style={{
            borderStyle: 'hidden',
          }}
        >
          <tbody className="text-sm">
            {practitionerBookingWindows.map((w) => {
              const day = formatWindowDay(w);

              const interval = formatWindowInterval(w);

              return (
                <tr
                  key={w.id}
                  className={clsx(
                    !w.available &&
                      (w.override?.id ? 'bg-red-100' : 'bg-gray-300'),
                  )}
                >
                  <td className={clsx(cellBorderStyle, 'text-center')}>
                    {w.available ? (
                      <RadioButton
                        id={w.id}
                        ref={register}
                        name="targetBookingId"
                        selected={watch().targetBookingId === w.id}
                        customIcon={<FaCheck className="my-0 mx-auto" />}
                      />
                    ) : (
                      ' - '
                    )}
                  </td>
                  <td className={cellBorderStyle}>{day}</td>
                  <td className={cellBorderStyle}>{interval}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <form className="mt-6" onSubmit={submit}>
          <div className="flex flex-row gap-x-5 mt-8">
            <Button
              fullWidth
              loading={loading}
              disabled={loading}
              color="danger"
              onClick={onModalClose}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              loading={loading}
              disabled={loading}
              type="submit"
              color="primary"
            >
              Confirm
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
