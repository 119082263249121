import { config } from 'config';
import { formatInTimeZone } from 'date-fns-tz';

export const formatWindowDay = (window: { startAt: string | Date | number }) =>
  formatInTimeZone(window.startAt, config.defaultTimezone, 'EEE LLL do');

export const formatWindowInterval = (window: {
  startAt: string | Date | number;
  endAt: string | Date | number;
}): string => {
  const startInterval = formatInTimeZone(
    window.startAt,
    config.defaultTimezone,
    'haa',
    {
      locale: config.dateFnsLocale,
    },
  );

  const endInterval = formatInTimeZone(
    window.endAt,
    config.defaultTimezone,
    'haa',
    {
      locale: config.dateFnsLocale,
    },
  );

  return `${startInterval} - ${endInterval}`.toLocaleLowerCase();
};

export const formattedWindowTimezone = formatInTimeZone(
  new Date(),
  config.defaultTimezone,
  'zzz',
  {
    locale: config.dateFnsLocale,
  },
);
