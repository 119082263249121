import { DefaultConfig } from './types';
import enAU from 'date-fns/locale/en-AU';

export const defaultConfig: DefaultConfig = {
  brand: 'pilot', // Default brand, will be overridden by brand default config.
  datadogToken: 'pub9d124d4d6cd237c7b21f5b61f8bb9d43',
  datadogRumApplicationId: '6286cb45-e8ed-43ce-9423-62177ac03fa0',
  datadogRumClientToken: 'puba5d63e833251851cd83fb5859d2367e6',
  membershipEnabled: false,
  launchDarklyClientId: '6062ab38c8369f0e6d6c5ee5',
  currency: 'AUD',
  currencyLocales: 'en-AU',
  country: 'Australia',
  enableDeliveryBufferInProduct: false,
  requireInternationalClinicianPhone: false,
  fulfilmentVendors: ['shopify'],
  healthCoachCreation: false,
  healthCoachingFlows: false,
  showDeliveryInstructions: false,
  defaultTimezone: 'Australia/Sydney',
  dateFnsLocale: enAU,
  planIntervals: ['Daily', 'Weekly', 'Monthly'],
  syncConsultationsEnabled: false,
  medicareEnabled: false,
  lgaEnabled: false,
  plugsEnabled: false,
  shopEnabled: false,
  isDigitalSigningEnabled: false,
  isDispensementReuseEnabled: false,
};
