import React, { useEffect, useRef } from 'react';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
} from '@apollo/client';
import possibleTypes from 'graphql/possible-types';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { calcMillisUntilExpiry, useAuth } from 'auth';

import { config } from './config';
import { useNotifications } from './notifications';

export const ClientProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const [client, setClient] =
    React.useState<ApolloClient<NormalizedCacheObject>>();

  const showNotification = useNotifications();

  const { accessToken: token, logout } = useAuth();

  const tokenRef = useRef(token);
  tokenRef.current = token;

  useEffect(() => {
    const httpLink = createHttpLink({
      uri: `${config.apiUrl}/graphql`,
    });

    const withHeaders = setContext(async (_, { headers }) => {
      let authorization = '';
      if (tokenRef.current) {
        authorization = `Bearer ${tokenRef.current}`;

        // Assume requests take 5 seconds and log
        // user out preemptively.
        if (calcMillisUntilExpiry(tokenRef.current) < 5_000) {
          logout?.();
        }
      }

      return {
        headers: {
          ...headers,
          brand: config.brand,
          accept: 'multipart/mixed;deferSpec=20220824,application/json',
          authorization,
        },
      };
    });

    const errorLink = onError(({ operation, networkError, graphQLErrors }) => {
      if (graphQLErrors && operation.operationName !== 'BigSearch') {
        graphQLErrors.forEach((err) => {
          if (err.extensions?.code === 'UNAUTHENTICATED') {
            logout?.();
          }

          showNotification({
            type: 'error',
            message: err.message,
          });
        });
      }

      if (networkError) {
        showNotification({
          type: 'warning',
          message: networkError.message,
        });
      }
    });

    const apolloClient = new ApolloClient({
      cache: new InMemoryCache({
        possibleTypes: possibleTypes.possibleTypes,
      }),
      link: withHeaders.concat(errorLink).concat(httpLink),
      defaultOptions: {
        watchQuery: {
          // Make sure we always get latest state from API when
          // a query is used for the first time.
          fetchPolicy: 'cache-and-network',
          // For the remainder of a query's lifecycle we're happy to
          // read already-cached data.
          nextFetchPolicy: 'cache-first',
          // Support partial responses in the case of errors.
          errorPolicy: 'all',
        },
      },
    });

    setClient(apolloClient);
  }, [showNotification, logout]);

  return client ? (
    <ApolloProvider client={client}>{children}</ApolloProvider>
  ) : (
    <div />
  );
};
