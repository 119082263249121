import { CreateCollection } from './create-collection';
import { ContentCollectionsTable } from './content-collections-table';

export function ContentCollectionsPage(): JSX.Element {
  return (
    <>
      <section>
        <div className="flex justify-end mb-8">
          <div>
            <CreateCollection />
          </div>
        </div>
      </section>
      <ContentCollectionsTable />
    </>
  );
}
