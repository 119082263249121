import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

const createErxMedicineMutation = gql`
  mutation createErxMedicine($erxMedicine: ErxMedicineCreateInput!) {
    createErxMedicine(erxMedicine: $erxMedicine) {
      id
    }
  }
`;

const updateErxMedicineMutation = gql`
  mutation updateErxMedicine($erxMedicine: ErxMedicineUpdateInput!) {
    updateErxMedicine(erxMedicine: $erxMedicine) {
      id
    }
  }
`;

const deleteErxMedicineMutation = gql`
  mutation deleteErxMedicine($id: String!) {
    deleteErxMedicine(id: $id)
  }
`;

export interface ErxMedicineCreateInput {
  productId: string;
  productName: string;
  genericName: string;
  itemForm: string;
  routeAdministration: string;
  mimsId?: string | null;
  instructions: string;
  quantity: string;
}

export interface ErxMedicineUpdateInput {
  id: string;
  productName?: string;
  genericName?: string;
  itemForm?: string;
  routeAdministration?: string;
  mimsId?: string | null;
  itemStrength?: string | null;
  instructions?: string;
  quantity?: string;
}

export const useErxMedicineCrud = (): {
  createErxMedicine: (
    erxMedicineCreateInput: ErxMedicineCreateInput,
  ) => Promise<void>;
  updateErxMedicine: (
    erxMedicineUpdateInput: ErxMedicineUpdateInput,
  ) => Promise<void>;
  deleteErxMedicine: (id: string) => Promise<void>;
} => {
  const [createErxMedicine] = useMutation<{
    createErxMedicine: { id: string };
  }>(createErxMedicineMutation);
  const [updateErxMedicine] = useMutation<{
    updateErxMedicine: { id: string };
  }>(updateErxMedicineMutation);
  const [deleteErxMedicine] = useMutation<{ deleteErxMedicine: boolean }>(
    deleteErxMedicineMutation,
  );

  const createErxMedicineWrapper = useCallback(
    async (erxMedicineCreateInput: ErxMedicineCreateInput): Promise<void> => {
      await createErxMedicine({
        variables: {
          erxMedicine: erxMedicineCreateInput,
        },
      });
    },
    [createErxMedicine],
  );

  const updateErxMedicineWrapper = useCallback(
    async (erxMedicineUpdateInput: ErxMedicineUpdateInput): Promise<void> => {
      await updateErxMedicine({
        variables: {
          erxMedicine: erxMedicineUpdateInput,
        },
      });
    },
    [updateErxMedicine],
  );

  const deleteErxMedicineWrapper = useCallback(
    async (id: string): Promise<void> => {
      await deleteErxMedicine({
        variables: {
          id,
        },
      });
    },
    [deleteErxMedicine],
  );

  return {
    createErxMedicine: createErxMedicineWrapper,
    updateErxMedicine: updateErxMedicineWrapper,
    deleteErxMedicine: deleteErxMedicineWrapper,
  };
};
