import { ReactElement } from 'react';
import { Input } from 'components/input';
import { DeepMap, useForm } from 'react-hook-form';
import { BillingInterval } from 'graphql/types';
import { Dropdown, Option } from 'components/dropdown';
import { Button } from 'components/button';
import {
  positiveIntegerValidation,
  requiredValidation,
} from 'utils/form-validation';
import clsx from 'clsx';
import { config } from 'config';

export type Fields = {
  name: string;
  amount: number;
  description: string;
  slug: string;
  interval: BillingInterval;
  intervalCount: number;
  repeats: number;
};

type SharedProps = {
  onSubmit: (
    fields: Fields,
    dirtyFields: DeepMap<Fields, true>,
    reset: (values?: Fields) => void,
  ) => Promise<void>;
  submitting: boolean;
};

type UpdateProps = {
  type: 'update';
  initialValues: Fields;
  delete: () => void;
};

type CreateProps = {
  type: 'create';
};

type Props = SharedProps & (UpdateProps | CreateProps);

type Interval = Option & { value: BillingInterval };

const intervals = [
  { label: 'Daily', value: 'Daily' },
  { label: 'Weekly', value: 'Weekly' },
  { label: 'Monthly', value: 'Monthly' },
].filter((i) =>
  config.planIntervals.includes(i.value as BillingInterval),
) as Interval[];

const PlanForm = ({ onSubmit, submitting, ...props }: Props): ReactElement => {
  const {
    control,
    errors,
    formState: { isDirty, dirtyFields, isValid },
    handleSubmit,
    register,
    reset,
  } = useForm<Fields>({
    defaultValues: props.type === 'update' ? props.initialValues : {},
    mode: 'onChange',
  });

  return (
    <form
      className="flex flex-col gap-8"
      onSubmit={handleSubmit((fields: Fields) => {
        onSubmit(fields, dirtyFields, reset);
      })}
    >
      <div className="flex gap-4">
        <div className="w-1/2">
          <Input
            name="name"
            label="Name"
            autoFocus
            ref={register(requiredValidation('name'))}
            errorMessage={errors.name?.message}
          />
        </div>
        <div className="w-1/2">
          <Input
            name="amount"
            label="Amount in cents"
            type="text"
            ref={register({
              ...requiredValidation('amount'),
              ...positiveIntegerValidation('amount'),
            })}
            errorMessage={errors.amount?.message}
          />
        </div>
      </div>
      <div>
        <Input
          name="description"
          label="Description"
          ref={register(requiredValidation('description'))}
          errorMessage={errors.description?.message}
        />
      </div>
      <div>
        <Input
          name="slug"
          label="Slug"
          ref={register(requiredValidation('slug'))}
          errorMessage={errors.description?.message}
        />
      </div>
      <div className="flex gap-4">
        <div className="w-1/2">
          <Dropdown
            name="interval"
            label="Interval"
            options={intervals}
            control={control}
            rules={requiredValidation('interval')}
            errorMessage={errors.interval?.message}
          />
        </div>
        <div className="w-1/2">
          <Input
            name="intervalCount"
            label="Interval Count"
            type="text"
            ref={register({
              ...requiredValidation('intervalCount'),
              ...positiveIntegerValidation('interval count'),
            })}
            errorMessage={errors.intervalCount?.message}
          />
        </div>
      </div>
      <div>
        <Input
          name="repeats"
          label="Repeats"
          type="text"
          ref={register({
            ...requiredValidation('repeats'),
            ...positiveIntegerValidation('repeats'),
          })}
          errorMessage={errors.repeats?.message}
        />
      </div>
      <div className="flex justify-between">
        <div className={clsx({ hidden: props.type === 'create' })}>
          <Button
            fullWidth
            variant="outline"
            loading={submitting}
            onClick={props.type === 'update' ? props.delete : undefined}
          >
            Delete
          </Button>
        </div>
        <div>
          <Button
            fullWidth
            type="submit"
            loading={submitting}
            disabled={!isDirty || !isValid}
          >
            {props.type === 'create' ? 'Create' : 'Save changes'}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default PlanForm;
