export const card = {
  'overflow-hidden': true,
  rounded: true,
  shadow: true,
  'bg-white': true,
} as const;

export const cardHeading = {
  'text-lg': true,
  'leading-6': true,
  'font-medium': true,
  'text-gray-900 ': true,
  // spacing/containing styles
  'px-4': true,
  'py-5': true,
  'first:border-t-0': true,
  'last:border-b-0': true,
  'border-t': true,
  'border-b': true,
  'border-gray-200': true,
} as const;

export const cardContents = {
  'p-4': true,
} as const;
