import { Modal } from 'components/modal';
import { UpsertNoteForm } from '../upsert-note-form';
import { NoteRowFragment, TargetType } from 'graphql/types';

export type EditNoteModalProps = {
  note: NoteRowFragment;
  show: boolean;
  onClose: () => void;
};

export const EditNoteModal: React.FC<EditNoteModalProps> = ({
  note,
  show,
  onClose,
}) => {
  let targetType: TargetType | undefined = undefined;
  let targetId;

  switch (note.__typename) {
    case 'CustomerNote':
      targetId = note.customer?.id;
      targetType = 'CUSTOMER';
      break;
    case 'ConsultationNote':
      targetId = note.consultation?.id;
      targetType = 'CONSULTATION';
      break;
    case 'MetaNote':
      throw new Error('Editing of MetaNotes is not enabled');
  }

  if (!targetId) {
    throw new Error(`targetId not set.`);
  }

  if (!targetType) {
    throw new Error(`targetType not set.`);
  }

  return (
    <Modal show={show} onClose={onClose} width="w-5/6">
      <section className="bg-gray-200">
        <header className="border-b border-gray-500 py-3 px-5 bg-gray-300">
          <h2 className="text-lg font-semibold mb-2">Edit note</h2>
        </header>
        <div className="space-y-3 px-5 py-3">
          <p className="text-sm">
            Editing notes should only be done to fix spelling/grammatical
            errors, or to update styling. Extra information should be added via
            addenda or a new note.
          </p>

          <UpsertNoteForm
            title="Note"
            onCompleted={onClose}
            noteInput={{
              id: note.id,
              body: note.body,
              targetType,
              targetId,
              domain: note.domain,
            }}
          />
        </div>
      </section>
    </Modal>
  );
};
