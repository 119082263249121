import { gql, useQuery } from '@apollo/client';
import { v4, validate } from 'uuid';
import { Card } from 'components/card';
import { ContextMenu } from 'components/context-menu';
import { CopyToClipboardButton } from 'components/copy-to-clipboard-button';
import { Loading } from 'components/loading';
import { Tag } from 'components/tag';
import {
  ConsignmentStatus,
  PurchaseQuery,
  PurchaseQueryVariables,
  PurchaseStatus,
  PurchaseCancelReason,
  Maybe,
  PurchaseSyncGroupStatus,
} from 'graphql/types';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { buildRoute } from 'utils/routes';
import {
  upperSnakeCaseToCapitalCase,
  Colors,
  formatDateAndTime,
  formatCurrencyAmountCents,
  purchaseCancelReasonToDisplayString,
} from '../../utils/misc';
import { PauseButton } from './pause';
import { ResumeButton } from './resume';
import { useCopyToClipboard } from 'react-use';
import { CancelButton } from './cancel';
import { ReactNode, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import {
  FaBolt,
  FaBox,
  FaChevronDown,
  FaChevronUp,
  FaClock,
  FaExternalLinkAlt,
  FaPlay,
} from 'react-icons/fa';
import { Mermaid } from 'components/mermaid';
import { SwitchButton, switchCandidatesFragment } from './switch';
import { Copyable } from 'components/copyable';
import { ReschedulePurchaseSyncGroup } from './reschedule-purchase-sync-group';
import { ResumePurchaseSyncGroup } from './resume-purchase-sync-group';
import { RepeatPurchaseSyncGroupOrder } from './repeat-purchase-sync-group-order';
import { compareAsc } from 'date-fns';

export function Purchase() {
  const history = useHistory();
  const [, copyToClipboard] = useCopyToClipboard();
  const { purchaseId, customerId } = useParams<{
    purchaseId: string;
    customerId: string;
  }>();
  const location = useLocation();
  const [focusedCsc, setFocusedCsc] = useState<string | null>(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('csc');
  });
  const [openDetails, setOpenDetails] = useState(() => new Set<string>());
  const [openVisualisations, setOpenVisualisations] = useState(
    () => new Set<string>(),
  );
  const [openTimelines, setOpenTimelines] = useState(() => new Set<string>());
  const [rxHover, setRxHover] = useState(false);

  const [modal, setModal] = useState<{
    syncGroupId: string;
    type: 'reschedule' | 'reschedule-immediately' | 'resume' | 'repeat';
  }>();

  useEffect(() => {
    const handler = () => setFocusedCsc(null);
    document.addEventListener('mousedown', handler);
    return () => document.removeEventListener('mousedown', handler);
  }, []);

  const {
    data: currentData,
    previousData,
    loading,
  } = useQuery<PurchaseQuery, PurchaseQueryVariables>(
    gql`
      query Purchase(
        $id: ID!
        $focusedSyncGroupId: ID!
        $focusingSyncGroup: Boolean!
      ) {
        focusedPurchaseSyncGroup: purchaseSyncGroup(id: $focusedSyncGroupId)
          @include(if: $focusingSyncGroup) {
          ...ReschedulePurchaseSyncGroup
          ...ResumePurchaseSyncGroup
          ...RepeatPurchaseSyncGroupOrder
        }

        purchase(id: $id) {
          id
          status
          cancelReason
          purchaseGroup {
            id
            pricingSessionId
          }
          supersededBy {
            id
          }
          offering {
            id
            friendlyName
            advertisedName
            ... @defer {
              ...SwitchCandidates
            }
          }
          syncGroups {
            id
            status
            sequenceContexts {
              id
              addon
              status
              mermaid
              pauseDescription
              pausedAt
              nextOrderDue
              overrides {
                id
                reason
                createdAt
              }
              scripts {
                id
                product {
                  id
                  name
                }
                repeats
                dispensementsRemaining
              }
              nextMoveDue
              startDate
              sequence {
                id
                friendlyName
              }
              prescribedSequences {
                id
                sequence {
                  id
                }
              }
            }
            ... @defer {
              timeline {
                id
                date
                status
                totalAmount
                discountAmount
                preDiscountTotalAmount
                order {
                  id
                  consignments {
                    id
                    status
                  }
                }
                products {
                  id
                  quantity
                  product {
                    id
                    friendlyName
                    name
                    productType
                    variants {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
      ${switchCandidatesFragment}
      ${ReschedulePurchaseSyncGroup.fragment}
      ${ResumePurchaseSyncGroup.fragment}
      ${RepeatPurchaseSyncGroupOrder.fragment}
    `,
    {
      variables: {
        id: purchaseId,
        focusedSyncGroupId: modal?.syncGroupId ?? '',
        focusingSyncGroup: !!modal,
      },
      skip: !validate(purchaseId),
    },
  );

  const data = currentData ?? previousData;
  const purchase = data?.purchase;

  const scrolled = useRef(false);
  useEffect(() => {
    if (!focusedCsc) {
      return;
    }

    const elem = document.getElementById(focusedCsc);
    if (!elem) {
      return;
    }

    if (!scrolled.current) {
      window.scrollTo({
        top: (elem?.getBoundingClientRect()?.top ?? 0) - 16,
        behavior: 'smooth',
      });
      scrolled.current = true;
    }
  }, [focusedCsc, loading]);

  let rxCscId = undefined;
  let prescribedSequence = undefined;
  let containsOverrides = false;
  let activeCscs = 0;
  for (const psg of purchase?.syncGroups ?? []) {
    for (const csc of psg.sequenceContexts ?? []) {
      if (csc.status === 'ACTIVE') {
        activeCscs++;
      }

      if (csc.prescribedSequences?.length) {
        prescribedSequence ??= csc.prescribedSequences?.[0];
        rxCscId ??= csc.id;
      }
    }
  }

  const [prevRxCscId, setPrevRxCscId] = useState(rxCscId);
  if (rxCscId && rxCscId !== prevRxCscId) {
    setPrevRxCscId(rxCscId);
    setOpenTimelines(new Set([rxCscId]));
    setOpenDetails(new Set([rxCscId]));
  }

  if (loading && !data) {
    return <Loading />;
  }

  if (!purchase) {
    return <div>Unable to find matching Purchase</div>;
  }

  const timelineItems = mapPurchaseTimelineItems(
    (purchase.syncGroups ?? []).map((sg) => sg.timeline ?? []).flat(),
  );

  const sequenceSelections = [];
  for (const psg of purchase.syncGroups ?? []) {
    for (const csc of psg.sequenceContexts ?? []) {
      if (csc.sequence?.id) {
        sequenceSelections.push({
          sequenceId: csc.sequence.id,
          isDisabled: csc.sequence.id === prescribedSequence?.sequence?.id,
        });
      }
    }
  }

  const supersededBy = purchase?.supersededBy;

  return (
    <div className="space-y-6">
      {supersededBy && (
        <div className="rounded p-5 bg-red-200 border border-red-500 flex flex-col gap-2">
          <h2 className="text-lg">
            Superseded by{' '}
            <span
              className="cursor-pointer underline text-slate-600"
              onClick={() => {
                history.push(
                  buildRoute.customerPurchase(customerId, supersededBy.id),
                );
              }}
            >
              {supersededBy.id}
            </span>
          </h2>
          <p>No action can be taken on superseded purchases.</p>
        </div>
      )}
      <div>
        <div className="flex justify-between">
          <div className="overflow-hidden">
            <h1 className="font-semibold text-xs uppercase mb-1 text-primary-300">
              Purchase
            </h1>
            <Link
              to={buildRoute.offering(purchase.offering?.id ?? '')}
              className="font-semibold text-lg truncate text-primary"
            >
              {purchase.offering?.friendlyName}
            </Link>
            {purchase.offering?.advertisedName && (
              <div className="mt-1 text-sm text-primary">
                {purchase.offering.advertisedName}
              </div>
            )}
            <div className="mt-7 space-x-2">
              <PauseButton
                purchaseId={purchase.id}
                disabled={purchase.status !== 'ACTIVE' || activeCscs === 0}
              />
              <ResumeButton
                purchaseId={purchase.id}
                disabled={purchase.status !== 'PAUSED'}
              />
              <CancelButton
                purchaseId={purchase.id}
                disabled={['COMPLETED', 'CANCELLED'].includes(purchase.status)}
              />
              <SwitchButton
                purchaseId={purchase.id}
                switchCandidates={purchase?.offering?.switchCandidates}
                purchaseStatus={purchase.status}
                selectedSequences={sequenceSelections}
              />
            </div>
          </div>
          <div className="flex flex-col justify-between items-end">
            <div>
              <PurchaseStatusTag
                status={purchase.status}
                cancelReason={purchase.cancelReason}
              />
            </div>
            <div>
              <Copyable text={purchase.id}>
                {(copied) => (
                  <pre className="text-xs cursor-pointer text-stone-400">
                    ID:&nbsp;&nbsp;&nbsp;
                    {copied ? 'COPIED' : purchase.id.slice(-6)}
                  </pre>
                )}
              </Copyable>
              <Copyable text={purchase.purchaseGroup?.id ?? ''}>
                {(copied) => (
                  <pre className="text-xs cursor-pointer text-stone-400">
                    PGID:&nbsp;
                    {copied ? 'COPIED' : purchase.purchaseGroup?.id.slice(-6)}
                  </pre>
                )}
              </Copyable>
              <Copyable text={purchase.purchaseGroup?.pricingSessionId ?? ''}>
                {(copied) => (
                  <pre className="text-xs cursor-pointer text-stone-400">
                    PSID:&nbsp;
                    {copied
                      ? 'COPIED'
                      : purchase.purchaseGroup?.pricingSessionId.slice(-6)}
                  </pre>
                )}
              </Copyable>
            </div>
          </div>
        </div>
      </div>

      {(() => {
        if (purchase.supersededBy) {
          return null;
        }

        return purchase.syncGroups?.map((sg) => {
          if (!sg.sequenceContexts?.length) {
            return null;
          }

          let accentBg = 'bg-purple-50';
          let accentButtonBg = 'bg-purple-50';
          let accentText = 'text-purple-700';
          let accentButtonText = 'text-purple-500';
          let accentBorder = 'border-purple-300';
          let accentButtonBorder = 'border-purple-400';

          if (sg.sequenceContexts.length === 1) {
            accentBg = 'bg-blue-50';
            accentButtonBg = 'bg-blue-50';
            accentText = 'text-blue-700';
            accentButtonText = 'text-blue-500';
            accentBorder = 'border-blue-300';
            accentButtonBorder = 'border-blue-400';
          }

          const buttonClass = `inline-flex relative text-center hover:brightness-95 disabled:cursor-not-allowed disabled:opacity-25 items-center px-3 py-1 border text-sm leading-5 rounded ${accentButtonBorder} ${accentButtonText} ${accentButtonBg}`;

          const rescheduleLoading =
            modal?.type === 'reschedule' &&
            modal.syncGroupId === sg.id &&
            !data.focusedPurchaseSyncGroup;

          const rescheduleImmediatelyLoading =
            modal?.type === 'reschedule-immediately' &&
            modal.syncGroupId === sg.id &&
            !data.focusedPurchaseSyncGroup;

          const resumeLoading =
            modal?.type === 'resume' &&
            modal.syncGroupId === sg.id &&
            !data.focusedPurchaseSyncGroup;

          const repeatLoading =
            modal?.type === 'repeat' &&
            modal.syncGroupId === sg.id &&
            !data.focusedPurchaseSyncGroup;

          return (
            <div
              key={sg.id}
              className={`p-4 border ${accentBg} ${accentBorder} rounded-md`}
            >
              <div className="flex justify-between items-center mb-3">
                <h2 className={`font-medium text-sm ${accentText}`}>
                  {sg.sequenceContexts.length > 1 ? 'Synced' : 'Independent'}
                </h2>
                <PurchaseSyncGroupStatusTag status={sg.status} />
              </div>
              <div className="space-y-3 mb-5">
                {sg.sequenceContexts.map((csc) => {
                  containsOverrides ||= !!csc.overrides?.length;

                  const prescribedSequenceId =
                    csc?.sequence?.id === prescribedSequence?.sequence?.id
                      ? prescribedSequence?.id
                      : undefined;

                  return (
                    <Card
                      key={csc.id}
                      className={clsx(
                        focusedCsc === csc.id && 'outline outline-primary-400',
                      )}
                    >
                      <div
                        id={csc.id}
                        className="px-4 py-3 border-b border-slate-300"
                      >
                        <div className="flex w-full justify-between">
                          <div className="flex items-center">
                            <Link
                              to={buildRoute.sequence(csc.sequence?.id ?? '')}
                            >
                              <h2 className="text-sm font-medium mr-4 text-slate-700">
                                {csc.sequence?.friendlyName ?? '—'}
                              </h2>
                            </Link>
                            {!!csc.prescribedSequences?.length && (
                              <span
                                className="cursor-pointer select-none"
                                onMouseOver={() => setRxHover(true)}
                                onMouseOut={() => setRxHover(false)}
                              >
                                <Tag size="small" color="primary">
                                  {rxHover ? (
                                    <Copyable text={prescribedSequenceId ?? ''}>
                                      {(copied) => (
                                        <pre>
                                          {copied
                                            ? 'Copied'
                                            : prescribedSequenceId?.slice(-6)}
                                        </pre>
                                      )}
                                    </Copyable>
                                  ) : (
                                    'Prescribed'
                                  )}
                                </Tag>
                              </span>
                            )}
                            {csc.addon && (
                              <Tag size="small" color="primary">
                                Add-on
                              </Tag>
                            )}
                          </div>
                          <div>
                            <Copyable text={csc.id}>
                              {(copied) => (
                                <pre className="text-xs cursor-pointer text-slate-400">
                                  ID:&nbsp;&nbsp;&nbsp;&nbsp;
                                  {copied ? 'COPIED' : csc.id.slice(-6)}
                                </pre>
                              )}
                            </Copyable>
                            <Copyable text={sg.id}>
                              {(copied) => (
                                <pre className="text-xs cursor-pointer text-slate-400">
                                  PSGID:&nbsp;
                                  {copied ? 'COPIED' : sg.id.slice(-6)}
                                </pre>
                              )}
                            </Copyable>
                          </div>
                        </div>
                      </div>

                      <Accordion
                        open={openDetails.has(csc.id)}
                        onClick={() =>
                          setOpenDetails((s) => {
                            if (s.has(csc.id)) {
                              s.delete(csc.id);
                            } else {
                              s.add(csc.id);
                            }
                            return new Set(Array.from(s));
                          })
                        }
                        openTitle="Details"
                        closedTitle="Details"
                      >
                        <div className="grid grid-cols-2 truncate text-xs [&>*]:px-4 [&>*]:py-2 [&>*]:flex [&>*]:items-center [&>*]:justify-between [&>*:nth-child(4n)]:bg-slate-50 [&>*:nth-child(4n+3)]:bg-slate-50 border-b border-slate-200">
                          <div>
                            <div className="text-slate-600 font-medium">
                              Next Move
                            </div>
                            <div className="text-slate-700">
                              {csc.nextMoveDue
                                ? formatDateAndTime(csc.nextMoveDue)
                                : '—'}
                            </div>
                          </div>
                          <div>
                            <div className="text-slate-600 font-medium">
                              Next Order
                            </div>
                            <div className="text-slate-700">
                              {csc.nextOrderDue
                                ? formatDateAndTime(csc.nextOrderDue)
                                : '—'}
                            </div>
                          </div>
                          <div>
                            <div className="text-slate-600 font-medium">
                              Started
                            </div>
                            <div className="text-slate-700">
                              {csc.startDate &&
                                formatDateAndTime(csc.startDate)}
                            </div>
                          </div>
                          {!!csc.pausedAt && (
                            <>
                              <div>
                                <div className="text-slate-600 font-medium min-w-32">
                                  Pause Reason
                                </div>
                                <div
                                  title={csc.pauseDescription ?? ''}
                                  className="text-slate-700 truncate"
                                >
                                  {csc.pauseDescription}
                                </div>
                              </div>

                              <div>
                                <div className="text-slate-600 font-medium">
                                  Paused At
                                </div>
                                <div className="text-slate-700">
                                  {formatDateAndTime(csc.pausedAt)}
                                </div>
                              </div>
                            </>
                          )}
                          <div />
                        </div>

                        {!!csc.overrides?.length && (
                          <table className="table-fixed text-left w-full border-b border-slate-200 text-slate-800">
                            <thead className="text-xs whitespace-nowrap leading-8 text-slate-500 py-4 border-b border-slate-200">
                              <tr>
                                <th className="w-1/3 pl-6">Overrides</th>
                                <th>Reason</th>
                                <th className="w-1/3">Created At</th>
                              </tr>
                            </thead>
                            <tbody className="text-xs leading-8">
                              {csc.overrides.map((o, idx) => {
                                return (
                                  <tr
                                    key={o.id}
                                    className={
                                      idx % 2 === 0 ? 'bg-slate-50' : 'bg-white'
                                    }
                                  >
                                    <Copyable text={o.id}>
                                      {(copied) => (
                                        <td className="pl-6">
                                          <pre className="cursor-pointer text-slate-700">
                                            {copied ? 'COPIED' : o.id.slice(-6)}
                                          </pre>
                                        </td>
                                      )}
                                    </Copyable>
                                    <td>{o.reason}</td>
                                    <td>{formatDateAndTime(o.createdAt)}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        )}
                        {!!csc.scripts?.length && (
                          <table className="table-fixed text-left w-full border-b border-slate-200 text-slate-800">
                            <thead className="text-xs whitespace-nowrap leading-8 text-slate-500 py-4 border-b border-slate-200">
                              <tr>
                                <th className="pl-4">Script</th>
                                <th className="w-1/3">Product</th>
                                <th>Total Dispensements</th>
                                <th>Available Dispensements</th>
                              </tr>
                            </thead>
                            <tbody className="text-xs leading-8">
                              {csc.scripts.map((script, index) => {
                                return (
                                  <tr
                                    key={script.id}
                                    className={
                                      index % 2 === 0
                                        ? 'bg-slate-50'
                                        : 'bg-white'
                                    }
                                  >
                                    <td className="pl-4">
                                      <Link
                                        to={buildRoute.script(script.id)}
                                        className="text-slate-500 underline"
                                      >
                                        <pre>
                                          {script.id.slice(-6)}
                                          <CopyToClipboardButton
                                            value={script.id}
                                          />
                                        </pre>
                                      </Link>
                                    </td>
                                    <td className="truncate pr-5">
                                      {script.product?.name || 'Unknown'}
                                    </td>
                                    <td>{script.repeats + 1}</td>
                                    <td>{script.dispensementsRemaining}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        )}
                      </Accordion>

                      {csc.mermaid &&
                        (() => (
                          <Accordion
                            openTitle="Visualisation"
                            closedTitle="Visualisation"
                            onClick={() =>
                              setOpenVisualisations((s) => {
                                if (s.has(csc.id)) {
                                  s.delete(csc.id);
                                } else {
                                  s.add(csc.id);
                                }
                                return new Set(Array.from(s));
                              })
                            }
                            open={openVisualisations.has(csc.id)}
                          >
                            <Mermaid key={csc.id}>{csc.mermaid}</Mermaid>
                          </Accordion>
                        ))()}

                      {!!sg.timeline?.length &&
                        (() => (
                          <Accordion
                            openTitle="Timeline"
                            closedTitle="Timeline"
                            onClick={() =>
                              setOpenTimelines((t) => {
                                if (t.has(csc.id)) {
                                  t.delete(csc.id);
                                } else {
                                  t.add(csc.id);
                                }
                                return new Set(Array.from(t));
                              })
                            }
                            open={openTimelines.has(csc.id)}
                          >
                            <table className="table-fixed text-left w-full">
                              <thead className="text-xs leading-8 text-slate-500 font-normal bg-slate-50">
                                <tr>
                                  <th className="pl-4 w-1/6">Order</th>
                                  <th className="w-1/6">Date</th>
                                  <th className="w-2/6">Products</th>
                                  <th className="w-1/12">Due</th>
                                  <th className="w-1/12">Total</th>
                                  <th className="w-1/12">Discount</th>
                                </tr>
                              </thead>
                              <tbody className="text-xs text-gray-700 leading-8 [&>*:nth-child(2n)]:bg-white [&>*:nth-child(2n+1)]:bg-slate-50">
                                {sg.timeline.map((item) => (
                                  <tr key={item.id}>
                                    <td className="pl-4">
                                      {(() => {
                                        let tagColor: Colors = 'gray';
                                        let content: ReactNode = 'Upcoming';
                                        switch (item.status) {
                                          case 'PLACED':
                                            tagColor = 'teal';
                                            content = (
                                              <div className="flex gap-1 items-center">
                                                Placed
                                                <FaExternalLinkAlt size={10} />
                                              </div>
                                            );
                                            break;
                                          case 'FULFILLED':
                                            tagColor = 'green';
                                            content = (
                                              <div className="flex gap-1 items-center">
                                                Fulfilled
                                                <FaExternalLinkAlt size={10} />
                                              </div>
                                            );
                                            break;
                                        }

                                        const T = (
                                          <Tag size="small" color={tagColor}>
                                            {content}
                                          </Tag>
                                        );

                                        if (
                                          ['PLACED', 'FULFILLED'].includes(
                                            item.status,
                                          )
                                        ) {
                                          return (
                                            <Link
                                              to={buildRoute.coreOrder(
                                                item.order?.id ?? '',
                                              )}
                                            >
                                              {T}
                                            </Link>
                                          );
                                        }

                                        return T;
                                      })()}
                                    </td>
                                    <td>{formatDateAndTime(item.date)}</td>
                                    <td>
                                      <ul className="leading-10">
                                        {item.products?.map(
                                          ({ id, product, quantity }) => (
                                            <li className="truncate" key={id}>
                                              {quantity} x{' '}
                                              {product?.friendlyName ??
                                                product?.name}
                                            </li>
                                          ),
                                        )}
                                      </ul>
                                    </td>
                                    <td>
                                      {item.totalAmount
                                        ? formatCurrencyAmountCents(
                                            item.totalAmount,
                                          )
                                        : '-'}
                                    </td>
                                    <td>
                                      {item.preDiscountTotalAmount
                                        ? formatCurrencyAmountCents(
                                            item.preDiscountTotalAmount,
                                          )
                                        : '-'}
                                    </td>
                                    <td>
                                      {item.discountAmount
                                        ? formatCurrencyAmountCents(
                                            item.discountAmount,
                                          )
                                        : '-'}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </Accordion>
                        ))()}
                    </Card>
                  );
                })}
              </div>
              <div className="flex gap-2">
                <button
                  type="button"
                  className={buttonClass}
                  onClick={() =>
                    setModal({ syncGroupId: sg.id, type: 'resume' })
                  }
                >
                  <span
                    className={clsx(
                      'flex items-center',
                      !resumeLoading ? 'visible' : 'invisible',
                    )}
                  >
                    <FaPlay className="mr-2 text-xs" />
                    Resume
                  </span>
                  <span
                    className={clsx(
                      'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
                      resumeLoading ? 'visible' : 'invisible',
                    )}
                  >
                    <Loading />
                  </span>
                </button>

                <button
                  type="button"
                  className={buttonClass}
                  onClick={() =>
                    setModal({
                      syncGroupId: sg.id,
                      type: 'reschedule-immediately',
                    })
                  }
                >
                  <span
                    className={clsx(
                      'flex items-center',
                      !rescheduleImmediatelyLoading ? 'visible' : 'invisible',
                    )}
                  >
                    <FaBolt className="mr-2 text-xs" />
                    Refill Now
                  </span>
                  <span
                    className={clsx(
                      'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
                      rescheduleImmediatelyLoading ? 'visible' : 'invisible',
                    )}
                  >
                    <Loading />
                  </span>
                </button>

                <button
                  type="button"
                  className={buttonClass}
                  onClick={() =>
                    setModal({ syncGroupId: sg.id, type: 'reschedule' })
                  }
                >
                  <span
                    className={clsx(
                      'flex items-center',
                      !rescheduleLoading ? 'visible' : 'invisible',
                    )}
                  >
                    <FaClock className="mr-2 text-xs" />
                    Reschedule
                  </span>
                  <span
                    className={clsx(
                      'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
                      rescheduleLoading ? 'visible' : 'invisible',
                    )}
                  >
                    <Loading />
                  </span>
                </button>

                <button
                  type="button"
                  className={buttonClass}
                  onClick={() =>
                    setModal({ syncGroupId: sg.id, type: 'repeat' })
                  }
                >
                  <span
                    className={clsx(
                      'flex items-center',
                      !repeatLoading ? 'visible' : 'invisible',
                    )}
                  >
                    <FaBox className="mr-2 text-xs" />
                    Repeat last order
                  </span>
                  <span
                    className={clsx(
                      'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
                      repeatLoading ? 'visible' : 'invisible',
                    )}
                  >
                    <Loading />
                  </span>
                </button>

                {(() => {
                  if (data.focusedPurchaseSyncGroup?.id !== sg.id) {
                    return;
                  }

                  switch (modal?.type) {
                    case 'reschedule-immediately':
                      return (
                        <ReschedulePurchaseSyncGroup
                          onClose={() => setModal(undefined)}
                          immediate
                          syncGroup={data.focusedPurchaseSyncGroup}
                        />
                      );

                    case 'reschedule':
                      return (
                        <ReschedulePurchaseSyncGroup
                          onClose={() => setModal(undefined)}
                          syncGroup={data.focusedPurchaseSyncGroup}
                        />
                      );

                    case 'resume':
                      return (
                        <ResumePurchaseSyncGroup
                          onClose={() => setModal(undefined)}
                          syncGroup={data.focusedPurchaseSyncGroup}
                        />
                      );

                    case 'repeat':
                      return (
                        <RepeatPurchaseSyncGroupOrder
                          onClose={() => setModal(undefined)}
                          syncGroup={data.focusedPurchaseSyncGroup}
                        />
                      );
                  }
                })()}
              </div>
            </div>
          );
        });
      })()}

      {timelineItems.length > 0 && (
        <Card>
          <div className="pt-5 space-y-4">
            <div className="mx-4 text-slate-900 justify-between flex">
              <h2 className="text-lg font-semibold">
                Timeline{containsOverrides ? '*' : ''}
              </h2>
              {containsOverrides && (
                <p className="text-sm text-slate-700">
                  * has been adjusted and includes overrides
                </p>
              )}
            </div>
            <table className="table-fixed text-left w-full">
              <thead className="text-xs font-semibold leading-8 text-slate-900 uppercase py-4 border-b border-t border-slate-300">
                <tr>
                  <th className="pl-6 w-2/12">Type</th>
                  <th className="w-2/12">Date</th>
                  <th className="w-6/12">Products</th>
                  <th className="w-1/12">Amount</th>
                  <th className="w-1/12" />
                </tr>
              </thead>
              <tbody className="text-sm leading-8">
                {timelineItems.map((item, index) => (
                  <tr
                    key={item.id}
                    className={index % 2 === 0 ? 'bg-slate-50' : 'bg-white'}
                  >
                    <td className="pl-6">
                      <Tag size="small" shape="box" color={item.statusColor}>
                        {item.statusText}
                      </Tag>
                    </td>
                    <td>{formatDateAndTime(item.date)}</td>
                    <td className="py-2">
                      <ul className="space-y-2 pr-32">
                        {item.products.map((product) => (
                          <li className="text-sm truncate" key={product.id}>
                            {product.type} - {product.name}
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td>
                      {item.amount
                        ? formatCurrencyAmountCents(item.amount)
                        : '-'}
                    </td>
                    <td>
                      {item.orderId ? (
                        <ContextMenu
                          copy="Actions"
                          options={(() => {
                            const options = [
                              {
                                copy: 'View Order',
                                onClick(): void {
                                  if (item.orderId) {
                                    history.push(
                                      buildRoute.coreOrder(item.orderId),
                                    );
                                  }
                                },
                              },
                              {
                                copy: 'Copy Order ID',
                                onClick(): void {
                                  if (item.orderId) {
                                    copyToClipboard(item.orderId);
                                  }
                                },
                              },
                            ];
                            return options;
                          })()}
                        />
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>
      )}
    </div>
  );
}

function Accordion(props: {
  children: React.ReactNode;
  openTitle: React.ReactNode;
  closedTitle: React.ReactNode;
  open: boolean;
  onClick: () => void;
}) {
  return (
    <div>
      <div
        onClick={props.onClick}
        className="w-full py-3 px-4 select-none bg-zinc-100 hover:bg-zinc-200 cursor-pointer flex justify-between items-center font-semibold text-xs text-zinc-600 hover:text-zinc-800 border-b border-zinc-300"
      >
        {props.open ? props.openTitle : props.closedTitle}
        {props.open ? (
          <FaChevronUp className="text-zinc-500 hover:text-zinc-600" />
        ) : (
          <FaChevronDown className="text-zinc-500 hover:text-zinc-600" />
        )}
      </div>
      {props.open && props.children}
    </div>
  );
}

const PurchaseSyncGroupStatusTag = ({
  status,
}: {
  status: PurchaseSyncGroupStatus;
}): JSX.Element => {
  let tagColor: Colors = 'gray';
  switch (status) {
    case 'ACTIVE':
      tagColor = 'green';
      break;
    case 'PAUSED':
      tagColor = 'yellow';
      break;
    case 'CANCELLED':
      tagColor = 'red';
      break;
    case 'COMPLETED':
      tagColor = 'blue';
      break;
    default:
      tagColor = 'gray';
      break;
  }

  return (
    <Tag size="small" color={tagColor}>
      {upperSnakeCaseToCapitalCase(status)}
    </Tag>
  );
};

const PurchaseStatusTag = ({
  status,
  cancelReason,
}: {
  status: PurchaseStatus;
  cancelReason: Maybe<PurchaseCancelReason>;
}): JSX.Element => {
  switch (status) {
    case 'ACTIVE':
      return (
        <Tag size="large" color="green">
          Active
        </Tag>
      );
    case 'CANCELLED':
      return (
        <Tag size="large" color="red">
          {purchaseCancelReasonToDisplayString(cancelReason)}
        </Tag>
      );
    case 'COMPLETED':
      return (
        <Tag size="large" color="gray">
          Completed
        </Tag>
      );
    case 'PAUSED':
      return (
        <Tag size="large" color="yellow">
          Paused
        </Tag>
      );
    case 'AWAITING_PAYMENT':
      return (
        <Tag size="large" color="yellow">
          Awaiting Payment
        </Tag>
      );
    case 'AWAITING_VALID_SCRIPTS':
      return (
        <Tag size="large" color="yellow">
          Awaiting Valid Scripts
        </Tag>
      );
    case 'AWAITING_PATIENT_CONFIRMATION':
      return (
        <Tag size="large" color="yellow">
          Awaiting Patient Confirmation
        </Tag>
      );
    case 'PENDING_ACTIVATION':
      return (
        <Tag size="large" color="orange">
          Pending Activation
        </Tag>
      );
    default:
      return (
        <Tag size="large" color="gray">
          {status}
        </Tag>
      );
  }
};

type PurchaseTimelineItems = NonNullable<
  NonNullable<
    NonNullable<NonNullable<PurchaseQuery['purchase']>['syncGroups']>
  >[number]['timeline']
>;

const mapPurchaseTimelineItems = (items: PurchaseTimelineItems) => {
  if (!items) {
    return [];
  }

  const orders = new Map<
    string,
    {
      type: string;
      id: string;
      date: Date;
      products: {
        id: string;
        type: string;
        name: string;
        quantity: number;
      }[];
      orderId: string | undefined;
      amount: number;
      statusColor: Colors;
      statusText: string;
    }
  >();
  const entries = [];

  for (const item of items) {
    switch (item.status) {
      case 'PLACED':
      case 'FULFILLED': {
        const key = item.order?.id ?? v4();

        const order = orders.get(key) ?? {
          type: 'consumed',
          id: item.id,
          date: new Date(item.date),
          products: [],
          orderId: item.order?.id ?? undefined,
          amount: 0,
          statusColor: 'indigo',
          statusText: 'Created',
        };

        for (const product of item.products ?? []) {
          order.products.push({
            id: product.id,
            type: product.product?.productType ?? '',
            name: `${
              product.product?.friendlyName ?? product?.product?.name
            } - ${product?.product?.variants[0]?.name}`,
            quantity: product.quantity,
          });
        }

        order.amount += item.totalAmount;

        const fulfilledStatus: ConsignmentStatus[] = ['FULFILLED', 'DELIVERED'];
        if (item.order) {
          const consignments = item.order.consignments;

          if (consignments.some((c) => fulfilledStatus.includes(c.status))) {
            order.statusColor = 'teal';
            order.statusText = 'Partially Fulfilled';
          }

          if (consignments.every((c) => fulfilledStatus.includes(c.status))) {
            order.statusColor = 'green';
            order.statusText = 'Fulfilled';
          }

          if (consignments.every((c) => c.status === 'DELIVERY_FAILED')) {
            order.statusColor = 'orange';
            order.statusText = 'Delivery Failed';
          }
        }

        orders.set(key, order);
        break;
      }
      case 'UPCOMING': {
        entries.push({
          type: 'predicted',
          orderId: undefined,
          id: item.id,
          date: new Date(item.date),
          products: (item.products ?? []).map((p) => ({
            id: p.id,
            type: p.product?.productType ?? '',
            name: `${p.product?.friendlyName ?? p?.product?.name} - ${p?.product
              ?.variants[0]?.name}`,
            quantity: p.quantity,
          })),
          amount: item.totalAmount,
          statusColor: 'gray' as const,
          statusText: 'Upcoming' as const,
        });
        break;
      }
      default:
        throw new Error('Could not resolve timeline item type');
    }
  }

  for (const [, order] of orders) {
    entries.push(order);
  }
  return entries.sort((a, b) => compareAsc(a.date, b.date));
};
