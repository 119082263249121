import React from 'react';

interface Props {
  children: React.ReactNode;
}

const BreadcrumbSeparator = ({ children, ...props }: Props): JSX.Element => (
  <li className="mx-3" {...props}>
    {children}
  </li>
);

const BreadcrumbItem = ({ children, ...props }: Props): JSX.Element => (
  <li className="font-semibold text-primary-darker " {...props}>
    {children}
  </li>
);

export const Breadcrumbs = (props: Props): JSX.Element => {
  let children: React.ReactNode[] = React.Children.toArray(props.children);

  children = children.map((child, index) => (
    <BreadcrumbItem key={`breadcrumb_item${index}`}>{child}</BreadcrumbItem>
  ));

  const lastIndex = children.length - 1;

  children = children.reduce(
    (acc: React.ReactNode[], child: React.ReactNode, index: number) => {
      const notLast = index < lastIndex;
      if (notLast) {
        acc.push(
          child,
          <BreadcrumbSeparator key={`breadcrumb_sep${index}`}>
            /
          </BreadcrumbSeparator>,
        );
      } else {
        acc.push(child);
      }
      return acc;
    },
    [],
  );

  return <ol className="flex items-center">{children}</ol>;
};
