import { TrackerPatient } from 'graphql/types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/table';
import { useHistory } from 'react-router';
import { TableInstance } from 'react-table';
import { routes } from 'utils/routes';

const trackingCustomerURL = (patient: TrackerPatient): string =>
  `${routes.tracking}/${patient.customerId}?pt=${patient.problemType}`;

export const TrackingTable = ({
  tableInstance,
}: {
  tableInstance: TableInstance<TrackerPatient>;
}): React.ReactElement => {
  const history = useHistory();
  return (
    <Table tableInstance={tableInstance}>
      <TableHead />
      <TableBody>
        {tableInstance.page?.map((row) => {
          tableInstance.prepareRow(row);
          return (
            <TableRow row={row} key={row.id}>
              {row.cells.map((cell) => (
                <TableCell
                  key={cell.column.id}
                  cell={cell}
                  onClick={(): void =>
                    history.push(trackingCustomerURL(cell.row.original))
                  }
                />
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
