import React, { useMemo, useRef } from 'react';
import {
  MutationUpdateResidentialAddressArgs,
  UpdateResidentialAddressMutationVariables,
} from 'graphql/types';
import { gql, useMutation } from '@apollo/client';
import { useHasPermissions } from 'components/permissions';
import { config } from 'config';
import { useNotifications } from 'notifications';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { AddressInputIntl } from 'components/address-input/address-input-intl';
import { AddressSubmit } from 'components/address-input/types';
import { FaQuestionCircle } from 'react-icons/fa';
import { Customer } from './types';
import { AddressHistoryDrawerButton } from './addressHistoryDrawerButton';

export const ResidentialAddressSection = ({
  customer,
  refetchCustomer,
}: {
  customer: Customer;
  refetchCustomer: () => void;
}): React.ReactElement => {
  const showNotification = useNotifications();
  const { customerId } = useParams<{ customerId: string }>();
  const residentialAddressHistory = customer.residentialAddress?.history ?? [];
  const [updateResidentialAddressMutation, { loading }] = useMutation<
    MutationUpdateResidentialAddressArgs,
    UpdateResidentialAddressMutationVariables
  >(gql`
    mutation UpdateResidentialAddress(
      $patientId: String!
      $residentialAddress: ResidentialAddressCreateInput!
    ) {
      updateResidentialAddress(
        patientId: $patientId
        residentialAddress: $residentialAddress
      ) {
        id
        line1
        line2
        city
        postalCode
        prefecture
        municipality
        townArea
        state
        country
        company
        building
        history {
          id
          createdAt
          formattedString
          author {
            id
            role
            fullName
            clinicianName
          }
        }
      }
    }
  `);

  const defaultValues = useMemo(() => {
    return {
      patientId: customerId,
      residentialAddress: {
        company: customer?.residentialAddress?.company || undefined,
        building: customer?.residentialAddress?.building || undefined,
        line1: customer?.residentialAddress?.line1 || undefined,
        line2: customer?.residentialAddress?.line2 || undefined,
        city: customer?.residentialAddress?.city || undefined,
        postalCode: customer?.residentialAddress?.postalCode || undefined,
        prefecture: customer?.residentialAddress?.prefecture || undefined,
        municipality: customer?.residentialAddress?.municipality || undefined,
        townArea: customer?.residentialAddress?.townArea || undefined,
        state: customer?.residentialAddress?.state || undefined,
        country: customer?.residentialAddress?.country || config.country,
      },
    };
  }, [customer, customerId]);

  const {
    register,
    handleSubmit: handleResidentialAddressSubmit,
    formState: { isSubmitting: residentialAddressSubmitting, isDirty, errors },
  } = useForm<AddressSubmit>({
    defaultValues,
    reValidateMode: 'onChange',
    mode: 'onChange',
  });
  const updateResidentialAddressVariablesRef =
    useRef<UpdateResidentialAddressMutationVariables | null>(null);
  const updateResidentialAddress = async (
    variables: UpdateResidentialAddressMutationVariables,
  ): Promise<void> => {
    try {
      await updateResidentialAddressMutation({
        variables,
      });
      showNotification({
        type: 'success',
        message: 'Updated patient residential address',
      });
      refetchCustomer();
    } catch {
      // error events show notifications already
      return;
    }
  };

  const submitResidentialAddress = async (
    formData: AddressSubmit,
  ): Promise<void> => {
    updateResidentialAddressVariablesRef.current = {
      patientId: customerId,
      residentialAddress: {
        company: formData.residentialAddress?.company || undefined,
        building: formData.residentialAddress?.building || undefined,
        line1: formData.residentialAddress?.line1 || undefined,
        line2: formData.residentialAddress?.line2 || undefined,
        city: formData.residentialAddress?.city || undefined,
        state: formData.residentialAddress?.state || undefined,
        postalCode: formData.residentialAddress?.postalCode || undefined,
        prefecture: formData.residentialAddress?.prefecture || undefined,
        municipality: formData.residentialAddress?.municipality || undefined,
        townArea: formData.residentialAddress?.townArea || undefined,
        country: formData.residentialAddress?.country,
      },
    };

    if (isDirty) {
      await updateResidentialAddress(
        updateResidentialAddressVariablesRef.current,
      );
    }
  };

  const canEditPatients = useHasPermissions(['EDIT_PATIENTS']);
  return (
    <div className="bg-white shadow overflow-hidden rounded my-4">
      <div className="flex justify-between px-4 py-5 border-b border-gray-200">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Residential Address{' '}
          <FaQuestionCircle
            className="ml-1 inline align-top text-sm"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="This is the address used on the script, changes will not modify previous scripts"
          />
        </h3>
        <AddressHistoryDrawerButton history={residentialAddressHistory} />
      </div>
      <form
        onSubmit={handleResidentialAddressSubmit(submitResidentialAddress)}
        className="overflow-hidden bg-white p-4"
      >
        <div className="mb-3">
          <AddressInputIntl
            name="residentialAddress"
            registerField={register}
            errors={errors.residentialAddress}
          />
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="inline-flex items-center px-4 py-2 border disabled:cursor-not-allowed disabled:opacity-25 border-gray-300 text-sm leading-5 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-gray-800 active:bg-white transition duration-150 ease-in-out"
            disabled={
              residentialAddressSubmitting ||
              !isDirty ||
              loading ||
              !canEditPatients
            }
          >
            ✔ Save changes
          </button>
        </div>
      </form>
    </div>
  );
};
