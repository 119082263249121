import { NoteRowFragment } from 'graphql/types';
import { FaFlag } from 'react-icons/fa';
import { getAuthorDisplayName } from 'utils/get-author-display-name';
import { formatDateAndTime } from 'utils/misc';
import { Markdown } from 'components/markdown';

export const ArchiveReason: React.FC<{ note: NoteRowFragment }> = ({
  note,
}) => {
  if (!note.archivalNote) {
    return null;
  }

  return (
    <div className="px-6 py-3 bg-orange-200 outline outline-1 outline-orange-500 text-sm space-y">
      <div className="flex gap-2 font-semibold items-center">
        <FaFlag /> This note has been archived
      </div>
      <div className="flex gap-2">
        <div className="text-gray-700">Reason:</div>
        <Markdown src={note.archivalNote.body} />
      </div>
      <div className="flex justify-end text-xs text-gray-700">
        Archived by {getAuthorDisplayName(note.archivalNote.author)},{' '}
        {formatDateAndTime(note.archivalNote.updatedAt)}
      </div>
    </div>
  );
};
