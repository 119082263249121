import { Markdown } from 'components/markdown';
import { ConsultationQuestionAttachment } from 'graphql/types';
import { ReactElement } from 'react';
import { Media } from './media';
import { Question } from './types';

const getAttachmentsFromQuestion = (
  question: Question,
): ConsultationQuestionAttachment[] => {
  if (question.data.__typename === 'ConsultationQuestion') {
    return question.data.attachments ?? [];
  } else {
    // ConsultationPatientQuestion, no attachments available as of 19/09/2022
    return [];
  }
};

export const DoctorMessage = ({
  question,
}: {
  question: Question;
}): ReactElement => {
  const attachments = getAttachmentsFromQuestion(question);

  return (
    <div>
      <Markdown src={question.data.value} />
      {attachments.length ? (
        <div className="space-y-2 my-4">
          {attachments.map((attachment) => (
            <Media key={attachment.id} content={attachment} />
          ))}
        </div>
      ) : null}
    </div>
  );
};
