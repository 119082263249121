import { VFC } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { Button } from 'components/button';
import { EducationReading } from 'graphql/types';
import { FaBars, FaRegTrashAlt } from 'react-icons/fa';

type ReadingsTableProps = {
  readings: EducationReading[];
  onRemoveReading: (readingId: string) => void;
  onReorderReadings: (readings: EducationReading[]) => void;
};

export const ReadingsTable: VFC<ReadingsTableProps> = ({
  readings,
  onRemoveReading,
  onReorderReadings,
}) => {
  if (!readings) {
    return <>No readings...</>;
  }

  const handleOnDragEnd = ({ source, destination }: DropResult): void => {
    if (!destination || destination.index === source.index) {
      return;
    }

    const newReadings = [...readings];
    const [movedReading] = newReadings.splice(source.index, 1);
    newReadings.splice(destination.index, 0, movedReading);

    onReorderReadings(newReadings);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <div className="flex heading-sm mb-3">
        <div className="w-1/3">Name</div>
        <div className="w-1/3">Contentful ID</div>
      </div>
      <Droppable droppableId="readings">
        {(provided): JSX.Element => (
          <ul
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="divide-y"
          >
            {readings.map((reading, i) => (
              <Draggable key={reading.id} draggableId={reading.id} index={i}>
                {(provided): JSX.Element => (
                  <li
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className="flex items-center py-2 bg-white"
                  >
                    <div className="w-1/3">{reading.name}</div>
                    <div className="w-1/3">{reading.contentfulId}</div>
                    <div className="w-1/3 flex space-x-3 items-center justify-end">
                      <div className="w-10">
                        <Button
                          fullWidth
                          onClick={(): void => onRemoveReading(reading.id)}
                          color="danger"
                        >
                          <FaRegTrashAlt />
                        </Button>
                      </div>
                      <div className="p-1" {...provided.dragHandleProps}>
                        <FaBars className="text-sm" />
                      </div>
                    </div>
                  </li>
                )}
              </Draggable>
            ))}
            {/* maintains height of container during drag */}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};
