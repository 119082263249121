import React from 'react';
import clsx from 'clsx';
import { tinykeys } from 'tinykeys';

interface Props {
  children: React.ReactElement;
  show: boolean;
  width?: string;
  height?: string;
  isAutoOverflow?: boolean;
  onClose: () => void;
}

export const Modal = ({
  children,
  show,
  width = 'w-1/2',
  height = undefined,
  isAutoOverflow = true,
  onClose,
}: Props): JSX.Element | null => {
  const handleClose = (e: React.MouseEvent): void => {
    if (e.currentTarget === e.target) {
      onClose();
    }
  };

  React.useEffect(() => {
    return tinykeys(window, {
      Escape: () => {
        onClose();
      },
    });
  }, [onClose]);

  const modalClass = clsx(
    { 'overflow-auto': isAutoOverflow },
    'relative mx-auto max-h-full transition-opacity duration-300',
    width,
    height,
    { 'opacity-0': !show },
    { 'opacity-1': show },
  );

  return (
    <div
      className={clsx(
        'fixed py-8 inset-0 z-50 bg-black bg-opacity-50 overflow-hidden !m-0',
        { hidden: !show },
      )}
      onClick={handleClose}
    >
      <div className={modalClass}>{children}</div>
    </div>
  );
};
