import { VFC } from 'react';
import { gql, useQuery } from '@apollo/client';
import { ReadingsHeader } from 'components/education/readings/readings-header';
import { ReadingsTable } from 'components/education/readings/readings-table';
import { Loading } from 'components/loading';
import {
  GetEducationReadingsQuery,
  GetEducationReadingsQueryVariables,
} from 'graphql/types';

const ReadingsPage: VFC = () => {
  const { data, loading } = useQuery<
    GetEducationReadingsQuery,
    GetEducationReadingsQueryVariables
  >(gql`
    query GetEducationReadings {
      educationReadings {
        id
        name
        contentfulId
      }
    }
  `);
  const readings = data?.educationReadings;

  if (loading) {
    return <Loading />;
  }

  if (!readings?.length) {
    return <div>No readings</div>;
  }

  return (
    <div>
      <ReadingsHeader />
      <ReadingsTable readings={readings} />
    </div>
  );
};

export default ReadingsPage;
