import { gql, useQuery } from '@apollo/client';
import clsx from 'clsx';
import { Loading } from 'components/loading';
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useOrderBy,
  usePageIndex,
  useSelectablePaginatingSortingTable,
} from 'components/table';
import { Tag } from 'components/tag';
import { ProblemTypeTag } from 'components/tags/problem-type';
import {
  Consultation,
  ConsultationOrderByInput,
  ConsultationWhereInput,
  Maybe,
  ProblemType,
  User,
} from 'graphql/types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Cell, CellProps, Column } from 'react-table';
import {
  formatDateAndTime,
  getConsultationStageColor,
  upperSnakeCaseToCapitalCase,
} from 'utils/misc';
import { routes } from 'utils/routes';
import {
  ConsultationFilter,
  useBuildConsultationQueryFilter,
} from './awaiting-script-filters';

const TABLE_PAGE_SIZE = 30;

const AwaitingScripts = (): React.ReactElement => {
  const history = useHistory();
  const activeFilter = useBuildConsultationQueryFilter();
  const orderBy = useOrderBy({ defaultValue: 'updatedAt_desc' });
  const pageIndex = usePageIndex();
  const { data, loading } = useQuery<
    { consultations: Consultation[]; consultationsCount: number },
    {
      orderBy: ConsultationOrderByInput[];
      where: ConsultationWhereInput;
      pageSize: number;
      skip: number;
    }
  >(
    gql`
      query AwaitingScripts(
        $orderBy: [ConsultationOrderByInput!]!
        $where: ConsultationWhereInput
        $pageSize: Int
        $skip: Int
      ) {
        consultations(
          orderBy: $orderBy
          where: $where
          take: $pageSize
          skip: $skip
        ) {
          id
          createdAt
          updatedAt
          type
          status
          stage
          order {
            id
            updatedAt
          }

          customer {
            id
            email
          }

          doctor {
            id
            email
            firstName
            lastName
            fullName
            clinicianName
          }
        }
        consultationsCount(where: $where)
      }
    `,
    {
      variables: {
        where: activeFilter,
        pageSize: TABLE_PAGE_SIZE,
        skip: pageIndex * TABLE_PAGE_SIZE,
        orderBy,
      },
    },
  );

  const total = data?.consultationsCount ?? 0;

  const pageNumber = Math.ceil((total ?? 1) / TABLE_PAGE_SIZE);

  // Row data needs to be memoised so that the reference to the data remains the same
  const consultations = React.useMemo(() => {
    return data?.consultations || [];
  }, [data]);

  // Columns needs to be memoised so that the reference to the columns remains the same
  const tableColumns = React.useMemo(() => columns(), []);

  const tableInstance = useSelectablePaginatingSortingTable({
    columns: tableColumns,
    data: consultations,
    pageNumber,
    orderBy,
    pageIndex,
  });

  return (
    <div>
      <ConsultationFilter />
      <div
        className={clsx(
          { 'visually-hidden': !consultations.length },
          'overflow-x-auto',
        )}
      >
        <Table tableInstance={tableInstance}>
          <TableHead />
          <TableBody>
            {tableInstance.page?.map((row) => {
              tableInstance.prepareRow(row);
              return (
                <TableRow row={row} key={row.id}>
                  {row.cells.map((cell) => (
                    <TableCell
                      key={`${cell.row.original.id}-${cell.column.id}`}
                      cell={cell}
                      onClick={(): void => {
                        if (
                          cell.column.id !== 'selection' &&
                          cell.column.id !== 'doctor'
                        ) {
                          history.push(
                            `${routes.consultations}/${cell.row.original.id}`,
                          );
                        }
                      }}
                    />
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      {!consultations.length && !loading && (
        <div className="text-center font-medium pt-8">
          No consultations found
        </div>
      )}
      {loading && (
        <div className="flex justify-center text-lg">
          <Loading />
        </div>
      )}
      <Pagination total={total} tableInstance={tableInstance} />
    </div>
  );
};

const PatientCell = (
  cell: CellProps<Consultation, User>,
): React.ReactElement => <div>{cell.value.email}</div>;

const ProblemCell = (
  cell: CellProps<Consultation, ProblemType>,
): React.ReactNode => <ProblemTypeTag problemType={cell.value} />;

const DoctorCell = ({
  cell,
}: {
  cell: Cell<Consultation, Maybe<User>>;
}): React.ReactElement => {
  return <div> {cell.value ? cell.value.clinicianName : `N/A`}</div>;
};

const columns = (): Column<Consultation>[] => [
  {
    Header: 'Consultation Id',
    accessor: 'id',
    disableSortBy: true,
  },
  {
    Header: 'Date Awaiting Script',
    accessor: 'order',
    Cell: (c) => (
      <div>{c.value ? formatDateAndTime(c.value.updatedAt) : null}</div>
    ),
    disableSortBy: true,
  },
  {
    Header: 'Patient',
    accessor: 'customer',
    Cell: PatientCell,
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    disableSortBy: true,
    Cell: (c) => <div>{c.value}</div>,
  },
  {
    Header: 'Type',
    accessor: 'assignedAt',
    disableSortBy: true,
    Cell: (c) => (
      <Tag size="small" color={getConsultationStageColor(c.row.original.stage)}>
        {upperSnakeCaseToCapitalCase(c.row.original.stage)}
      </Tag>
    ),
  },
  {
    Header: 'Problem Type',
    accessor: 'type',
    Cell: ProblemCell,
  },
  {
    Header: 'Practitioner Assigned',
    accessor: 'doctor',
    disableSortBy: true,
    Cell: (props): React.ReactElement => {
      return <DoctorCell {...props} />;
    },
  },
];

export default AwaitingScripts;
