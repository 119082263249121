import { gql, useMutation } from '@apollo/client';
import { Button } from 'components/button';
import {
  PractitionerBookingsTableRowFragment,
  PrioritiseBookingMutation,
  PrioritiseBookingMutationVariables,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import { FaStar } from 'react-icons/fa';

export const PrioritiseBookingsButton: React.FC<{
  bookings: PractitionerBookingsTableRowFragment[];
  onComplete: () => void;
}> = ({ bookings, onComplete }) => {
  const showNotification = useNotifications();

  const [prioritiseBooking, { loading }] = useMutation<
    PrioritiseBookingMutation,
    PrioritiseBookingMutationVariables
  >(
    gql`
      mutation PrioritiseBooking(
        $input: UpdatePractitionerBookingPriorityInput!
      ) {
        updatePractitionerBookingPriority(input: $input) {
          practitionerBooking {
            id
            priority
          }
        }
      }
    `,
    {
      onError: () => {
        showNotification({
          type: 'error',
          message: 'Unable to update booking priority',
        });
        onComplete();
      },
    },
  );

  if (!bookings.length) {
    return null;
  }

  const handleClick = async () => {
    await Promise.all(
      bookings.map((b) =>
        prioritiseBooking({
          variables: {
            input: { bookingId: b.id, priority: b.priority + 1 },
          },
        }),
      ),
    );

    showNotification({
      type: 'success',
      message: 'Booking priorities updated',
    });
    onComplete();
  };

  return (
    <div>
      <Button loading={loading} onClick={handleClick}>
        <div className="flex items-center gap-2">
          <FaStar color="gold" /> Prioritise in window
        </div>
      </Button>
    </div>
  );
};
