import { Button } from 'components/button';
import { useState } from 'react';
import { UpsertCriticalNoteModal } from './upsert-critical-note-modal';

export const AddCriticalNoteModalButton: React.FC<{
  customerId: string;
  noteId: string | undefined;
}> = ({ customerId, noteId }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button onClick={() => setShowModal(true)} size="small">
        Add
      </Button>
      <UpsertCriticalNoteModal
        show={showModal}
        onClose={() => setShowModal(false)}
        defaultValues={{
          customerId,
          noteId,
        }}
      />
    </>
  );
};
