import { LogsAccordion } from './logs-accordion';
import { RenderAny } from './render-any';

export const ComparisonTable = ({
  oldData,
  newData,
}: {
  oldData: Record<string, unknown>;
  newData: Record<string, unknown>;
}): React.ReactElement => {
  const outputObj: { key: string; from: unknown; to: unknown }[] = [];
  for (const key of Array.from(
    new Set([...Object.keys(oldData), ...Object.keys(newData)]),
  )) {
    const from = key in oldData ? oldData[key] : '';
    const to = key in newData ? newData[key] : '';
    if (from !== to) {
      outputObj.push({
        key,
        from,
        to,
      });
    }
  }
  if (!outputObj.length) {
    return <>No changes found</>;
  }
  return (
    <LogsAccordion>
      <table>
        <tr>
          {['property', 'from', 'to'].map((header) => (
            <th
              key={`header-${header}`}
              className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
            >
              {header}
            </th>
          ))}
        </tr>
        {outputObj.map(({ key, from, to }) => {
          return (
            <tr key={`${key}`}>
              <td className="px-6 py-2 whitespace-nowrap font-semibold">
                {key}
              </td>
              <td className="px-6 py-2 whitespace-nowrap">
                <RenderAny obj={from} />
              </td>
              <td className="px-6 py-2 whitespace-nowrap">
                <RenderAny obj={to} />
              </td>
            </tr>
          );
        })}
      </table>
    </LogsAccordion>
  );
};
