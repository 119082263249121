import { ComparisonTable } from './comparison-table';
import { RenderAny } from './render-any';
import { flattenObject, isRecord } from './utils';

export const AuditDetails = ({
  details,
}: {
  details: unknown;
}): React.ReactElement => {
  if (isRecord(details) && 'oldData' in details && 'newData' in details) {
    const oldData = flattenObject(details.oldData);
    const newData = flattenObject(details.newData);
    return <ComparisonTable oldData={oldData} newData={newData} />;
  }

  return <RenderAny obj={details} />;
};
