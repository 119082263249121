import {
  GoalsPageQuery,
  InputMaybe,
  LevelTreePublishedState,
  QueryLevelTreeGoalTemplatesArgs,
} from 'graphql/types';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Option } from 'components/dropdown';
import { isValidProblemType } from 'utils/misc';

const TEMPLATE_STATUSES: LevelTreePublishedState[] = ['DRAFT', 'PUBLISHED'];

const isValidPublishedState = (s: string): s is LevelTreePublishedState =>
  TEMPLATE_STATUSES.includes(s as LevelTreePublishedState);

export const useGoalsSearchParams = (): QueryLevelTreeGoalTemplatesArgs => {
  const { search } = useLocation();

  return useMemo<QueryLevelTreeGoalTemplatesArgs>(() => {
    const searchParams = new URLSearchParams(search);
    const searchTerm = searchParams.get('searchTerm');
    const goalId = searchParams.get('goalId');
    const pillarId = searchParams.get('pillarId');
    const levelId = searchParams.get('levelId');
    const problemTypes = searchParams.getAll('problemTypes');
    const status = searchParams.get('status');

    const templateStatus =
      status && isValidPublishedState(status) ? status : undefined;

    const filteredProblemTypes = problemTypes.filter(isValidProblemType);

    return {
      searchTerm: searchTerm ?? undefined,
      goalId: goalId ?? undefined,
      levelId: levelId ?? undefined,
      pillarId: pillarId ?? undefined,
      problemTypes: filteredProblemTypes,
      publishedState: templateStatus,
    };
  }, [search]);
};

export const usePillarAndLevelOptions = (
  pillars: GoalsPageQuery['levelTreePillarTemplates'],
  selectedPillarId: InputMaybe<string>,
): { pillarOptions: Option[]; levelOptions: Option[] } => {
  return useMemo(() => {
    if (!pillars || pillars.length === 0) {
      return {
        pillarOptions: [],
        levelOptions: [],
      };
    }

    const pillar = pillars.find((p) => p.id === selectedPillarId);

    const pillarOptions = pillars.map((p) => ({
      label: p.name ?? 'Unnamed pillar',
      value: p.id,
    }));

    const levelOptions =
      pillar?.levels?.map((l) => ({
        label: `${l.levelNumber}`,
        value: l.id,
      })) ?? [];

    return { pillarOptions, levelOptions };
  }, [pillars, selectedPillarId]);
};
