import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

const createVariantInventoryMutation = gql`
  mutation createVariant($variantInventory: VariantInventoryCreateInput!) {
    createVariantInventory(variantInventory: $variantInventory) {
      id
    }
  }
`;

const updateVariantInventoryMutation = gql`
  mutation updateVariantInventory(
    $variantInventory: VariantInventoryUpdateInput!
  ) {
    updateVariantInventory(variantInventory: $variantInventory) {
      id
    }
  }
`;

const deleteVariantInventoryMutation = gql`
  mutation deleteVariantInventory($id: String!) {
    deleteVariantInventory(id: $id)
  }
`;

export interface VariantInventoryCreateInput {
  variantId: string;
  sku?: string | null;
  quantity?: number | null;
  canPurchaseOutOfStock?: boolean | null;
}

export interface VariantInventoryUpdateInput {
  id: string;
  variantId?: string;
  sku?: string | null;
  quantity?: number | null;
  canPurchaseOutOfStock?: boolean | null;
}

export const useVariantInventoryCrud = (): {
  createVariantInventory: (
    VariantInventoryCreateInput: VariantInventoryCreateInput,
  ) => Promise<void>;
  updateVariantInventory: (
    VariantInventoryUpdateInput: VariantInventoryUpdateInput,
  ) => Promise<void>;
  deleteVariantInventory: (id: string) => Promise<void>;
} => {
  const [createVariantInventory] = useMutation<{
    createVariantInventory: { id: string };
  }>(createVariantInventoryMutation);
  const [updateVariantInventory] = useMutation<{
    updateVariantInventory: { id: string };
  }>(updateVariantInventoryMutation);
  const [deleteVariantInventory] = useMutation<{
    deleteVariantInventory: boolean;
  }>(deleteVariantInventoryMutation);

  const createVariantInventoryWrapper = useCallback(
    async (
      variantInventoryCreateInput: VariantInventoryCreateInput,
    ): Promise<void> => {
      await createVariantInventory({
        variables: {
          variantInventory: variantInventoryCreateInput,
        },
      });
    },
    [createVariantInventory],
  );

  const updateVariantInventoryWrapper = useCallback(
    async (
      variantInventoryUpdateInput: VariantInventoryUpdateInput,
    ): Promise<void> => {
      await updateVariantInventory({
        variables: {
          variantInventory: variantInventoryUpdateInput,
        },
      });
    },
    [updateVariantInventory],
  );

  const deleteVariantInventoryWrapper = useCallback(
    async (id: string): Promise<void> => {
      await deleteVariantInventory({
        variables: {
          id,
        },
      });
    },
    [deleteVariantInventory],
  );

  return {
    createVariantInventory: createVariantInventoryWrapper,
    updateVariantInventory: updateVariantInventoryWrapper,
    deleteVariantInventory: deleteVariantInventoryWrapper,
  };
};
