import { ConsultationMedium, ProblemType } from 'graphql/types';

export const isNonRxConsultation = (consultation: {
  medium: ConsultationMedium;
  type: ProblemType;
}): boolean => {
  return (
    consultation.medium === 'VIDEO' ||
    ['STI', 'FERTILITY_HORMONE'].includes(consultation.type)
  );
};
