import React from 'react';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import clsx from 'clsx';

const renderer = new marked.Renderer();

renderer.code = function (text: string): string {
  return marked.Renderer.prototype.paragraph.apply(this, [text]);
};

marked.setOptions({
  renderer,
});

export function Markdown({
  src,
  className,
}: {
  src: string;
  className?: string;
}): React.ReactElement {
  const htmlMarkdown = marked(src.trim(), {});
  const sanitised = DOMPurify.sanitize(htmlMarkdown);

  return (
    <div
      className={clsx('markdown space-y-5', className)}
      dangerouslySetInnerHTML={{
        __html: sanitised,
      }}
    />
  );
}
