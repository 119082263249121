import { useState, VFC } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  EducationCollection,
  EducationReading,
  UpdateEducationCollectionInput,
  UpdateEducationCollectionMutation,
  UpdateEducationCollectionMutationVariables,
} from 'graphql/types';
import { Card } from '../../card';
import { ReadingsTableCard } from '../programs/readings-table-card';
import { EducationHeader } from '../programs/education-header';
import { getCardOutlineClassName } from '../utils';

type CollectionsPageTemplateProps = {
  collection: EducationCollection;
};

export const CollectionCard: VFC<CollectionsPageTemplateProps> = ({
  collection,
}) => {
  const [localCollection, setLocalCollection] =
    useState<EducationCollection>(collection);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [
    updateEducationCollectionMutation,
    { loading: updateEducationCollectionMutationLoading },
  ] = useMutation<
    UpdateEducationCollectionMutation,
    UpdateEducationCollectionMutationVariables
  >(gql`
    mutation UpdateEducationCollection(
      $updateEducationCollectionInput: UpdateEducationCollectionInput!
    ) {
      updateEducationCollection(
        updateEducationCollectionInput: $updateEducationCollectionInput
      ) {
        educationCollection {
          id
        }
      }
    }
  `);

  const updateLocalCollection = (newCollection: EducationCollection): void => {
    setLocalCollection(newCollection);
    setUnsavedChanges(true);
  };

  const addReadingToCollection = (reading: EducationReading): void => {
    const newCollection: EducationCollection = {
      ...localCollection,
      readings: [...(localCollection.readings || []), reading],
    };
    updateLocalCollection(newCollection);
  };

  const removeReadingFromCollection = (readingId: string): void => {
    const newCollection: EducationCollection = {
      ...localCollection,
      readings: localCollection.readings
        ? localCollection.readings.filter((reading) => reading.id !== readingId)
        : [],
    };
    updateLocalCollection(newCollection);
  };

  const reorderReadings = (newReadingsOrder: EducationReading[]): void => {
    const newCollection: EducationCollection = {
      ...localCollection,
      readings: newReadingsOrder,
    };
    updateLocalCollection(newCollection);
  };

  const onSaveCollection = async (): Promise<void> => {
    const updateEducationCollectionInput: UpdateEducationCollectionInput = {
      id: localCollection.id,
      name: localCollection.name,
      readingIds: localCollection.readings?.map((reading) => reading.id) || [],
    };
    await updateEducationCollectionMutation({
      variables: { updateEducationCollectionInput },
    });
    setUnsavedChanges(false);
  };

  return (
    <>
      <div className="space-y-2">
        <Card className={getCardOutlineClassName(unsavedChanges)}>
          <EducationHeader
            unsavedChanges={unsavedChanges}
            name={`Collection: ${localCollection.name}`}
            description={localCollection.description}
            onSaveChanges={onSaveCollection}
            saveChangesLoading={updateEducationCollectionMutationLoading}
          />
        </Card>
        <div className="p-4">
          <ReadingsTableCard
            key={localCollection.id}
            readings={localCollection.readings || []}
            addReading={addReadingToCollection}
            unsavedChanges={unsavedChanges}
            removeReading={removeReadingFromCollection}
            reorderReadings={reorderReadings}
          />
        </div>
      </div>
    </>
  );
};
