import { AdminPermission } from 'graphql/types';
import { useAuth } from 'auth';
import { memo } from 'react';

/**
 * Returns whether the authenticated user has at least one of the listed perms.
 *
 * @param permissions set of possible permissions required
 * @returns boolean
 */
export function useHasOneOfPermissions(
  permissions: Array<AdminPermission>,
): boolean {
  const auth = useAuth();
  const userPermissions = new Set(auth.permissions);
  let hasPermission = false;
  permissions.forEach((p) => {
    if (userPermissions.has(p)) {
      hasPermission = true;
    }
  });

  return hasPermission;
}

/**
 * Returns whether the authenticated user has all listed permissions.
 *
 * @param permissions set of permissions required
 * @returns boolean
 */
export function useHasPermissions(
  permissions: Array<AdminPermission>,
): boolean {
  const auth = useAuth();
  const userPermissions = new Set(auth.permissions);
  let hasPermission = true;
  permissions.forEach((p) => {
    if (!userPermissions.has(p)) {
      hasPermission = false;
    }
  });

  return hasPermission;
}

/**
 * Component will show child content only if the authenticated user
 * has all listed permissions.
 *
 * @prop permissions set of permissions required to show children
 * @prop children content to show if permitted
 */
export const HasPermissions = memo(
  (props: {
    permissions: Array<AdminPermission>;
    children: React.ReactNode;
  }) => {
    const hasPermissions = useHasPermissions(props.permissions);
    if (!hasPermissions) {
      return (
        <p>
          You don&apos;t have the {props.permissions.join(', ')} permission
          required to access this page.
        </p>
      );
    }

    return <>{props.children}</>;
  },
);

/**
 * Component will show child content only if the authenticated user
 * has one of the listed permissions.
 *
 * @prop permissions set of permissions required to show children
 * @prop children content to show if permitted
 */
export const HasOneOfPermissions = memo(
  (props: {
    permissions: Array<AdminPermission>;
    children: React.ReactNode;
  }) => {
    const hasPermissions = useHasOneOfPermissions(props.permissions);
    if (!hasPermissions) {
      return (
        <p>
          You don&apos;t have the {props.permissions.join(', ')} permission
          required to access this page.
        </p>
      );
    }

    return <>{props.children}</>;
  },
);
