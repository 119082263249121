import React from 'react';
import { Button } from 'components/button';
import { UpdateModal } from './update-modal';
import { DeleteModal } from './delete-modal';
import { TrackerAction, TrackerActionsProps } from './types';

export const TrackerActions = (
  props: TrackerActionsProps,
): React.ReactElement | null => {
  const [activeModal, setActiveModal] = React.useState<TrackerAction | null>(
    null,
  );

  const handleCloseModal = (): void => {
    setActiveModal(null);
  };

  const handleActionComplete = async (): Promise<void> => {
    await props.refetch();
  };

  return (
    <div className="flex space-x-2">
      {!props.disableEdit && (
        <Button
          fullWidth
          size="small"
          variant="outline"
          onClick={(): void => setActiveModal(TrackerAction.UPDATE)}
        >
          Edit
        </Button>
      )}
      <Button
        fullWidth
        size="small"
        color="danger"
        variant="outline"
        onClick={(): void => setActiveModal(TrackerAction.DELETE)}
      >
        Delete
      </Button>
      <UpdateModal
        show={activeModal === TrackerAction.UPDATE}
        onClose={handleCloseModal}
        onActionComplete={handleActionComplete}
        {...props}
      />
      <DeleteModal
        show={activeModal === TrackerAction.DELETE}
        onClose={handleCloseModal}
        onActionComplete={handleActionComplete}
        {...props}
      />
    </div>
  );
};
