import { Button } from 'components/button';
import { Tag } from 'components/tag';
import { Maybe } from 'graphql/types';
import { VFC } from 'react';

type EducationHeaderProps = {
  unsavedChanges: boolean;
  name: string;
  description?: Maybe<string>;
  onSaveChanges: () => void;
  saveChangesLoading: boolean;
};
export const EducationHeader: VFC<EducationHeaderProps> = ({
  unsavedChanges,
  name,
  description,
  onSaveChanges,
  saveChangesLoading,
}) => {
  const saveStateLabel = unsavedChanges ? (
    <Tag size="small" color="orange">
      Changed
    </Tag>
  ) : (
    <Tag size="small" color="green">
      Published
    </Tag>
  );

  return (
    <div className="flex flex-row space-y-2 p-4">
      <div className="flex-grow">
        <h1 className="text-2xl font-medium">{name}</h1>
        {description && <p>{description}</p>}
      </div>
      <div className="flex flex-col items-end space-y-2">
        <div className="w-48">
          <Button
            fullWidth
            color="success"
            disabled={!unsavedChanges}
            onClick={onSaveChanges}
            loading={saveChangesLoading}
          >
            Save Changes
          </Button>
        </div>
        <div>
          <span className="font-medium">Status:</span> {saveStateLabel}
        </div>
      </div>
    </div>
  );
};
