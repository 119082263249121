import React, { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useTitle } from 'utils/use-title';
import { useAuth } from 'auth';
import AuthLayout from './auth-layout';
import { useNotifications } from 'notifications';
import { useHistory, useLocation } from 'react-router-dom';
import { Loading } from 'components/loading';

const Login = (): React.ReactElement => {
  const location = useLocation<{
    from: Location;
  }>();
  const history = useHistory();
  const showNotification = useNotifications();
  const { login } = useAuth();

  const [loading, setLoading] = useState(false);

  useTitle('Login');

  const performLogin = async (idToken: string | undefined): Promise<void> => {
    if (!login || !idToken) {
      throw new Error('Unable to login.');
    }

    setLoading(true);

    try {
      await login(idToken);

      if (location.state?.from) {
        history.push(location.state.from);
      } else {
        history.push('/');
      }
    } catch (err) {
      setLoading(false);

      showNotification({
        type: 'error',
        message: err instanceof Error ? err.message : 'Something went wrong',
      });
    }

    // NOTE: not calling setLoading(false) here since after login is complete, this component is unmounted
    // React logs a warning when updating state on an unmounted component
  };

  return (
    <AuthLayout>
      {loading ? (
        <div className="flex items-center justify-center">
          <Loading />
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <GoogleLogin
            onSuccess={async ({ credential: idToken }): Promise<void> =>
              performLogin(idToken)
            }
            onError={(): void => {
              throw new Error('Unable to login.');
            }}
            useOneTap
            auto_select
          />
        </div>
      )}
    </AuthLayout>
  );
};

export default Login;
