import { gql, useQuery } from '@apollo/client';
import { TrackerPatient } from 'graphql/types';
import { Loading } from 'components/loading';
import {
  SimplePagination,
  usePageIndex,
  usePaginatingSortingTable,
} from 'components/table';
import React from 'react';
import { calculateTablePaginationArgs } from 'utils/calculate-pagination-args';
import { TrackingTable } from './table';
import { columns } from './table/columns';
import { TrackingFilters } from './tracking-filters';
import { useSearchTerm } from './utils/useSearchTerm';
import { useSearchType } from './utils/useSearchType';

const trackerPatientsQuery = gql`
  query TrackerPatients(
    $pagination: Pagination!
    $search: TrackerPatientSearch
  ) {
    trackerPatients(pagination: $pagination, search: $search) {
      customerId
      firstName
      lastName
      fullName
      email
      lastCheckIn
      problemType
      count
    }
  }
`;

const patientTrackerTableSize = 30;

export const TrackingList = (): React.ReactElement => {
  const pageIndex = usePageIndex();
  const searchTerm = useSearchTerm();
  const searchType = useSearchType();

  const search = searchTerm ? { searchType, searchTerm } : undefined;

  const { data, loading, error } = useQuery<{
    trackerPatients: TrackerPatient[];
  }>(trackerPatientsQuery, {
    variables: {
      pagination: calculateTablePaginationArgs(
        pageIndex,
        patientTrackerTableSize,
      ),
      search,
    },
  });

  const patients = data?.trackerPatients;

  const tableInstance = usePaginatingSortingTable({
    columns: columns,
    data: patients || [],
    pageSize: patientTrackerTableSize,
    pageIndex: pageIndex,
    pageNumber: -1,
    disableSortBy: true,
    orderBy: [{ lastCheckIn: 'desc' }],
  });

  if (error) {
    return <>Error: {error}</>;
  }

  const trackingTable = (
    <>
      <TrackingTable tableInstance={tableInstance} />
      <SimplePagination
        pageIndex={pageIndex}
        canNextPage={tableInstance.canNextPage}
      />
    </>
  );

  return (
    <div>
      <TrackingFilters />
      {loading ? <Loading /> : trackingTable}
    </div>
  );
};
