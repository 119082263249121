import { ApolloQueryResult, gql, useQuery } from '@apollo/client';
import clsx from 'clsx';
import { Button } from 'components/button';
import { DeleteManyUsersButton } from 'components/delete-many-users-button';
import { Loading } from 'components/loading';
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useOrderBy,
  usePageIndex,
  useSelectablePaginatingSortingTable,
} from 'components/table';
import { User, UserOrderByInput, UserWhereInput } from 'graphql/types';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Column, TableInstance } from 'react-table';
import { routes } from 'utils/routes';
import {
  UserFilter,
  useBuildUserQueryFilter,
} from '../../components/user-filter';
const TABLE_PAGE_SIZE = 30;

const columns: Column<User>[] = [
  {
    Header: 'Name',
    Cell: (c) => <div>{c.row.original.fullName}</div>,
    className: 'w-1/6',
  },
  {
    Header: 'Email',
    accessor: 'email',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-5/6',
  },
];

const useHealthCoachesTable: () => [
  TableInstance<User>,
  boolean,
  (
    variables?: Partial<{
      where: UserWhereInput;
      orderBy: UserOrderByInput[];
      skip: number;
      pageSize: number;
    }>,
  ) => Promise<
    ApolloQueryResult<{
      healthCoaches: User[];
      healthCoachesCount: number;
    }>
  >,
  number | string,
] = () => {
  const activeFilter = useBuildUserQueryFilter('HEALTH_COACH');
  const orderBy = useOrderBy({ defaultValue: 'createdAt_desc' });
  const pageIndex = usePageIndex();
  const { data, loading, refetch } = useQuery<
    {
      healthCoaches: User[];
      healthCoachesCount: number;
    },
    {
      where: UserWhereInput;
      orderBy: UserOrderByInput[];
      skip: number;
      pageSize: number;
    }
  >(
    gql`
      query healthCoachesTable(
        $where: UserWhereInput
        $orderBy: [UserOrderByInput!]
        $skip: Int
        $pageSize: Int
      ) {
        healthCoaches: users(
          orderBy: $orderBy
          where: $where
          skip: $skip
          take: $pageSize
        ) {
          id
          createdAt
          isAvailable
          deactivatedAt
          email
          firstName
          lastName
          fullName
        }
        healthCoachesCount: usersCount(where: $where)
      }
    `,
    {
      variables: {
        where: activeFilter,
        orderBy: orderBy,
        skip: pageIndex * TABLE_PAGE_SIZE,
        pageSize: TABLE_PAGE_SIZE,
      },
    },
  );

  const healthCoaches = React.useMemo(() => data?.healthCoaches ?? [], [data]);
  const total = data?.healthCoachesCount ?? 0;
  const pageNumber = Math.ceil((total ?? 1) / TABLE_PAGE_SIZE);
  const healthCoachesTable = useSelectablePaginatingSortingTable<User>({
    columns,
    data: healthCoaches,
    pageNumber,
    orderBy,
    pageIndex,
  });

  return [healthCoachesTable, loading, refetch, total];
};

const HealthCoaches = (): React.ReactElement => {
  const history = useHistory();
  const [healthCoachesTable, loading, refetch, total] = useHealthCoachesTable();
  const selectedRows = healthCoachesTable.selectedFlatRows;
  return (
    <div>
      <div className="flex flex-row w-1/3 justify-end float-right">
        {!!selectedRows.length && (
          <div className="w-auto pr-4">
            <DeleteManyUsersButton
              table="User"
              ids={selectedRows.map((row) => row.original.id)}
              onDeleted={refetch}
            />
          </div>
        )}
        <div className="w-auto">
          <Link to={routes.createHealthCoach}>
            <Button fullWidth variant="outline">
              Create New
            </Button>
          </Link>
        </div>
      </div>
      <div className="pt-16">
        <UserFilter />
      </div>
      <div
        className={clsx({ 'visually-hidden': !healthCoachesTable.page.length })}
      >
        <Table tableInstance={healthCoachesTable}>
          <TableHead />
          <TableBody>
            <React.Fragment>
              {healthCoachesTable.page?.map((row) => {
                healthCoachesTable.prepareRow(row);
                return (
                  <TableRow row={row} key={row.id}>
                    <React.Fragment>
                      {row.cells.map((cell) => (
                        <TableCell
                          key={`${cell.row.original.id}-${cell.column.id}`}
                          cell={cell}
                          onClick={(): void => {
                            if (cell.column.id !== 'selection') {
                              history.push(
                                `${routes.healthCoaches}/${cell.row.original.id}`,
                              );
                            }
                          }}
                        />
                      ))}
                    </React.Fragment>
                  </TableRow>
                );
              })}
            </React.Fragment>
          </TableBody>
        </Table>
        <Pagination total={total} tableInstance={healthCoachesTable} />
      </div>
      {!healthCoachesTable.page.length && !loading && (
        <div className="text-center font-medium pt-8">
          No Health Coaches found
        </div>
      )}
      {loading && (
        <div className="flex justify-center text-lg">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default HealthCoaches;
