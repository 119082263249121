import { Input } from 'components/input';
import { useForm } from 'react-hook-form';
import React from 'react';
import { requiredValidation } from 'utils/form-validation';
import { TextArea } from 'components/text-area';
import { Dropdown } from 'components/dropdown';
import { isEmpty } from 'lodash';

export const RxMedicationFields = ({
  register,
  control,
  errors,
  defaultValues,
  medicationNumber,
  readOnly = true,
  manuallyAddProduct = false,
  isExtemp = false,
}: {
  register: ReturnType<typeof useForm>['register'];
  control: ReturnType<typeof useForm>['control'];
  errors: ReturnType<typeof useForm>['errors'];
  medicationNumber: number;
  defaultValues?: {
    productName: string;
    tradeName: string;
    genericName: string;
    itemForm: string;
    quantity?: string | null;
    instructions: string;
    itemStrength?: string | null;
    routeAdministration: string;
    routeAdministrationOptions: string[];
    mimsId: string;
  };
  readOnly?: boolean;
  manuallyAddProduct?: boolean;
  isExtemp?: boolean;
}): React.ReactElement => {
  // If there are multiple route administration options, show a dropdown element
  // unless the product is extemporaneous, then join them together and show in an input.
  const showRouteAdministrationDropdown =
    defaultValues &&
    defaultValues.routeAdministrationOptions &&
    defaultValues.routeAdministrationOptions.length > 1 &&
    !manuallyAddProduct &&
    !isExtemp;

  /**
   * If there is a single option, use it as the default.
   * If the product is extemporaneous, join the routes together.
   */
  const getDefaultRouteAdministration = (
    options: string[],
    isExtemp: boolean,
  ): string => {
    if (isEmpty(options)) {
      return '';
    }
    if (options.length === 1) {
      return options[0];
    }
    if (isExtemp) {
      return options.join(', ');
    }
    return '';
  };
  const defaultRouteAdministration = defaultValues
    ? getDefaultRouteAdministration(
        defaultValues.routeAdministrationOptions,
        isExtemp,
      )
    : '';
  return (
    <>
      <div>
        <Input
          label="Product Name"
          name={`erxMedicines[${medicationNumber}].productName`}
          ref={register(requiredValidation('Product Name'))}
          defaultValue={defaultValues?.productName}
          errorMessage={
            errors?.erxMedicines?.[medicationNumber]?.productName?.message
          }
          readOnly={readOnly}
        />
      </div>
      <div>
        <Input
          label="Trade Name"
          name={`erxMedicines[${medicationNumber}].tradeName`}
          ref={register(requiredValidation('Trade Name'))}
          defaultValue={defaultValues?.tradeName}
          errorMessage={
            errors?.erxMedicines?.[medicationNumber]?.tradeName?.message
          }
          readOnly={readOnly}
        />
      </div>
      <div>
        <Input
          label="Generic Name"
          name={`erxMedicines[${medicationNumber}].genericName`}
          ref={register(requiredValidation('Generic Name'))}
          defaultValue={defaultValues?.genericName}
          errorMessage={
            errors?.erxMedicines?.[medicationNumber]?.genericName?.message
          }
          readOnly={readOnly}
        />
      </div>
      <div>
        <Input
          label="Item Form"
          name={`erxMedicines[${medicationNumber}].itemForm`}
          ref={register(requiredValidation('Item Form'))}
          defaultValue={defaultValues?.itemForm}
          errorMessage={
            errors?.erxMedicines?.[medicationNumber]?.itemForm?.message
          }
          readOnly={readOnly}
        />
      </div>
      <div>
        <Input
          label="Item Strength"
          name={`erxMedicines[${medicationNumber}].itemStrength`}
          ref={register(requiredValidation('Item Strength'))}
          defaultValue={defaultValues?.itemStrength || ''}
          errorMessage={
            errors?.erxMedicines?.[medicationNumber]?.itemStrength?.message
          }
          readOnly={readOnly}
        />
      </div>
      <div>
        {showRouteAdministrationDropdown ? (
          <Dropdown
            label="Route of Administration"
            name={`erxMedicines[${medicationNumber}].routeAdministration`}
            options={(defaultValues?.routeAdministrationOptions || []).map(
              (roa) => ({
                label: roa,
                value: roa,
              }),
            )}
            control={control}
            defaultValue={defaultValues?.routeAdministration}
          />
        ) : (
          <Input
            label="Route of Administration"
            name={`erxMedicines[${medicationNumber}].routeAdministration`}
            ref={register(requiredValidation('Route Administration'))}
            defaultValue={defaultRouteAdministration}
            errorMessage={
              errors?.erxMedicines?.[medicationNumber]?.routeAdministration
                ?.message
            }
            readOnly={readOnly}
          />
        )}
      </div>
      <div>
        <TextArea
          label="Instructions"
          name={`erxMedicines[${medicationNumber}].instructions`}
          placeholder="Instructions"
          rows={5}
          ref={register(requiredValidation('Instructions'))}
          errorMessage={
            errors?.erxMedicines?.[medicationNumber]?.instructions?.message
          }
          defaultValue={defaultValues?.instructions}
        />
      </div>
      <div>
        <Input
          label="Quantity"
          name={`erxMedicines[${medicationNumber}].quantity`}
          ref={register(requiredValidation('Quantity'))}
          defaultValue={defaultValues?.quantity || ''}
          errorMessage={
            errors?.erxMedicines?.[medicationNumber]?.quantity?.message
          }
        />
      </div>
    </>
  );
};
