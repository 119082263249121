import { gql, useQuery } from '@apollo/client';
import { Loading } from 'components/loading';
import { ChatUserQuery, UserWhereUniqueInput } from 'graphql/types';
import { useParams } from 'react-router-dom';
import {
  Channel,
  MessageList,
  Thread,
  Window,
  useChatContext,
} from 'stream-chat-react';
import './chat-styles.css';
import CustomerDetails from 'components/customer-details';
import { CustomAttachment } from '../../components/stream/attachment';

const PAGE_QUERY = gql`
  query ChatUser($where: UserWhereUniqueInput!) {
    customer: user(where: $where) {
      id
      fullName
      firstName
      lastName
      email
      phone
      avatar {
        id
        url
      }
      chatThreads(type: HEALTH_COACH) {
        id
      }
    }
  }
`;

const Chat = (): React.ReactElement => {
  const { customerId } = useParams<{ customerId: string }>();
  const { data, loading } = useQuery<
    ChatUserQuery,
    {
      where: UserWhereUniqueInput;
    }
  >(PAGE_QUERY, {
    variables: {
      where: { id: customerId },
    },
    fetchPolicy: 'cache-first',
  });

  const { client } = useChatContext();

  const threadId = data?.customer?.chatThreads?.[0]?.id;

  if (loading) {
    return <Loading />;
  }

  if (!client) {
    return (
      <>
        No chat connection created. Please contact{' '}
        <a href="https://eucalyptus-vc.slack.com/archives/CVB1LPNQN">
          #help-engineering
        </a>
      </>
    );
  }

  if (!threadId) {
    return <>Patient does not have a chat</>;
  }

  const channel = client.getChannelById('messaging', threadId, {});

  return (
    <div className="w-full flex gap-10">
      <div className="w-9/12">
        <Channel
          channel={channel}
          Attachment={(props) => (
            <CustomAttachment customerId={customerId} {...props} />
          )}
        >
          <Window>
            <MessageList />
          </Window>
          <Thread />
        </Channel>
      </div>
      {data?.customer && (
        <div className="w-3/12">
          <CustomerDetails
            customer={data.customer}
            problemType={'WEIGHT_LOSS'}
          />
        </div>
      )}
    </div>
  );
};

export default Chat;
