import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { ChatProviderQuery } from 'graphql/types';
import { Chat } from 'stream-chat-react';
import { StreamChat } from 'stream-chat';
import { logger } from 'logging';
import { config } from 'config';
import { useAuth } from 'auth';

export const ChatProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const auth = useAuth();
  const { data } = useQuery<ChatProviderQuery>(
    gql`
      query ChatProvider {
        readOnlyChatToken
        profile {
          id
        }
      }
    `,
    {
      skip: !auth.accessToken,
    },
  );

  const [client, setClient] = useState<StreamChat>();

  useEffect(() => {
    const key = config.streamAppKey;
    if (!key || !data?.profile?.id || !data?.readOnlyChatToken) {
      return;
    }

    const newClient = new StreamChat(key, {
      timeout: 10000,
    });

    const handleConnectionChange = ({ online = false }): void => {
      if (!online) return logger.info('connection lost');
      setClient(newClient);
    };

    newClient.on('connection.changed', handleConnectionChange);

    newClient
      .connectUser(
        {
          id: data.profile.id,
        },
        data?.readOnlyChatToken,
      )
      .then(() => {
        setClient(newClient);
      });

    return (): void => {
      newClient.off('connection.changed', handleConnectionChange);
      newClient.disconnectUser().then(() => logger.info('connection closed'));
    };
  }, [data?.profile?.id, data?.readOnlyChatToken]);

  if (!client) {
    return <>{children}</>;
  }

  return <Chat client={client}>{children}</Chat>;
};
