import { gql, useQuery } from '@apollo/client';
import {
  AuditLogsByIdsQuery,
  AuditLogsByIdsQueryVariables,
} from 'graphql/types';

export const useAuditLogs = ({
  skip,
  targetIds,
}: {
  skip: boolean;
  targetIds: string[];
}): { loading: boolean; data: AuditLogsByIdsQuery | undefined } => {
  const { data, loading } = useQuery<
    AuditLogsByIdsQuery,
    AuditLogsByIdsQueryVariables
  >(
    gql`
      query AuditLogsByIds($targetIds: [String!]) {
        auditLogs(
          orderBy: { createdAt: asc }
          where: { targetId: { in: $targetIds } }
        ) {
          action
          createdAt
          details
          id
          targetId
          targetType
          user {
            id
            role
            lastName
            firstName
            fullName
          }
        }
      }
    `,
    {
      variables: {
        targetIds,
      },
      skip,
    },
  );
  return { loading, data };
};
