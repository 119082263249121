import { ReactElement } from 'react';
import { problemTypeOptions } from 'utils/dropdown-options';
import { requiredValidation } from 'utils/form-validation';
import { Label } from 'components/label';
import { ControlledFileUpload } from 'components/file-upload';
import { TextArea } from 'components/text-area';
import Creatable from 'react-select/creatable';
import { ValueType } from 'react-select';
import { Input } from 'components/input';
import { Controller, UseFormMethods } from 'react-hook-form';
import { ProblemType } from 'graphql/types';
import { Dropdown } from 'components/dropdown';

export type SharedFormValues = {
  friendlyName: string;
  advertisedName: string;
  description: string;
  problemTypes: ProblemType[];
  photo: string;
  contents: { icon: string; description: string; tooltip: string | null }[];
  advertisedShippingCadence: string;
  advertisedPrice: {
    cost: number;
    cadenceDescription: string;
    header: string;
    qualifier: string;
  };
  tags: { label: string; value: string }[];
};

type Props = {
  disableProductTypes?: boolean;
  defaultTagOptions?: { label: string; value: string }[];
} & Pick<UseFormMethods<SharedFormValues>, 'register' | 'control' | 'errors'>;

const SharedOfferingForm = ({
  register,
  control,
  errors,
  disableProductTypes,
  defaultTagOptions,
}: Props): ReactElement => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-4">
        <div className="flex flex-col gap-2 w-1/2 p-2">
          <Input
            name="friendlyName"
            label="Friendly Name"
            errorMessage={errors.friendlyName?.message}
            ref={register(requiredValidation('friendlyName'))}
            tooltipProps={{
              hoverText:
                'The common name of the offering. i.e. Weight Reset Program',
            }}
          />
          <Input
            name="advertisedName"
            label="Advertised Name"
            errorMessage={errors.advertisedName?.message}
            ref={register(requiredValidation('advertisedName'))}
            tooltipProps={{
              hoverText:
                'Used to distinguish offering superiority amongst tiered offerings. i.e. Progress Plus',
            }}
          />
          <TextArea
            name="description"
            label="Description"
            rows={3}
            placeholder=""
            errorMessage={errors.description?.message}
            ref={register(requiredValidation('description'))}
          />
          <Dropdown
            name="problemTypes"
            label="Problem Types"
            isMulti
            disabled={disableProductTypes}
            errorMessage={errors.problemTypes
              ?.map((ptErr) => ptErr?.message)
              .join('. ')}
            options={problemTypeOptions}
            control={control}
            rules={requiredValidation('problemTypes')}
            tooltipProps={{
              hoverText:
                'These are the problem types that this offering will be available on',
            }}
          />
          <div>
            <div className="mb-3 flex flex-col gap-1">
              <Label
                htmlFor={'tags'}
                tooltip={{
                  hoverText:
                    'Add additional functionality or information to an offering.',
                }}
              >
                Tags
              </Label>
            </div>
            <Controller
              defaultValue={[]}
              render={({ value, onChange, onBlur, name, ref }) => (
                <Creatable
                  isMulti={true}
                  inputId="tags"
                  options={defaultTagOptions ?? []}
                  className="dropdown"
                  classNamePrefix="dd"
                  innerRef={ref}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={(v: ValueType<{ value: string }, true>): void => {
                    if (v === null || v === undefined) {
                      onChange([]);
                    } else {
                      onChange(v);
                    }
                  }}
                />
              )}
              name="tags"
              control={control}
            />
          </div>
        </div>

        <div className="w-1/2">
          <ControlledFileUpload
            label="Photo"
            control={control}
            name="photo"
            bucket="ASSETS"
            errorMessage={errors.photo?.message}
            rules={requiredValidation('photo')}
          />
        </div>
      </div>
      <div className="flex">
        <div className="flex flex-col gap-2 w-1/2 p-2">
          <Input
            name="advertisedPrice.header"
            label="Advertised Price Header"
            errorMessage={errors.advertisedPrice?.header?.message}
            placeholder="Starting at"
            ref={register(requiredValidation('advertisedPrice.header'))}
          />
          <Input
            name="advertisedPrice.cost"
            type="number"
            label="Cost in base currency unit (e.g. cents, pence, yen)"
            errorMessage={errors.advertisedPrice?.cost?.message}
            placeholder="10000"
            ref={register(requiredValidation('advertisedPrice.cost'))}
          />
          <Input
            name="advertisedPrice.cadenceDescription"
            label="Cadence (e.g. Day, 28 days, Month)"
            errorMessage={errors.advertisedPrice?.cadenceDescription?.message}
            placeholder="Month"
            ref={register()}
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2 p-2">
          <Input
            name="advertisedShippingCadence"
            label="Advertised Shipping Cadence"
            placeholder="You'll be shipped your medication every 28 days"
            errorMessage={errors.advertisedShippingCadence?.message}
            ref={register(requiredValidation('advertisedShippingCadence'))}
          />
          <Input
            name="advertisedPrice.qualifier"
            label="Advertised Price Qualifier"
            placeholder="Includes any dosage up to 1.0mg"
            errorMessage={errors.advertisedPrice?.qualifier?.message}
            ref={register(requiredValidation('advertisedPrice.qualifier'))}
          />
        </div>
      </div>
    </div>
  );
};

export default SharedOfferingForm;
