import React from 'react';
import clsx from 'clsx';
import { FaCaretRight } from 'react-icons/fa';

export const LogsAccordion = ({
  title,
  children,
}: {
  title?: string;
  children?: React.ReactChild;
}): JSX.Element => {
  const [open, setOpen] = React.useState(false);

  const caretClass = clsx(
    'transition-transform transform duration-300 ease-in-out scale-150 text-gray-600',
    { 'rotate-90': open },
  );

  const accordionClass = clsx(
    'transition-all ease-in-out duration-200 overflow-hidden',
    open ? 'max-h-max' : 'max-h-0',
  );

  const wrapperClass = clsx(
    'flex justify-between items-center cursor-pointer',
    title || open ? 'border-b border-gray-200' : '',
    title ? 'p-4' : '',
  );

  return (
    <div>
      <div className={wrapperClass} onClick={(): void => setOpen(!open)}>
        {title && (
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {title}
          </h3>
        )}
        <div className={caretClass}>
          <FaCaretRight />
        </div>
      </div>
      <div className={accordionClass}>
        <div>{children}</div>
      </div>
    </div>
  );
};
