import { gql, useQuery } from '@apollo/client';
import { PathologyRequest } from 'graphql/types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import { routes } from 'utils/routes';
import { Loading } from '../../components/loading';
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useOrderBy,
  usePageIndex,
  usePaginatingSortingTable,
} from '../../components/table';
import { Tag } from '../../components/tag';
import {
  formatDate,
  formatPatientName,
  getPathologyStatusColor,
  getProblemTypeColor,
  getProblemTypeEmoji,
} from '../../utils/misc';
import {
  PathologiesFilter,
  useBuildPathologiesQueryFilter,
} from './pathologies-filter';

const columns: Column<PathologyRequest>[] = [
  {
    Header: 'Reference no',
    accessor: 'reference',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-1/7',
  },
  {
    Header: 'Name',
    accessor: 'patient',
    Cell: (c) => (
      <div>
        {c.row.original.patient && formatPatientName(c.row.original.patient)}
      </div>
    ),
    className: 'w-1/7',
    disableSortBy: true,
  },
  {
    Header: 'Practitioner',
    accessor: 'doctor',
    Cell: (c) => (
      <div>
        {c.row.original.doctor ? c.row.original.doctor.clinicianName : ''}
      </div>
    ),
    className: 'w-1/7',
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: (c) => (
      <Tag size="small" color={getPathologyStatusColor(c.row.original?.status)}>
        {c.row.original?.status}
      </Tag>
    ),
    className: 'w-1/7',
    disableSortBy: true,
  },
  {
    Header: 'Created',
    accessor: 'createdAt',
    Cell: (c) => <div>{c.value ? formatDate(new Date(c.value)) : ''}</div>,
    className: 'w-1/7',
  },
  {
    Header: 'Tested at',
    Cell: (c) => {
      const testedAt =
        c.row.original.result?.collectedAt ||
        c.row.original.customerStatedCollectedAt;

      return <div>{testedAt ? formatDate(new Date(testedAt)) : ''}</div>;
    },
    className: 'w-1/7',
  },
  {
    Header: 'Type',
    accessor: 'consultation',
    Cell: (c) => (
      <Tag
        size="small"
        color={getProblemTypeColor(c.row.original.consultation?.type)}
      >
        <span role="img" className="pr-1">
          {getProblemTypeEmoji(c.row.original.consultation?.type)}
        </span>
        {c.row.original.consultation?.type}
      </Tag>
    ),
    className: 'w-1/7',
  },
];

const TABLE_PAGE_SIZE = 30;

const Pathologies = (): React.ReactElement => {
  const activeFilter = useBuildPathologiesQueryFilter();

  const orderBy = useOrderBy({ defaultValue: 'createdAt_desc' });
  const pageIndex = usePageIndex();
  const history = useHistory();

  const { data, loading, error } = useQuery<
    { pathologyRequests: PathologyRequest[]; pathologyCount: number },
    {
      pageSize: number;
      skip: number;
    }
  >(
    gql`
      query pathologyRequests(
        $search: String
        $pageSize: Int
        $skip: Int
        $type: ProblemType
        $status: PathologyRequestStatus
      ) {
        pathologyRequests(
          search: $search
          pageSize: $pageSize
          skip: $skip
          type: $type
          status: $status
        ) {
          id
          status
          reference
          patient {
            id
            firstName
            lastName
            fullName
          }
          doctor {
            id
            firstName
            lastName
            fullName
            clinicianName
          }
          consultation {
            id
            type
          }
          result {
            id
            collectedAt
          }
          createdAt
          customerStatedCollectedAt
        }
        pathologyCount: pathologyRequestCount(
          search: $search
          type: $type
          status: $status
        )
      }
    `,
    {
      variables: {
        ...activeFilter,
        pageSize: TABLE_PAGE_SIZE,
        skip: pageIndex * TABLE_PAGE_SIZE,
      },
    },
  );
  const total = data?.pathologyCount ?? 0;
  const pageNumber = Math.ceil((total ?? 1) / TABLE_PAGE_SIZE);

  const pathologyRequests = React.useMemo(
    () => data?.pathologyRequests ?? [],
    [data],
  );

  const tableInstance = usePaginatingSortingTable({
    columns,
    data: pathologyRequests,
    pageNumber,
    orderBy,
    pageIndex,
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <section>
      <PathologiesFilter />
      {!error && data?.pathologyRequests ? (
        <div>
          <Table tableInstance={tableInstance}>
            <TableHead />
            <TableBody>
              <React.Fragment>
                {tableInstance.page?.map((row) => {
                  tableInstance.prepareRow(row);
                  return (
                    <TableRow row={row} key={row.id}>
                      <React.Fragment>
                        {row.cells.map((cell) => (
                          <TableCell
                            key={`${cell.row.original.id}-${cell.column.id}`}
                            cell={cell}
                            onClick={(): void => {
                              history.push(
                                `${routes.pathologies}/${cell.row.original.id}`,
                              );
                            }}
                          />
                        ))}
                      </React.Fragment>
                    </TableRow>
                  );
                })}
              </React.Fragment>
            </TableBody>
          </Table>
          <Pagination total={total} tableInstance={tableInstance} />
        </div>
      ) : (
        <div>No matching pathology requests were found</div>
      )}
    </section>
  );
};

export default Pathologies;
