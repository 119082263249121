import { gql, useMutation } from '@apollo/client';
import { ProblemType } from 'graphql/types';
import { Button } from 'components/button';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Checkbox } from '../../components/checkbox';
import { useNotifications } from '../../notifications';

const reactivateMutation = gql`
  mutation reactivate($userId: String!, $type: ProblemType!, $free: Boolean) {
    reactivateMembership(userId: $userId, type: $type, free: $free) {
      id
    }
  }
`;

export const ReactivateModal = ({
  userId,
  type,
  onReactivated,
  close,
}: {
  userId: string;
  type: ProblemType;
  onReactivated: () => void;
  close: () => void;
}): React.ReactElement => {
  const showNotification = useNotifications();
  const { register, handleSubmit } = useForm<{
    free: boolean;
  }>();

  const [reactivate, { loading: reactivateLoading }] =
    useMutation<void>(reactivateMutation);
  return (
    <div className="bg-white rounded p-4">
      <p className="mb-3">
        Are you sure you want to reactivate the patient&apos;s membership?
      </p>
      <form
        onSubmit={handleSubmit(async (data) => {
          try {
            await reactivate({
              variables: {
                userId,
                type,
                free: !!data.free,
              },
            });

            onReactivated();
          } catch (err) {
            showNotification({
              message:
                err instanceof Error ? err.message : 'Something went wrong',
              type: 'error',
            });
          }
        })}
      >
        <div className="flex items-center">
          <Checkbox
            name="free"
            ref={register()}
            label={'Do you want to reactivate for free?'}
          />
        </div>

        <div className="pt-4 flex flex-row space-x-3">
          <Button fullWidth type="submit" loading={reactivateLoading}>
            Reactivate Membership
          </Button>
          <Button
            fullWidth
            variant={'outline'}
            loading={reactivateLoading}
            onClick={close}
          >
            Close
          </Button>
        </div>
      </form>
    </div>
  );
};
