import { gql, useMutation } from '@apollo/client';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import {
  CancelPurchaseMutation,
  CancelPurchaseMutationVariables,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import React from 'react';
import { FaRegTimesCircle } from 'react-icons/fa';

type Props = {
  disabled: boolean;
  purchaseId: string;
};

export const CancelButton: React.FC<Props> = ({ disabled, purchaseId }) => {
  const showNotification = useNotifications();
  const [showModal, setShowModal] = React.useState(false);
  const [cancelPurchase, { loading }] = useMutation<
    CancelPurchaseMutation,
    CancelPurchaseMutationVariables
  >(
    gql`
      mutation CancelPurchase($input: CancelPurchaseInput!) {
        cancelPurchase(input: $input) {
          purchase {
            id
            status
            contexts {
              id
              status
            }
          }
        }
      }
    `,
    {
      refetchQueries: ['Purchase'],
      onCompleted: () => {
        setShowModal(false);
        showNotification({
          type: 'success',
          message: 'Purchase cancelled',
        });
      },
      onError: () => {
        showNotification({
          type: 'error',
          message: 'Unable to cancel purchase',
        });
      },
    },
  );

  return (
    <>
      <button
        type="button"
        className="inline-flex disabled:cursor-not-allowed disabled:opacity-25 items-center px-4 py-2 border bg-primary-100 border-primary-500 text-primary text-sm leading-5 font-medium rounded hover:bg-primary-200 transition duration-150 ease-in-out"
        disabled={disabled || loading}
        onClick={(): void => setShowModal(true)}
      >
        <FaRegTimesCircle className="mr-2" />
        Cancel Purchase
      </button>

      {showModal && (
        <Modal
          show
          onClose={(): void => setShowModal(false)}
          isAutoOverflow={false}
        >
          <div className="bg-white shadow overflow-hidden rounded px-4 py-5 max-w-lg mr-auto ml-auto space-y-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Cancel purchase
            </h3>
            <p>
              A cancelled purchased cannot be resumed. Are you sure you want to
              cancel this purchase?
            </p>

            <div className="flex space-x-4 bg-gray-100">
              <Button
                fullWidth
                variant="outline"
                disabled={loading}
                onClick={(): void => setShowModal(false)}
              >
                Close
              </Button>
              <Button
                fullWidth
                color="danger"
                loading={loading}
                onClick={(): void => {
                  cancelPurchase({
                    variables: {
                      input: {
                        purchaseId,
                      },
                    },
                  });
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
