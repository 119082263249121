import { Maybe, QuizFlagLevel, TrackerMeta } from 'graphql/types';
import { formatDateAndTime } from 'utils/misc';
import { TrackerMetric } from '../types';

export const getQuizFlagLevel = (
  meta: Maybe<TrackerMeta>,
): QuizFlagLevel | undefined => {
  if (!meta) {
    return;
  }

  if (meta.deletedAt) {
    return 'CRITICAL';
  }

  if (meta.overrides?.length) {
    return 'MODERATE';
  }
};

export const getQuestionCopyForMetric = (metric: TrackerMetric): string => {
  switch (metric) {
    case 'WEIGHT':
      return 'What is your body weight?';
    case 'WAIST_SIZE':
      return 'What is your waist size?';
  }
};

export const getMetaKeyForMetric = (
  metric: TrackerMetric,
): 'bodyWeightMeta' | 'waistSizeMeta' | undefined => {
  switch (metric) {
    case 'WEIGHT':
      return 'bodyWeightMeta';
    case 'WAIST_SIZE':
      return 'waistSizeMeta';
  }
};

export const getKeyForMetric = (
  metric: TrackerMetric,
): 'bodyWeight' | 'waistSize' => {
  switch (metric) {
    case 'WEIGHT':
      return 'bodyWeight';
    case 'WAIST_SIZE':
      return 'waistSize';
  }
};

export const getLatestUpdatedAt = (
  meta: Maybe<TrackerMeta>,
  metric: TrackerMetric,
): string | undefined => {
  if (!meta) {
    return;
  }

  const metaKey = getMetaKeyForMetric(metric);

  if (!metaKey) {
    return;
  }
  const latestOverrideDeletion = meta.overrides?.[0]?.[metaKey]?.deletedAt;

  if (!latestOverrideDeletion) {
    return;
  }

  return formatDateAndTime(latestOverrideDeletion);
};

export const getDeletedAt = (meta: Maybe<TrackerMeta>): string | undefined => {
  if (!meta?.deletedAt) {
    return;
  }

  return formatDateAndTime(meta.deletedAt);
};

export const getUnitForMetric = (metric: TrackerMetric): 'kg' | 'cm' => {
  switch (metric) {
    case 'WEIGHT':
      return 'kg';
    case 'WAIST_SIZE':
      return 'cm';
  }
};
