import React from 'react';
import { Dropdown, DropdownProps } from './dropdown';

const AVAILABLE_DELIVERY_BUFFERS_OPTIONS: {
  value: string;
  label: string;
}[] = [
  {
    value: '2',
    label: '2 days (Can not be sent by Auspost eparcel)',
  },
  {
    value: '5',
    label: '5 days (Refill can be sent by Auspost eparcel if possible)',
  },
];

type Props = Omit<DropdownProps, 'options' | 'isMulti' | 'handleSingleSelect'>;

export const DeliveryBufferSelect = (props: Props): JSX.Element => (
  <Dropdown {...props} options={AVAILABLE_DELIVERY_BUFFERS_OPTIONS} />
);
