import React from 'react';
import clsx from 'clsx';

export const Card = ({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}): React.ReactElement => {
  return (
    <div className={clsx('bg-white shadow rounded', className)}>{children}</div>
  );
};
