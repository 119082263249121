import React from 'react';
import { PathologyRequest } from 'graphql/types';

const PathologyResultsReleaseWarning = ({
  pathologyRequest,
}: {
  pathologyRequest: PathologyRequest;
}): React.ReactElement => {
  const consultation = pathologyRequest?.consultation;
  const treatment = consultation?.treatment;
  const consultationDoctor = consultation?.doctor;
  const pathologyRequestDoctor = pathologyRequest?.doctor;

  const defaultDoctor = consultationDoctor || pathologyRequestDoctor;

  const doctorName = defaultDoctor
    ? defaultDoctor.clinicianName
    : 'the requesting or reviewing practitioner';

  return (
    <div className="space-y-3">
      <p className="font-bold uppercase">⚠️ Warning</p>
      <p>
        We are not able to release results to a practitioner in the
        patient&apos;s current state:
      </p>
      <p>
        <span className="block">
          Consultation status: {consultation?.status ?? '-'}
        </span>
        <span className="block">
          Treatment status: {treatment?.status ?? '-'}
        </span>
      </p>
      <p>
        Once you save the results and they are ready to be reviewed, you must
        manually notify {doctorName}.
      </p>
    </div>
  );
};

export default PathologyResultsReleaseWarning;
