import React from 'react';
import photoRedactedPlaceholder from '../../../../components/assets/photo-redacted-placeholder.png';

const ProgressEntryImage = ({
  imageId,
}: {
  imageId: string;
}): React.ReactElement => (
  <div className="w-full">
    {imageId === 'redacted.png' ? (
      <img src={photoRedactedPlaceholder} alt="Photo Redacted" />
    ) : (
      <img
        src={imageId}
        className="w-full  bg-gray-200 rounded-md bg-cover bg-center bg-no-repeat"
      />
    )}
  </div>
);

export default ProgressEntryImage;
