import { Button } from 'components/button';
import { useState } from 'react';
import { AddAddendumModal, AddAddendumModalProps } from './add-addendum-modal';

export const AddAddendumModalButton: React.FC<
  Omit<AddAddendumModalProps, 'show' | 'onClose'>
> = (props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button size="small" variant="outline" onClick={() => setShowModal(true)}>
        Add Addendum
      </Button>
      <AddAddendumModal
        show={showModal}
        onClose={() => setShowModal(false)}
        {...props}
      />
    </>
  );
};
