import { Button } from 'components/button';
import React, { ReactNode } from 'react';

type ModalLayoutProps = {
  back: () => void;
  backText?: string;
  next: () => void;
  nextText?: string;
  nextDisabled?: boolean;
  children: ReactNode;
};

export const ModalLayout: React.FC<ModalLayoutProps> = ({
  back,
  backText,
  next,
  nextText,
  nextDisabled,
  children,
}) => {
  return (
    <div className="w-full">
      {children}
      <div className="w-full flex flex-row gap-4 mt-4">
        <div className="flex-1">
          <Button fullWidth variant="outline" type="button" onClick={back}>
            {backText ?? 'Back'}
          </Button>
        </div>
        <div className="flex-1">
          <Button
            fullWidth
            type="button"
            onClick={next}
            disabled={nextDisabled}
          >
            {nextText ?? 'Next'}
          </Button>
        </div>
      </div>
    </div>
  );
};
