import React from 'react';
import { OrdersTableItem } from './types';
import { Link } from 'react-router-dom';
import { routes } from '../../utils/routes';
import {
  formatDateAndTime,
  statusColorBackground,
  statusColorText,
  upperSnakeCaseToCapitalCase,
} from '../../utils/misc';
import clsx from 'clsx';

export const OrdersTable = ({
  completedOrders,
  title = 'Completed Orders',
  showConsultColumn = true,
  description,
}: {
  completedOrders: OrdersTableItem[];
  title?: string;
  description?: string;
  showConsultColumn?: boolean;
}): React.ReactElement => {
  const columns = [
    'ID',
    'Created',
    'Updated',
    'Product',
    'Status',
    'Fulfillment',
    'Payment',
  ];
  if (showConsultColumn) {
    columns.push('Consult');
  }

  return (
    <div className="border-t border-gray-200">
      <div className="px-4 py-5 border-b border-gray-200 space-y-2">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {title}
          <span className="ml-2 text-gray-500">{completedOrders.length}</span>
        </h3>
        {description && <p className="text-xs">{description}</p>}
      </div>
      <div>
        <div className="-mt-2 overflow-x-auto">
          <div className="pt-2 min-w-full">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  {columns.map((h) => (
                    <th
                      key={h}
                      className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {h}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {completedOrders.length === 0 && 'No completed orders'}
                {completedOrders.map((o) => (
                  <tr key={o.id}>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-sm leading-5 text-gray-500">
                        <Link to={`${routes.orders}/${o.id}`}>{o.id}</Link>
                      </div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="text-sm leading-5 text-gray-900">
                          {formatDateAndTime(o.created)}
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="text-sm leading-5 text-gray-900">
                          {formatDateAndTime(o.updated)}
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-sm leading-5 text-gray-900">
                        {o.product}
                      </div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <span
                        className={clsx(
                          `px-2 inline-flex text-xs leading-5 font-semibold rounded-full`,
                          statusColorBackground(o.status || ''),
                          statusColorText(o.status || ''),
                        )}
                      >
                        {o.status
                          ? upperSnakeCaseToCapitalCase(o.status)
                          : 'Unknown'}
                      </span>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <span
                        className={clsx(
                          `px-2 inline-flex text-xs leading-5 font-semibold rounded-full`,
                          statusColorText(o.fulfillment || ''),
                          statusColorBackground(o.fulfillment || ''),
                        )}
                      >
                        {o.fulfillment
                          ? upperSnakeCaseToCapitalCase(o.fulfillment)
                          : 'Unknown'}
                      </span>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-sm leading-5 text-gray-900">
                        {o.gateway}
                      </div>
                    </td>
                    {showConsultColumn && (
                      <td className="px-6 py-2 whitespace-nowrap text-sm leading-5 text-gray-500">
                        <Link to={`/consultations/${o.consultId}`}>
                          {o.consultId}
                        </Link>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
