import { ConsultationQuestionAttachment } from 'graphql/types';
import { ReactElement } from 'react';

export const Media = ({
  content,
}: {
  content: ConsultationQuestionAttachment;
}): ReactElement | null => {
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types#structure_of_a_mime_type
  const [type] = content.mimetype.split('/');

  if (type === 'video' && content.signedUrl) {
    return <video controls src={content.signedUrl} className="w-full h-64" />;
  }

  return (
    <p>
      Unsupported media type: {content.mimetype}, of id: {content.id}
    </p>
  );
};
