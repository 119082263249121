import { gql, useMutation } from '@apollo/client';
import {
  FileCreateWithoutAvatarOfInput,
  FileCreateWithoutSignatureOfInput,
  RevalidateHpiiMutation,
  RevalidateHpiiMutationVariables,
  CreateDoctorMutation,
  CreateDoctorMutationVariables,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'utils/routes';
import { Fields } from './types';
import ClinicianForm from './clinician-form';

const createDoctorMutation = gql`
  mutation createDoctor(
    $firstName: String
    $lastName: String
    $phone: String
    $bio: String
    $email: String!
    $role: UserRole!
    $address: AddressCreateNestedOneWithoutUserInput
    $provider: ProviderCreateNestedOneWithoutUserInput
    $avatar: FileCreateNestedOneWithoutAvatarOfInput
  ) {
    createOneUser(
      data: {
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        email: $email
        role: $role
        bio: $bio
        address: $address
        provider: $provider
        avatar: $avatar
      }
    ) {
      id
    }
  }
`;

const mapFieldsToGraphQLVariables = async (
  fields: Fields,
): Promise<CreateDoctorMutationVariables> => {
  const variables: CreateDoctorMutationVariables = {
    firstName: fields.firstName,
    lastName: fields.lastName,
    phone: fields.phone,
    bio: fields.bio,
    email: fields.emailAddress,
    role: 'DOCTOR',
    address: {
      create: {
        line1: fields.line1,
        line2: fields.line2,
        city: fields.city,
        postalCode: fields.postalCode,
        state: fields.state,
        country: fields.country,
      },
    },
    provider: {
      create: {
        providerNumber: fields.providerNumber,
        prescriberNumber: fields.prescriberNumber,
        qualifications: fields.qualifications,
        ahpraNumber: fields.ahpraNumber,
        introduction: fields.introduction,
        erxEntityId: fields.erxEntityId,
        clinicianType: fields.clinicianType,
        clinicianTitle: fields.clinicianTitle,
      },
    },
  };
  if (fields.avatar) {
    variables.avatar = {
      create: JSON.parse(fields.avatar) as FileCreateWithoutAvatarOfInput,
    };
  }
  if (fields.signature && variables.provider?.create) {
    variables.provider.create.signature = {
      create: JSON.parse(fields.signature) as FileCreateWithoutSignatureOfInput,
    };
  }
  return variables;
};

const CreateDoctor = (): React.ReactElement => {
  const showNotification = useNotifications();
  const history = useHistory();

  const [revalidateHpiiMutation] = useMutation<
    RevalidateHpiiMutation,
    RevalidateHpiiMutationVariables
  >(
    gql`
      mutation RevalidateHpii($doctorId: String!) {
        revalidateHpii(doctorId: $doctorId) {
          id
          healthcareProviderIdentifer {
            id
            status
            hpii
            createdAt
          }
        }
      }
    `,
    {
      onError: (errors) => {
        showNotification({
          type: 'error',
          message: errors.graphQLErrors[0].message,
        });
      },
    },
  );
  const [createDoctor, { loading }] = useMutation<
    CreateDoctorMutation,
    CreateDoctorMutationVariables
  >(createDoctorMutation, {
    onCompleted: async ({ createOneUser: doctor }) => {
      showNotification({
        type: 'success',
        message: 'Practitioner created successfully',
      });

      await revalidateHpiiMutation({
        variables: {
          doctorId: doctor.id,
        },
      });

      history.push(`${routes.clinicians}/${doctor.id}`);
    },
    onError: () =>
      showNotification({
        type: 'error',
        message: 'There was an error attempting to create this practitioner',
      }),
  });
  const onSubmit = async (fields: Fields): Promise<void> => {
    await createDoctor({
      variables: await mapFieldsToGraphQLVariables(fields),
    });
  };

  return (
    <ClinicianForm onSubmit={onSubmit} submitting={loading} type="create" />
  );
};
export default CreateDoctor;
