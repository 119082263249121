import { gql, useQuery } from '@apollo/client';
import {
  CanRestartInitialQuizApplicationQuery,
  CanRestartInitialQuizApplicationQueryVariables,
  ProblemType,
} from 'graphql/types';
import { ActionButton } from 'components/actionButton';
import { Customer, CustomerConsultationView } from 'pages/customer/types';
import { RequestReviewButton } from 'pages/customer/request-review/button';
import { ReopenLastConsultationButton } from 'pages/customer/reopen-last-consultation/button';

const restartInitialQuizApplicationDocument = gql`
  mutation restartInitialQuizApplication(
    $problemType: ProblemType!
    $patientId: String!
  ) {
    restartInitialQuizApplication(
      problemType: $problemType
      patientId: $patientId
    ) {
      id
    }
  }
`;

export const ConsultationActions = ({
  customer,
  problemType,
  consultations,
  refetchCustomer,
}: {
  problemType: ProblemType;
  customer: Customer;
  consultations: CustomerConsultationView[];
  refetchCustomer: () => void;
}): React.ReactElement | null => {
  const { data, loading } = useQuery<
    CanRestartInitialQuizApplicationQuery,
    CanRestartInitialQuizApplicationQueryVariables
  >(
    gql`
      query canRestartInitialQuizApplication(
        $customerId: String!
        $problemType: ProblemType!
      ) {
        canRestartInitialQuizApplication(
          customerId: $customerId
          problemType: $problemType
        )
      }
    `,
    {
      variables: {
        customerId: customer.id,
        problemType,
      },
    },
  );

  const mostRecentConsult: CustomerConsultationView | undefined = [
    ...consultations,
  ].sort(
    (a, b) => (b.createdAt?.getTime() || 0) - (a.createdAt?.getTime() || 0),
  )[0];

  if (!mostRecentConsult) {
    return null;
  }

  const canRestartInitialQuizApplication =
    data?.canRestartInitialQuizApplication && !loading;
  const canCreateReviewConsultation =
    customer.conditions?.find((c) => c.problemType === problemType)
      ?.canCreateReviewConsultation ?? false;
  const canReopenLastConsultation =
    customer.conditions?.find((c) => c.problemType === problemType)
      ?.reopenableConsultation?.id === mostRecentConsult.id;

  const actionButtons: ActionButton[] = [
    {
      label: '♻️ Restart initial consult',
      disabled: !canRestartInitialQuizApplication,
      mutationArgs: { patientId: customer.id, problemType },
      refetchCustomer,
      confirmMsg: (
        <>
          <h1 className="heading-md mb-5">Restart initial consult</h1>
          <p>
            This will create a new initial quiz for the patient to complete.
          </p>
          <p className="font-bold">
            Please confirm this action with{' '}
            {mostRecentConsult.doctor ?? "the patient's practitioner"} before
            proceeding.
          </p>
        </>
      ),
      successMsg: 'Consult restarted',
      mutationGql: restartInitialQuizApplicationDocument,
    },
  ];

  return (
    <div className="flex justify-end gap-2 p-2 border-t border-gray-200">
      {actionButtons.map(
        ({
          label,
          disabled,
          mutationArgs,
          confirmMsg,
          successMsg,
          mutationGql,
        }) => (
          <ActionButton
            key={label}
            label={label}
            disabled={disabled}
            mutationArgs={mutationArgs}
            refetchCustomer={refetchCustomer}
            confirmMsg={confirmMsg}
            successMsg={successMsg}
            mutationGql={mutationGql}
          />
        ),
      )}
      {canCreateReviewConsultation && (
        <RequestReviewButton customer={customer} problemType={problemType} />
      )}
      {canReopenLastConsultation && (
        <ReopenLastConsultationButton
          patientFullName={customer.fullName}
          problemType={problemType}
          consultationId={mostRecentConsult.id}
        />
      )}
    </div>
  );
};
